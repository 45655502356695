import { Component } from 'react'
import { Card, Grid, Typography } from '@mui/material';
import IbssSwitchLabel, { LabelPlacementType } from '../../../../Components/Inputs/Switch/IbssSwitchLabel';
import { getUserPrefrenceJosn } from "../../../../Common/UserPreferenceJSON";
import { getAllBuildingsData, getBuildingNameUsingBuildingId } from '../../../../Common/Helper';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { appContext } from '../../../../AppContext';

class BuildingSpecificNotificationState {
    public BuildingSpecificNotification = getUserPrefrenceJosn();
    public isLoading = false;
    public buildingEmail = false;
    public buildingPush = false;
    public building = [{ NodeId: "", name: "", value: false }];

}

class BuildingSpecificNotification extends Component<any, BuildingSpecificNotificationState> {
    private labels = appContext().labels;
    private alert = appContext().alert;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider;

    constructor(props: any) {
        super(props)
        this.state = new BuildingSpecificNotificationState();
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        const response = this.local.getUserPreferences();
        this.setState({ isLoading: false, BuildingSpecificNotification: response });

        let buildList = [];

        if (response?.NotificationPrefs?.BuildingsPrefs && response?.NotificationPrefs?.BuildingsPrefs.length === 0) {
            buildList = getAllBuildingsData();
            buildList = buildList.map((building: any) => {
                return (
                    {
                        NodeId: building.Node_Id,
                        name: building.Name,
                        value: false,
                    }
                )
            });
        } else if (response?.NotificationPrefs?.BuildingsPrefs?.length === 1 && response.NotificationPrefs.BuildingsPrefs[0].NodeId === 0) {
            buildList = getAllBuildingsData();
            buildList = buildList.map((building: any) => {
                return (
                    {
                        NodeId: building.Node_Id,
                        name: building.Name,
                        value: false,
                    }
                )
            });
        } else {
            buildList = response?.NotificationPrefs?.BuildingsPrefs && response?.NotificationPrefs?.BuildingsPrefs.map((item: any) => {
                const buildName = getBuildingNameUsingBuildingId(item.NodeId);
                return {
                    NodeId: item.NodeId,
                    name: buildName,
                    value: item.Value === 0 ? false : true,
                }
            })
        }

        this.setState({ building: buildList });

    };

    public submit = async () => {
        this.setState({ isLoading: true })
        let nodes = this.state.BuildingSpecificNotification.Nodes;
        nodes[0] = { ...this.state.BuildingSpecificNotification.Nodes[0], }
        const buildingPref = this.state.building.map((item: any) => {
            return {
                NodeId: item.NodeId,
                Value: item.value === false ? 0 : 5
            }
        });
        this.setState({
            BuildingSpecificNotification: {
                ...this.state.BuildingSpecificNotification,
                NotificationPrefs: {
                    ...this.state.BuildingSpecificNotification.NotificationPrefs,
                    BuildingsPrefs: buildingPref
                },
                Nodes: [...nodes]
            },
        }, async () => {
            const payload = this.state.BuildingSpecificNotification;
            try {
                await this.userPreferences.update(payload);
                this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelDataHasBeenSavedSuccessfully);
            }
            catch { }

        });
    };

    public onToggle = (e: any, id: number, value: number) => {
        const newBuildingList = this.state.building.map((object: any) => {
            if (object.NodeId === id) {
                return { ...object, value: e.target.checked }
            }

            return object;
        });

        this.setState({
            building: newBuildingList
        })
    };

    render() {
        const { building } = this.state;

        const buildingInput = building.map((inputObject: any) => {
            return ({
                name: inputObject.name,
                label: inputObject.name,
                value: inputObject.value,
                onchange: (e: any) => this.onToggle(e, inputObject.NodeId, inputObject.value),
            })
        })
        return (
            <>
                <Grid
                    container
                >
                    <Card className='card-userPref'>
                        <Grid
                            container
                            gap={1}
                        >
                            <Typography className='card-userPref-title' variant="h6" gutterBottom>{this.labels.HubTitleTailorNotification}</Typography>

                            <Typography className='card-userPref-text' variant="body2" marginBottom={"32px"}>{this.labels.HubSubTitleBuildingNotification}</Typography>

                        </Grid>

                        <Grid
                            container
                            item
                            display={"flex"}
                            alignItems="start"
                            direction="column"
                            gap={2}
                            className="switchPosition"
                        >
                            {buildingInput.map(({ label, onchange, name, value }) =>
                                <IbssSwitchLabel
                                    labelClasses={{ "style": { margin: "16px 0px", padingLeft: "30px" } }}
                                    label={label}
                                    labelPlacement={LabelPlacementType.start}
                                    onChange={onchange}
                                    name={name}
                                    checked={value}
                                />
                            )}
                        </Grid>

                    </Card>
                </Grid>
                <div className="btn-right">
                    <IbssButton variant='contained' onClick={() => { this.submit() }}>
                        {this.labels.HubButtonSave}
                    </IbssButton>
                </div>
            </>
        )
    }
}

export default BuildingSpecificNotification;
