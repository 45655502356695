import { Component } from 'react';
export default class AddIcon extends Component {
    render() {
        return (
            <svg width="18" height="18" viewBox="0 0 18 18" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.24902 12.9384V15.7509H5.06152L13.3565 7.45593L10.544 4.64343L2.24902 12.9384ZM15.5315 5.28093C15.824 4.98843 15.824 4.51593 15.5315 4.22343L13.7765 2.46843C13.484 2.17593 13.0115 2.17593 12.719 2.46843L11.3465 3.84093L14.159 6.65343L15.5315 5.28093Z" fill="inherit" />
            </svg>

        )
    }
};
