import React from "react";


function FormPanelInfo(props: any) {
    const {iconSrc, formHeading, formDescription, style } = props

    return (
        <div className="form-panel-info" style={style}>
            <div className="form-panel-heading">
                <div className="form-panel-icon">
                    <img src={iconSrc} alt=""/>
                </div>
                <h1 className="form-panel-header">{formHeading}</h1>
            </div>
            <div className="form-panel-description">
                {formDescription}
            </div>
        </div>
    )
}


export default FormPanelInfo;