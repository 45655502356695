import { Component } from "react";
import "../../../../styles/css/dashboard.scss";
import "../../../../styles/css/header.scss";
import "../../../../App.css";
import apis from "../../../../Providers.Api/apis";
import Inputbox from "../../../../Components/Inputs/TextInput/Inputbox";
import { getFloorsFromSelectedBuilding, getAllBuildingsData, getFloorUsingFloorId, getBuildingNameUsingFloorNodeId } from "../../../../Common/Helper";
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay"
import TextField from '@mui/material/TextField';
import FormPanelInfo from "../../../../Components/Headings/FormHeading/FormPanelInfo"
import { appContext } from "../../../../AppContext";
import Helper from "../../../../Common/Helper";
import { DateHelper } from "../../../../Common/DateHelper";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { Box, Grid, TextareaAutosize } from "@mui/material";
import IbssAutocomplete from "../../../../Components/Inputs/AutoComplete/IbssAutocomplete";
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import IbssInputDropDown from "../../../../Components/Inputs/SelectList/IbssInputDropDown";
import IbssFormControl from "../../../../Components/Forms/FormControl/IbssFormControl";
import { ICreateTask } from "../../../../Providers.Api/Tasks/CreateTaskEndpoint";
import { IUpdateTask } from "../../../../Providers.Api/Tasks/UpdateTaskEndpoint";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import { RouteComponentProps, RouterProps } from "react-router";
import { INode, IFloor } from "../../../../Providers.Api/Models";
import { IDispatch, IPropsFromState } from "../../../../redux/Interfaces";
import Typography from '@mui/material/Typography';

export default class EditTask extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get session() { return appContext().sessionStorageProvider; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            isLoadingToSave: false,
            activePage: 0,
            updates: false,
            title: "Tasks",
            taskCategoryLoading: false,
            task_Cost_Code: "",
            loading: false,
            Task_Id: "",
            Task_Priority: 0,
            Task_Owner: "",
            Task_Description: "",
            task_Category_Id: "",
            Task_Category_Name: "",
            Task_Type_Id: 0,
            Task_Type_Name: "",
            space_Id_Value: "",
            Space_Id: "",
            spaceLabel: "",
            Space_Type: "",
            Booking_Id: "",
            Equip_Id: "",
            Task_Type: 0,
            show: false,
            nodeid: 0,
            isDelete: false,
            tab: 1,
            taskCategories: [{ title: "", value: "", Space_Type: "" }],
            taskTypes: [{ title: "", value: "" }],
            isUpdateRight: false,
            isCreateRight: false,
            isDeleteRight: false,
            spaceList: [],
            copySpaceList: {} as INodeCategory,
            apiVersion: 11,
            buildingId: this.session.getBuildingId(),
            building: [],
            selectedBuilding: "",
            floors: [],
            selectedFloor: "",
            categories: [],
            categoryId: "",
            categoryName: "",
            taskTypeId: "",
            spaceTypes: [],
            description: "",
            spaceId: "",
            isEdit: false,
            floorNameById: "",
            buildingNameById: "",
            editSpaceName: "",
            editCategoryName: "",
            editSpaceTypeName: "",
            bookingId: "",
            taskCreatedBy: "",
            taskOwner: "",
            taskStatus: "",
            defaultStatus: [{
                label: this.labels.HubLabelUnassigned,
                value: "Unassigned"
            },
            {
                label: this.labels.HubButtonInProgress,
                value: "In-Progress"
            },
            {
                label: this.labels.HubLabelResolved,
                value: "Resolved"
            },
            {
                label: this.labels.HubLabelCancelled,
                value: "Cancelled"
            },
            {
                label: this.labels.HubLabelAssigned,
                value: "Assigned"
            }],
        }
    }

    public componentDidMount(): void 
    {
        let versions = 11;
        const version = this.local.getVersion();
        if (version !== 0)
        {
            versions = version;
        }
        this.setState({
            apiVersion: versions
        }, () =>
        {
            this.handleComponentMount()
        })
        const buildings = getAllBuildingsData();
        this.setState({
            building: buildings
        })
    }

    private async handleComponentMount(): Promise<void> 
    {
        const { match } = this.props;

        if (match.params.task_Id !== "0") 
        {
            await this.handleGetDetails(match.params.task_Id);
        }
        if (match.params.task_Id !== "0") 
        {
            this.setRights();
            this.setState({
                Task_Id: match.params.task_Id,
                nodeid: this.state.buildingId,
            });
        }
        else 
        {
            this.setState({
                nodeid: this.state.buildingId,
                isUpdateRight: true
            });
        }
    }

    private setRights(): void 
    {
        this.setState({
            isCreateRight: this.local.hasRight("DATAMODEL.Tasks.Create"),
            isUpdateRight: this.local.hasRight("DATAMODEL.Tasks.Update"),
        });
    }

    private async handleGetDetails(id: string): Promise<void>
    {
        try
        {
            this.setState({
                isEdit: true
            })
            const responseData = await this.apiClient.tasks.getById(this.state.buildingId, id);
            const getFloor = getFloorUsingFloorId(responseData.Node_Id);
            const getBuilding = getBuildingNameUsingFloorNodeId(responseData.Node_Id);
            this.setState({
                spaceId: responseData.Space_Id,
                categoryId: responseData.Task_Type_Id,
                floorNameById: getFloor,
                buildingNameById: getBuilding,
                editSpaceName: responseData.Space_Id,
                editCategoryName: responseData.Task_Category_Name,
                editSpaceTypeName: responseData.Task_Type_Name,
                description: responseData.Task_Description,
                Task_Priority: responseData.Task_Priority,
                copySpaceList: {
                    Node_Id: responseData.Node_Id,
                    Space_Id: "",
                    Space_Type: "",
                },
                bookingId: responseData.Booking_Id,
                taskCreatedBy: responseData.Task_CreatedBy,
                taskOwner: responseData.Task_Owner,
                taskStatus: responseData.Task_Status,
            })
        }
        catch (error)
        {
            console.log(error);
        }
    }

    private async handleGetSelectList(): Promise<ICategory[]>
    {
        try
        {
            const response = await apis.getTaskCategoriesByNodeid(this.state.selectedBuilding)
            const taskCategoryList = response.data && response.data.map((item: ICategory) =>
            {
                const taskCategoryObj = {
                    title: item.task_Category_Name,
                    task_Category_Name: item.task_Category_Name,
                    task_Category_Id: item.task_Category_Id,
                    value: item.task_Category_Id,
                    Space_Type: item.space_Type,
                }
                return taskCategoryObj
            })

            this.setState({
                taskCategories: taskCategoryList
            });
            return taskCategoryList;
        }
        catch (error)
        {
            console.log(error);
            return [];
        }
    }

    private submit(): void
    {
        this.setState({ isLoadingToSave: true })
        const { match } = this.props;

        const nodeid = this.state.copySpaceList.Node_Id;
        if (match.params.task_Id === "0") 
        {
            const createPayload: ICreateTask =
            {
                Space_Id: this.state.copySpaceList.Space_Id,
                Task_Category_Id: parseInt(this.state.categoryId as string),
                Task_Type_Id: parseInt(this.state.taskTypeId as string),
                Task_Description: this.state.description,
                Booking_Start: DateHelper.now(),
                Catering_Service_Time: DateHelper.now(),
                Catering_Clearing_Time: DateHelper.now(),
                Catering_Attendees: 0,
                Catering_Notes: "",
                DisableExtUpdate: false
            }
            this.create(nodeid, createPayload);
        }
        else 
        {
            const updatePayload: IUpdateTask =
            {
                Task_Priority: this.state.Task_Priority,
                Task_Description: this.state.description,
                Task_Resolve_Description: "",
                Task_Cost_Code: this.state.task_Cost_Code,
                Catering_Attendees: 0,
                Catering_Service_Time: DateHelper.now(),
                Catering_Clearing_Time: DateHelper.now(),
                Task_Status: this.state.taskStatus
            }
            this.update(nodeid, updatePayload)
        }
    }

    private async create(nodeid: number, payload: ICreateTask): Promise<void>  
    {
        try 
        {
            const res = await this.apiClient.tasks.createTask(nodeid, payload);
            if (res)
            {
                this.setState({
                    isLoadingToSave: false
                })
                this.redirectPath();
            }
        }
        catch (error) 
        {
            this.setState({
                isLoadingToSave: false
            })
        }
    }

    private async update(nodeid: number, updatePayload: IUpdateTask): Promise<void>  
    {
        const { Task_Id } = this.state;
        try 
        {
            const res = await this.apiClient.tasks.updateTask(nodeid, Number(Task_Id), updatePayload);
            if (res)
            {
                this.setState({
                    isLoadingToSave: false
                })
                this.redirectPath();
            }
        }
        catch (error) 
        {
            this.setState({
                isLoadingToSave: false
            })
        }
    }

    private redirectPath(): void
    {
        const { history } = this.props;
        let activeurl = window.location.href;
       
            if (activeurl.includes("flex-my-tasks"))
            {
                history.push("/flex-my-tasks");
            }
            else
            {
                history.push("/operational-services-tasks/" + this.state.buildingId);
            }
    }

    private buildingChange(event: SelectChangeEvent): void
    {
        this.setState({
            selectedBuilding: event.target.value
        });
        const _getFloor = this.state.building.filter((item: INode) => item.Node_Id === Number(event.target.value)).map((item: INode) => item.Floors);
        this.setState({
            floors: _getFloor[0],
        });
    };

    private async handleSpaceChange(event: React.SyntheticEvent<Element, Event>, value: INodeCategory): Promise<void>
    {
        this.setState({
            copySpaceList: {
                Node_Id: value.Node_Id,
                Space_Id: value.Space_Id,
                Space_Type: value.Space_Type
            }
        })
        const _allCategories = await this.handleGetSelectList();
        const _categories = _allCategories.filter((item: ICategory) => item.Space_Type === value.Space_Type)
            .sort((a: ICategory, b: ICategory) => a.task_Category_Name.localeCompare(b.task_Category_Name, 'en', { sensitivity: 'base' }));
        this.setState({
            categories: _categories,
            spaceId: value.Space_Id
        })
    };

    private async handleFloorChange(event: React.SyntheticEvent<Element, Event>, value: IFloor): Promise<void>
    {
        const val = await apis.getSpaceByFloorId(this.state.selectedBuilding, value.Node_Id);
        this.setState({
            spaceList: val.data,

        })
    };

    private async categoryChange(event: SelectChangeEvent): Promise<void>
    {
        this.setState({
            categoryId: event.target.value,
        })
        const res = await apis.getTaskTypesByCategoryIdfacility(this.state.selectedBuilding, event.target.value)
        this.setState({
            spaceTypes: res.data
        })
    }

    private spaceTypeChange(event: React.SyntheticEvent<Element, Event>, value: ISpaceType): void
    {
        this.setState({
            taskTypeId: value.Task_Type_Id
        })
    }

    private classificationChanged(e: React.ChangeEvent<HTMLInputElement>): void
    {
        const value = e.target.value;
        this.setState({
            taskStatus: value,
        })
    }


    public render(): JSX.Element
    {
        const requiredStatus = (
            this.state.taskStatus === "Unassigned" ||
            this.state.taskStatus === "Assigned" ||
            this.state.taskStatus === "In-Progress" ||
            this.state.taskStatus === "Resolved" ||
            this.state.taskStatus === "Cancelled"
        );

        return (
            <>
                {(this.state.isLoadingToSave || this.state.taskCategoryLoading) && <LoadingOverlay />}
                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content">
                        <div className="form-panel ">
                            <FormPanelInfo
                                formHeading={this.labels.HubLabelTaskDetails}
                                iconSrc="/images/Sidebar_Icons/Light_theme/Tasks.svg"
                            />
                            {this.state.loading || (
                                <div className="form-panel-form">
                                    <form>
                                        <Grid container spacing={2}>
                                            <Grid item sm={12}>
                                                <Typography variant="body1" className="my-0">{this.labels.HubLabelTaskDetailsAdd}</Typography>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={12}>
                                                        {this.state.isEdit ?
                                                            <TextField id="outlined-basic" disabled value={this.state.buildingNameById} label={this.labels.HubLabelBuilding} variant="outlined" fullWidth /> :
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">{this.labels.HubLabelBuilding}</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={this.state.selectedBuilding}
                                                                    label={this.labels.HubLabelBuilding}
                                                                    onChange={(event) => this.buildingChange(event)}
                                                                >
                                                                    {this.state.building.map((item: ISelectedBuilding) =>
                                                                        <MenuItem value={item.Node_Id}>{item.Name}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        }
                                                    </Grid>

                                                    {/* <div className="form-input-box"> */}
                                                    <Grid item sm={6}>
                                                        {this.state.isEdit ?
                                                            <IbssTextField id="outlined-basic" disabled value={this.state.floorNameById} label={this.labels.HubLabelFloor} variant="outlined" fullWidth />
                                                            :
                                                            <IbssAutocomplete
                                                                disablePortal
                                                                id="combo-box-demo"
                                                                options={this.state.floors}
                                                                onChange={(event, value) => this.handleFloorChange(event, value)}
                                                                getOptionLabel={(option: IFloor) => option.Node_Name}
                                                                renderOption={(props, option) => (
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        {option.Node_Name}
                                                                    </Box>
                                                                )}
                                                                renderInput={(params) => <TextField {...params} label={this.labels.HubLabelFloor} />}
                                                            />}
                                                    </Grid>
                                                    {/* </div> */}
                                                    <Grid item sm={6}>
                                                        {this.state.isEdit ?
                                                            <IbssTextField id="outlined-basic" disabled value={this.state.editSpaceName} label={this.labels.HubLabelSpaces} variant="outlined" fullWidth />
                                                            :
                                                            <IbssAutocomplete
                                                                disablePortal
                                                                id="combo-box-demo"
                                                                options={this.state.spaceList as ISpace[]}
                                                                getOptionLabel={(option: ISpace) => option.Space_Id}
                                                                renderOption={(props, option) => (
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        {option.Space_Id}
                                                                    </Box>
                                                                )}
                                                                value={this.state.spaceList.find((space: ISpace) => space.Space_Id === this.state.spaceId)}
                                                                onChange={(event, value) => this.handleSpaceChange(event, value)}
                                                                renderInput={(params) => <TextField{...params} label={this.labels.HubLabelSpaces} />}
                                                            />}
                                                    </Grid>

                                                    <Grid item sm={12}>
                                                        {this.state.isEdit ?
                                                            <TextField id="outlined-basic" disabled value={this.state.editCategoryName} label={this.labels.HubLabelCategories} variant="outlined" fullWidth />
                                                            :
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">{this.labels.HubLabelCategories}</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    displayEmpty
                                                                    value={this.state.categoryId}
                                                                    label={this.labels.HubLabelCategories}
                                                                    onChange={(event) => this.categoryChange(event as SelectChangeEvent)}
                                                                >
                                                                    {this.state.categories.map((item: ICategory) =>
                                                                        <MenuItem value={item.task_Category_Id}>{item.task_Category_Name}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>}
                                                    </Grid>
                                                    <Grid item sm={12}>
                                                        {this.state.isEdit ?
                                                            <IbssTextField id="outlined-basic" disabled value={this.state.editSpaceTypeName} label={this.labels.HubLabeltaskType} variant="outlined" fullWidth />
                                                            :
                                                            <IbssAutocomplete
                                                                id="combo-box-demo"
                                                                options={this.state.spaceTypes}
                                                                getOptionLabel={(option) => option.Task_Type_Name}
                                                                renderOption={(props, option) => (
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        {option.Task_Type_Name}
                                                                    </Box>
                                                                )}
                                                                onChange={(event, value) => this.spaceTypeChange(event, value)}
                                                                renderInput={(params) => <TextField {...params} label={this.labels.HubLabeltaskType} />}
                                                            />
                                                        }
                                                    </Grid>
                                                    <Grid item sm={12}>
                                                        <IbssTextField
                                                            onChange={(e) => this.setState({
                                                                description: e.target.value
                                                            })}
                                                            label={this.labels.HubLabelDescription}
                                                            multiline
                                                            id="outlined-basic" variant="outlined"
                                                            fullWidth
                                                            value={this.state.description}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {this.state.isEdit ?
                                                <Grid item sm={6}>
                                                    <Grid container spacing={2}>
                                                        {this.state.bookingId &&
                                                            <Grid item sm={12}>
                                                                <IbssTextField disabled fullWidth id="outlined-basic" value={this.state.bookingId} label="Associated Booking" variant="outlined" />
                                                            </Grid>
                                                        }
                                                        <Grid item sm={12}>
                                                            <IbssTextField disabled fullWidth id="outlined-basic" value={this.state.taskCreatedBy} label="Booking Host" variant="outlined" />
                                                        </Grid>
                                                        <Grid item sm={12}>
                                                            <IbssTextField disabled fullWidth id="outlined-basic" value={this.state.taskOwner} label="Assigned" variant="outlined" />
                                                        </Grid>
                                                        <Grid item sm={12}>
                                                            {requiredStatus ?
                                                                <IbssFormControl fullWidth>
                                                                    <IbssInputDropDown
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.classificationChanged(e)}
                                                                        value={this.state.taskStatus}
                                                                        inputLabel={this.labels.dataNotificationClassification_S}
                                                                        id='classification'
                                                                        options={this.state.defaultStatus}
                                                                        disabled={this.state.isEdit}
                                                                    />
                                                                </IbssFormControl>
                                                                :
                                                                <IbssTextField disabled fullWidth id="outlined-basic" value={this.state.taskStatus} label="Status" variant="outlined" />
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                : null}
                                        </Grid>
                                    </form>
                                </div>
                            )}
                        </div>
                        <div className="rightPanel-main-button-group">
                            <div>

                                {/* {(this.state.Task_Id !== "0" && this.state.isDeleteRight === false) &&
              <IbssButtonRedo color="primary" variant="contained" className="button-tertiary-disable mr-2" disabled={true}>{this.labels.HubButtonDelete}</IbssButtonRedo>
            } */}
                                {/* {(this.state.Task_Id !== "0" && this.state.isDeleteRight === true) &&
              <IbssButtonRedo color="primary" variant="contained" className="mr-2">{this.labels.HubButtonDelete}</IbssButtonRedo>
            } */}
                            </div>
                            <div>
                                <IbssButtonRedo color="secondary" variant="contained" className="mr-2" onClick={() => this.redirectPath()}>{this.labels.HubButtonCancel}</IbssButtonRedo>
                                <IbssButtonRedo color="primary" variant="contained" className="ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</IbssButtonRedo>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState, IDispatch
{
}

interface IMatchParams 
{
    task_Id: string;
}

interface IState
{
    isLoadingToSave: boolean;
    activePage: number;
    updates: boolean;
    title: string;
    taskCategoryLoading: boolean;
    task_Cost_Code: string;
    loading: boolean;
    Task_Id: string;
    Task_Priority: number;
    Task_Owner: string;
    Task_Description: string;
    task_Category_Id: string;
    Task_Category_Name: string;
    Task_Type_Id: number;
    Task_Type_Name: string;
    space_Id_Value: string;
    Space_Id: string;
    spaceLabel: string;
    Space_Type: string;
    Booking_Id: string;
    Equip_Id: string;
    Task_Type: number;
    show: boolean;
    nodeid: number;
    isDelete: boolean;
    tab: number;
    taskCategories: ITaskCategory[];
    taskTypes: ITaskType[];
    isUpdateRight: boolean;
    isCreateRight: boolean;
    isDeleteRight: boolean;
    spaceList: ISpace[];
    copySpaceList: INodeCategory,
    apiVersion: number;
    buildingId: number;
    building: INode[];
    selectedBuilding: string;
    floors: IFloor[];
    selectedFloor: string;
    categories: ICategory[];
    categoryId: string | number; // todo: which one is it?!
    categoryName: string;
    taskTypeId?: string;
    spaceTypes: ITaskType[];
    description: string;
    spaceId: string;
    isEdit: boolean;
    floorNameById: string;
    buildingNameById: string;
    editSpaceName: string;
    editCategoryName: string;
    editSpaceTypeName: string;
    bookingId: string;
    taskCreatedBy: string;
    taskOwner: string;
    taskStatus: string;
    defaultStatus: IStatus[];
}

interface ISpace
{
    Node_Id: number;
    Space_Id: string;
    Space_Type: string;
    title?: string;
    label?: string;
}

interface ICategory
{
    Space_Type: string;
    task_Category_Id: string;
    task_Category_Name: string;
    title: string;
    value: string;
    space_Type?: string;
}

interface ITaskCategory
{
    title: string;
    value: string;
    Space_Type: string;
}

interface ITaskType
{
    title: string;
    value: string;
}

interface ISpaceType
{
    Task_Type_Name: string;
    Task_Type_Id?: string;
}

interface ISelectedBuilding
{
    Node_Id: number;
    Name: string;
}

interface INodeCategory
{
    Node_Id: number;
    Space_Id: string;
    Space_Type: string;
}

interface IStatus
{
    label: string;
    value: string;
}
