import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../../Common/TypeHelper";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import apis from "../apis";
import { DateHelper } from "../../Common/DateHelper";
import { ICreateV2Booking_CostCodeAllocation } from "./CreateV2BookingEndpoint";

export class GetBookingsEndpoint implements IGetBookingsEndpoint
{
    public async execute(nodeId: number, top: number, startTime?: DateTime, endTime?: DateTime, filter?: { spaceId?: string, statusNot?: string[] }): Promise<IBooking[]>
    {
        let startTimeAsString = startTime?.toUTC()?.toString() ?? "";
        let endTimeAsString = endTime?.toUTC()?.toString() ?? "";

        // build query
        let query = `$top=${top}&$filter=`;
        filter = filter ?? {};

        if (filter.spaceId != null)
        {
            query = query + ` Space_Id eq '${filter.spaceId}' and `
        }
        if (filter.statusNot != null)
        {
            let index = 0;
            while (index < filter.statusNot.length)
            {
                query = query + `Booking_Status ne '${filter.statusNot[index]}' `;
                if (filter.statusNot.length - 1 != index)
                {
                    query = query + 'and '
                }
                index += 1;
            }
        }

        query = query + '&$select=Booking_Owner_Name, Booking_Start, Booking_End, Booking_Share_Loc'

        // call api
        try
        {
            const response = await axios.get<IRawBooking[]>(`${apis.allApi}${nodeId}/Bookings?${query}&bookingStart=${startTimeAsString}&bookingEnd=${endTimeAsString}`);
            const bookings = response.data.map(i => BookingHelper.ToBooking(i));
            return bookings;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetBookingsEndpoint
{
    execute(nodeId: number, top: number, startTime?: DateTime, endTime?: DateTime, filter?: { spaceId?: string, statusNot?: string[] }): Promise<IBooking[]>;
}

class BookingHelper
{
    public static ToBooking(rawBooking: IRawBooking): IBooking
    {
        let booking = rawBooking as any as IBooking
        booking.Booking_IsActive = TypeHelper.ToBoolean(rawBooking.Booking_IsActive) ?? false;
        booking.Booking_Start = DateTime.fromISO(rawBooking.Booking_Start);
        booking.Booking_End = DateTime.fromISO(rawBooking.Booking_End);
        booking.Booking_Share_Loc = TypeHelper.ToBoolean(rawBooking.Booking_Share_Loc);
        return booking;
    }
}

export interface IBooking extends Omit<IRawBooking, "Booking_IsActive" | "Booking_Start" | "Booking_End" | "Booking_Share_Loc">
{
    Booking_IsActive: boolean;
    Booking_Start: DateTime;
    Booking_End: DateTime;
    Booking_Share_Loc: (boolean | null);
}

interface IRawBooking
{
    Approval_Groups: string,
    Approval_Required: number,
    Auto_Rejection_At: string,
    Booking_Actual_Duration: number,
    Booking_All_Day: number,
    Booking_Approval_Time: string,
    Booking_AutoCheckIn: number,
    Booking_Cancelled_Time: string
    Booking_Checkin_Time: string
    Booking_Completed_Time: string
    Booking_Description: string,
    Booking_Early_Checkin: string,
    Booking_Host_Email: string,
    Booking_Host_Name: string,
    Booking_Id: string,
    Booking_IsActive: number,
    Booking_IsApproved: number,
    Booking_IsCancelled: number,
    Booking_IsCheckedIn: number,
    Booking_IsCompleted: number,
    Booking_IsEarly_Checkin: number,
    Booking_IsLate_Checkin: number,
    Booking_IsPrivate: number,
    Booking_Name: string,
    Booking_Owner_Email: string,
    Booking_Owner_Id: string,
    Booking_Owner_Name: string,
    Booking_Parties: string,
    Booking_Parties_Formatted: string,
    Booking_Party_Count: number,
    Booking_Resources: string,
    Booking_Scheduled_Duration: number,
    Booking_Share_Loc: number,
    Booking_Start: string,
    Booking_End: string,
    Booking_Status: string,
    Cost_Code_Allocation: ICreateV2Booking_CostCodeAllocation[],
    Meta_Auto_Cancel_Thld_Min: number,
    Meta_Ext_Booking_Id: string,
    Meta_Organisation: string,
    Node_Id: number,
    Online_Meeting: string,
    Space_Id: string,
    Space_Layout: string,
}
