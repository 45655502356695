import { ApiHelper } from "../Providers.Api/ApiHelper";
import { IFilter } from "./ODataFilter";

export class ODataQueryV2<TSelect extends ODataSelect>
{
    public select = ODataSelect as (new () => TSelect);
    public nodeId = 1;
    public top = 0;
    public skipToken = "";
    public orderBy = "";
    public filter = "";

    constructor(value: Partial<ODataQueryV2<TSelect>>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        let query: string[] = [];
        query.push(this.select == null ? "" : `$select=${new this.select().toODataString()}`);
        query.push(this.filter == "" ? "" : `$filter=${this.filter}`);
        query.push(this.top == 0 ? "" : `$top=${this.top}`);
        query.push(this.skipToken == "" ? "" : `$skipToken=${this.skipToken}`);
        query.push(this.orderBy == "" ? "" : `$orderby=${this.orderBy}`);
        query = query.filter(i => i != "");

        const queryString = query.join("&");
        return queryString;
    }

    public createResponse(response: {}): TSelect
    {
        const instance = new this.select();
        instance.init(response);
        return instance;
    }
}

/** @deprecated */
export class ODataQuery<TSelect = void>
{
    public select: ((new () => TSelect) | null) = null;
    public nodeId = 1;
    public top = 0;
    public skipToken = "";
    public orderBy = "";
    public filter: (IFilter | null) = null;

    public static get empty(): ODataQuery
    {
        return new ODataQuery({});
    }

    constructor(value: Partial<ODataQuery<TSelect>>)
    {
        Object.assign(this, value);
    }

    public toODataString(filterSeparator: string, customFilter: string): string
    {
        let filterParts: string[] = [];
        filterParts.push(this.filter == null ? "" : this.filter.toODataString());
        filterParts.push(customFilter);
        filterParts = filterParts.filter(i => i != "");
        const filterString = (filterParts.length == 0 ? "" : "$filter=" + filterParts.join(filterSeparator));

        let query: string[] = [];
        query.push(this.select == null ? "" : `$select=${ApiHelper.toODataSelect(this.select)}`);
        query.push(filterString);
        query.push(this.top == 0 ? "" : `$top=${this.top}`);
        query.push(this.skipToken == "" ? "" : `$skipToken=${this.skipToken}`);
        query.push(this.orderBy == "" ? "" : `$orderby=${this.orderBy}`);
        query = query.filter(i => i != "");

        const queryString = query.join("&");
        return queryString;
    }

    public fixTypes(primitiveResponse: TSelect): void
    {
        if (this.select == null)
        {
            return;
        }

        const select = new this.select() as any;
        if (select.fixTypes == null)
        {
            return;
        }

        select.fixTypes(primitiveResponse);
    }
}

export class ODataSelect
{
    public init(response: {}): void
    {
        Object.assign(this, response);
    }

    public toODataString(): string
    {
        return this.odataSelect;
    }

    public get odataSelect(): string
    {
        return Object.entries(this).map(i => i[0]).join(",");
    }
}
