import { Component } from 'react';
import { Card } from "@mui/material";
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import { appContext } from '../../../../AppContext';
import IbssDataGrid from '../../../../Components/Data/DataGrid/IbssDataGrid';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { VisitShort } from '../../../../Providers.Api/Visits/VisitsRepository';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import IbssChip from '../../../../Components/Navigation/Chip/IbssChip';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './VisitorListWidget.scss'
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';

class VisitorListWidget extends Component<IProps, IState>
{
    private get appState() { return appContext().state; }
    private get labels() { return appContext().labels; }
    private get visitsService() { return appContext().visitsService; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            searchText: '',
            buildingId: this.appState.buildingId,
            visitsRowData: [],
            showUserPopup: false,
            selectedUser: { visitId: '', name: '', arrivalTime: '', hostName: '', status: '' },
            loading: false,
            successfulCheckin: false,
            successfulCheckout: false
        };
    };

    public componentDidMount(): void
    {
        this.getRowData()
    }

    public componentDidUpdate(prevProps: IProps): void
    {
        if (prevProps.visits != this.props.visits)
        {
            this.getRowData()
        }
    }

    private getRowData(): void
    {
        const rowData = this.props.visits.map(visit => { return ({ id: visit.Visit_Id, ...visit }) });
        const filteredRowData = rowData.filter(x => DateTime.fromISO(x.Visit_Start_Date).offsetTimeByNode(this.state.buildingId, true) >= DateTime.now().offsetTimeByNode(this.state.buildingId, true).minus({ minutes: 30 }));
        this.setState({ visitsRowData: filteredRowData });
    }

    private filterSearchUpdated(text: string): void
    {
        this.setState({ searchText: text });
        if (text !== '' && text.length >= 3)
        {
            const rowData = this.props.visits.map(visit => ({ id: visit.Visit_Id, ...visit }));
            const searchText = text.toLowerCase();

            const filteredRows = rowData.filter(x =>
                x.Visit_Id?.toLowerCase().includes(searchText) ||
                (x.Visitor_First_Name + ' ' + x.Visitor_Last_Name).toLowerCase().includes(searchText) ||
                x.Visitor_Email.toLowerCase().includes(searchText)
            );
            this.setState({ visitsRowData: filteredRows });
        }
        if (text === '')
        {
            const rowData = this.props.visits.map(visit => { return ({ id: visit.Visit_Id, ...visit }) });
            const filteredRowData = rowData.filter(x => DateTime.fromISO(x.Visit_Start_Date).offsetTimeByNode(this.state.buildingId, true) >= DateTime.now().minus({ minutes: 30 }));
            this.setState({ visitsRowData: filteredRowData });
        }
    }

    private routeToVisiorsList(): void
    {
        const { history } = this.props;
        history.push("/operational-services-visitors/" + this.state.buildingId.toString());
    }

    private selectUser(data: GridRowParams<IRow>): void
    {
        this.setState({
            selectedUser: {
                visitId: data.id.toString(),
                name: data.row.Visitor_First_Name + ' ' + data.row.Visitor_Last_Name,
                arrivalTime: DateTime.fromISO(data.row.Visit_Start_Date).offsetTimeByNode(this.state.buildingId, true).toLocaleTimeString(),
                hostName: data.row.Visit_Host_Name,
                status: data.row.Visit_Status
            },
            showUserPopup: true
        });
    }

    private async checkoutUser(id: string): Promise<void>
    {
        this.setState({ loading: true });
        try
        {
            await this.visitsService.checkout(this.state.buildingId, [id]);
            this.setState({ loading: false, successfulCheckout: true, successfulCheckin: false });
        } catch (error)
        {
            this.setState({ loading: false });
        }
    }

    private async checkinUser(id: string): Promise<void>
    {
        this.setState({ loading: true });
        try
        {
            await this.visitsService.checkin(this.state.buildingId, [id]);
            this.setState({ loading: false, successfulCheckin: true });
        } catch (error)
        {
            this.setState({ loading: false });
        }
    }

    private closeUserPopup(): void
    {
        if (this.state.successfulCheckin || this.state.successfulCheckout)
        {
            this.props.fetchVisits();
        }
        this.setState({ showUserPopup: false, successfulCheckin: false, successfulCheckout: false })
    }

    public render(): JSX.Element
    {
        const columns: GridColDef[] = [
            {
                headerName: this.labels.HubLabelExp,
                field: "Visit_Start_Date",
                minWidth: 140,
                flex: 1,
                renderCell: (params) => DateTime.fromISO(params.row.Visit_Start_Date).offsetTimeByNode(this.state.buildingId, true).toLocaleTimeString()
            },
            {
                headerName: this.labels.HubLabelName,
                field: "Visitor_Name",
                minWidth: 140,
                flex: 1,
                renderCell: (params) => `${params.row.Visitor_First_Name} ${params.row.Visitor_Last_Name}`

            },
            {
                headerName: this.labels.HubLabelHost,
                field: "Visit_Host_Name",
                minWidth: 140,
                flex: 1
            },
            {
                headerName: this.labels.HubLabelStatus,
                field: "Visit_Status",
                minWidth: 140,
                flex: 1,
                renderCell: (params) => this.renderStatus(params.row)
            },
        ];

        return (
            <Card>
                <div className='m-3'>
                    <div className='mb-3 mt-3' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <IbssTextField style={{ width: '70%' }} value={this.state.searchText} fullWidth variant='standard' onChange={e => this.filterSearchUpdated(e.target.value)} placeholder={this.labels.HubLabelSearchForAVisiorOrRef} />
                        <IbssButton
                            color="primary"
                            variant="contained"
                            size="medium"
                            className="ml-2"
                            style={{ marginTop: '-4px', width: '25%', maxWidth: '200px' }}
                            onClick={() => this.routeToVisiorsList()}
                        >
                            {this.labels.HubLabelViewDetails}
                        </IbssButton>
                    </div>
                    <IbssDataGrid
                        className='clickable-data-grid-row'
                        height="calc(100vh - 220px)"
                        rows={this.state.visitsRowData}
                        columns={columns}
                        disableRowSelectionOnClick
                        slots={{ noRowsOverlay: NoVisitsMessage }}
                        loading={this.props.loading}
                        hideFooter
                        onRowClick={(x :GridRowParams<IRow>) => this.selectUser(x)}
                    />
                </div>
                <IbssDialog
                    open={this.state.showUserPopup}
                    onClose={() => this.closeUserPopup()}
                    content=
                    {
                        <div className='mt-5' style={{ paddingBottom: '40px' }}>
                            {this.state.loading && <LoadingOverlay />}
                            <div>
                                <div style={{ fontSize: '14px' }}>{this.labels.HubLabelVisitorName}</div>
                                <div className='d-flex' style={{ alignItems: 'center' }}>
                                    <div style={{ marginRight: '4px', fontSize: '45px' }}>{this.state.selectedUser.name}</div>
                                    {this.state.selectedUser.status == 'Awaiting Approval' ? <ErrorIcon color='error' sx={{ marginTop: '10px' }} /> : <CheckCircleIcon color='success' sx={{ marginTop: '10px' }} />}
                                </div>
                                <div style={{ fontSize: '14px' }} className='mb-3'>#{this.state.selectedUser.visitId}</div>
                            </div>
                            {
                                !this.state.successfulCheckin && !this.state.successfulCheckout &&
                                <div className='d-flex mt-2' style={{ justifyContent: 'space-between' }}>
                                    <div>
                                        <div style={{ fontSize: '14px' }}>{this.labels.HubLabelExp} {this.labels.HubLabelArrival}</div>
                                        <div className='d-flex' style={{ alignItems: 'center' }}>
                                            {this.state.selectedUser.status == 'Approved' || this.state.selectedUser.status == 'Checked In' ? <CheckCircleIcon style={{ marginRight: '4px' }} color='success' /> : ''}
                                            <div>{this.state.selectedUser.arrivalTime}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>{this.labels.HubLabelHost}</div>
                                        <div>{this.state.selectedUser.hostName}</div>
                                    </div>
                                    <div>
                                        <div>{this.labels.HubLabelStatus}</div>
                                        <div className='d-flex' style={{ alignItems: 'center' }}>
                                            {this.state.selectedUser.status == 'Checked In' ? <CheckCircleIcon sx={{ marginRight: '6px' }} color='success' /> : ''}
                                            <div>{this.state.selectedUser.status}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.selectedUser.status == 'Checked In' && !this.state.successfulCheckout && <IbssButton
                                variant="contained"
                                sx={{ width: "100%", marginTop: '35px' }}
                                color="error"
                                onClick={() => this.checkoutUser(this.state.selectedUser.visitId)}
                                disabled={this.state.loading}
                            >
                                {this.labels.HubButtonCheckOut}
                            </IbssButton>}
                            {(this.state.selectedUser.status == 'Approved' && !this.state.successfulCheckin && !this.state.successfulCheckout) && <IbssButton
                                variant="contained"
                                sx={{ width: "100%", marginTop: '35px', color: 'white' }}
                                color="success"
                                onClick={() => this.checkinUser(this.state.selectedUser.visitId)}
                                disabled={this.state.loading}
                            >
                                {this.labels.HubButtonCheckIn}
                            </IbssButton>}
                            {this.state.successfulCheckin &&
                                <div>
                                    <div className='d-flex' style={{ alignItems: 'center' }}>
                                        <CheckCircleIcon sx={{ marginRight: '6px' }} color='success' />
                                        <div className='text-success'>{this.state.selectedUser.name} {this.labels.HubLabelSuccessfullyCheckedInMessage}</div>
                                    </div>
                                    <IbssButton
                                        variant="contained"
                                        sx={{ width: "100%", marginTop: '25px', color: 'white' }}
                                        color="secondary"
                                        onClick={() => this.closeUserPopup()}
                                        disabled={this.state.loading}
                                    >
                                        {this.labels.HubLabelReturnToVisitorOverview}
                                    </IbssButton>
                                    <div className='text-danger' onClick={() => this.checkoutUser(this.state.selectedUser.visitId)} style={{ cursor: 'pointer', textDecoration: 'underline', marginTop: '10px' }}>{this.labels.HubButtonCheckOut}</div>
                                </div>
                            }
                            {this.state.successfulCheckout &&
                                <div>
                                    <div className='d-flex' style={{ alignItems: 'center' }}>
                                        <CheckCircleIcon sx={{ marginRight: '6px' }} color='success' />
                                        <div className='text-success'>{this.state.selectedUser.name} {this.labels.HubLabelSuccessfullyCheckedOutMessage}</div>
                                    </div>
                                    <IbssButton
                                        variant="contained"
                                        sx={{ width: "100%", marginTop: '25px', color: 'white' }}
                                        color="secondary"
                                        onClick={() => this.closeUserPopup()}
                                        disabled={this.state.loading}
                                    >
                                        {this.labels.HubLabelReturnToVisitorOverview}
                                    </IbssButton>
                                </div>
                            }
                        </div>
                    }
                    fullWidth
                />
            </Card>
        )
    }

    private renderStatus(row: VisitShort): React.ReactNode
    {
        const visitStatus = row.Visit_Status;
        if (visitStatus === "Checked In")
        {
            return <IbssChip label={this.labels.HubLabelCheckedIn} sx={{
                backgroundColor: 'var(--ui-success)',
                color: "var(ui-text)",
            }} />;
        } else if (visitStatus === "Approved" && DateTime.fromISO(row.Visit_Start_Date).offsetTimeByNode(this.state.buildingId, true).startOf('day').toISO() != DateTime.now().startOf('day').toISO())
        {
            return <IbssChip label={this.labels.HubLabelApproved} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((visitStatus === "Approved" || visitStatus === "Awaiting Approval") && (DateTime.fromISO(row.Visit_Start_Date).offsetTimeByNode(this.state.buildingId, true).startOf('day').toISO() == DateTime.now().startOf('day').toISO()))
        {
            return <IbssChip label={this.labels.HubLabelAwaitingArrival} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (visitStatus === "Checked Out")
        {
            return <IbssChip label={this.labels.HubLabelCheckedOut} sx={{
                backgroundColor: 'var(--ui-primary-pastel)',
                color: "var(ui-text)",
            }} />;
        }
        else
        {
            return <p>-</p>;
        }
    }
}

class NoVisitsMessage extends Component
{
    private get labels() { return appContext().labels; }

    public render(): JSX.Element
    {
        return (
            <div style={{ justifyContent: 'center', paddingTop: '50px' }} className='d-flex'>
                <img src={'/images/NoUpcomingVisitors.svg'} />
                <div className='ml-3' style={{ fontSize: '35px', width: '180px' }}>
                    {this.labels.HubLabelNoUpcomingVisitors}
                </div>
            </div>
        )
    }
}

export default withRouter(VisitorListWidget);

export interface IProps extends RouteComponentProps
{
    visits: VisitShort[];
    loading: boolean;
    fetchVisits: () => {};
}

export interface IState
{
    searchText: string;
    buildingId: number;
    visitsRowData: VisitShort[];
    showUserPopup: boolean;
    selectedUser: ISelectedUser;
    loading: boolean;
    successfulCheckin: boolean;
    successfulCheckout: boolean;
}

export interface ISelectedUser
{
    visitId: string;
    name: string;
    arrivalTime: string;
    hostName: string;
    status: string;
}

export interface IRow
{
    Visitor_First_Name: string;
    Visitor_Last_Name: string;
    Visit_Start_Date: string;
    Visit_Host_Name: string;
    Visit_Status: string;
}
