import React, { Component } from 'react';
import './SpaceInfoDialog.scss';
import IbssSvgIcon from '../../Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../Common/AllsvgIcons';
import IbssDialog from '../BaseDialog/IbssDialog';
import { appContext } from '../../../AppContext';

export default class SpaceInfoDialog extends Component<IProps, IState>
{
    private get api() { return appContext().apiClient; }
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            bookingPolicyDescription: "",
            additionalInformation: "",
            spaceName: "",
            hasPresentationAids: false,
            hasHearingAids: false,
            hasCatering: false,
            hasLayout: false,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        await this.initialise();
    }

    public async componentDidUpdate(prevProps: IProps): Promise<void>
    {
        if (prevProps.buildingId !== this.props.buildingId || prevProps.spaceId !== this.props.spaceId)
        {
            await this.initialise();
        }
    }

    private async initialise(): Promise<void>
    {
        if (!this.props.isOpen)
        {
            return;
        }

        try
        {
            this.setState({
                bookingPolicyDescription: "",
                additionalInformation: "",
                spaceName: "",
                hasPresentationAids: false,
                hasHearingAids: false,
                hasCatering: false,
                hasLayout: false,
            });

            const { spaceId, buildingId } = this.props;
            const space = await this.api.spaces.getV1SpaceById(buildingId, spaceId);

            const bookingPolicy = await this.api.bookingPolicies.getSpaceBookingPolicy(buildingId, spaceId);

            this.setState({
                bookingPolicyDescription: bookingPolicy?.Booking_Policy_Description || this.labels.HubLabelBookingPolicyDescriptionNotAvailable,
                additionalInformation: space?.Space_Custom_Info || "",
                spaceName: space?.Space_Id || "",
                hasPresentationAids: !!space?.Meta_Serv_Reqs_Presentation,
                hasHearingAids: !!space?.Meta_Serv_Reqs_Hearing,
                hasCatering: !!space?.Meta_Serv_Reqs_Catering,
                hasLayout: !!space?.Space_Layout,
            });
        }
        catch
        {
        }
    }

    private dialogClosed(): void
    {
        this.props.onClose();
    }

    public render(): JSX.Element
    {
        const { isOpen } = this.props;
        const { bookingPolicyDescription, additionalInformation, spaceName, hasCatering, hasHearingAids, hasPresentationAids, hasLayout } = this.state;

        return (
            <IbssDialog
                open={isOpen}
                onClose={() => this.dialogClosed()}
                BackdropComponent={React.Fragment}
                header={<div className="space-info-modal-header">{spaceName}</div>}
                content={
                    <div className="space-info-content">
                        <div className="row pb-1">
                            <div className="col-12 mt-8 d-flex" style={{ padding: 0, paddingBottom: '3px' }}>
                                {
                                    hasCatering &&
                                    <IbssSvgIcon
                                        sx={{ marginTop: '3px', color: (theme) => theme.palette.text.secondary, fontSize: '2rem' }}
                                        className="mr-2"
                                    >
                                        {Icons.CateringIcon}
                                    </IbssSvgIcon>
                                }
                                {
                                    hasHearingAids &&
                                    <IbssSvgIcon
                                        sx={{ marginTop: '3px', color: (theme) => theme.palette.text.secondary, fontSize: '2rem' }}
                                        className="mr-2"
                                    >
                                        {Icons.HearingAidIcon}
                                    </IbssSvgIcon>
                                }
                                {
                                    hasPresentationAids &&
                                    <IbssSvgIcon
                                        sx={{ marginTop: '3px', color: (theme) => theme.palette.text.secondary, fontSize: '2rem' }}
                                        className="mr-2"
                                    >
                                        {Icons.PresentationAidIcon}
                                    </IbssSvgIcon>
                                }
                                {
                                    hasLayout &&
                                    <IbssSvgIcon
                                        sx={{ marginTop: '1px', color: (theme) => theme.palette.text.secondary, fontSize: '1.8rem' }}
                                        className="mr-2"
                                    >
                                        {Icons.SpaceLayoutIcon}
                                    </IbssSvgIcon>
                                }
                            </div>
                        </div>

                        <h3>Booking Policy Description</h3>
                        <p>{bookingPolicyDescription}</p>

                        {
                            additionalInformation &&
                            <>
                                <h3>Additional Information</h3>
                                <p>{additionalInformation}</p>
                            </>
                        }
                    </div>
                }
            />
        );
    }
}

interface IProps
{
    isOpen: boolean;
    buildingId: number;
    spaceId: string;
    onClose: () => void;
}

interface IState
{
    bookingPolicyDescription: string;
    additionalInformation: string;
    spaceName: string;
    hasPresentationAids: boolean;
    hasHearingAids: boolean;
    hasCatering: boolean;
    hasLayout: boolean;
}
