import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import { appContext } from '../../../../AppContext';
import { IListOption } from '../../../OneLens/Bookings/ViewSchedule/ScheduleViewModal';
import { IOption } from '../../../../Components/Layout/BuildingSelector/BuildingSelector';
import { IFloor } from '../../../../Providers.Api/Models';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import Helper from '../../../../Common/Helper';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import SvgIcon from '@mui/material/SvgIcon';
import FilterListIcon from '@mui/icons-material/FilterList';

class ScheduleFilter extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            buildingOptions: [],
            floorOptions: [],
            spaceTypeOptions: [],
            spaceWorkTypeOptions: [],
        };
    }

    // on mounting, using buildingId, load floors, spaceTypes of building. create options for the select fields.
    public async componentDidMount(): Promise<void>
    {     
        // load options
        this.populateBuildings();        
        this.populateSpaceTypes(this.props.buildingId);
        this.populateSpaceWorkTypes(this.props.buildingId);
        this.populateFloors(this.props.buildingId);
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        // load options
        if(prevProps.buildingId !== this.props.buildingId)
        {
            this.populateSpaceTypes(this.props.buildingId);
            this.populateSpaceWorkTypes(this.props.buildingId);
            this.populateFloors(this.props.buildingId);
        }
    }

    private populateBuildings(): void
    {
        const buildings = Helper.getAllBuildingsData().sort((a, b) => a.Name.localeCompare(b.Name));
        const buildingOptions = buildings.map(i => ({ value: i.Node_Id, label: i.Name }) as IOption);
        this.setState({ buildingOptions: buildingOptions});
    }
    
    private populateSpaceWorkTypes(selectedBuildingId: number): void
    {
        const spaceWorkTypes = Helper.getWorkSpaceTypesByNodeId(selectedBuildingId);

        const options = spaceWorkTypes
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }))
            .sort((a, b) => (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? - 1 : 1)); // sort by name;

        options.unshift({ label: this.labels.HubLabelAny, value: "Any" });
        this.setState({ spaceWorkTypeOptions: options });
    }

    private populateSpaceTypes(selectedBuildingId: number): void
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(selectedBuildingId);

        const options = spaceTypes.result
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }))
            .sort((a, b) => (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? - 1 : 1)); // sort by name;

        options.unshift({ label: this.labels.HubLabelAny, value: "Any" });
        this.setState({ spaceTypeOptions: options });
    }

    private populateFloors(selectedBuildingId: number): void
    {
        const floors: IFloor[] = Helper.getFloorsByBuildingId(selectedBuildingId);

        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id.toString() }))
            .sort((a, b) => (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? - 1 : 1)); // sort by name

        options.unshift({ label: this.labels.HubLabelAny, value: 'Any' });
        this.setStateAsync({ floorOptions: options });
        this.props.changeFloor('Any', this.labels.HubLabelAny);
    }

    private changeSpaceType(spaceType: string): void
    {
        if(spaceType === 'Any')
        {
            // check spaceWorkType first
            if(this.props.spaceWorkType === 'Any')
            {
                // set spaceWorkType to something other than 'Any' with second in list of spaceWorkTypes options
                this.props.changeSpaceWorkType(this.state.spaceWorkTypeOptions[1].value);
            } 

            // finally change spaceType
            this.props.changeSpaceType(spaceType);

        }
        else
        {
            // set spaceType & set spaceWorkType to "Any"
            this.props.changeSpaceType(spaceType);
            this.props.changeSpaceWorkType('Any');
        }
    }

    private changeSpaceWorkType(spaceWorkType: string): void
    {
        if(spaceWorkType === 'Any')
        {
            // check spaceType first
            if(this.props.spaceType === 'Any')
            {
                // set spaceType to something oether than 'Any' with second in list of spaceTypes options
                this.props.changeSpaceType(this.state.spaceTypeOptions[1].value);
            } 

            // finally change spaceWorkType
            this.props.changeSpaceWorkType(spaceWorkType);

        }
        else
        {
            // set SpaceWorkType & set spaceType to "Any"
            this.props.changeSpaceWorkType(spaceWorkType);
            this.props.changeSpaceType('Any');
        }
    }

    private changeFloor(floorId: string): void
    {
        const floor = this.state.floorOptions.find(floor => floor.value===floorId);
        const floorName =  floor ? floor.label: '';
        this.props.changeFloor(floorId, floorName); 
    }

    public render(): JSX.Element
    {
        return (
            <Grid container spacing={3}>
                <Grid item xs={0.8} alignSelf={'center'}>
                    <Box display='flex' gap={1}>
                        <SvgIcon component={FilterListIcon}></SvgIcon>
                        <Typography
                            sx={{fontSize: '1rem', fontFamily: 'Source Sans Pro', fontWeight: 'bold'}}
                        >
                            {this.labels.HubLabelFilter}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="buildingSelection"
                            inputLabel={this.labels.HubLabelBuilding}
                            fullWidth={true}
                            options={this.state.buildingOptions}
                            value={this.props.buildingId}
                            onChange={(e: { target: { value: string; }; }) => { this.props.changeBuildingId(e.target.value)}}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={2}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="floorSelection"
                            inputLabel={this.labels.HubLabelFloor}
                            fullWidth={true}
                            options={this.state.floorOptions}
                            value={this.props.floor}
                            onChange={(e: { target: { value: string; }; }) => { this.changeFloor(e.target.value)}}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={2}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="zoneSelection"
                            inputLabel={this.labels.HubLabelZone}
                            fullWidth={true}
                            options={this.props.zoneOptions}
                            value={this.props.zone}
                            onChange={(e: { target: { value: string; }; }) => { this.props.changeZone(e.target.value)}}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={2}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="workTypeSelection"
                            inputLabel={this.labels.HubLabelworkType}
                            fullWidth={true}
                            options={this.state.spaceWorkTypeOptions}
                            value={this.props.spaceWorkType}
                            onChange={(e: { target: { value: string; }; }) => { this.changeSpaceWorkType(e.target.value)}}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={2}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="spaceTypeSelection"
                            inputLabel={this.labels.HubLabelSpaceType}
                            fullWidth={true}
                            options={this.state.spaceTypeOptions}
                            value={this.props.spaceType}
                            onChange={(e: { target: { value: string; }; }) => { this.changeSpaceType(e.target.value)}}
                        />
                    </IbssFormControl>
                </Grid>
            </Grid>
        )
    }
}

export default ScheduleFilter;

export interface IProps
{
    buildingId: number,
    floor: string,
    spaceType: string,
    spaceWorkType: string,
    zone: string,
    zoneOptions: Array<IListOption<string>>,
    changeBuildingId: (buildingId: string) => void,
    changeFloor: (floor: string, floorName: string) => void,
    changeSpaceType: (spaceType: string)=> void,
    changeSpaceWorkType: (spaceWorkType: string)=> void,
    changeZone: (zone: string) => void,
}

export interface IState
{
    buildingOptions: Array<IOption>,
    floorOptions: Array<IListOption<string>>,
    spaceTypeOptions: Array<IListOption<string>>,
    spaceWorkTypeOptions: Array<IListOption<string>>,
}