import { Box, Card, MenuItem, SelectChangeEvent, TextField, Typography } from '@mui/material';
import IbssDataGrid from '../../../../Components/Data/DataGrid/IbssDataGrid';
import IbssPageHeader from '../../../../Components/Forms/DateRange/IbssPageHeader';
import IbssFilter from '../../../../Components/Forms/Filter/IbssFilter';
import { appContext } from '../../../../AppContext';
import { RouteComponentProps } from 'react-router';
import { DateTime } from 'luxon';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import ListFilterModal from './ListFilterModal';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PauseIcon from '@mui/icons-material/Pause';
import IbssActionButton, { IActionButton } from '../../../../Components/Buttons/ActionButton/IbssActionButton';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IbssButtonRedo from '../../../../Components/Buttons/Button/IbssButton';
import { Icons } from '../../../../Common/AllsvgIcons';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import IbssChip from '../../../../Components/Navigation/Chip/IbssChip';
import { NotificationsFilter } from '../../../../Providers.Api/Notifications/NotificationRepository';
import Helper from '../../../../Common/Helper';
import { Announcement } from '@mui/icons-material';
import { IbssLuxonDateTimePicker } from '../../../../Components/Inputs/LuxonDateTimePicker/IbssLuxonDateTimePicker';
import { IUser } from '../../../../Components/Inputs/UserPicker/UserPicker';

class ListNotifications extends IbssComponent<IProps, IState>
{

    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get localStorage() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; }

    private userCanCreate: boolean;
    private userCanDelete: boolean;
    private userCanUpdate: boolean;

    constructor(props: IProps)
    {
        super(props);
        this.userCanCreate = this.localStorage.hasRight('DATAMODEL.Notifications.Create');
        this.userCanDelete = this.localStorage.hasRight('DATAMODEL.Notifications.Delete');
        this.userCanUpdate = this.localStorage.hasRight('DATAMODEL.Notifications.Update');
        this.state =
        {
            loading: false,
            rowData: [],
            announcements: [],
            dateRangeFilter: "",
            fromFilter: DateTime.now(),
            toFilter: DateTime.now(),
            datePickerFiltersDisabled: true,
            searchTerm: '',
            openFilterModal: false,
            broadcastSelectValue: 'any',
            statusSelectValue: 'any',
            selectedAnnouncementCreatorEmail: '',
            selectedAnnouncementCreator: null,
            showDeleteConfirmationModal: false,
            selectedFieldIds: [],
            buildingId: this.appState.buildingId,
            showDuplicateNotificationModal: false,
            duplicateNotificationTitle: '',
            showExpireConfirmationModal: false,
            selectedAnnouncement: null,
            duplicateBuildingOptions: new Array<IOption>(),
            startDateTime: DateTime.now(),
            endDateTime: DateTime.now(),
            showDateTimeError: false,
            selectedBuildings: [],
        };
    };

    public async componentDidMount(): Promise<void>
    {
        this.pageTitle = this.labels.funcBuildingAnnouncement_S;
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        await this.buildingChanged();
        await this.setStateAsync({
            dateRangeFilter: this.labels.HubLabelThisWeek,
            fromFilter: DateTime.now().startOf('week'),
            toFilter: DateTime.now().startOf('week').plus({ days: 6 }),
        })
        const buildings = Helper.getAllBuildingsData().sort((a, b) => a.Name.localeCompare(b.Name));
        const options = buildings.map(i => ({ value: i.Node_Id, label: i.Name }) as IOption);
        this.setState({ duplicateBuildingOptions: options });
        await this.getRowData();
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        if (prevState.buildingId != this.state.buildingId)
        {
            this.props.history.push("/oneLens/" + this.state.buildingId + "/announcements/list");
            await this.buildingChanged();
        }
    }

    private get isZeroOrOneBuildings() { return this.state.duplicateBuildingOptions.length <= 1; }
    private get selectedBuildingId() { return this.isZeroOrOneBuildings ? this.state.duplicateBuildingOptions[0]?.value : -1; }

    private async buildingChanged(): Promise<void>
    {
        this.pageTitle = this.labels.funcBuildingAnnouncement_S;
        await this.getRowData();
        this.setState({ loading: false });
    }

    private async getRowData(): Promise<void>
    {
        this.setState({ loading: true });

        try
        {
            const filter = new NotificationsFilter({ classification: 'Building' });
            const announcements = await this.apiClient.notifications.getMany(this.state.buildingId, this.state.fromFilter.toUTC().toISO(), this.state.toFilter.toUTC().toISO(), filter);

            if (announcements.length > 0)
            {
                const rows = announcements.map(x => ({
                    id: x.Notification_Id.toString(),
                    broadcast: x.Subclassification == 'inspire' ? this.labels.HubMenuInspire : this.labels.funcAnnouncementRoamerAndFlex,
                    label: x.Subclassification == 'inspire' ? '-' : x.Subclassification,
                    title: x.Notification_Title,
                    start: x.Available_From,
                    end: x.Expiry_Date,
                    priority: x.Notification_Priority,
                    createdBy: x.Notification_User_Email,
                    status: x.Notification_Status,
                    imageUri: x.ImageURI,
                    classification: x.Classification,
                    subClassification: x.Subclassification,
                    message: x.Notification_Message,
                    shortMessage: x.Notification_Short_Message
                }));
                this.setState({ rowData: rows, announcements: rows });
            }
            else
            {
                this.setState({ rowData: [], announcements: [] });
            }
        } catch (error)
        {
            this.setState({ loading: false });
        }


        this.setState({ loading: false });
    }

    private async dateRangeChanged(e: SelectChangeEvent<string>): Promise<void>
    {
        this.setState({ dateRangeFilter: e.target.value });
        let getRowData = true;

        if (e.target.value === this.labels.HubLabelToday)
        {
            await this.setStateAsync({
                fromFilter: DateTime.now().date(),
                toFilter: DateTime.now().date(),
                datePickerFiltersDisabled: true,
            });
        }
        if (e.target.value === this.labels.HubLabelTomorrow)
        {
            await this.setStateAsync({
                fromFilter: DateTime.now().date().plus({ days: 1 }),
                toFilter: DateTime.now().date().plus({ days: 1 }),
                datePickerFiltersDisabled: true,
            });
        }
        if (e.target.value === this.labels.HubLabelThisWeek)
        {
            await this.setStateAsync({
                fromFilter: DateTime.now().startOf('week'),
                toFilter: DateTime.now().startOf('week').plus({ days: 6 }),
                datePickerFiltersDisabled: true,
            });
        }
        if (e.target.value === this.labels.HubLabelNextWeek)
        {
            await this.setStateAsync({
                fromFilter: DateTime.now().startOf('week').plus({ days: 7 }),
                toFilter: DateTime.now().startOf('week').plus({ days: 13 }),
                datePickerFiltersDisabled: true,
            });
        }
        if (e.target.value === this.labels.HubLabelThisMonth)
        {
            await this.setStateAsync({
                fromFilter: DateTime.now().startOf('month'),
                toFilter: DateTime.now().startOf('month').plus({ months: 1, days: -1 }),
                datePickerFiltersDisabled: true,
            });
        }
        if (e.target.value === this.labels.HubLabelNextMonth)
        {
            await this.setStateAsync({
                fromFilter: DateTime.now().startOf('month').plus({ months: 1 }),
                toFilter: DateTime.now().startOf('month').plus({ months: 2, days: -1 }),
                datePickerFiltersDisabled: true,
            });
        }
        if (e.target.value === this.labels.HubLabelCustom)
        {
            await this.setStateAsync({
                datePickerFiltersDisabled: false
            });
            getRowData = false;
        }

        if (getRowData)
        {
            this.getRowData();
        }
    }

    private async startDateChanged(e: DateTime): Promise<void>
    {
        await this.setStateAsync({
            fromFilter: e,
        });
        await this.getRowData();
    }

    private async endDateChanged(e: DateTime): Promise<void>
    {
        await this.setStateAsync({
            toFilter: e
        });
        await this.getRowData();
    }

    private filterRowData(searchTerm: string): IGridRow[] 
    {
        let filteredRowData = this.state.rowData.filter(x =>
        {
            let key: keyof IGridRow;
            for (key in x)
            {
                if (x[key]?.toString().toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
                {
                    return true;
                }
            }
            return false;
        });

        return filteredRowData;
    }

    private filterModalSubmitted(): void
    {
        let filteredRowData = this.state.announcements;
        if (this.state.statusSelectValue != 'any')
        {
            if (this.state.statusSelectValue == this.labels.funcAnnouncementsStatusExpired_Short)
            {
                filteredRowData = filteredRowData.filter(x => DateTime.fromISO(x.end) < DateTime.now());
            }
            else
            {
                filteredRowData = this.state.announcements.filter(x => x.status === this.state.statusSelectValue && DateTime.fromISO(x.end) > DateTime.now());
            }
        }
        if (this.state.broadcastSelectValue != 'any')
        {
            filteredRowData = filteredRowData.filter(x => x.broadcast == this.state.broadcastSelectValue);
        }
        if (this.state.selectedAnnouncementCreator != null)
        {
            filteredRowData = filteredRowData.filter(x => x.createdBy == this.state.selectedAnnouncementCreator?.email);
        }

        this.setState({ rowData: filteredRowData, openFilterModal: false });
    }

    private async deleteSelectedItems(): Promise<void>
    {
        this.setState({ loading: true, showDeleteConfirmationModal: false });

        try
        {
            for (let index = 0; index < this.state.selectedFieldIds.length; index++)
            {
                const element = this.state.selectedFieldIds[index];
                await this.apiClient.notifications.delete(this.state.buildingId, element);
            }
            this.getRowData();
        } catch (error)
        {
            this.setState({ loading: false });
        }
        this.setState({ loading: false, selectedFieldIds: [] });
    }

    private async createDuplicate(): Promise<void>
    {
        this.setState({ loading: true, showDuplicateNotificationModal: false });
        const currentUser = this.localStorage.getUserDetails();
        try
        {
            const selectedNotification = await this.apiClient.notifications.getById(this.state.buildingId, this.state.selectedFieldIds[0]);
            await this.state.selectedBuildings.map((buildingId)=> {
                let payload = {
                    ImageURI: selectedNotification.ImageURI,
                    Notification_Title: this.state.duplicateNotificationTitle,
                    Notification_Priority: selectedNotification.Notification_Priority,
                    Classification: selectedNotification.Classification,
                    SubClassfication: selectedNotification.Subclassification,
                    Notification_Message: selectedNotification.Notification_Message,
                    Notification_Short_Message: selectedNotification.Notification_Short_Message,
                    Available_From: this.state.startDateTime.setZoneByNode(buildingId).toUTC().toISO(),
                    Expiry_Date: this.state.endDateTime.setZoneByNode(buildingId).toUTC().toISO(),
                    Notification_Type: 1,
                    Notification_Status: selectedNotification.Notification_Status,
                    Notification_User_Email: currentUser.email
                }

                this.apiClient.notifications.create(buildingId, payload)
            });
            
            this.getRowData();
        } catch (error)
        {
            this.setState({ loading: false });
        }
        this.setState({ 
            loading: false, 
            selectedFieldIds: [], 
            selectedAnnouncement: null, 
            selectedBuildings: [], 
            startDateTime: DateTime.now(),
            endDateTime: DateTime.now()
        });
    }

    private async expireSelectedItems(): Promise<void>
    {
        this.setState({ loading: true, showExpireConfirmationModal: false });

        try
        {
            for (let index = 0; index < this.state.selectedFieldIds.length; index++)
            {
                const element = this.state.selectedFieldIds[index];
                const selectedNotification = this.state.announcements.filter(x => x.id == element)[0];
                let payload = {
                    ImageURI: selectedNotification.imageUri,
                    Notification_Title: selectedNotification.title,
                    Notification_Priority: selectedNotification.priority,
                    Classification: selectedNotification.classification,
                    SubClassfication: selectedNotification.subClassification,
                    Notification_Message: selectedNotification.message,
                    Notification_Short_Message: selectedNotification.shortMessage,
                    Available_From: selectedNotification.start,
                    Expiry_Date: DateTime.now().toISO(),
                    Notification_Type: 1,
                    Notification_Status: selectedNotification.status,
                    Notification_User_Email: selectedNotification.createdBy,
                }
                await this.apiClient.notifications.edit(parseInt(this.props.match.params.buildingid), parseInt(element), payload);
            }
            this.getRowData();
        } catch (error)
        {
            this.setState({ loading: false });
        }
        this.setState({ loading: false, selectedFieldIds: [] });
    }

    public renderNotificationStatus(row: IGridRow): React.ReactNode
    {
        if (DateTime.fromISO(row.end) >= DateTime.now() && row.status === this.labels.HubLabelActive)
        {
            return <IbssChip label={this.labels.HubLabelActive} sx={{
                backgroundColor: 'var(--ui-success)',
                color: "var(--ui-light-text)",
            }} />;
        }
        else if (DateTime.fromISO(row.end) < DateTime.now())
        {
            return <IbssChip label={this.labels.funcAnnouncementsStatusExpired_Short} sx={{
                backgroundColor: 'var(--ui-error)',
                color: "var(--ui-light-text)",
            }} />;
        }
        else if (row.status === this.labels.HubLabelInActive)
        {
            return <IbssChip label={this.labels.HubLabelInActive} sx={{
                backgroundColor: 'var(--ui-text-alternate)',
                color: "var(--ui-light-text)",
            }} />;
        }
        else
        {
            return <p>-</p>;
        }
    }

    public getPriorityLabel(priorityValue: number): string
    {
        switch (priorityValue)
        {
            case 1:
                return this.labels.HubLabelHighPriority
            case 2:
                return this.labels.funcAnouncementPriorityMedium_Short
            case 3:
                return this.labels.HubLabelLowPriority
            default:
                return '-'
        }
    }

    private buildingClicked(selectedBuildingId: number): void
    {
        if (!this.state.selectedBuildings.includes(selectedBuildingId))
        {
            this.setState({ selectedBuildings: [...this.state.selectedBuildings, selectedBuildingId] });
        }
    }

    private duplicateAnnouncementClicked(): void
    {
       const selectedAnnouncement = this.state.rowData.find((announcement) => announcement.id === this.state.selectedFieldIds[0]);
       this.setState({ 
        showDuplicateNotificationModal: true, 
        selectedBuildings: this.isZeroOrOneBuildings ? [this.state.duplicateBuildingOptions[0].value] : [],
        duplicateBuildingOptions: this.isZeroOrOneBuildings ? [this.state.duplicateBuildingOptions[0]] : [{ label: this.labels.HubMenuSelectBuildings, value: -1}, ...this.state.duplicateBuildingOptions] ,
        selectedAnnouncement: selectedAnnouncement ? selectedAnnouncement : null, 
        duplicateNotificationTitle: selectedAnnouncement?.title || "",
        startDateTime: selectedAnnouncement?.start ? DateTime.fromISO(selectedAnnouncement?.start).offsetTimeByNode(this.state.buildingId) : this.state.startDateTime,
        endDateTime: selectedAnnouncement?.end ? DateTime.fromISO(selectedAnnouncement?.end).offsetTimeByNode(this.state.buildingId) : this.state.endDateTime,
       });

    }

    private startDateTimeChanged(value: DateTime): void
    {
        if(value > this.state.endDateTime)
        {
            this.setState({ showDateTimeError: true });
        }
        else
        {
            this.setState({ showDateTimeError: false });
        }
        this.setState({ startDateTime: value });
    }

    private endDateTimeChanged(value: DateTime): void
    {
        if(value < this.state.startDateTime)
        {
            this.setState({ showDateTimeError: true });
        }
        else
        {
            this.setState({ showDateTimeError: false });
        }
        this.setState({ endDateTime: value });
    }

    private getBuildingName(buildingId: number): string
    {
       const building = this.state.duplicateBuildingOptions.find((duplicateBuildingOption) => duplicateBuildingOption.value === buildingId);
       return building?.label || "";
    }

    private deleteBuildingClicked(buildingId: number): void
    {
        if (this.isZeroOrOneBuildings)
        {
            return;
        }
       const updatedBuildings = this.state.selectedBuildings.filter((building)=> buildingId !== building);
       this.setState({ selectedBuildings: updatedBuildings });
    }
    
    public render(): JSX.Element
    {
        const columns =
            [
                {
                    field: Helper.nameOf<IGridRow>("id"),
                    headerName: this.labels.HubLabelId,
                    flex: 1,
                },
                {
                    field: Helper.nameOf<IGridRow>("broadcast"),
                    headerName: this.labels.funcAnnouncementsBroadcast_Short,
                    flex: 1,
                },
                {
                    field: Helper.nameOf<IGridRow>("label"),
                    headerName: this.labels.HubLabelLabel,
                    flex: 1,
                },
                {
                    field: Helper.nameOf<IGridRow>("title"),
                    headerName: this.labels.HubLabelTitle,
                    flex: 1,
                },
                {
                    field: Helper.nameOf<IGridRow>("start"),
                    headerName: this.labels.HubLabelStart,
                    renderCell: (params: { row: IGridRow; }) => DateTime.fromISO(params.row.start).toLocaleDateTimeString(),
                    flex: 1,
                },
                {
                    field: Helper.nameOf<IGridRow>("end"),
                    headerName: this.labels.HubLabelEnd,
                    renderCell: (params: { row: IGridRow; }) => DateTime.fromISO(params.row.end).toLocaleDateTimeString(),
                    flex: 1,
                },
                {
                    field: Helper.nameOf<IGridRow>("priority"),
                    headerName: this.labels.HubLabelPriorityLabel,
                    flex: 1,
                    renderCell: (params: { row: IGridRow; }) => this.getPriorityLabel(params.row.priority),
                },
                {
                    field: Helper.nameOf<IGridRow>("createdBy"),
                    headerName: this.labels.HubLabelCreatedBy,
                    flex: 1,
                    renderCell: (params: { row: IGridRow; }) => params.row.createdBy ?? '-',
                },
                {
                    field: Helper.nameOf<IGridRow>("status"),
                    headerName: this.labels.HubLabelStatus,
                    renderCell: (params: { row: IGridRow; }) => this.renderNotificationStatus(params.row),
                    flex: 1,
                },
            ];

        const actionButtons: IActionButton[] =
            [
                {
                    label: this.labels.funcDuplicate,
                    icon: (
                        <IbssSvgIcon sx={{ marginTop: '4px' }}>
                            {Icons.RepeatIcon}
                        </IbssSvgIcon>
                    ),
                    color: "info",
                    onClick: () => this.duplicateAnnouncementClicked(),
                    disabled: !this.userCanCreate || this.state.selectedFieldIds.length != 1,
                },
                {
                    label: this.labels.funcAnnouncementsExpire_Short,
                    icon: (
                        <IbssSvgIcon>
                            <PauseIcon />
                        </IbssSvgIcon>
                    ),
                    color: "info",
                    onClick: () => this.setState({ showExpireConfirmationModal: true }),
                    disabled: !this.userCanUpdate || this.state.selectedFieldIds.length == 0,
                },
                {
                    label: this.labels.HubButtonDelete,
                    icon: (
                        <IbssSvgIcon>
                            <DeleteOutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => this.setState({ showDeleteConfirmationModal: true }),
                    disabled: !this.userCanDelete || this.state.selectedFieldIds.length == 0,
                },
                {
                    label: this.labels.HubLabelAddButton,
                    icon: <IbssSvgIcon>{Icons.AddIcon}</IbssSvgIcon>,
                    color: "info",
                    onClick: () => this.props.history.push("/oneLens/" + this.state.buildingId + "/announcements/create"),
                },
            ];

        const filteredRowData = this.filterRowData(this.state.searchTerm);
        return (
            <Box className="page-height-exct-header">
                    <Box className="rightPanel-main-content">
                {/* Filter Modal */}
                <ListFilterModal
                    open={this.state.openFilterModal}
                    onClose={() => this.setState({ openFilterModal: false })}
                    broadcastSelectValue={this.state.broadcastSelectValue}
                    statusSelectValue={this.state.statusSelectValue}
                    selectedAnnouncementCreator={this.state.selectedAnnouncementCreatorEmail}
                    onBroadcastValueChanged={(value) => this.setState({ broadcastSelectValue: value })}
                    onStatusValueChanged={(value) => this.setState({ statusSelectValue: value })}
                    onAnnouncementCreatorTextChanged={(value) => this.setState({ selectedAnnouncementCreatorEmail: value })}
                    onAnnouncementCreatorChanged={user => this.setState({ selectedAnnouncementCreator: user })}
                    onSubmit={() => this.filterModalSubmitted()}
                />
                {/* Delete confirmation Modal */}
                <IbssDialog
                    open={this.state.showDeleteConfirmationModal}
                    onClose={() => this.setState({ showDeleteConfirmationModal: false })}
                    fullWidth
                    header={this.labels.HubLabelModalConfirmTitle}
                    content=
                    {
                        <div>{this.labels.funcAnnouncementsConfirmDelete_Message}</div>
                    }
                    footer=
                    {
                        <>
                            <IbssButtonRedo
                                color="primary"
                                variant="contained"
                                size="medium"
                                onClick={() => this.deleteSelectedItems()}
                            >
                                {this.labels.HubLabelOk}
                            </IbssButtonRedo>
                        </>
                    }
                />
                {/* Duplicate notification Modal */}
                <IbssDialog
                    open={this.state.showDuplicateNotificationModal}
                    onClose={() => this.setState({ showDuplicateNotificationModal: false })}
                    fullWidth
                    header={this.labels.funcAnnouncementsCreateDuplicate_Short}
                    content=
                    {
                        <Box>
                            <Typography variant='body2' className='ui-text-light'>{this.labels.funcAnnouncementsDuplicate_D}</Typography>
                            <hr/>
                            <Box>
                                <Typography variant='body2' className='ui-text-light' mb={1}>{this.labels.funcAnnouncementSelected_S.toUpperCase()}</Typography>
                                <Box className='card-secondary'>
                                    <Typography variant='body2' className='p-2'>{this.state.selectedAnnouncement?.title}</Typography>
                                </Box>
                            </Box>
                            <hr/>
                            <Box>
                            <Typography variant='body2' mb={1} className='ui-text-light'>{this.labels.funcAnnouncementDuplicateLocation_S.toUpperCase()}</Typography>
                                <Box>
                                <TextField
                                    select
                                    id="buildingDropdown"
                                    value={this.selectedBuildingId}
                                    disabled={this.isZeroOrOneBuildings}
                                    fullWidth
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}>
                                    {this.state.duplicateBuildingOptions.map(duplicateBuildingOption =>
                                        <MenuItem
                                            key={duplicateBuildingOption.value}
                                            value={duplicateBuildingOption.value}
                                            onClick={() => this.buildingClicked(duplicateBuildingOption.value)}>
                                            {duplicateBuildingOption.label}
                                        </MenuItem>
                                    )}
                                </TextField>
                                </Box>
                            </Box>
                            <Box className='my-2'>
                                {this.state.selectedBuildings.map((building) => {return (
                                    <IbssChip label={this.getBuildingName(building)} className='p-2 mr-2' onDelete={() => this.deleteBuildingClicked(building)}/>
                                )})}
                            </Box>
                            <hr/>
                            <Box sx={{'& > :not(style)': { my: 1}}}>
                            <Typography variant='body2' className='ui-text-light' mb={1.5}>{this.labels.funcAnnouncementEditDetails_S.toUpperCase()}</Typography>
                            <IbssFormControl fullWidth className='mb-3'>
                                <IbssTextField value={this.state.duplicateNotificationTitle} onChange={(e) => this.setState({ duplicateNotificationTitle: e.target.value })} label={this.labels.HubLabelTitle} />
                            </IbssFormControl>
                            <IbssFormControl className='mr-3' fullWidth>
                                <IbssLuxonDateTimePicker
                                    label={this.labels.HubLabelStart}
                                    value={this.state.startDateTime}
                                    onChange={e => this.startDateTimeChanged(e)}
                                    input=
                                    {{
                                        error: this.state.showDateTimeError,
                                        helperText: this.state.showDateTimeError ? this.labels.funcAnnouncementStartError_Message : ''
                                    }}
                                />
                            </IbssFormControl>
                            <IbssFormControl fullWidth>
                                <IbssLuxonDateTimePicker
                                    label={this.labels.HubLabelEnd}
                                    value={this.state.endDateTime}
                                    onChange={e => this.endDateTimeChanged(e)}
                                    input=
                                    {{
                                        error: this.state.showDateTimeError,
                                        helperText: this.state.showDateTimeError ? this.labels.funcAnnouncementEndError_Message : ''
                                    }}
                                />
                            </IbssFormControl>
                            </Box>
                        </Box>
                    }
                    footer=
                    {
                        <>
                            <IbssButtonRedo
                                color="primary"
                                variant="contained"
                                size="medium"
                                onClick={() => this.createDuplicate()}
                                disabled={this.state.selectedBuildings.length === 0} 
                            >
                                {this.labels.funcDuplicate}
                            </IbssButtonRedo>
                        </>
                    }
                />
                {/* Expire confirmation Modal */}
                <IbssDialog
                    open={this.state.showExpireConfirmationModal}
                    onClose={() => this.setState({ showExpireConfirmationModal: false })}
                    fullWidth
                    header={this.labels.HubLabelModalConfirmTitle}
                    content=
                    {
                        <div>{this.labels.funcAnnouncementsConfirmExpire_Message}</div>
                    }
                    footer=
                    {
                        <>
                            <IbssButtonRedo
                                color="primary"
                                variant="contained"
                                size="medium"
                                onClick={() => this.expireSelectedItems()}
                            >
                                {this.labels.HubLabelOk}
                            </IbssButtonRedo>
                        </>
                    }
                />
                
                        <Card className="table-panel">
                        <IbssPageHeader
                            pageTitle={this.labels.funcAnnouncementsSheduledContent_Short}
                            daysFilter={this.state.dateRangeFilter}
                            todayChanged={(e) => this.dateRangeChanged(e)}
                            startDate={this.state.fromFilter.toJSDate()}
                            endDate={this.state.toFilter.toJSDate()}
                            dateDisabled={this.state.datePickerFiltersDisabled}
                            onStartDateChange={i => this.startDateChanged(i)}
                            onEndDateChange={i => this.endDateChanged(i)}
                            additionalDateFilterOptions={[this.labels.HubLabelTomorrow, this.labels.HubLabelThisWeek, this.labels.HubLabelNextWeek, this.labels.HubLabelThisMonth, this.labels.HubLabelNextMonth]}
                        />
                        <Typography mb={1} className="table-panel-description">
                            {this.labels.funcAnnouncementsTableDescriptionPt1_Message}
                        </Typography>
                        <div style={{ display: 'grid' }}>
                            {/* <IbssPageHeader /> */}
                            <IbssFilter
                                searchTerm={this.state.searchTerm}
                                searchTermChanged={(event) => this.setState({ searchTerm: event.target.value })}
                                filterButtonClicked={() => this.setState({ openFilterModal: true })}
                            />
                            <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', mr: 0 }}>
                                <IbssActionButton buttons={actionButtons} />
                            </Box>
                            <IbssDataGrid
                                className="view-booking-policies-data-grid"
                                onRowClick={row => this.props.history.push("/oneLens/" + this.state.buildingId + "/announcements/edit/" + row.id)}
                                loading={this.state.loading}
                                disableRowSelectionOnClick
                                onRowSelectionModelChange={e => this.setState({ selectedFieldIds: e as string[] })}
                                rowSelectionModel={this.state.selectedFieldIds}
                                checkboxSelection
                                columns={columns}
                                rows={filteredRowData}
                                onPaginationModelChange={() => this.setState({ selectedFieldIds: [] })}
                                pageSizeOptions={[100]}
                            />
                        </div>
                        </Card>
                    </Box>
                </Box>
        )
    }
}

export default ListNotifications;

export interface IProps extends RouteComponentProps<IQueryParams>
{
}

export interface IState
{
    loading: boolean,
    rowData: IGridRow[],
    announcements: IGridRow[],
    dateRangeFilter: string,
    fromFilter: DateTime,
    toFilter: DateTime,
    datePickerFiltersDisabled: boolean,
    searchTerm: string,
    openFilterModal: boolean
    broadcastSelectValue: string;
    statusSelectValue: string;
    selectedAnnouncementCreatorEmail: string;
    selectedAnnouncementCreator: IUser | null;
    showDeleteConfirmationModal: boolean;
    selectedFieldIds: string[],
    buildingId: number,
    showDuplicateNotificationModal: boolean,
    duplicateNotificationTitle: string,
    showExpireConfirmationModal: boolean,
    selectedAnnouncement: IGridRow | null,
    duplicateBuildingOptions: IOption[];
    startDateTime: DateTime;
    endDateTime: DateTime;
    showDateTimeError: boolean;
    selectedBuildings: number[];
}

interface IOption
{
    label: string;
    value: number;
}

export interface IQueryParams
{
    buildingid: string;
}

export interface IGridRow
{
    id: string;
    broadcast: string;
    label: string;
    title: string;
    start: string;
    end: string;
    priority: number;
    createdBy: string;
    status: string;
    imageUri: string;
    classification: string;
    subClassification: string;
    message: string;
    shortMessage: string;
}