import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import { appContext } from '../../../../AppContext';
import { Typography, Box, SvgIcon, Divider, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, CircularProgress, TextField, Checkbox } from '@mui/material';
import { ClockPickerView } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Dayjs } from 'dayjs';
import { ReactComponent as SpacesIcon } from '../../../../Components/Layout/Sidebar/icons/Spaces.svg';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { IMeetingSpace } from './BookingCriteria';
import { SpacesFilter } from '../../../../Providers.Api/Spaces/SpaceRepository';
import { SpaceView } from './ViewBookingsSchedule';
import { Space } from '../../../../Providers.Api/Spaces/SpaceRepository';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import IbssDatePicker from '../../../../Components/Inputs/DatePicker/IbssDatePicker';
import IbssTimePicker from '../../../../Components/Inputs/TimePicker/IbssTimePicker';
import { DateHelper } from '../../../../Common/DateHelper';
import { IBookingSlots } from '../../../../Providers.Api/BookingPolicies/BookingPolicyRepository';
import { CateringOrdersFilter } from '../../../../Providers.Api/CateringOrders/CateringOrderRepository';
import { IPatchCateringOrder } from '../../../../Providers.Api/CateringOrders/PatchCateringOrderEndpoint';
import { IRootNode } from '../../../../Providers.Api/Models';
import { BookingSlotHelper } from '../../../../Common/BookingSlotHelper';
class SwapDateTime extends IbssComponent<IProps, IState>
{
    private get api() { return appContext().apiClient; }
    private get labels() { return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }
    private get hasV2Rights() { return appContext().localStorageProvider.hasRight("API.Bookings.V2"); }
    private nodes: IRootNode;

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            bookingDate: new Date(),
            start: new Date(),
            end:  new Date(),

            bookingSlotsPolicy: this.props.bookingSlotsPolicy,
            startIntervalMinutes: [],
            endIntervalMinutes: [],
            isStartValid: true,
            isEndValid: true,
            timeDifference: '',

            isLoading: false,
            spaces: [],
            selectedSpaceId: this.props.space.spaceId,
            bookedEquipment: [],
            moveResources: true,
            showSpacesResults: true,
        };

        this.nodes = this.local.getNodeData()
    }

    private getSpacesFilter(): SpacesFilter
    {
        const selectedSpace = this.props.space;
        const numberOfPeople = selectedSpace.capacity ===''? undefined : parseInt(selectedSpace.capacity);
        return new SpacesFilter({
            isEnabled: true,
            workspaceType: selectedSpace.spaceWorkType,
            spaceType: selectedSpace.spaceType,
            availableFrom: DateTime.fromJSDate(this.state.start),
            availableTo: DateTime.fromJSDate(this.state.end),
            audioVisual: !!selectedSpace.requiresAV,
            presentationAids: !!selectedSpace.presentationAidReqs,
            hearingAids: !!selectedSpace.hearingAidReqs,
            catering: !!this.props.cateringOrderId,
            linkedSpace: selectedSpace.spaceSetup> 1,
            // layouts: !!selectedSpace.spaceLayout, 
            minCapacity: numberOfPeople,
        });
    }

    private async loadMeetingRooms(): Promise<void>
    {
        this.setState({isLoading: true});
        const altSpacesfilter = this.getSpacesFilter();
        altSpacesfilter.availableFrom = altSpacesfilter.availableFrom?.setZoneByNode(this.props.buildingId) ?? undefined;
        altSpacesfilter.availableTo = altSpacesfilter.availableTo?.setZoneByNode(this.props.buildingId) ?? undefined;
        // using getV1Spaces directly, instead of using spaceService because getV2Spaces calls on /v2/spaces/search endpoint, which requires AvailableBetween in filter by design. Need this call to return all meeting rooms however. An alternative to call on GET spaces endpoint, but it only exist in V1. 
        
        // call 1 to get list of alternative spaces, 
        const altSpacesResponse = await this.api.spaces.getV1Spaces(Space, this.props.buildingId, 10000, altSpacesfilter);
        const spacesList = altSpacesResponse.map( i => SpaceView.fromSpace(i)).filter( i => i.spaceId !== this.props.space.spaceId);

        // if list of available spaces already include current space, setState
        if(spacesList.find(i => i.spaceId === this.props.space.spaceId))
        {
            this.setState({
                spaces: spacesList,
                isLoading: false,
            });
        }
        else
        {
            // call 2 to get current Space
            const currentSpaceResponse = await this.api.spaces.getV1SpaceById(this.props.buildingId, this.props.space.spaceId);

            this.setState({
                spaces: currentSpaceResponse ? [SpaceView.fromSpace(currentSpaceResponse), ...spacesList] : spacesList, // if currentSpace contains an element, add it to the front of spacesList, otherwise use spacesList
                isLoading: false, 
            });
        }
    }

    public async componentDidMount(): Promise<void>
    {
        //set start and end time for date & time pickers
        const startTime = DateTime.fromISO(this.props.bookingStart);
        const endTime = DateTime.fromISO(this.props.bookingEnd);
        this.setState({ 
            // Date interface takes monthIndex, rather than month. It is 0 indexed. e.g feb is represented with 1.
            start: startTime.toJSDate(), 
            end: endTime.toJSDate(), 
            bookingDate: startTime.toJSDate(),
        });
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        // if user selects a new SpaceId, get space's booking policy and set bookingSlotsPolicy, startIntervalMinutes & endIntervalMinutes
        if(prevState.selectedSpaceId !== this.state.selectedSpaceId)
        {
            const policyId = this.state.spaces[0]?.bookingPolicyId;
            if (policyId && policyId !== '0')
            {
                const bookingPolicy = await this.api.bookingPolicies.getBookingPolicy(this.props.buildingId, policyId);
                if (bookingPolicy == null)
                {
                    return;
                }

                this.setState({
                    startIntervalMinutes: JSON.parse(bookingPolicy.Booking_Policy).BookingSlots.BookingStart.SpecificMinutes,
                    endIntervalMinutes: JSON.parse(bookingPolicy.Booking_Policy).BookingSlots.BookingEnd.SpecificMinutes,
                    bookingSlotsPolicy: JSON.parse(bookingPolicy.Booking_Policy).BookingSlots ?? {}
                });
            }
        }

        // if user updates bookingDate, start or end states, hide current set of spaces results until user has pressed search button again.
        if(prevState.bookingDate !== this.state.bookingDate || prevState.start !== this.state.start || prevState.end !== this.state.end)
        {
            this.setState({showSpacesResults: false});
        }

        // if bookingDate is updated, startTime and endTime is also updated to be on the same date. 
        if(prevState.bookingDate !== this.state.bookingDate)
        {
            const bookingDate = DateTime.fromJSDate(this.state.bookingDate);
            const startTime = DateTime.fromJSDate(this.state.start);
            const endTime = DateTime.fromJSDate(this.state.end);

            const newStartTime = startTime.set({ year: bookingDate.year, month: bookingDate.month, day: bookingDate.day});
            const newEndTime = endTime.set({ year: bookingDate.year, month: bookingDate.month, day: bookingDate.day});
            
            this.setState({
                start: newStartTime.toJSDate(),
                end: newEndTime.toJSDate(),
            });
        }

    }

    private handleDateChange(event: Date): void
    {
        const selectedDate = new Date(event);
        this.setState({ bookingDate: selectedDate });
    }

    private startTimeChanged(time: Dayjs | null): void
    {
        const date = DateTime.fromJSDate(this.state.bookingDate).date();
        const startTime = ((time == null || !time.isValid()) ? DateHelper.null() : DateTime.fromISO(time.toISOString()).set({ year: date.year, month: date.month, day: date.day }));
        let endTime = (!this.state.isEndValid ? DateHelper.null() : DateTime.fromJSDate(this.state.end).set({ year: date.year, month: date.month, day: date.day }));

        if (startTime.isValid && endTime.isValid && startTime > endTime)
        {
            endTime = startTime.clone();
        }

        const duration = (startTime.isValid && endTime.isValid ? endTime.diff(startTime, ["hours", "minutes"]) : null);
        const durationAsString = (duration == null ? "-" : duration.durationToString());

        this.setState(
        {
            start: (startTime.isValid ? startTime.toJSDate() : this.state.start),
            end: (endTime.isValid ? endTime.toJSDate() : this.state.end),
            isStartValid: startTime.isValid,
            isEndValid: endTime.isValid,
            timeDifference: durationAsString,
        });
    }

    private endTimeChanged(time: Dayjs | null): void
    {
        const date = DateTime.fromJSDate(this.state.bookingDate).date();
        let startTime = (!this.state.isStartValid ? DateHelper.null() : DateTime.fromJSDate(this.state.start).set({ year: date.year, month: date.month, day: date.day }));
        const endTime = ((time == null || !time.isValid()) ? DateHelper.null() : DateTime.fromISO(time.toISOString()).set({ year: date.year, month: date.month, day: date.day }));

        if (startTime.isValid && endTime.isValid && startTime > endTime)
        {
            startTime = endTime.clone();
        }

        const duration = (startTime.isValid && endTime.isValid ? endTime.diff(startTime, ["hours", "minutes"]) : null);
        const durationAsString = (duration == null ? "-" : duration.durationToString());

        this.setState(
        {
            start: (startTime.isValid ? startTime.toJSDate() : this.state.start),
            end: (endTime.isValid ? endTime.toJSDate() : this.state.end),
            isStartValid: startTime.isValid,
            isEndValid: endTime.isValid,
            timeDifference: durationAsString,
        });
    }

    private async closeBooking(): Promise<void>
    {
        // close the swap and bookingCriteria, get new bookings data.

        // refresh bookings data
        await this.props.getBookings();
        // hide bookingCriteria slider.
        this.props.showBookingCriteria(false);
        this.setState({isLoading: false});
        this.props.setOpen(false);
    }

    private async modifyBooking(): Promise<void>
    {
        this.setState({isLoading: true});

        const cateringCancelPayload = 
        { 
            Catering_Status: 'CancelledNoCharge' 
        };

        const caterinPatchPayload = 
        { 
            Catering_Status: 'PendingApproval', 
            Space_Id: this.state.selectedSpaceId,
            Catering_Service_Time: DateTime.fromJSDate(this.state.start).setZoneByNode(this.props.buildingId).toISO(),
            Catering_Clearing_Time: DateTime.fromJSDate(this.state.end).setZoneByNode(this.props.buildingId).toISO(),
        };

        if(this.state.moveResources === true && this.hasV2Rights)
        {
            //bookings patch only exists on v2, and if user elects not to moveResources, the change to booking is made via an update call.
            try
            {
                await this.props.patchBooking(this.state.selectedSpaceId, DateTime.fromJSDate(this.state.start), DateTime.fromJSDate(this.state.end));
                await this.patchCateringOrder(caterinPatchPayload);
            }
            catch
            {
                return;
            }
            finally
            {
                await this.closeBooking()
            }
        }
        else if (this.state.moveResources === true && !this.hasV2Rights)
        {
            try
            {
                await this.props.updateBooking(this.state.selectedSpaceId, this.state.start, this.state.end);
                await this.patchCateringOrder(caterinPatchPayload);
            }
            catch
            {
                return;
            }
            finally
            {
                await this.closeBooking();
            }
        }
        else
        {
            // additional steps to update a booking to handle NOT moving across a booking's resources and catering to a new space or time.
            try
            {
                await this.props.updateBooking(this.state.selectedSpaceId, this.state.start, this.state.end);
                await this.patchCateringOrder(cateringCancelPayload);
                await this.deleteEquipment(this.props.nodeId, this.props.bookingId);
            }
            catch
            {
                return;
            }
            finally
            {
                await this.closeBooking();
            }
        }
    }

    private async deleteEquipment(nodeId: number, bookingId: string): Promise<void>
    {   
        // delete booked equipment, e.g. hearing aids and presentation aids.     
        try
        {
            const bookingParties = await this.api.bookingParties.getByBookingId(nodeId, bookingId);
            const bookedEquipment = bookingParties.map(i => BookedEquipment.fromEquipment({...i, 'Floor_Name': this.nodes.Regions.flatMap(region => region.Buildings.flatMap(building => building.Floors.find(floor => floor.Node_Id === nodeId)?.Node_Name ?? ''))[0] }));
            this.setStateAsync({bookedEquipment: bookedEquipment});
        }
        catch
        {
            return;
        }

        if(this.state.bookedEquipment.length === 0)
        {
            return;
        }

        const deleteEquipmentIdsPromises = this.state.bookedEquipment.map(i => this.api.bookingParties.delete(nodeId, i.recordId));
        const promiseAll = Promise.all(deleteEquipmentIdsPromises);
        
        try
        {
            await promiseAll;
        }
        catch
        {
            return;
        }
    }

    private async patchCateringOrder(payload: IPatchCateringOrder): Promise<void>
    {

        const selectedSpace = this.state.spaces.find(i => i.spaceId === this.state.selectedSpaceId);

        // if space has no catering, return out of function.
        if(selectedSpace?.metaServReqsCatering!==1 ?? true)
        {
            return;
        }

        try 
        {
            const filter = new CateringOrdersFilter(
                {
                    bookingId: this.props.bookingId
                });

            const order = await this.api.cateringOrders.getMany(this.props.buildingId, filter);
            if (order.length > 0)
            {
                // patch the booking order
                this.api.cateringOrders.patchCateringOrder(this.props.nodeId, order[0].Order_Id, payload);
            }           
        }
        catch (error) 
        {
            return;
        }
    }

    private async searchSpaces(): Promise<void>
    {
        this.setState({
            showSpacesResults: true,
            selectedSpaceId: this.props.space.spaceId, // reset selectedSpaceId to initial value when calling searchSpaces, to show user where they are.
        });
        await this.loadMeetingRooms();
    }

    public render(): JSX.Element
    {
        const bookingOnLinkedSpace = this.props.bookingSpaceId.includes(';');
        return (
            <div className="flexMySearch-filter-criteria m-3 ml-5 mr-5">
                <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'baseline', mt: '3rem'}}>
                    <div className="flexMySearch-filter-criteria-title">
                        <Typography variant="h4" sx={{fontFamily: 'Source Sans Pro', fontWeight: '700', mb: '0.5rem', color: (theme)=> theme.palette.text.primary}}>
                            {/* {`Swap date and time`} */}
                            {this.labels.funcSwapTimeSwapDateAndTime_S}
                        </Typography>
                    </div>
                    <IbssSvgIcon cursor='pointer' fontSize='large' sx={{ color: (theme) => theme.palette.text.primary }} onClick={() => this.props.setOpen(false)}>
                        {Icons.RightArrowIcon}
                    </IbssSvgIcon>
                </Box>

                <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', mb: '1.5rem', color: (theme)=> theme.palette.text.secondary}}>
                    {/* {'Original date for meeting is set to: '} */}
                    {`${this.labels.funcSwapTimeOriginalDateMeeting_S}: `}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'end', mb: '0.5rem'}}>
                    <SvgIcon component={SpacesIcon} inheritViewBox sx={{height: '34px', width: '34px', marginRight: '1rem',color: (theme)=> theme.palette.text.primary}}/>
                    <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: '700', color: (theme)=> theme.palette.text.primary}}>
                        {DateTime.fromJSDate(this.state.bookingDate).toLocaleDateString() ?? ''}
                    </Typography>
                </Box>
                <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme)=> theme.palette.text.secondary}}>
                    {/* {'Curent Date'} */}
                    {this.labels.funcSwapTimeCurrentDate_S.toLocaleUpperCase()}
                </Typography>
                <Divider  sx={{ mt: '1.5rem', mb: '1.5rem' }} />
                <Box>
                    {bookingOnLinkedSpace &&
                        <Box sx={{padding: '0.8rem', mb: '1rem', backgroundColor: (theme)=> theme.palette.error.light}} borderRadius={'8px'}>
                            <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme)=> theme.palette.error.main}}>
                                {this.labels.funcSwapSpaceLinkedWarning_L}
                            </Typography>
                            <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme)=> theme.palette.error.main}}>
                                {this.labels.funcSwapSpaceLinkedWarning_D}
                            </Typography>
                        </Box>
                    }
                    <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', mb: '1rem', color: (theme)=> theme.palette.text.secondary}}>
                        {/* {`Provide a new date and time for the meeting and we will check if the current room, or other rooms are available for this slot which is able to accommodate the number of attendees selected.`} */}
                        {this.labels.funcSwapTimeSearchSpacesInstruction_D}
                    </Typography>

                    <IbssDatePicker
                        label={this.labels.HubLabelDate}
                        value={this.state.bookingDate}
                        onChange={(event) => this.handleDateChange(event as Date)}
                        renderInput={(params) =>
                        {
                            const { sx, ...paramsMinusSx } = params
                            return <TextField

                                {...paramsMinusSx} 
                                fullWidth 
                                error={false}
                            />
                        }}
                        InputAdornmentProps={{sx:{padding:'0px', marginLeft: '0px'}}}
                        //disabled={updateBookingBtnDisable}
                        minDate={DateHelper.now().toJSDate()}
                        shouldDisableDate={date => BookingSlotHelper.isDateUnavailable(date as unknown as DateTime, this.state.bookingSlotsPolicy)}
                    />

                        <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', mt: '1rem', mb: '1rem', gap: '0.5rem'}}>
                            <Box style={{ alignSelf: 'center' }}>
                                <IbssTimePicker
                                    shouldDisableTime={(time, type) => BookingSlotHelper.isTimeUnavailable(time, type, 'start', this.state.bookingDate, this.state.start, this.state.end, this.state.bookingSlotsPolicy )}
                                    // className="inputboxmodel-date"
                                    label={this.labels.HubLabelFrom}
                                    value={this.state.start}
                                    onChange={time => this.startTimeChanged(time as (Dayjs | null))}
                                    ampm={false}
                                    minutesStep={BookingSlotHelper.getMinutesStepFromPolicy(this.state.startIntervalMinutes)}
                                    //disabled={updateBookingBtnDisable}
                                    renderInput={(params) =>
                                    {
                                        const { sx, ...paramsMinusSx } = params
                                        return <TextField 
                                            {...paramsMinusSx}
                                            error={false}
                                        />
                                    }}
                                />
                            </Box>
                            <Box style={{ alignSelf: 'center' }}>
                                <IbssTimePicker
                                    shouldDisableTime={(time, type) => BookingSlotHelper.isTimeUnavailable(time, type, 'end', this.state.bookingDate, this.state.start, this.state.end, this.state.bookingSlotsPolicy )}
                                    //className="inputboxmodel-date"
                                    label={this.labels.HubLabelTo}
                                    value={this.state.end}
                                    onChange={time => this.endTimeChanged(time as (Dayjs | null))}
                                    ampm={false}
                                    minutesStep={BookingSlotHelper.getMinutesStepFromPolicy(this.state.endIntervalMinutes)}
                                    // disabled={updateBookingBtnDisable}
                                    renderInput={(params) =>
                                    {
                                        const { sx, ...paramsMinusSx } = params
                                        return <TextField 
                                            {...paramsMinusSx}
                                            error={false}
                                        />
                                    }}
                                />
                            </Box>
                        </Box>
                    
                    <IbssButton
                        variant={'contained'}
                        fullWidth
                        sx={{mt: '1rem', mb: '1rem', padding: '0.75rem', backgroundColor: (theme) => theme.palette.grey[400], color: (theme) => theme.palette.text.primary}} 
                        onClick={async()=> await this.searchSpaces()}
                        disabled={this.state.isLoading}
                    >
                        <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold'}}>
                            {this.labels.HubLabelSearch}
                        </Typography>
                    </IbssButton>
                    {
                        this.state.showSpacesResults && <>
                            <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme)=> theme.palette.text.secondary}}>
                                {/* {`The following spaces with matching criteria have been found`} */}
                                {this.labels.funcSwapTimeSpacesResults_L}
                            </Typography>
                            <FormControl sx={{width:'100%'}}>
                                <FormLabel id="spaces-radio-buttons-group-label" sx={{mt: '1rem', mb: '0.5rem'}}>{this.labels.HubLabelSpaces}</FormLabel>
                                <Box sx={{height: '13vh', maxHeight: '50vh', width: '100%', overflowY: 'auto'}}>
                                    <>
                                        {this.state.isLoading && <Box sx={{display:'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}><CircularProgress/></Box>}
                                        {!this.state.isLoading && 
                                            <RadioGroup
                                                aria-labelledby="spaces-radio-buttons-group-label"
                                                name="spaces-radio-buttons-group"
                                                value={this.state.selectedSpaceId}
                                                onChange={(event)=> this.setState({selectedSpaceId: (event.target as HTMLInputElement).value})}
                                            >
                                                {this.state.spaces.map(i=> <FormControlLabel key={i.spaceId} value={i.spaceId} control={<Radio />} label={<Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme)=> theme.palette.text.primary}}>{i.spaceName}</Typography>} />)}
                                            </RadioGroup>
                                        }
                                    </>
                                </Box>
                            </FormControl>
                        </>
                    }
                </Box>
                <Box sx={{mt: '1rem', mb: '1rem'}}>
                    <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme)=> theme.palette.text.secondary}}>
                        {/* {'By default all existing tasks associated with this meeting will be moved to the new space. To not do this and cancel all existing tasks, uncheck the button below.'} */}
                        {this.labels.funcSwapTimeMoveResourcesInstruction_D}
                    </Typography>
                </Box>

                <Box sx={{mt: '1rem', mb: '1rem'}}>
                    <FormControl>
                        <FormControlLabel 
                            control={
                                <Checkbox
                                    checked={this.state.moveResources}
                                    onChange={()=> this.setState({moveResources: !this.state.moveResources})}
                                />
                            } 
                            label={
                                <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme)=> theme.palette.text.secondary}}>
                                    {/* {'Move resources and catering orders to new location'} */}
                                    {this.labels.funcSwapTimeMoveResources_L}
                                </Typography>
                            } 
                        />
                    </FormControl>
                </Box>

                <IbssButton
                    variant={'contained'}
                    fullWidth
                    size={'large'}
                    sx={{mt: '1rem', padding: '0.75rem'}} 
                    onClick={async()=> await this.modifyBooking()}
                    //disable confirm swapDateTime button if the Search button has not been pressed. if component is loading or if for the same spaceId, booking date, start and end times have not bee updated.
                    disabled={!this.state.showSpacesResults || this.state.isLoading}
                >
                    <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold'}}>
                        {/* {'Confirm Space Swap'} */}
                        {this.labels.funcSwapSpaceSwap_S}
                    </Typography>
                </IbssButton>
            </div>
        )
    }
}

export default SwapDateTime;

export interface IProps
{
    open: boolean,
    bookingStart: string, // ISO compliant string representations of UTC time, passed from a get bookings call made in scheduleView.
    bookingEnd: string,
    bookingId: string,
    bookingSpaceId: string,
    
    bookingSlotsPolicy: IBookingSlots,
    startIntervalMinutes: string[],
    endIntervalMinutes: string[],

    buildingId: number,
    nodeId: number,
    space: IMeetingSpace,
    cateringOrderId: string,
    setOpen: (val: boolean) => void,
    updateBooking: (spaceId: string, startTime: Date, endTime: Date) => Promise<void>,
    patchBooking: (spaceId: string, startTime: DateTime, endTime: DateTime) => Promise<void>,
    showBookingCriteria: (val: boolean) => void,
    getBookings: () => Promise<void>,
}

export interface IState
{
    bookingDate: Date,
    start: Date,
    end: Date,

    bookingSlotsPolicy: IBookingSlots,
    startIntervalMinutes: string[],
    endIntervalMinutes: string[],
    isStartValid: boolean,
    isEndValid: boolean,
    timeDifference: string,

    isLoading: boolean,
    spaces: SpaceView[],
    selectedSpaceId: string,

    bookedEquipment: BookedEquipment[],
    moveResources: boolean,
    showSpacesResults: boolean,
}

export interface IBookedEquipment
{
    Booking_End: string;
    Booking_Id: string;
    Booking_Participant_CheckedIn: number;
    Booking_Participant_Email: string;
    Booking_Participant_Name: string;
    Booking_Participant_Organisation: string;
    Booking_Participant_Type: number;
    Booking_Resource_Id: string;
    Booking_Start: string;
    Booking_Visitor: number;
    Node_Id: number;
    Record_Id: string;
    Floor_Name: string;
}

export class BookedEquipment
{
    public bookingEnd = '';
    public bookingId ='';
    public bookingParticipantCheckedIn = 0;
    public bookingParticipantEmail = '';
    public bookingParticipantName = '';
    public bookingParticipantOrganisation = '';
    public bookingParticipantType = 0;
    public bookingResourceId = '';
    public bookingStart = '';
    public bookingVisitor = 0;
    public nodeId = 0;
    public recordId = '';
    public floorName = '';

    public static fromEquipment(equipment: IBookedEquipment): BookedEquipment
    {        
        return {
            bookingEnd: equipment.Booking_End,
            bookingId: equipment.Booking_Id,
            bookingParticipantCheckedIn: equipment.Booking_Participant_CheckedIn,
            bookingParticipantEmail: equipment.Booking_Participant_Email,
            bookingParticipantName: equipment.Booking_Participant_Name,
            bookingParticipantOrganisation: equipment.Booking_Participant_Organisation,
            bookingParticipantType: equipment.Booking_Participant_Type,
            bookingResourceId: equipment.Booking_Resource_Id,
            bookingStart: equipment.Booking_Start,
            bookingVisitor: equipment.Booking_Visitor,
            nodeId: equipment.Node_Id,
            recordId: equipment.Record_Id,
            floorName: equipment.Floor_Name,
        };
    }
}