import { DateTime } from "luxon";
import { DateHelper } from "../Common/DateHelper";

export class SessionStorageProvider implements ISessionStorageProvider
{

    public getBuildingId(): number
    {
        const value = parseInt(sessionStorage["buildingId"]);
        return (isNaN(value) ? 0 : value);
    }

    public setBuildingId(value: number): void
    {
        sessionStorage["buildingId"] = value;
    }

    public removeBuildingId():void
    {
        sessionStorage.removeItem("buildingid");
    }

    public getBuildingName(): string
    {
        return sessionStorage["buildingName"] ?? "";
    }

    public setBuildingName(value: string): void
    {
        sessionStorage["buildingName"] = value;
    }

    public removeBuildingName(): void 
    {
        sessionStorage.removeItem("buildingName"); 
    }

    public getFlexSpaceSearchCriteria(): { startTime: DateTime, endTime: DateTime }
    {
        let searchCriteria = { startTime: DateHelper.null(), endTime: DateHelper.null() };
        let valueAsString = sessionStorage["flexSpaceSearchCriteria"] as (string | null);

        if (valueAsString != null)
        {
            const valueAsObject = JSON.parse(valueAsString) as { startTime: string, endTime: string };
            searchCriteria.startTime = DateTime.fromISO(valueAsObject.startTime);
            searchCriteria.endTime = DateTime.fromISO(valueAsObject.endTime);
        }
        return searchCriteria;
    }

    public setFlexSpaceSearchCriteria(startTime: DateTime, endTime : DateTime): void
    {
        sessionStorage["flexSpaceSearchCriteria"] = JSON.stringify({ startTime: startTime, endTime: endTime });
    }

    public getSpaceAnalyticsCriteria(): ISpaceAnalyticsFilter
    {
        let searchCriteria: ISpaceAnalyticsFilter = { startDate: DateHelper.null(), endDate: DateHelper.null(), spaceId: "", periodType:  1};
        let valueAsString = sessionStorage["spaceAnalyticsCriteria"] as (string | null);

        if (valueAsString != null)
        {
            const valueAsObject = JSON.parse(valueAsString) as ISpaceAnalyticsFilter;
            searchCriteria.startDate = DateHelper.fromIsoOrDateTime(valueAsObject.startDate);
            searchCriteria.endDate = DateHelper.fromIsoOrDateTime(valueAsObject.endDate);
            searchCriteria.spaceId = valueAsObject.spaceId;
            searchCriteria.periodType = valueAsObject.periodType;

        }
        return searchCriteria;
    }

    public setSpaceAnalyticsCriteria(filter : ISpaceAnalyticsFilter): void
    {
        sessionStorage["spaceAnalyticsCriteria"] = JSON.stringify(filter);
    }
    

    public clear(): void
    {
        sessionStorage.clear();
    }
}

export interface ISessionStorageProvider
{
    getBuildingId(): number;
    setBuildingId(value: number): void;
    getBuildingName(): string;
    setBuildingName(value: string): void;
    removeBuildingId(): void;
    removeBuildingName(): void;
    clear(): void;
    getFlexSpaceSearchCriteria(): { startTime: DateTime, endTime: DateTime };
    setFlexSpaceSearchCriteria(startTime: DateTime, endTime : DateTime): void;
    setSpaceAnalyticsCriteria(filter : ISpaceAnalyticsFilter): void;
    getSpaceAnalyticsCriteria(): ISpaceAnalyticsFilter;
}

export interface ISpaceAnalyticsFilter
{
    startDate: DateTime,
    endDate : DateTime,
    spaceId: string,
    periodType?: number,
}
