import { Component } from 'react';
import { appContext } from '../../../../AppContext';
import { Typography, Box, SvgIcon, Divider, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, CircularProgress } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactComponent as SpacesIcon } from '../../../../Components/Layout/Sidebar/icons/Spaces.svg';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { IMeetingSpace } from './BookingCriteria';
import { SpacesFilter } from '../../../../Providers.Api/Spaces/SpaceRepository';
import { SpaceView } from './ViewBookingsSchedule';
import { Space } from '../../../../Providers.Api/Spaces/SpaceRepository';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { CateringOrdersFilter } from '../../../../Providers.Api/CateringOrders/CateringOrderRepository';
import { IPatchCateringOrder } from '../../../../Providers.Api/CateringOrders/PatchCateringOrderEndpoint';

class SwapSpace extends Component<IProps, IState>
{
    private get api() { return appContext().apiClient; }
    private get labels() { return appContext().labels; }
    private get hasV2Rights() { return appContext().localStorageProvider.hasRight("API.Bookings.V2"); }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            isLoading: false,
            spaces: [],
            selectedSpaceId: this.props.space.spaceId,
        };
    }

    private getSpacesFilter(): SpacesFilter
    {
        const selectedSpace = this.props.space;
        const numberOfPeople = selectedSpace.capacity ===''? undefined : parseInt(selectedSpace.capacity);
        return new SpacesFilter({
            isEnabled: true,
            workspaceType: selectedSpace.spaceWorkType,
            spaceType: selectedSpace.spaceType,
            availableFrom: DateTime.fromISO(this.props.bookingStart), // props.bookingStart, bookingEnd are ISO strings in UTC timezone.
            availableTo: DateTime.fromISO(this.props.bookingEnd),
            audioVisual: !!selectedSpace.requiresAV,
            presentationAids: !!selectedSpace.presentationAidReqs,
            hearingAids: !!selectedSpace.hearingAidReqs,
            catering: !!this.props.cateringOrderId,
            linkedSpace: selectedSpace.spaceSetup> 1, // align with condition (selectedSpace.spaceSetup> 1) to show spaceLayout & spaceArrangement in BookingCriteriaSlider.
            // layouts: !!selectedSpace.spaceLayout, 
            minCapacity: numberOfPeople,
        });
    }

    private async loadMeetingRooms(): Promise<void>
    {
        this.setState({isLoading: true});
        const filter = this.getSpacesFilter();
        filter.availableFrom = filter.availableFrom?.setZoneByNode(this.props.buildingId) ?? undefined;
        filter.availableTo = filter.availableTo?.setZoneByNode(this.props.buildingId) ?? undefined;
        // using getV1Spaces directly, instead of using spaceService because getV2Spaces calls on /v2/spaces/search endpoint, which requires AvailableBetween in filter by design. Need this call to return all meeting rooms however. An alternative to call on GET spaces endpoint, but it only exist in V1. 
        const response = await this.api.spaces.getV1Spaces(Space, this.props.buildingId, 10000, filter);
        const spaceView = response.map( i => SpaceView.fromSpace(i)).filter( i => i.spaceId !== this.props.space.spaceId);
        this.setState({
            spaces: spaceView,
            isLoading: false, 
        });
    }

    public async componentDidMount(): Promise<void>
    {
        await this.loadMeetingRooms();
    }

    public async componentDidUpdate(prevProps: IProps): Promise<void>
    {
        if(prevProps.space.spaceId !== this.props.space.spaceId)
        {
            await this.loadMeetingRooms();
        }
    }

    private async patchCateringOrder(payload: IPatchCateringOrder): Promise<void>
    {
        const selectedSpace = this.state.spaces.find(i => i.spaceId === this.state.selectedSpaceId);

        // if space has no catering, return out of function.
        if(selectedSpace?.metaServReqsCatering!==1 ?? true)
        {
            return;
        }

        try 
        {
            const filter = new CateringOrdersFilter(
                {
                    bookingId: this.props.bookingId
                });

            const order = await this.api.cateringOrders.getMany(this.props.buildingId, filter);
            if (order.length > 0)
            {
                // patch the booking order
                this.api.cateringOrders.patchCateringOrder(this.props.nodeId, order[0].Order_Id, payload);
            }           
        }
        catch (error) 
        {
            return;
        }
    }
  
    private async closeBooking(): Promise<void>
    {
        // close the swap and bookingCriteria, get new bookings data.

        // refresh bookings data
        await this.props.getBookings();
        // hide bookingCriteria slider.
        this.props.showBookingCriteria(false);
        this.setState({isLoading: false});
        this.props.setOpen(false);
    }

    private async modifyBooking(): Promise<void>
    {
        this.setState({isLoading: true});

        const caterinPatchPayload = 
        { 
            Catering_Status: 'PendingApproval', 
            Space_Id: this.state.selectedSpaceId,
        };

        if(this.hasV2Rights)
        {
            //bookings patch only exists on v2,
           this.props.patchBooking(this.state.selectedSpaceId, DateTime.fromISO(this.props.bookingStart), DateTime.fromISO(this.props.bookingEnd))
           .then(()=> this.patchCateringOrder(caterinPatchPayload))
           .finally(async()=> await this.closeBooking());
        }
        else
        {
            await this.props.updateBooking(this.state.selectedSpaceId)
            .then(()=> this.patchCateringOrder(caterinPatchPayload))
            .finally(async()=> await this.closeBooking());
        }
    }

    public render(): JSX.Element
    {
        const bookingOnLinkedSpace = this.props.bookingSpaceId.includes(';');
        return (
            <div className="flexMySearch-filter-criteria m-3 ml-5 mr-5">
                <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'baseline', mt: '3rem'}}>
                    <div className="flexMySearch-filter-criteria-title">
                        <Typography variant="h4" sx={{fontFamily: 'Source Sans Pro', fontWeight: '700', mb: '0.5rem', color: (theme)=> theme.palette.text.primary}}>
                            {this.labels.funcSwapSpaceSwap_S}
                        </Typography>
                    </div>
                    <IbssSvgIcon cursor='pointer' fontSize='large' sx={{ color: (theme) => theme.palette.text.primary }} onClick={() => this.props.setOpen(false)}>
                        {Icons.RightArrowIcon}
                    </IbssSvgIcon>
                </Box>

                <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', mb: '1.5rem', color: (theme)=> theme.palette.text.secondary}}>
                    {this.labels.funcBookingScheduleSelectSpace_S}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'end', mb: '0.5rem'}}>
                    <SvgIcon component={SpacesIcon} inheritViewBox sx={{height: '34px', width: '34px', marginRight: '1rem',color: (theme)=> theme.palette.text.primary}}/>
                    <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: '700', color: (theme)=> theme.palette.text.primary}}>
                        {this.props.bookingSpaceName}
                    </Typography>
                </Box>
                <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme)=> theme.palette.text.secondary}}>
                    {this.labels.funcSwapSpaceCurrentSpace_S.toLocaleUpperCase()}
                </Typography>
                <Divider  sx={{ mt: '1.5rem', mb: '1.5rem' }} />
                <Box>
                    {bookingOnLinkedSpace &&
                        <Box sx={{padding: '0.8rem', mb: '1rem', backgroundColor: (theme)=> theme.palette.error.light}} borderRadius={'8px'}>
                            <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme)=> theme.palette.error.main}}>
                                {this.labels.funcSwapSpaceLinkedWarning_L}
                            </Typography>
                            <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme)=> theme.palette.error.main}}>
                                {this.labels.funcSwapSpaceLinkedWarning_D}
                            </Typography>
                        </Box>
                    }
                    <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme)=> theme.palette.text.secondary}}>
                        {/* {`Based on your original room requirements and attendees, the following alterantive spaces are available to swap spaces with.`} */}
                        {this.labels.funcSwapSpaceAlternativeSpaces_D}
                    </Typography>
                    <FormControl sx={{width:'100%'}}>
                        <FormLabel id="spaces-radio-buttons-group-label" sx={{mt: '1rem', mb: '0.5rem'}}>{this.labels.HubLabelSpaces}</FormLabel>
                        <Box sx={{height: '13vh', maxHeight: '50vh', width: '100%', overflowY: 'auto'}}>
                            <>
                                {this.state.isLoading && <Box sx={{display:'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}><CircularProgress/></Box>}
                                {!this.state.isLoading && 
                                    <RadioGroup
                                        aria-labelledby="spaces-radio-buttons-group-label"
                                        name="spaces-radio-buttons-group"
                                        value={this.state.selectedSpaceId}
                                        onChange={(event)=> this.setState({selectedSpaceId: (event.target as HTMLInputElement).value})}
                                    >
                                        {this.state.spaces.map(i=> <FormControlLabel key={i.spaceId} value={i.spaceId} control={<Radio />} label={<Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme)=> theme.palette.text.primary}}>{i.spaceName}</Typography>} />)}
                                    </RadioGroup>
                                }
                            </>
                        </Box>
                    </FormControl>
                </Box>
                <IbssButton
                    variant={'contained'}
                    fullWidth
                    size={'large'}
                    sx={{mt: '1rem', padding: '0.75rem'}}  
                    onClick={async()=> await this.modifyBooking()}
                    disabled={this.props.space.spaceId===this.state.selectedSpaceId || this.state.isLoading}
                >
                    <Typography sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold'}}>
                        {this.labels.funcSwapSpaceConfirmSwap_S}
                    </Typography>
                </IbssButton>
            </div>
        )
    }
}

export default SwapSpace;

export interface IProps
{
    open: boolean,
    bookingStart: string,
    bookingEnd: string,
    bookingId: string,
    bookingSpaceId: string,
    bookingSpaceName: string,
    buildingId: number,
    nodeId: number,
    space: IMeetingSpace,
    cateringOrderId: string,
    setOpen: (val: boolean) => void,
    patchBooking: (spaceId: string, startTime: DateTime, endTime: DateTime) => Promise<void>,
    updateBooking: (spaceId: string) => Promise<void>,
    showBookingCriteria: (val: boolean) => void,
    getBookings: () => Promise<void>,
}

export interface IState
{
    isLoading: boolean,
    spaces: SpaceView[],
    selectedSpaceId: string,
}