import { Component } from "react";
import { IPartialAppState, appContext } from "../../../../AppContext";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import ChartPanelInfo from "../../../../Components/Headings/ChartHeading/ChartPanelInfo";
import { DateHelper } from "../../../../Common/DateHelper";
import { DateTime } from "luxon";
import PieChartCard, { IPieChartSlice } from "../../../../Components/Cards/PieChartCard/PieChartCard";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { Visit, VisitFilter } from "../../../../Providers.Api/Visits/VisitsRepository";
import { ODataQuery } from "../../../../Providers.Api/ODataQuery";
import { IProps, IState, IBar, ApprovalStatus, CompletionStatus, CheckInStatus } from "./Models";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";

class ViewVisitTrends extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get apiClient() { return appContext().apiClient; }
    private now  = DateHelper.null();


    constructor(props: IProps)
    {
        super(props);
        this.state = {
            isLoading: false,
            selectedDate: DateHelper.null(),
            buildingid: this.appState.buildingId,
            visits: [],
            overviewChart: [],
            overviewChartWidth: 0,
            approvalStatusChart: [],
            completionStatusChart: [],
            checkInStatusChart: [],
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.now  = DateHelper.now(this.state.buildingid);
        const { match, history } = this.props;
        this.appState.subscribe(this, i => this.appStateChanged(i));
        const buildingId = match.params["buildingid"];
        await this.appState.set({ buildingId: parseInt(buildingId) });
        this.windowResized();

        if (this.state.buildingid)
        {
            this.setState({ buildingid: this.state.buildingid });
            if (this.state.buildingid !== parseInt(match.params.buildingid))
            {
                let temp = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));
                history.push(temp + "/" + this.state.buildingid)
            }
        }

        if (this.state.buildingid && match.params.buildingid !== null && this.state.buildingid !== parseInt(match.params.buildingid))
        {
            history.push("/operational-services-visitors-trends/" + this.state.buildingid)
        }
        else if (this.state.buildingid && match.params.filter)
        {
            history.push("/operational-services-visitors-trends/" + this.state.buildingid + "/" + match.params.tab + "/" + match.params.filter)
        }
        else if (this.state.buildingid)
        {
            history.push("/operational-services-visitors-trends/" + this.state.buildingid)
        }
        else
        {
            history.push("/operational-services-visitors-trends/0")
        }

        this.loadCharts();
        window.addEventListener('resize', () => this.windowResized());
        window.addEventListener('load', () => this.windowResized());
    }

    public componentDidUpdate(prevProps: Readonly<{}>, prevState: IState): void
    {
        if (prevState.buildingid != this.state.buildingid)
        {
            this.loadCharts();
        }
    }

    private async appStateChanged(state: IPartialAppState): Promise<void>
    {
        const { history, match } = this.props;
        if (state.buildingId != undefined)
        {
            this.setState({ buildingid: state.buildingId });
            history.push(match.path.replace(":buildingid", state.buildingId.toString()));
        }
    }

    private async windowResized(): Promise<void>
    {
        this.setState({ overviewChartWidth: Math.min(Math.max(window.innerWidth - 390, 960), 2200) });
    }

    private async loadCharts(): Promise<void>
    {
        const { buildingid } = this.state;
        const today = DateHelper.today(buildingid);
        const from = today.minus({ days: 7 });
        const to = today.plus({ days: 14 });
        this.setState({ isLoading: true, selectedDate: today });

        const query = new ODataQuery({
            top: 2000,
            nodeId: buildingid,
            select: Visit,
            filter: new VisitFilter({
                minStartDate: from.setZoneByNode(buildingid),
                maxStartDate: to.setZoneByNode(buildingid),
            }),
        });

        let visits = new Array<Visit>();
        try
        {
            visits = await this.apiClient.visits.getMany(query);
            visits.forEach(i =>
            {
                i.Visit_Start_Date = i.Visit_Start_Date.offsetTimeByNode(buildingid, false);
            });
        }
        catch { }

        await this.setStateAsync({ visits: visits });
        this.loadOverviewChart();
        this.loadApprovalStatusChart();
        this.loadCompletionStatusChart();
        this.loadCheckInStatusChart();
        this.setState({ isLoading: false });
    }

    private loadOverviewChart(): void
    {
        const { buildingid, visits } = this.state;
        const chart: IBar[] = [];
        const today = DateHelper.today(buildingid, false);

        for (let day = 0; day < 14; day++)
        {
            const date = today.plus({ days: day });
            const statuses = this.visitsByDate(date).map(i => this.approvalStatus(i));

            const bar: IBar = {
                name: date.toLocaleDateString(),
                awaitingApproval: statuses.filter(i => i == "AwaitingApproval").length,
                approved: statuses.filter(i => i == "Approved").length,
                denied: statuses.filter(i => i == "Denied").length,
                neverApproved: statuses.filter(i => i == "NeverApproved").length,
            };
            chart.push(bar);
        }
        this.setState({ overviewChart: chart });
    }

    private loadApprovalStatusChart(): void
    {
        const visits = this.visitsByDate(this.state.selectedDate);
        const chart = new Array<IPieChartSlice>();
        const statuses = visits.map(i => this.approvalStatus(i));

        const denied = statuses.filter(i => i == "Denied").length;
        const neverApproved = statuses.filter(i => i == "NeverApproved").length;
        const awaitingApproval = statuses.filter(i => i == "AwaitingApproval").length;
        const approved = statuses.filter(i => i == "Approved").length;
        const total = denied + neverApproved + awaitingApproval + approved;

        chart.push({ name: this.labels.HubLabelDeniedLabel, value: Math.round((denied / total) * 100), count: denied })
        chart.push({ name: this.labels.HubLabelNeverApproved, value: Math.round((neverApproved / total) * 100), count: neverApproved })
        chart.push({ name: this.labels.HubTabAwaitingApproval, value: Math.round((awaitingApproval / total) * 100), count: awaitingApproval })
        chart.push({ name: this.labels.HubLabelApproved, value: Math.round((approved / total) * 100), count: approved })

        this.setState({ approvalStatusChart: chart });
    }

    private loadCompletionStatusChart(): void
    {
        const visits = this.visitsByDate(this.state.selectedDate);
        const chart = new Array<IPieChartSlice>();
        const statuses = visits.map(i => this.completionStatus(i));

        const cancelled = statuses.filter(i => i == "Cancelled").length;
        const completed = statuses.filter(i => i == "Completed").length;
        const active = statuses.filter(i => i == "Active").length;
        const total = cancelled + completed + active;

        chart.push({ name: this.labels.HubLabelCancelled, value: Math.round((cancelled / total) * 100), count: cancelled })
        chart.push({ name: this.labels.HubLabelCompleted, value: Math.round((completed / total) * 100), count: completed })
        chart.push({ name: this.labels.HubLabelActive, value: Math.round((active / total) * 100), count: active })

        this.setState({ completionStatusChart: chart });
    }

    private loadCheckInStatusChart(): void
    {
        const visits = this.visitsByDate(this.state.selectedDate);
        const chart = new Array<IPieChartSlice>();
        const statuses = visits.map(i => this.checkInStatus(i));

        const notCheckedIn = statuses.filter(i => i == "NotCheckedIn").length;
        const checkedIn = statuses.filter(i => i == "CheckedIn").length;
        const checkedOut = statuses.filter(i => i == "CheckedOut").length;
        const total = notCheckedIn + checkedIn + checkedOut;

        chart.push({ name: this.labels.HubLabelNotCheckedIn, value: Math.round((notCheckedIn / total) * 100), count: notCheckedIn })
        chart.push({ name: this.labels.HubLabelCheckedIn, value: Math.round((checkedIn / total) * 100), count: checkedIn })
        chart.push({ name: this.labels.HubLabelCheckedOut, value: Math.round((checkedOut / total) * 100), count: checkedOut })

        this.setState({ checkInStatusChart: chart });
    }

    private visitsByDate(date: DateTime): Visit[]
    {
        return this.state.visits.filter(i => i.Visit_Start_Date.toFormat("yyyy-MM-dd") == date.toFormat("yyyy-MM-dd"));
    }

    private approvalStatus(visit: Visit): ApprovalStatus
    {
        const isCheckedIn = visit.Visit_IsCheckedIn;
        const isApproved = visit.Visit_IsApproved;
        const isCancelled = visit.Visit_IsCancelled;
        const isNoShow = visit.Visit_IsNoShow;
        const isCheckedOut = visit.Visit_IsCheckedOut;
        const isDenied = visit.Visit_IsDenied;
        const hasStarted = (this.now >= visit.Visit_Start_Date);

        if (!isCheckedIn && !isApproved && !isCancelled && !isNoShow && !isCheckedOut && !isDenied && !hasStarted)
        {
            return "AwaitingApproval";
        }
        else if (isApproved && !isCancelled && !isDenied)
        {
            return "Approved";
        }
        else if (isDenied)
        {
            return "Denied";
        }
        else if (!isCheckedIn && !isApproved && !isCheckedOut && !isDenied && hasStarted)
        {
            return "NeverApproved";
        }
        else
        {
            return "";
        }
    }

    private completionStatus(visit: Visit): CompletionStatus
    {
        const isApproved = visit.Visit_IsApproved;
        const isCancelled = visit.Visit_IsCancelled;
        const isCheckedOut = visit.Visit_IsCheckedOut;

        if (!isApproved && isCancelled)
        {
            return "Cancelled";
        }
        else if (isApproved && isCheckedOut)
        {
            return "Completed";
        }
        else if (isApproved && !isCancelled && !isCheckedOut)
        {
            return "Active";
        }
        else
        {
            return "";
        }
    }

    private checkInStatus(visit: Visit): CheckInStatus
    {
        const isCheckedIn = visit.Visit_IsCheckedIn;
        const isApproved = visit.Visit_IsApproved;
        const isCancelled = visit.Visit_IsCancelled;
        const isNoShow = visit.Visit_IsNoShow;
        const isCheckedOut = visit.Visit_IsCheckedOut;
        const isDenied = visit.Visit_IsDenied;

        if (!isCheckedIn && isApproved && !isCancelled && !isNoShow && !isCheckedOut && !isDenied)
        {
            return "NotCheckedIn";
        }
        else if (isCheckedIn && isApproved && !isCancelled && !isNoShow && !isCheckedOut && !isDenied)
        {
            return "CheckedIn";
        }
        else if (isApproved && !isCancelled && !isNoShow && isCheckedOut && !isDenied)
        {
            return "CheckedOut";
        }
        else
        {
            return "";
        }
    }

    private async barClicked(state: CategoricalChartState): Promise<void>
    {
        if (!state.activeLabel)
        {
            return;
        }

        const selectedDate = DateHelper.fromLocaleDateString(state.activeLabel);
        await this.setStateAsync({ isLoading: true, selectedDate: selectedDate });

        this.loadApprovalStatusChart();
        this.loadCompletionStatusChart();
        this.loadCheckInStatusChart();

        this.setState({ isLoading: false });
    }

    public render(): JSX.Element
    {
        return (
            <>
                {(this.state.isLoading) && <Spinner />}
                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content">
                        <div className="chart-panel-section1">
                            <ChartPanelInfo
                                chartHeading={this.labels.HubLabelVisitorsOverview}
                                iconSrc="/images/Sidebar_Icons/Light_theme/Visitor Management.svg"
                            />
                            <div className="topchart">
                                <BarChart data={this.state.overviewChart} onClick={e => this.barClicked(e)} height={400} width={this.state.overviewChartWidth}>
                                    <CartesianGrid vertical={false} />
                                    <XAxis stroke="var(--ui-text)" dataKey="name" />
                                    <YAxis stroke="var(--ui-text)" label={{ value: this.labels.HubLabelVisitorLevel, angle: -90, position: 'insideLeft', fill: 'var(--ui-text)' }} />
                                    <Tooltip contentStyle={{ color: 'var(--ui-text)', backgroundColor: 'var(--ui-background-alternate)' }} />
                                    <Legend />
                                    <Bar dataKey="denied" name={this.labels.HubLabelDeniedLabel} stackId="a" fill="#9B59B6" />
                                    <Bar dataKey="neverApproved" name={this.labels.HubLabelNeverApproved} stackId="a" fill="#F44336" />
                                    <Bar dataKey="awaitingApproval" name={this.labels.HubTabAwaitingApproval} stackId="a" fill="#FF7913" />
                                    <Bar dataKey="approved" name={this.labels.HubLabelApproved} stackId="a" fill="#2962FF" />
                                </BarChart>
                            </div>
                        </div>
                        <div className="chart-panel-section2 justify-content-between">
                            <PieChartCard dataPie={this.state.approvalStatusChart} dataPieValue={this.state.approvalStatusChart.sum(i => i.count)} colorArray={['#9B59B6', '#F44336', '#FF7913', '#2962FF']} label={this.labels.HubLabelVisitApprovalStatus} selectedDate={this.state.selectedDate} />
                            <PieChartCard dataPie={this.state.completionStatusChart} dataPieValue={this.state.completionStatusChart.sum(i => i.count)} colorArray={['#F44336', '#FFC400', '#00C853', '#2962FF']} label={this.labels.HubLabelApprovedVisits} selectedDate={this.state.selectedDate} />
                            <PieChartCard dataPie={this.state.checkInStatusChart} dataPieValue={this.state.checkInStatusChart.sum(i => i.count)} colorArray={['#FFC400', '#2962FF', '#00C853']} label={this.labels.HubLabelCheckinCheckoutStatusLabel} selectedDate={this.state.selectedDate} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ViewVisitTrends;
