import { Card } from '@mui/material';
import { appContext } from '../../../../AppContext';
import { Icons } from '../../../../Common/AllsvgIcons';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import IbssHorizontalTabs, { TabsParameters } from '../../../../Components/Layout/Tabs/IbssTabs';
import BookingCostCodes from './BookingCostCodes';
import CateringCostCodes from './CateringCostCodes';
import './DefaultCostCodes.scss'

class EditCostCodes extends IbssComponent<IProps, IState>
{

    private get labels() { return appContext().labels; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {

        };
    };

    public componentDidMount(): void
    {
        this.pageTitle = this.labels.funcMenuDefaultCostCodes_Short;
    }

    public render(): JSX.Element
    {

        const listTabs: TabsParameters[] = [
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div className="mr-8px">
                            <IbssSvgIcon>
                                {Icons.building}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.funcDefaultCostCodesBooking_Short}
                        </div>
                    </div>
                ),
                components: <BookingCostCodes />,
            },
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div style={{ marginRight: '18px', marginTop: '5px' }}>
                            <IbssSvgIcon>
                                {Icons.TimeIcon}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.HubMenuCatering}
                        </div>
                    </div>
                ),
                components: <CateringCostCodes />,
            }
        ];

        return (
            <div className='m-3'>
                <IbssHorizontalTabs
                    className='ibss-vertical-tab default-cost-code-tabs'
                    orientation='vertical'
                    tabs={listTabs}
                    boxwidth="344px"
                    leftAlignTabContent
                    sx={{
                        fontSize: "18px",
                        marginTop: "25px",
                        fontFamily: "Source Sans Pro",
                        backgroundColor: `background.paper`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                />
            </div>
        )
    }
}

export default EditCostCodes;

export interface IProps
{
}

export interface IState
{
}