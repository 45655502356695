import React from 'react';
import * as ReactDOM from 'react-dom';
import { createPortal } from 'react-dom';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import { Day, Week, WorkWeek, Month, Agenda, ScheduleComponent, ResourcesDirective, ResourceDirective, ViewsDirective, ViewDirective, Inject, TimelineViews,  GroupModel, EventRenderedArgs, PopupOpenEventArgs, SelectEventArgs, ResourceDetails, RenderCellEventArgs } from '@syncfusion/ej2-react-schedule';
import { appContext } from '../../../../AppContext';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { Grid, Box, Typography, TextField, TablePagination, Button } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider, } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime, Interval } from 'luxon';
import Helper from '../../../../Common/Helper';
import { DateHelper } from '../../../../Common/DateHelper';
import { BookingView, ISelectedSlot, SpaceView, IScheduleDate } from '../../../OneLens/Bookings/ViewSchedule/ViewBookingsSchedule';
import { ODataQuery } from '../../../../Providers.Api/ODataQuery';
import { Space, SpacesFilter } from '../../../../Providers.Api/Spaces/SpaceRepository';
import { RouteComponentProps } from 'react-router';
import ViewBookingDialog from './ViewBookingDialog';
import { ReactComponent as NoSpaceScheduleViewIcon } from '../../../OneLens/Bookings/ViewSchedule/NoSpacesScheduleView.svg';
import SvgIcon from '@mui/material/SvgIcon';
import "./FlexScheduleStyles.scss";
import "../../../OneLens/Bookings/ViewSchedule/ScheduleView.scss";
import ScheduleFilter from './ScheduleFilter';
import { IListOption } from '../../../OneLens/Bookings/ViewSchedule/ScheduleViewModal';
import { IUserPreferences } from '../../../../Providers.Api/UserPreferences/UserPreferenceRepository';
import IbssIconButton from '../../../../Components/Buttons/IconButton/IbssIconButton';
import InfoIcon from '@mui/icons-material/Info';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TodayIcon from '@mui/icons-material/Today';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import SpaceInfoDialog from '../../../../Components/Dialogs/SpaceInfoDialog/SpaceInfoDialog';
import { ISpaceLayout } from '../../../Shared/Bookings/Edit/EditBooking';

class ViewSchedule extends IbssComponent<IProps, IState>
{
    private get appState() { return appContext().state; }
    private get api() { return appContext().apiClient; }
    private get apiCache() { return appContext().apiCache; }
    private get bookingService() { return appContext().bookingService; }
    private helper =  new Helper()
    private get labels() { return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }
    private get session() { return appContext().sessionStorageProvider; }
    private scheduleRef: React.RefObject<ScheduleComponent>;
    private userPreferences = {} as IUserPreferences;
    private sortSpacesOrderOptions: Array<IListOption<ISortSpaces>>

    constructor(props: IProps)
    {
        super(props);
        this.scheduleRef = React.createRef<ScheduleComponent>();
        // labels are 'Sort by'.
        this.sortSpacesOrderOptions = [{label: `A-Z`, value: ISortSpaces.Ascending}, {label: `Z-A`,  value: ISortSpaces.Descending}];
        this.state =
        {
            bookings: [],
            buildingId: 0,
            floor: 'Any', // Any or a string representation of floorId
            floorName: 'Any',
            linkedSpacesIds: [], 
            spaceType: 'Any',
            spaceWorkType: 'Any',
            capacity: '1',
            hasCatering: false,
            hasEquipment: false,
            zone: '',
            startTime: DateTime.now(),
            spaces: [],
            buildingStartHrs: '08:00',
            buildingEndHrs: '20:00',
            isFirstFloorUpdate: true,
            openFilterModal: false,
            // controls for pagination
            spacePageIndex: 0,
            
            // controls for button on scheduler
            showButton: false,
            selectedCell: null,
            showSpaceInfoDialog: false,

            // controls for create a booking filters
            showCreateBooking: false,
            selectedSlot: null,
            // selected booking data.
            selectedBooking: undefined,

            // state to go with async data calls.
            isLoading: false,

            // selected spaceId by clicking on info in resource header (left hand side of ViewSchedule);
            selectedSpaceId: '',

            // state used to control popup from user click on timeline free slot or timeine events
            showViewBookingModal: false,
            // list of zones per floor of a building.
            zoneOptions: [],
            //sort Spaces by name alphabetic order
            sortSpacesOrder: ISortSpaces.Ascending,
        };
    }

    public componentWillMount(): void
    {
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
    }
    
    public async componentDidMount(): Promise<void>
    {
        // set initial SpaceType
        this.setState({spaceType: this.getInitialSpaceType(this.state.buildingId)});
        this.pageTitle = this.labels.HubLabelScheduleView;
        
        // load config
        this.userPreferences = this.local.getUserPreferences();

        this.setStateAsync({buildingId: this.userPreferences.SearchPrefs.DefaultBuilding ?? 0});

        this.setUserPrefsFloorAndZone();

        // get zone types
        await this.populateZones(this.state.floor);

        // updating floor and zone states with default values in userPreferences triggers componentUpdate, so there is no need to do load meetingRooms, loadBookings here.
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        if(prevState.buildingId !== this.state.buildingId)
        {         
            // set initial SpaceType
            this.setState({spaceType: this.getInitialSpaceType(this.state.buildingId)});

            this.setUserPrefsFloorAndZone();

            // get zone types
            await this.populateZones(this.state.floor);

            // get spaces, bookings and building hours data
            await this.refreshSpacesAndBookings();
            
            return;
        }

        if(prevState.floor !== this.state.floor)
        {
            // get zone types
            await this.populateZones(this.state.floor);
            
            // set zone to "Any" when floor changes, as there is no gurantee of the new floor having the same zone as the previous floor.
            if(this.state.isFirstFloorUpdate)
            {
                // on the first time state.floor is updated, the component is just being mounted and populated with new information. Don't set zone here.
                this.setState({isFirstFloorUpdate: false});
            }
            else
            {
                this.setState({zone: "Any"});
            }
        }

        if(prevState.floor !== this.state.floor || prevState.capacity !== this.state.capacity || prevState.hasCatering !== this.state.hasCatering || prevState.hasEquipment !== this.state.hasEquipment || prevState.spaceType !== this.state.spaceType || prevState.spaceWorkType !== this.state.spaceWorkType || prevState.zone !== this.state.zone)
        {
            await this.refreshSpacesAndBookings();
        }

        if(prevState.startTime !== this.state.startTime)
        {
            await this.refreshSpacesAndBookings();
        }

        if(prevState.sortSpacesOrder !== this.state.sortSpacesOrder)
        {
            await this.refreshSpacesAndBookings();
        }
    }

    private setUserPrefsFloorAndZone(): void
    {
        const buildingSpecificUserSearchPrefs = this.userPreferences.Nodes.find(building => building.NodeId === this.state.buildingId);
        const userPrefsFloor = buildingSpecificUserSearchPrefs?.DefaultFloor? buildingSpecificUserSearchPrefs?.DefaultFloor.toString() : '';
        const userPrefsZone = buildingSpecificUserSearchPrefs?.DefaultZone? buildingSpecificUserSearchPrefs?.DefaultZone.toString() : '';

        this.setStateAsync(prevState => ({
            floor: userPrefsFloor? userPrefsFloor: prevState.floor, 
            zone:  userPrefsZone? this.capitalize(userPrefsZone): prevState.zone,
        }));
    }

    private async refreshSpacesAndBookings(): Promise<void>
    {
        // get spaces, bookings and building hours data
        this.setState({isLoading: true});
        await this.loadCachedSpaces();
        await this.loadLinkedSpaces();
        await this.loadBookings();
        await this.getBuildingHours();
        this.setState({isLoading: false});
    }

    private filterByInputs(spaces: SpaceView[]): SpaceView[]
    {
        const { floor, spaceType, spaceWorkType, zone, zoneOptions} = this.state;
        const zoneLabel = zone === "Any"? zone : zoneOptions.find( zoneOption => zoneOption.value === zone)?.label ?? '';

        return spaces.filter(space => {
            
            return this.isEqualOrAny((space.nodeId).toString(), floor)
            && this.isEqualOrAny(space.spaceType, spaceType) 
            && this.isEqualOrAny(space.spaceWorkType, spaceWorkType) 
            && this.isEqualOrAny(space.metaLocZone, zoneLabel)
            && space.spaceEnabled == 1
            && (space.metaBookable == 1 || space.metaBookable == 3 || space.metaBookable == 5)
        });
    }

    private isEqualOrAny(a: string, b: string)
    {
        // function to return true if both strings are equal or if any of them are "any" after text transform to lowercase.
        return ( a === b || (a.toLowerCase() === "any" || b.toLowerCase() === "any"))
    }

    private async loadCachedSpaces(): Promise<void>
    {
        try
        {
            const response = await this.apiCache.getSpacesByBuilding(this.state.buildingId);
            const spaceView = response.map(i => SpaceView.fromSpace(i));
            const filteredSpaceView = this.filterByInputs(spaceView).sort((a,b) => this.compareSpaceNames(a, b)); // sort spaces by Ascending Order on loading cached spaces.
            await this.setStateAsync({
                spaces: filteredSpaceView,
                spacePageIndex: 0,
            });
        }
        catch
        {
            return;
        }
    }

    private async loadLinkedSpaces(): Promise<void>
    {
        // spaces cache does not have linked spaces ids.
        // this function acts on spaces, parse space.spaceLayout, writes the spaceIds into a linkedSpacesIds state.
        const spaceLayouts = this.state.spaces
        .filter(space => space.spaceLayout !== "")
        .flatMap(space => 
            {
                try
                {
                    const layouts: ISpaceLayout[] = JSON.parse(space.spaceLayout)?.Layouts ?? [];
                    if(layouts.every( layout => this.isSpaceLayoutType(layout)))
                    {
                        return layouts;
                    }
                    else
                    {
                        return null;
                    }
                }
                catch
                {
                    return null;
                }
            }
        )
        .filter(space => space !== null && space.Space_Id.includes(';')) as ISpaceLayout[];

        const linkedSpaceIds = spaceLayouts.map(j => j.Space_Id );
        const uniqueLinkedSpaceIds = new Set(linkedSpaceIds);
        await this.setStateAsync({linkedSpacesIds: Array.from(uniqueLinkedSpaceIds)});
    }

    private isSpaceLayoutType(obj: any): obj is ISpaceLayout
    {
        // the parsed JSON's type begins as any, this function puts some type guard on the returned obj.
        return "Name" in obj && "Space_Id" in obj && typeof obj['Name'] === 'string' && typeof obj['Space_Id'] === 'string';
    }

    private linkedSpaceIdIncludesSpaceId(linkeSpaceId: string, spaceIds: string[]): boolean
    {
        return spaceIds.some(spaceId => linkeSpaceId.split(';').includes(spaceId));
    }

    private async loadBookings(): Promise<void>
    {
        // keep startTime in local time, and when calculating the start and end of date for API interaction, convert to new building timezone, whilst keeping local time and dates. e.g. 15th of feb in the uk would return 15th of feb in Sydney
        const startOfTodayWithZone = this.state.startTime.startOf('day').setZoneByNode(this.state.buildingId);
        const endOfTodayWithZone = this.state.startTime.endOf('day').setZoneByNode(this.state.buildingId);

        const query = new ODataQuery({ nodeId: this.state.buildingId });

        try
        {
            const visibleSpaceIds = [...this.state.spaces.slice(0, (this.state.spacePageIndex+1)*10).map(i => i.spaceId)];
            const spaceIds = [...visibleSpaceIds, ...this.state.linkedSpacesIds.filter(id => this.linkedSpaceIdIncludesSpaceId(id, visibleSpaceIds)) ];

            const bookings = await this.bookingService.getBookings(query, startOfTodayWithZone, endOfTodayWithZone, spaceIds);
            bookings.value.forEach(i =>
            {
                i.Booking_Start = i.Booking_Start.offsetTimeByNode(query.nodeId);
                i.Booking_End = i.Booking_End.offsetTimeByNode(query.nodeId);
            });
    
            const bookingsView = bookings.value.map(i => BookingView.fromBooking(i));
            this.setState({bookings: bookingsView});
        }
        catch
        {
            return;
        }
    }

    private async loadMoreBookings(): Promise<void>
    {
        // load more bookings when more spaces are loaded.
        // keep startTime in local time, and when calculating the start and end of date for API interaction, convert to new building timezone, whilst keeping local time and dates. e.g. 15th of feb in the uk would return 15th of feb in Sydney
        const startOfTodayWithZone = this.state.startTime.startOf('day').setZoneByNode(this.state.buildingId);
        const endOfTodayWithZone = this.state.startTime.endOf('day').setZoneByNode(this.state.buildingId);
        
        // load the bookings for the next batch of spaceIds, append the returned bookings to existing bookings.
        const query = new ODataQuery({ nodeId: this.state.buildingId});

        try
        {
            const visibleSpaceIds = [...this.state.spaces.slice(this.state.spacePageIndex*10, (this.state.spacePageIndex+1)*10).map(i => i.spaceId)];
            const spaceIds = [...visibleSpaceIds, ...this.state.linkedSpacesIds.filter(id => this.linkedSpaceIdIncludesSpaceId(id, visibleSpaceIds))];

            const bookings = await this.bookingService.getBookings(query, startOfTodayWithZone, endOfTodayWithZone, spaceIds);
            bookings.value.forEach(i =>
            {
                i.Booking_Start = i.Booking_Start.offsetTimeByNode(query.nodeId);
                i.Booking_End = i.Booking_End.offsetTimeByNode(query.nodeId);
            });
    
            const bookingsView = bookings.value.map(i => BookingView.fromBooking(i));
            this.setState(prevState => ({bookings: [...prevState.bookings, ...bookingsView]}));
        }
        catch
        {
            return;
        }
    }

    private transformBookings(): IScheduleDate[]
    {
        const scheduleDates = this.state.bookings.flatMap(booking =>
            {
                const scheduleDatesForBooking = booking.spaceId.split(';').map(spaceId =>
                {
                    const scheduleDate: IScheduleDate =
                    {
                        // Id, Subject, StartTime & EndTime fields (default action event data field names) cannot be written as camelCase strings without first mapping camelCase field names to ScheduleComponent.eventSettings.fields
                        // e.g. fields: { subject: { title: 'subject', name: 'subject'}}, make sure value of name property matches the camelCase field name.
                        id: booking.bookingId, 
                        subject: booking.bookingName,
                        bookingOwnerEmail: booking.bookingOwnerEmail,
                        startTime: DateTime.fromISO(booking.bookingStart).toJSDate(),
                        endTime: DateTime.fromISO(booking.bookingEnd).toJSDate(),
                        bookingIsActive: booking.bookingIsActive,
                        bookingIsApproved: booking.bookingIsApproved,
                        bookingStatus: booking.bookingStatus,
                        spaceId: spaceId,
                        categoryColor: this.processBookingColour(booking),
                        cancelledBookingsInSameSpaceTimeSlot: this.cancelledBookingsInSpaceAndTimeSlot(booking.spaceId, booking.bookingStart, booking.bookingEnd), // when showing cancelled bookings alongside non-cancelled bookings.
                    }
                    return scheduleDate;
                });

                return scheduleDatesForBooking;
            });
        return scheduleDates;
    }

    private groupData: GroupModel = 
    {
        // group bookings by spaces - in ResourceDirective with name="Spaces", matching on SpaceId field of bookings transformed by transformBookings() and id field of ResourceDirective's datasource.
        resources: ['Spaces']
    }

    private changeDate(event: DateTime): void
    {
        this.setState({startTime: event});
    }

    private async getBuildingHours(): Promise<void> 
    {
        // get a building's start and stop hours.
        const rootNode = this.local.getNodeData();
        const building = this.helper.getBuildingById(rootNode, this.state.buildingId);

        this.setStateAsync({
            buildingStartHrs: building?.Occ_Office_hrs_stt.slice(0, -3) ?? '07:00',
            buildingEndHrs: building?.Occ_Office_hrs_stp.slice(0, -3) ?? '19:00',
        });
    }

    private async handleLoadMoreSpaces(newPage: number): Promise<void>
    {
        await this.setStateAsync({spacePageIndex: newPage});
        await this.loadMoreBookings();
    }


    public resetFilters(): void
    {
        this.setUserPrefsFloorAndZone();
        this.setState(
            {
                spaceType: this.getInitialSpaceType(this.state.buildingId), 
                spaceWorkType: 'Any',
                capacity: '1',
                hasCatering: false,
                hasEquipment: false,
            }
        )
    }

    private cancelledBookingsInSpaceAndTimeSlot(spaceId: string, startTime: string, endTime: string): number
    {
        // need to count cancelled bookings in space and time
        // startTime and endTime are from a specific BookingView object. They are string type.
        const bookingIntervalX = Interval.fromDateTimes(DateTime.fromISO(startTime), DateTime.fromISO(endTime));

        const countOfBookings = this.state.bookings.filter( i => {
            const bookingIntervalY = Interval.fromDateTimes(DateTime.fromISO(i.bookingStart), DateTime.fromISO(i.bookingEnd));
            return (i.bookingStatus === "Cancelled" || i.bookingStatus === "Auto Cancelled") && i.spaceId === spaceId && bookingIntervalX.overlaps(bookingIntervalY);
        }).length;

        return countOfBookings;
    }

    private onEventRendered(args: EventRenderedArgs): void
    {
        // before bookings are rendered on the scheduler, apply some styles to the bookings, but primarily to assign borderColor to the CategoryColor in the bookings data.
        
        // background color for Cancelled bookings is solid grey
        const backgroundColour = (args.data.bookingStatus === 'Cancelled' || args.data.bookingStatus === 'Auto Cancelled') ? '#DCE1E5' : 'transparent';
        // opacity for Cancelled bookings is less than 1.
        const opacity = (args.data.bookingStatus === 'Cancelled' || args.data.bookingStatus === 'Auto Cancelled')? '0.6' : '1';
        // maxWidth for all Cancelled bookings combined is 70% of overall cell width. the appointment is inside a table, the number of cells per row is equal to the number of bookings in slot, regardless of whether tye are cacelled or not
        const maxWidth = (args.data.bookingStatus === 'Cancelled' || args.data.bookingStatus === 'Auto Cancelled')? `${70/args.data.cancelledBookingsInSameSpaceTimeSlot}%` : '100%'; 

        (args.element as HTMLElement).style.color = '#263238'; // this is equivalent to var(ui--text), change to this var if syncfusion scheduler has dark mode.
        (args.element as HTMLElement).style.backgroundColor = backgroundColour;
        (args.element as HTMLElement).style.borderColor = args.data.categoryColor;
        (args.element as HTMLElement).style.borderRadius = '5px';
        (args.element as HTMLElement).style.borderLeftWidth = 'thick';
        (args.element as HTMLElement).style.opacity = opacity;
    }

    private processBookingColour(booking: BookingView): string
    {
        if (booking.bookingStatus === 'Cancelled' || booking.bookingStatus === 'Auto Cancelled')
        {
            return '#DCE1E5'; // this is equivalent to uiMidTone.,
        }
        else if(booking.bookingStatus === 'Completed')
        {
            return 'grey';
        }
        else if(booking.bookingStatus === 'No Show')
        {
            return 'blue';
        }
        else if(booking.bookingStatus === 'In Progress')
        {
            return 'yellow';
        }
        else if (booking.bookingIsApproved === 0)
        {
            // new booking pending approval has Booking_IsApproved value of 0.
            return 'red';
        }
        else if(booking.bookingIsApproved === 3 || booking.bookingIsApproved === 4)
        {
            return '#00C853'; //green used in figma
        }
        else
        {
            return 'black';
        }
    }

    private createFlexBookingURL(spaceId: string): string
    {
        return `/flex-find-a-space/${this.state.buildingId}/searchaspace/${spaceId}`;
    }

    private clickCreateBookingBtn(spaceId: string, start: DateTime, end: DateTime): void
    {
        const { history } = this.props;
        this.session.setFlexSpaceSearchCriteria(start, end);
        history.push(this.createFlexBookingURL(spaceId));
    }

    private clickEditBooking(bookingOwnerEmail: string, bookingId: string, spaceId: string): void
    {
        const { history } = this.props;

        // bookings owned by self
        if(this.local.getUserDetails().email === bookingOwnerEmail)
        {
            const myBookingUrl = `/flex-my-bookings/${this.state.buildingId}/mybooking/${bookingId}/${spaceId}`;
            history.push(myBookingUrl);
        }
    }

    private onPopupOpen(args: PopupOpenEventArgs): void
    {
        if(args.type === 'QuickInfo' && args?.data?.id)
        {
            args.cancel = true;
            this.setState({
                showViewBookingModal: true,
                selectedBooking: this.state.bookings.find(i => 
                    {
                        const data = args.data as IScheduleDate;
                        return i.bookingId === data.id
                    }
                )
            });            
        }
        
        else if(args.type === 'QuickInfo' && args?.data?.id === undefined)
        {
            // create booking popup that appears when user clicks on a slot on the schedule
            const eventDetails = args.data;
            const targetElement = document.querySelector(`.e-popup-content`);
            if(targetElement)
            {
                ReactDOM.render(
                    <Box display='flex' justifyContent={'center'}>
                        <IbssButton
                            variant={'contained'} 
                            onClick={()=> this.clickCreateBookingBtn(eventDetails?.spaceId, DateTime.fromJSDate(args.data?.startTime), DateTime.fromJSDate(args.data?.endTime))}
                        >
                            {/* Create a booking */ }
                            {this.labels.funcScheduleViewCreateBooking_S}
                        </IbssButton>
                    </Box>,
                    targetElement
                )
            }
        }
        else
        {
            // disables ALL other popups besides 'QuickInfo'
            args.cancel = true;
        }
    }
    
    private onSelect(args: SelectEventArgs): void
    {
        return;
    }

    private resourceHeaderTemplate(props: ResourceDetails): JSX.Element
    {
        return (
            <div className="template-wrap">
                <div className="room-name">{this.getSpaceName(props)}</div>
                <div className="room-type">{this.getFloorName(props)}</div>
                <div className="room-capacity">{this.getSpaceCapacity(props)}</div>
                <div id="room-info">
                    <IbssIconButton
                        aria-label="info"
                        key={this.getSpaceId(props)}
                        sx={{ padding: 0 }}
                        onClick={() => this.spaceInfoClicked(props.resourceData.id)}
                    >
                        <SvgIcon fontSize={'small'} component={InfoIcon}></SvgIcon>
                    </IbssIconButton>
                </div>
            </div>
        );
    }

    private spaceInfoClicked(spaceId: string): void
    {
        const selectedSpace = this.state.spaces.find(room => room.spaceId === spaceId);
        if (!selectedSpace)
        {
            return;
        }
        this.setState({ selectedSpaceId: spaceId, showSpaceInfoDialog: true });
    }

    private getSpaceId(value: ResourceDetails): string
    {
        return (value as ResourceDetails).resourceData.id;
    }

    private getSpaceName(value: ResourceDetails): string
    {
        return (value as ResourceDetails).resourceData.name;
    }

    private getFloorName(value: ResourceDetails): string
    {
        const availableSpaces = this.local.getNodeData();
        const floor = availableSpaces.Regions.flatMap(i => i.Buildings).flatMap(i => i.Floors).find(i => i && i.Node_Id == (value as ResourceDetails).resourceData.nodeId);
        return floor?.Node_Name ?? '';
    }

    private getSpaceCapacity(value: ResourceDetails): string
    {
        return (value as ResourceDetails).resourceData.capacity;
    }

    private renderCell(args: RenderCellEventArgs): void
    {
        if(args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td'))
        {
            // labels for Space, Floor, Cap (as in Capacity), 4th header is empty.
            const target: HTMLElement = args.element.querySelector('.e-resource-text') as HTMLElement;
            target.innerHTML = `<div class="name">${this.labels.HubLabelSpace}</div><div class="type">${this.labels.HubLabelFloor}</div><div class="capacity">${this.labels.HubLabelCapacity}</div><div class="capacity">${''}</div>`
        }
    }

    private handleViewBookingModal(): void
    {
        this.setState((prevState) => ({
            showViewBookingModal: !prevState.showViewBookingModal
        }));
    }

    private getInitialSpaceType(buildingId: number): string
    {
        // returns the initial spaceType value, tries to avoid return 'Any'
        const spaceTypes = Helper.getSpaceTypesByNodeId(buildingId);

        const options = spaceTypes.result
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1)); // sort by name;

        options.unshift({ label: this.labels.HubLabelAny, value: "Any" });

        const deskOption = options.find(x => x.value === 'Desk')
        if (deskOption)
        {
            return deskOption.value;
        } 
        else
        {
            return options[1]?.value ?? options[0]?.value;
        }
    }

    private async populateZones(selectedFloor: string): Promise<void>
    {
        if (selectedFloor === "Any")
        {
            // When floor is 'Any', zone is also 'Any' because no floors are specified.
            this.setState(
                {
                    zoneOptions: [{ label: this.labels.HubLabelAny, value: "Any" }], 
                });
            return;
        }
        try
        {
            const zonesResponse = await this.api.spaceZones.getMultiple(parseInt(selectedFloor), true);
            const zones = zonesResponse;
            const options = zones
            .map(i => ({ label: i.Meta_Loc_Zone, value: (i.Space_Zone_Id).toString()}))
            .sort((a, b) => (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? - 1 : 1));

            // add 'Any' to the front of the list of options.
            options.unshift({ label: this.labels.HubLabelAny, value: "Any" });
            this.setState({ zoneOptions: options});
        }
        catch
        {
            this.setState({ zoneOptions: [{ label: this.labels.HubLabelAny, value: "Any" }]})
        }
    }

    private capitalize(input: string): string
    {
        // incase userPrefs zone's "any" value is not capitalized.
        return input.charAt(0).toUpperCase() + input.slice(1);
    }

    private compareSpaceNames(a: SpaceView, b: SpaceView): number
    {
        const spaceNameA = a.spaceName.toLocaleLowerCase();
        const spaceNameB = b.spaceName.toLocaleLowerCase();

        if(spaceNameA < spaceNameB)
        {
            return this.state.sortSpacesOrder === ISortSpaces.Ascending ? -1 : 1;
        }
        if(spaceNameA > spaceNameB)
        {
            return this.state.sortSpacesOrder === ISortSpaces.Ascending ? 1 : -1;
        }
        return 0;
    }

    private spaceOrderOptionSelected(selectedValue: string): void
    {
        this.setState({sortSpacesOrder: selectedValue as unknown as ISortSpaces})
    }

    public render(): JSX.Element
    {
        return (
            <>
                {this.state.selectedBooking?.bookingId &&
                    <ViewBookingDialog 
                        show={this.state.showViewBookingModal} 
                        onClose={()=> this.handleViewBookingModal()}
                        bookingParties={this.state.selectedBooking.bookingParties}
                        bookingId={this.state.selectedBooking.bookingId}
                        bookingName={this.state.selectedBooking.bookingName}
                        bookingOwnerEmail={this.state.selectedBooking.bookingOwnerEmail}
                        bookingShareLocation={this.state.selectedBooking.bookingShareLocation}
                        buildingId={this.state.buildingId}
                        clickEditBooking={this.clickEditBooking.bind(this)}
                        bookingColour={this.processBookingColour(this.state.selectedBooking)}
                        spaceId={this.state.selectedBooking.spaceId}
                        startTime={DateTime.fromISO(this.state.selectedBooking.bookingStart).setZoneByNode(this.state.buildingId)}
                        endTime={DateTime.fromISO(this.state.selectedBooking.bookingEnd).setZoneByNode(this.state.buildingId)}
                    />
                }
            <div className="page-height-exct-header">
                <div className="rightPanel-main-content">
                    <div className="table-panel">
                        <Grid container rowSpacing={0} sx={{display:'flex',alignItems:'center',mt: 0, ml: 0}}>
                            <Grid item md={6} sx={{pt: 0, pb: 1.8}} >
                                {/* Space Schedule */}
                                <Box className="table-panel-header" component="div" sx={{ ml: '0',textOverflow: 'ellipsis',whiteSpace:'nowrap',overflow:'hidden',width:"100%" }}>{this.labels.HubLabelSpaces}</Box>
                            </Grid>
                            <Grid item md={12} sx={{pt:0}}>
                            </Grid>
                            <Grid item md={12} sx={{pt:0}} >
                                <ScheduleFilter
                                    buildingId={this.state.buildingId}
                                    floor={this.state.floor}
                                    spaceType={this.state.spaceType}
                                    spaceWorkType={this.state.spaceWorkType}
                                    zone={this.state.zone}
                                    zoneOptions={this.state.zoneOptions}
                                    changeBuildingId={(buildingId) => this.setState({buildingId: parseInt(buildingId)})}
                                    changeFloor={(floor, floorName) => this.setState({floor: floor, floorName: floorName})}
                                    changeSpaceType={(spaceType)=> this.setState({spaceType: spaceType })}
                                    changeSpaceWorkType={(spaceWorkType)=> this.setState({spaceWorkType: spaceWorkType })}
                                    changeZone={(zone)=> this.setState({zone: zone})}
                                />
                            </Grid>
                            <Grid item md={12} sx={{pt:1.5}} >
                                <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        backgroundColor: (theme) => theme.palette.common.uiCore,
                                    }}>
                                    <Box display={'flex'} paddingTop='0.5rem' paddingBottom='0.5rem'>
                                        <Box sx={{padding: '0rem 1rem'}}>
                                            <IbssIconButton
                                                aria-label="navigate-to-previous-day"
                                                key={"navigate-to-previous-day"}
                                                onClick={()=> this.setState({startTime: this.state.startTime.plus({days: -1}) })}
                                            >
                                                <SvgIcon fontSize={'medium'} component={ChevronLeftIcon}></SvgIcon>
                                            </IbssIconButton>
                                            <IbssIconButton
                                                aria-label="navigate-to-next-day"
                                                key={"navigate-to-next-day"}
                                                onClick={()=> this.setState({startTime: this.state.startTime.plus({days: 1}) })}
                                            >
                                                <SvgIcon fontSize={'medium'} component={ChevronRightIcon}></SvgIcon>
                                            </IbssIconButton>
                                        </Box>
                                        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={DateTime.now().getLocale()}>
                                            <DesktopDatePicker
                                                value={this.state.startTime}
                                                onChange={(event) => this.changeDate(event as DateTime)}
                                                renderInput={(params) => 
                                                    {
                                                        const { sx, ...paramsMinusSx } = params;
                                                        return <TextField
                                                                    size="small"
                                                                    {...paramsMinusSx}
                                                                    sx={{
                                                                        width: "auto",
                                                                        "& legend": { display: "none" },
                                                                        "& fieldset": { top: 0 },
                                                                    }}
                                                                    error={false}
                                                                />
                                                    }
                                                }
                                            />
                                        </LocalizationProvider>      
                                        <IbssButton
                                            aria-label="navigate-today"
                                            key={"navigate-today"}
                                            sx={{marginLeft: '2rem', color: (theme) => theme.palette.text.primary}} 
                                            onClick={() => this.setState({startTime: DateTime.now()})}
                                        >
                                            <SvgIcon fontSize={'medium'} component={TodayIcon}></SvgIcon>
                                            <Typography paddingLeft={'0.5rem'}>
                                                {this.labels.funcCalendarJumpToToday_S} 
                                            </Typography>
                                        </IbssButton>
                                    </Box>
                                    <Box display='flex' alignItems='center'>
                                        <SvgIcon component={SwapVertIcon}></SvgIcon>
                                        {`${this.labels.HubLabelSortBy_S}: `}
                                        <IbssFormControl sx={{padding: '0.5rem 1rem', paddingTop: '0rem'}}>
                                            <IbssInputDropDown
                                                id='alphabeticSort'
                                                selectDisableUnderline={true}
                                                selectVariant={'standard'}
                                                fullWidth={true}
                                                options={this.sortSpacesOrderOptions}
                                                value={this.state.sortSpacesOrder}
                                                onChange={(e: { target: { value: string; }; }) => this.spaceOrderOptionSelected(e.target.value)}
                                            >
                                            </IbssInputDropDown>
                                        </IbssFormControl>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        {this.state.spaces.length > 0 &&
                            <>
                                <ScheduleComponent
                                    cssClass={'timeline-resource'}
                                    currentView="Day"
                                    height={this.state.spaces.slice(0, (this.state.spacePageIndex+1)*10).length <= 10? '62vh': 'auto'} 
                                    width='100%'
                                    ref={this.scheduleRef}
                                    // show time line indicator of current time only if browser timezone is equal to building timezone.
                                    showTimeIndicator={Intl.DateTimeFormat().resolvedOptions().timeZone === DateHelper.getZoneByNode(this.state.buildingId)}
                                    timeScale={{interval: 60, slotCount: 1}}
                                    eventSettings=
                                    {{
                                        dataSource: this.transformBookings(),
                                        fields: 
                                        {
                                            id: { title: 'id', name: 'id'},
                                            subject: { title: 'subject', name: 'subject'},
                                            startTime: { title: 'start time', name: 'startTime' },
                                            endTime:  { title: 'end time', name: 'endTime' },
                                        }
                                    }}
                                    group={this.groupData}
                                    showHeaderBar={false}
                                    startHour={`${this.state.buildingStartHrs.split(':')[0]}:00`} //ensure the start hour is a whole hour rather than 07:15.
                                    endHour={this.state.buildingEndHrs}
                                    workHours={{highlight: true, start: this.state.buildingStartHrs, end: this.state.buildingEndHrs}}
                                    eventRendered={this.onEventRendered.bind(this)}
                                    renderCell={this.renderCell.bind(this)}
                                    resourceHeaderTemplate={this.resourceHeaderTemplate.bind(this)}
                                    selectedDate={this.state.startTime.toJSDate()}
                                    popupOpen={this.onPopupOpen.bind(this)}
                                    select={this.onSelect.bind(this)}
                                    quickInfoTemplates={{footer: ()=> {return <div></div>}}} // display nothing in place of the default syncfusion quickInfo popup footer.
                                >
                                    <ResourcesDirective>
                                        <ResourceDirective
                                            field='spaceId' // has to match to a field of the data passed to ScheduleComponent eventSettings DataSource.  
                                            title='Space Id' // string displayed when one clicks on the booking
                                            name='Spaces' // match to a string in the arrya of groupData.resources
                                            allowMultiple={true}
                                            idField='id'  
                                            textField='name'
                                            colorField='Color'
                                            dataSource={this.state.spaces.slice(0, (this.state.spacePageIndex+1)*10).map(space => 
                                                ({
                                                    id: space.spaceId, 
                                                    capacity: space.spaceCapacity,
                                                    name: space.spaceName, 
                                                    nodeId: space.nodeId, 
                                                    type: space.spaceType
                                                })
                                            )}
                                        />
                                    </ResourcesDirective> 
                                    <Inject services={[TimelineViews]}/>
                                    <ViewsDirective>
                                        <ViewDirective option='TimelineDay'/>
                                    </ViewsDirective>
                                </ScheduleComponent>
                                <Box sx={{display: 'flex', justifyContent: 'space-between' , pt:1 }}>
                                    <IbssButton
                                        color="secondary"
                                        disableElevation={true}
                                        disabled={this.state.spaces.slice(0, (this.state.spacePageIndex+1)*10).length >= this.state.spaces.length}
                                        variant={'contained'}
                                        onClick={() => this.handleLoadMoreSpaces(this.state.spacePageIndex + 1)}
                                    >
                                        <Typography>
                                            {`${this.labels.funcViewScheduleLoadNextTen_S}`}
                                        </Typography>
                                        <SvgIcon component={ChevronRightIcon}></SvgIcon>
                                    </IbssButton>

                                    {/* shows the number of spaces visible to user */}
                                    <Typography variant='body1'>
                                        {`${this.state.spaces.slice(0, (this.state.spacePageIndex+1)*10).length}`}
                                    </Typography> 
                                </Box>
                            </>
                        }
                        {
                            this.state.spaces.length === 0 && this.state.isLoading === false &&
                            <Box sx={{height: '62vh', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <NoSpaceScheduleViewIcon />
                                <Typography variant="h5" sx={{mt: 4, mb: 2, fontFamily: "Source Sans Pro" , fontWeight: 'bold', color: (theme)=> theme.palette.text.primary}}>
                                    {this.labels.funcScheduleNoSpaces_L}
                                    </Typography>
                                <Typography sx={{fontFamily: "Source Sans Pro", fontWeight: 'bold', color: (theme)=> theme.palette.text.primary}}>
                                    {this.labels.funcScheduleNoSpaces_D}
                                </Typography>
                                <IbssButton 
                                    size='large'
                                    sx={{fontFamily: "Source Sans Pro", fontWeight: 'bold'}}
                                    onClick={()=> this.resetFilters()}
                                >
                                <Typography variant='body1'>{this.labels.funcScheduleResetFilters_S}</Typography>
                                </IbssButton>
                            </Box>
                        }
                    </div>
                </div>
                </div>
                <SpaceInfoDialog
                    isOpen={this.state.showSpaceInfoDialog}
                    onClose={() => this.setState({ showSpaceInfoDialog: false })}
                    spaceId={this.state.selectedSpaceId}
                    buildingId={this.state.buildingId}
                />
            </>
        )
    }
}

export default ViewSchedule;

export interface IProps extends RouteComponentProps
{
}

export interface IState
{
    bookings: BookingView[],
    buildingId: number,
    floor: string,
    floorName: string,
    linkedSpacesIds: string[],
    spaceType: string,
    spaceWorkType: string,
    capacity: string,
    zone: string,
    startTime: DateTime,
    spaces: SpaceView[],
    buildingStartHrs: string,
    buildingEndHrs: string,
    isFirstFloorUpdate: boolean,
    openFilterModal: boolean,
    spacePageIndex: number,
    
    hasCatering: boolean,
    hasEquipment: boolean,

    showButton: boolean,
    selectedCell: Element | null,
    showSpaceInfoDialog: boolean,

    showCreateBooking: boolean,
    selectedSlot: ISelectedSlot | null,

    selectedBooking: BookingView | undefined,
    isLoading: boolean,

    selectedSpaceId: string,

    showViewBookingModal: boolean,
    zoneOptions: Array<IListOption<string>>,
    sortSpacesOrder: ISortSpaces,
}

enum ISortSpaces
{
    Ascending,
    Descending
}