import React, { Component } from 'react'
import { Box, Grid, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { RouteComponentProps, RouterProps, generatePath } from 'react-router';
import { appContext } from '../../../../AppContext';
import { IDispatch, IPropsFromState } from '../../../../redux/Interfaces';
import IbssButtonRedo from '../../../../Components/Buttons/Button/IbssButton';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import FormPanelInfo from '../../../../Components/Headings/FormHeading/FormPanelInfo';
import IbssTimePicker from '../../../../Components/Inputs/TimePicker/IbssTimePicker';
import { DateTime } from 'luxon';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';

export default class EditCateringOrderPolicy extends IbssComponent<IProps, IState>
{
    private get alert() { return appContext().alert; }
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            loading: false,
            isCreateRight: false,
            isCancelRight: false,
            isUpdateRight: false,
            buildingId: this.appState.buildingId,
            paramPolicyId:"",
            isDisable:false,
            policyData: {
              policyName:"",
              policyId:"",
              policyDescription:"",
              kitchenOperatingHoursFrom:"",
              kitchenOperatingHoursTo:"",
              advancedNotice:"",
              preparationTime:  "",
              timeDesignation:"",
              userId:"",
              orderPolicyRef:"",
              concurrencyStamp:"",
              modifiedByUserId:"",
              ownedByRoleId:"",
              tenancyId:""
            },
        }
    }

    public async componentDidMount(): Promise<void> 
    {
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        const buildingId = parseInt(this.props.match.params["buildingid"]);
        this.pageTitle = this.labels.HubLabelFacilityManagementText + ' ' + this.appState.buildingName;
        this.setRights();
        const policyId =  this.props.match.params.policyId;
        this.setState({
          paramPolicyId:policyId
        })
        try {
          if(policyId)
          {
            const data = await this.apiClient.cateringOrderPolicies.getById(buildingId,policyId);

            this.setState({
              policyData: 
              {
                ...this.state.policyData, // Preserve other fields in policyData
                policyId: data.OrderPolicy_Id, // Set the new value for policyId
                policyName: data.Name,
                policyDescription:data.Description,
                kitchenOperatingHoursFrom: DateTime.fromFormat(data.Kitchen_OperatingHours_Start, "HH:mm").toISOTime(),
                kitchenOperatingHoursTo: DateTime.fromFormat(data.Kitchen_OperatingHours_Stop, "HH:mm").toISOTime(),
                preparationTime: String(data.PreparationTime_Mins),
                advancedNotice: data.AdvanceNotice,
                userId:data.CreatedByUserId,
                concurrencyStamp: data.ConcurrencyStamp,
                orderPolicyRef: data.OrderPolicy_Ref,
                modifiedByUserId: data.ModifiedByUserId,
                tenancyId: data.TenancyId
              },
            });
          }
        } catch (error) 
        {
        }
    }

    public async componentDidUpdate(prevProps: Readonly<IProps>, prevState: IState, snapshot?: IState): Promise<void> 
    {
        if (prevState.buildingId != this.state.buildingId)
        {
            const { history } = this.props;
            const buildingID = this.state.buildingId;
            const path = generatePath(this.props.match.path, { buildingid: buildingID });
            history.replace(path);
            await this.buildingChanged();
        }
    }

    private async buildingChanged(): Promise<void>
    {
        this.setState({ loading: true });
        this.pageTitle = this.labels.HubLabelFacilityManagementText + ' ' + this.appState.buildingName;
        await this.setRights();
        this.setState({ loading: false });
    }

    private setRights(): void 
    {
        this.setState({ 
          isCreateRight: this.local.hasRight("DATAMODEL.CateringOrderPolicies.Create"), 
          isCancelRight: this.local.hasRight("DATAMODEL.CateringOrderPolicies.Delete"), 
          isUpdateRight: this.local.hasRight("DATAMODEL.CateringOrderPolicies.Update"), 
        });
    }

    private policyNameChanged (event: React.ChangeEvent<HTMLInputElement>): void 
    {
      const { value } = event.target;
      this.setState((prevState) => ({
          policyData: {
              ...prevState.policyData,
              policyName: value,
          },
      }));
    }

    private policyIdChanged (event: React.ChangeEvent<HTMLInputElement>): void 
    {
      const { value } = event.target;
      this.setState((prevState) => ({
          policyData: {
              ...prevState.policyData,
              policyId: value,
          },
      }));
    }

    private policyDescriptionChanged (event: React.ChangeEvent<HTMLInputElement>): void 
    {
      const { value } = event.target;
      this.setState((prevState) => ({
          policyData: {
              ...prevState.policyData,
              policyDescription: value,
          },
      }));
    }

    private advanceNoticeChanged (event: React.ChangeEvent<HTMLInputElement>): void 
    {
      const { value } = event.target;
      this.setState((prevState) => ({
          policyData: {
              ...prevState.policyData,
              advancedNotice: value,
          },
      }));
    }
  
    public kitchenOperatingHoursFromChanged(date: Date|null): void
    {
      if (date !== null) {
        const isoDate = DateTime.fromJSDate(date).toISO();
        this.setState((prevState) => ({
          policyData: {
            ...prevState.policyData,
            kitchenOperatingHoursFrom: isoDate,
          },
        }));
      }
  }

    public kitchenOperatingHoursToChanged(date: Date|null): void
    {
        if (date !== null) {
          const isoDate = DateTime.fromJSDate(date).toISO();
          this.setState((prevState) => ({
            policyData: {
              ...prevState.policyData,
              kitchenOperatingHoursTo: isoDate,
            },
          }));
        }
    }

    public preparationTimeChanged(value: string): void
    {
        this.setState((prevState) =>({
          policyData: {
            ...prevState.policyData,
            preparationTime: value,
          },
        }));
    }


    private getHoursFromDate(date: DateTime | null): string 
    {
      if (date !== null) 
      {
        const hours = date.toFormat('HH:mm');
        return hours.toString().padStart(2, '0');
      }
      return "";
    }
    
    private getTotalMinutes(preparationTime: DateTime | null): number 
    {
      if (preparationTime) 
      {
        const hours = preparationTime.hour;
        const minutes = preparationTime.minute;
        const totalMinutes = hours * 60 + minutes;
        return totalMinutes;
      }
      return 0;
    }
    
    
    async handleSubmit (): Promise<void>
    {
      if(this.state.policyData.kitchenOperatingHoursFrom && this.state.policyData.kitchenOperatingHoursTo && this.state.policyData.preparationTime)
      {
        const operatingHourFrom = DateTime.fromISO(this.state.policyData.kitchenOperatingHoursFrom);
        const operatingHourTo = DateTime.fromISO(this.state.policyData.kitchenOperatingHoursTo);

        const payload = 
        {
            Description: this.state.policyData.policyDescription,
            AdvanceNotice: this.state.policyData.advancedNotice,
            PreparationTime_Mins: Number(this.state.policyData.preparationTime),  
            Kitchen_OperatingHours_Start:this.getHoursFromDate(operatingHourFrom),
            Kitchen_OperatingHours_Stop: this.getHoursFromDate(operatingHourTo),
            OrderPolicy_Ref: this.state.policyData.policyName,
            Name: this.state.policyData.policyName,
            Node_Id: this.state.buildingId
        }

        if(this.state.paramPolicyId === undefined)
        {
          try 
          {
            this.setState({
              isDisable: true
            })
            await this.apiClient.cateringOrderPolicies.create(this.state.buildingId,payload);
            this.props.history.goBack();            
          } 
          catch (error) 
          {
          }
        } 
        else 
        {
          const updated = 
          {
            ...payload,
            OrderPolicy_Id: this.state.paramPolicyId,
            concurrencyStamp: this.state.policyData.concurrencyStamp,
            orderPolicyRef: this.state.policyData.orderPolicyRef,
            OwnedByUserId: this.state.policyData.userId,
            ownedByRoleId: this.state.policyData.ownedByRoleId,
            CreatedByUserId: this.state.policyData.userId,
            modifiedByUserId: this.state.policyData.modifiedByUserId,
            tenancyId: this.state.policyData.tenancyId
          }
          try 
          {
            this.setState({
              isDisable: true
            })
            await this.apiClient.cateringOrderPolicies.update(this.state.buildingId,updated);
            this.props.history.goBack();
          } 
          catch (error) 
          {
          }
        }
      }
    }

    public render(): JSX.Element
    {
        return (
            <>
              <Box className="page-height-exct-header">
                <Box className="rightPanel-main-content">
                <Box className='form-panel-except-header'>
                  <FormPanelInfo
                    formHeading={this.labels.HubMenuCateringOrderPolicy}
                    iconSrc="/images/Sidebar_Icons/Light_theme/Tasks.svg"
                  />
                  {this.state.loading || (
                    <div className="form-panel-form">
                      <form>
                        <Grid container spacing={2}>
                          <Grid item sm={7}>
                            <Grid container spacing={3}>
                            <Grid item sm={12}>
                              <Typography variant="body1" className="my-0">{this.labels.HubLabelOrderPoliciesAdd}</Typography>
                              </Grid>
                              <Grid item sm={12}>                         
                                <IbssTextField value={this.state.policyData.policyName} name="policyName" onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.policyNameChanged(e)} id="outlined-basic" label={this.labels.HubLabelViewPoliciesTableColumnPolicyName} variant="outlined" fullWidth />
                              </Grid>
                              <Grid item sm={12}>
                                <IbssTextField value={this.state.policyData.policyId} name="policyId" onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.policyIdChanged(e)} id="outlined-basic" disabled label={this.labels.HubLabelPolicyId} variant="outlined" fullWidth />
                              </Grid>
                              <Grid item sm={12}>
                                <IbssTextField id="outlined-basic" name="policyDescription" onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.policyDescriptionChanged(e)} value={this.state.policyData.policyDescription} label={this.labels.HubLabelPolicyDescription} variant="outlined" fullWidth />
                              </Grid>
                              <Grid item sm={6}>
                              <IbssTimePicker
                                  label={this.labels.HubLabelKitchenOperatingHoursFrom}
                                  ampm={false}
                                  value={DateTime.fromISO(this.state.policyData.kitchenOperatingHoursFrom).toJSDate()}
                                  onChange={e => e !== null ? this.kitchenOperatingHoursFromChanged(new Date(e)) : {}}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item sm={6}>
                                <IbssTimePicker
                                  label={this.labels.HubLabelKitchenOperatingHoursTo}
                                  ampm={false}
                                  value={DateTime.fromISO(this.state.policyData.kitchenOperatingHoursTo).toJSDate()}
                                  onChange={e => e !== null ? this.kitchenOperatingHoursToChanged(new Date(e)) : {}}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item sm={12}>
                                <IbssTextField
                                  label={this.labels.HubLabelAdvancedNotice}
                                  multiline
                                  id="outlined-basic" 
                                  value={this.state.policyData.advancedNotice}
                                  name="advancedNotice" 
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.advanceNoticeChanged(e)}
                                  variant="outlined"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item sm={12}>
                                <Box sx={{display:"flex"}}>
                                  <TextField
                                    type="number"
                                    label={this.labels.HubLabelPreparationTime}
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.policyData.preparationTime}
                                    onChange={(e) => this.preparationTimeChanged(e.target.value)}
                                  />
                                <Box
                                  sx={{
                                    padding:"10px",
                                    backgroundColor: 'rgb(220 225 229)',
                                    alignItems:"center",
                                    display:"flex",
                                    '&:hover': {
                                      backgroundColor: 'rgb(220 225 229)',
                                    },
                                  }}
                                  >{this.labels.HubLabelMinutes}</Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sm={5}></Grid>
                      </form>
                    </div>
                  )}
                </Box>
                <Box display={'flex'} justifyContent={'end'} mt={2}>
                  <IbssButtonRedo className="mr-2" variant="contained" color="secondary" onClick={() => this.props.history.goBack()}>{this.labels.HubButtonCancel}</IbssButtonRedo>
                  <IbssButtonRedo color="primary" disabled={this.state.isDisable || !(this.state.isCreateRight || this.state.isUpdateRight)} variant="contained" onClick={() => this.handleSubmit()}>{this.state.paramPolicyId ? this.labels.HubLabelUpdate : this.labels.HubButtonSubmit}</IbssButtonRedo>
                </Box>
                </Box>
             </Box>
          </>
        )
    }

}

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState, IDispatch
{
}

interface IMatchParams
{
  buildingid: string;
  policyId: string;
}

interface ICateringOrderPolicy 
{
  policyName:string;
  policyId:string;
  policyDescription:string;
  kitchenOperatingHoursFrom: string;
  kitchenOperatingHoursTo: string;
  advancedNotice: string;
  preparationTime: string;
  timeDesignation: string;
  userId: string;
  concurrencyStamp: string;
  orderPolicyRef: string;
  modifiedByUserId: string;
  ownedByRoleId: string;
  tenancyId: string;
}

interface IState
{
  loading: boolean;
  isDisable: boolean;
  isCreateRight: boolean;
  isCancelRight: boolean;
  isUpdateRight: boolean;
  buildingId: number;
  policyData: ICateringOrderPolicy;
  paramPolicyId:string;
}