import { Component } from 'react';
import IbssDialog from '../../Dialogs/BaseDialog/IbssDialog';
import { appContext } from '../../../AppContext';
import IbssButton from "../../Buttons/Button/IbssButton";
import IbssCheckBox from '../../Inputs/CheckBox/IbssCheckBox';
class SpaceArrangementsDialog extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            selectedSpaceId: '',
            selectedSpaceName: '',
            selectedSeatingArrangement: { breakdown: 0, capacity: 0, setup: 0, style: '' },

            spaceId: '',
            spaceLayout: [],
            spaceArrangementList: [],
            lightModeTheme: false,
        };
    };

    public componentDidMount(): void
    {
        this.appState.autoMap(this, i => ({ lightModeTheme: i.lightModeTheme }));

        this.setSpaceLayoutAndSpaceArrangementList();
    }

    public componentDidUpdate(prevProps: IProps): void
    {
        if(prevProps.spaceLayout !== this.props.spaceLayout || prevProps.spaceId !== this.props.spaceId || prevProps.selectedLayoutStyle !== this.props.selectedLayoutStyle)
        {
            this.setSpaceLayoutAndSpaceArrangementList();
        }
    }

    private setSpaceLayoutAndSpaceArrangementList(): void
    {
        const jsonSpaceLayout: ISpaceLayout[] = this.props.spaceLayout ? JSON.parse(this.props.spaceLayout).Layouts.map((x: { Name: string; Space_Id: string; SeatingArrangements: { Breakdown: number; Capacity: number; Setup: number; Style: string; }[]; }) =>
            {
                return (
                    {
                        name: x.Name,
                        spaceId: x.Space_Id,
                        seatingArrangements:
                            x.SeatingArrangements.map((arrangement: { Breakdown: number; Capacity: number; Setup: number; Style: string; }) => { return ({ breakdown: arrangement.Breakdown, capacity: arrangement.Capacity, setup: arrangement.Setup, style: arrangement.Style }) })
                    }
                )
            }) : [];
            const spaceArrangementOption = jsonSpaceLayout.filter(i => i.spaceId === this.props.spaceId)[0]?.seatingArrangements ?? [];
            const seatingArrangement: ISpaceArrangement = jsonSpaceLayout.find(i => i.spaceId === this.props.spaceId)?.seatingArrangements.find(j=> j.style === this.props.selectedLayoutStyle) ?? { breakdown: 0, capacity: 0, setup: 0, style: '' };

            this.setState({ 
                spaceLayout: jsonSpaceLayout, 
                spaceArrangementList: spaceArrangementOption, 
                selectedSeatingArrangement: seatingArrangement,

                selectedSpaceId: this.props?.spaceId ?? '',
                selectedSpaceName: jsonSpaceLayout.filter(i => i.spaceId === this.props.spaceId)[0]?.name ?? '',
            });
    }

    private spaceLayoutChanged(selectedLayout: ISpaceLayout): void
    {
        if (this.state.selectedSpaceId === selectedLayout.spaceId)
        {
            this.setState({
                selectedSpaceId: "",
                selectedSpaceName: ""
            })
        } else
        {
            this.setState({
                selectedSpaceId: selectedLayout.spaceId,
                selectedSpaceName: selectedLayout.name,
                spaceArrangementList: selectedLayout.seatingArrangements,
                selectedSeatingArrangement: selectedLayout.seatingArrangements[0],
                spaceId: selectedLayout.spaceId,
            })
        }
    }

    private seatingArrangementChanged(selectedSeating: ISpaceArrangement): void
    {
        if (this.state.selectedSeatingArrangement.style === selectedSeating.style)
        {
            this.setState({
                selectedSeatingArrangement: { breakdown: 0, capacity: 0, setup: 0, style: '' }
            })
        } else
        {
            this.setState({
                selectedSeatingArrangement: selectedSeating
            })
        }
    }


    private saveClicked(): void
    {
        this.props.onSave(this.state.selectedSeatingArrangement, this.state.selectedSpaceId, this.state.selectedSpaceName)
    }

    private closeClicked(): void
    {
        this.props.onClose()
    }

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                aria-modal="true"
                aria-label="space layout modal"
                open={this.props.modalOpen}
                fullWidth
                header=
                {
                    <>
                        <label className="modal-heading">{this.labels.HubLabelLayoutAndArrangement}</label>
                    </>
                }
                content=
                {
                    <div>
                        {/* -------------------select a linked spaces option--------------------*/}
                        {this.props.spaceSetup === 5 && (
                            <>

                                <div className="p-0 my-24">
                                    <label className="lable-modal text-bold">{this.labels.HubLabelSelectLinkedSpace}</label>
                                </div>

                                {this.state.spaceLayout?.map((item) =>
                                {
                                    return (
                                        <div className="p-0" key={item.spaceId}>
                                            <div className="d-flex justify-content-between">
                                                <div className="lable-modal btn-align w-50 justify-content-start">
                                                    <img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Linked Space (Fill).svg`} alt="Linked Space" width="20" height="20" />
                                                    <label className="lable-modal">{item.name}</label>
                                                </div>
                                                <div>                                                  
                                                    <IbssCheckBox 
                                                    id={item.spaceId} 
                                                    checked={this.state.selectedSpaceId == item.spaceId} 
                                                    onClicked={() => this.spaceLayoutChanged(item)}
                                                />                                                    
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })}
                            </>
                        )}

                        {/* -------------------select a layout--------------------*/}
                        <div className="p-0 my-24">
                            <label className="lable-modal text-bold">{this.labels.HubLabelSelectALayout}</label>
                        </div>

                        {this.state.spaceArrangementList?.map((item) =>
                        {
                            return (
                                <div className="p-0" key={`${item.breakdown}-${item.capacity}-${item.setup}-${item.style}`}>
                                    <div className="d-flex justify-content-between">
                                        <div className="lable-modal btn-align w-50 justify-content-start">
                                            <img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Seating Arrangement.svg`} alt="Seating Arrangement" width="20" height="20" />
                                            <label className="ml-3">{item.style}</label>            
                                        </div>
                                        <div>
                                            <IbssCheckBox 
                                            id={item.style} 
                                            checked={this.state.selectedSeatingArrangement.style == item.style} 
                                            onClicked={() => { this.seatingArrangementChanged(item) }} 
                                        />                                        
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            )
                        })}
                    </div>
                }
                footer=
                {
                    <div className="px-58 w-100 d-flex justify-content-end">
                        <IbssButton
                            className='ml-2'
                            variant='contained'
                            onClick={() => this.saveClicked()}
                        >
                            {this.labels.HubButtonSave}
                        </IbssButton>
                    </div>
                }
                onClose={() => this.closeClicked()}
            />
        )
    }
}

export default SpaceArrangementsDialog;

export interface IProps
{
    modalOpen: boolean;
    spaceSetup: number;
    onSave: (seatingArrangements: ISpaceArrangement, spaceLayout: string, spaceLayoutName: string) => void;
    onClose: () => void;
    spaceLayout?: string; // JSON string

    selectedLayoutStyle?: string; // Layout of space, e.g. boardroom, theatre.
    spaceId?: string;
}

export interface IState
{
    selectedSpaceId: string;
    selectedSpaceName: string;
    spaceArrangementList: ISpaceArrangement[];
    selectedSeatingArrangement: ISpaceArrangement;
    spaceId: string;
    spaceLayout: ISpaceLayout[];
    lightModeTheme: boolean,
}

export interface ISpaceArrangement
{
    breakdown: number;
    capacity: number;
    setup: number;
    style: string;
}

export interface ISpaceLayout
{
    name: string;
    spaceId: string;
    seatingArrangements: ISpaceArrangement[];
}