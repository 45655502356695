import { Component } from "react";
import { appContext } from "../../../AppContext";
import IbssDialog from "../../Dialogs/BaseDialog/IbssDialog";
import IbssButton from "../../Buttons/Button/IbssButton";

export default class ConfirmModal extends Component<IProps>
{
    private labels = appContext().labels;

    public render(): JSX.Element
    {
        const { name, show, handleModal, okButton, modalHeading, modalMessage } = this.props;
        return (
            <IbssDialog
                fullWidth
                open={show}
                header={modalHeading}
                onClose={() => this.props.handleModal()}
                content={
                    <>
                        {name && <div className="modal-space-name">{name}</div>}
                        <div>{modalMessage}</div>
                    </>
                }
                footer=
                {
                    <div className="d-flex justify-content-center">
                        <IbssButton fullWidth variant="outlined" color="secondary" className="mr-2 modal-button" onClick={() => handleModal()}>{this.labels.HubButtonCancel}</IbssButton>
                        <IbssButton fullWidth variant="contained" onClick={() => okButton()}>{this.labels.HubLabelOk}</IbssButton>
                    </div>
                }
            />
        );
    }
}

export interface IProps
{
    show: boolean;
    modalHeading: string;
    name?: string | null;
    modalMessage: string;
    handleModal: () => void;
    okButton: () => void;
}
