
export class Constants
{
    public static readonly imagePaths = new Map<string, string>();
    public static emailExpression = /^(("[\w-\s']+")|([\w-']+(?:\.[\w-']+)*)|("[\w-\s']+")([\w-']+(?:\.[\w-']+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    
    public static initialise(): void
    {
        // workspaces
        Constants.imagePaths.set("Desk", "/images/spaceImages/space_desk.png");
        Constants.imagePaths.set("SitStandDesk", "/images/spaceImages/space_sitstanddesk.png");
        Constants.imagePaths.set("SpecialistDesk", "/images/spaceImages/space_specialistdesk.png");
        Constants.imagePaths.set("MeetingRoom", "/images/spaceImages/space_meetingroom.png");
        Constants.imagePaths.set("MeetingBooth", "/images/spaceImages/space_meetingbooth.png");
        Constants.imagePaths.set("PhoneBooth", "/images/spaceImages/space_phonebooth.png");
        Constants.imagePaths.set("FocusBooth", "/images/spaceImages/space_focusBooth.png");
        Constants.imagePaths.set("Touchdown", "/images/spaceImages/space_touchdown.png");
        Constants.imagePaths.set("CollaborationSpace", "/images/spaceImages/space_collaborationspace.png");
        Constants.imagePaths.set("Office", "/images/spaceImages/space_office.png");
        Constants.imagePaths.set("PrivateRoom", "/images/spaceImages/space_privateroom.png");
        Constants.imagePaths.set("VCRoom", "/images/spaceImages/space_vcroom.png");
        Constants.imagePaths.set("Boardroom", "/images/spaceImages/space_boardroom.png");
        Constants.imagePaths.set("SharedQuietRoom", "/images/spaceImages/space_quietroom.png");
        Constants.imagePaths.set("LectureTheatre", "/images/spaceImages/space_lecturetheatre.png");
    
        // support spaces
        Constants.imagePaths.set("InformalLounge", "/images/spaceImages/space_informallounge.png");
        Constants.imagePaths.set("TrainingRoom", "/images/spaceImages/space_trainingroom.png");
        Constants.imagePaths.set("BenchSeating", "/images/spaceImages/space_benchseating.png");
        Constants.imagePaths.set("Storage", "/images/spaceImages/space_storage.png");
        Constants.imagePaths.set("PrintRoom", "/images/spaceImages/space_printroom.png");
        Constants.imagePaths.set("Lockers", "/images/spaceImages/space_lockers.png");
        Constants.imagePaths.set("Stationery", "/images/spaceImages/space_stationery.png");
        Constants.imagePaths.set("PostRoom", "/images/spaceImages/space_postroom.png");
    
        // amenities
        Constants.imagePaths.set("Reception", "/images/spaceImages/amenity_reception.png");
        Constants.imagePaths.set("WaitingLounge", "/images/spaceImages/amenity_waitinglounge.png");
        Constants.imagePaths.set("Restaurant", "/images/spaceImages/amenity_restaurant.png");
        Constants.imagePaths.set("Caf�", "/images/spaceImages/amenity_cafe.png");
        Constants.imagePaths.set("Kitchenette", "/images/spaceImages/amenity_kitchenette.png");
        Constants.imagePaths.set("Bar", "/images/spaceImages/amenity_bar.png");
        Constants.imagePaths.set("LiftLobby", "/images/spaceImages/amenity_liftlobby.png");
        Constants.imagePaths.set("TreatmentRoom", "/images/spaceImages/amenity_treatmentroom.png");
        Constants.imagePaths.set("BOHOffice", "/images/spaceImages/space_office.png");
        Constants.imagePaths.set("Gym", "/images/spaceImages/amenity_gym.png");
        Constants.imagePaths.set("Changing", "/images/spaceImages/amenity_changing.png");
        Constants.imagePaths.set("UnisexWCs", "/images/spaceImages/amenity_toilets.png");
        Constants.imagePaths.set("MaleWC", "/images/spaceImages/amenity_toiletsmale.png");
        Constants.imagePaths.set("FemaleWC", "/images/spaceImages/amenity_toiletsfemale.png");
        Constants.imagePaths.set("AccessibleWC", "/images/spaceImages/amenity_toilets.png");
        Constants.imagePaths.set("BikeStore", "/images/spaceImages/amenity_bikestore.png");
        Constants.imagePaths.set("FirstAid", "/images/spaceImages/amenity_firstaid.png");
        Constants.imagePaths.set("MothersRoom", "/images/spaceImages/amenity_mothersroom.png");
        Constants.imagePaths.set("Security", "/images/spaceImages/amenity_security.png");
        Constants.imagePaths.set("CoatStorage", "/images/spaceImages/amenity_coatstorage.png");
        Constants.imagePaths.set("IndoorGarden", "/images/spaceImages/amenity_indoorgarden.png");
        Constants.imagePaths.set("PrayerRoom", "/images/spaceImages/amenity_prayerroom.png");
        Constants.imagePaths.set("Prayer", "/images/spaceImages/amenity_prayer.png");
        Constants.imagePaths.set("CleaningStation", "/images/spaceImages/amenity_cleaningstation.png");
        Constants.imagePaths.set("PlantRoom", "/images/spaceImages/ancillary_plantroom.png");
        Constants.imagePaths.set("Riser", "/images/spaceImages/ancillary_riser.png");
        Constants.imagePaths.set("EquipmentRoom", "/images/spaceImages/ancillary_equipmentroom.png");
        Constants.imagePaths.set("WellnessRoom", "/images/spaceImages/amenity_wellness.png");
        Constants.imagePaths.set("Pantry", "/images/spaceImages/amenity_pantry.png");
        Constants.imagePaths.set("WaterPoint", "/images/spaceImages/amenity_waterpoint.png");
        Constants.imagePaths.set("ParkingSpace", "/images/spaceImages/amenity_parkingspace.png");
        Constants.imagePaths.set("ParcelLocker", "/images/spaceImages/amenity_parcellocker.png");

        // equipments
        Constants.imagePaths.set("AC", "/images/Equipments/Equipment_AC.jpg");
        Constants.imagePaths.set("CHW", "/images/Equipments/Equipment_CHW.jpg");
        Constants.imagePaths.set("Controllers", "/images/Equipments/Equipment_Controllers.jpg");
        Constants.imagePaths.set("CWS", "/images/Equipments/Equipment_CWS.jpg");
        Constants.imagePaths.set("Electrical", "/images/Equipments/Equipment_Electrical.jpg");
        Constants.imagePaths.set("FireProtection", "/images/Equipments/Equipment_FireProtection.jpg");
        Constants.imagePaths.set("HWS", "/images/Equipments/Equipment_HWS.jpg");
        Constants.imagePaths.set("LTHW", "/images/Equipments/Equipment_LTHW.jpg");
        Constants.imagePaths.set("Meters", "/images/Equipments/Equipment_Meters.jpg");
        Constants.imagePaths.set("TerminalUnits", "/images/Equipments/Equipment_TerminalUnits.jpg");
        Constants.imagePaths.set("Ventilation", "/images/Equipments/Equipment_Ventilation.jpg");
        Constants.imagePaths.set("Workplace", "/images/Equipments/Equipment_Workplace.jpg");
    }
}

Constants.initialise();
