import { appContext } from "../../../AppContext";
import BuildingSelector from "../BuildingSelector/BuildingSelector";
import { MenuItemKey } from "./MenuItemKey";
import { ReactComponent as HomeIcon } from './icons/Home.svg';
import { ReactComponent as SetupIcon } from './icons/Setup.svg';
import { ReactComponent as ManageIcon } from './icons/Manage.svg';
import { ReactComponent as SecurityIcon } from './icons/Security.svg';
import { ReactComponent as OrganisationIcon } from './icons/Organisation.svg';
import { ReactComponent as UsersIcon } from './icons/Users.svg';
import { ReactComponent as BookingPoliciesIcon } from './icons/BookingPolicy.svg';
import { ReactComponent as FileManagementIcon } from './icons/FileManagement.svg';
import { ReactComponent as HistogramIcon } from './icons/SoundLevel.svg';
import { ReactComponent as LeafIcon } from './icons/Leaf.svg';
import { ReactComponent as SearchIcon } from './icons/Search.svg';
import { ReactComponent as CalendarIcon } from './icons/Calendar.svg';
import { ReactComponent as MyTasksIcon } from './icons/MyTasks.svg';
import { ReactComponent as CategoryIcon } from './icons/Category.svg';
import { ReactComponent as VisitorManagementIcon } from './icons/VisitorManagement.svg';
import { ReactComponent as TasksIcon } from './icons/Tasks.svg' 
import { ReactComponent as WorkplaceIcon } from './icons/Workplace.svg'
import { ReactComponent as BellIcon } from './icons/Bell.svg';
import { ReactComponent as HotDrinkIcon } from './icons/HotDrink.svg';
import { ReactComponent as SpacesIcon } from './icons/Spaces.svg';
import { ReactComponent as CleaningIcon } from './icons/Cleaning.svg';
import { ReactComponent as AdminPortalIcon } from './icons/AdminPortal.svg'
import { ReactComponent as FlexIcon } from './icons/Flex.svg';
import { ReactComponent as OneLensIcon } from './icons/OneLens.svg';
import { ReactComponent as CostCodeIcon } from './icons/CostCode.svg'
import { ReactComponent as OrphanedRecordsIcon } from './icons/Warning.svg'
import { ReactComponent as FindColleagueIcon } from './icons/FindAColleague.svg';
import { ReactComponent as BuildingIcon } from './icons/Building.svg';
import { ReactComponent as FlexScheduleIcon } from './icons/Schedule.svg';
import { ReactComponent as DelegateIcon } from './icons/Delegate.svg';
import { ReactComponent as AnnouncementsIcon } from './icons/Announcements.svg';

enum IIconPosition
{
    Left = "LEFT",
    Right = "RIGHT",
}


export class MenuItems
{
    private labels = appContext().labels;
    private appState = appContext().state;
    private local = appContext().localStorageProvider;
    public menuItems = new Array<IMenuItem>();

    constructor()
    {
        const labels = this.labels;
        const rights = this.local.getUserDetails().userRights;

        if (rights == null)
        {
            return;
        }

        this.menuItems =
        [
            {
                id: MenuItemKey.Flex,
                label: labels.HubMenuFlex,
                link: () => null,
                permissions: (rights && rights.FLEX && Object.keys(rights.FLEX).length > 0),
                appMenu: "flex",
                titleImageUrl: "/images/Flex Menu Hero (Switcher).svg",
                icon: {
                    name: "flex",
                    position: IIconPosition.Left,
                    component: FlexIcon,
                },
                children:
                    [
                        {
                            id: MenuItemKey.Flex_Home,
                            label: labels.HubMenuHome,
                            link: () => "/flex-home/",
                            appMenu: 'flex',
                            permissions: true,
                            children: [],
                            icon: 
                            {
                                name: 'home',
                                position: IIconPosition.Left,
                                component: HomeIcon,
                            },
                        },
                        
                        {
                            id: MenuItemKey.Flex_FindASpace,
                            label: labels.HubMenuSearchaSpace,
                            link: () => null,
                            appMenu: 'flex',
                            permissions: (rights?.FLEX?.Search?.includes?.("Spaces") ?? false),
                            children: [
                                {
                                    id: MenuItemKey.Flex_FindASpace_Single,
                                    label: labels.HubLabelSingleBooking,
                                    link: () => "/flex-find-a-space",
                                    permissions: (rights?.FLEX?.Search?.includes?.("Spaces") ?? false) || (rights?.FLEX?.FindASpace?.includes?.("RecurringBookings") ?? false ),
                                    children: [],
                                },
                                {
                                    id: MenuItemKey.Flex_FindASpace_Recurring,
                                    label: this.labels.funcAdvancedBooking_Short,
                                    link: () => "/flex-recurring-booking",
                                    permissions: (rights?.FLEX?.FindASpace?.includes?.("RecurringBookings") ?? false),
                                    children: [],
                                }
                            ],
                            icon: {
                                name: 'magnifying glass',
                                position: IIconPosition.Left,
                                component: SearchIcon,                    
                            }
                        },
                        {
                            id: MenuItemKey.Flex_FindAColleague,
                            label: labels.HubMenuFindColleague,
                            link: () => "/flex/users/search-colleagues",
                            appMenu: 'flex',
                            permissions: this.local.hasRight("FLEX.SearchPeople.Colleague"),
                            children: [],
                            icon: {
                                name: labels.HubMenuFindColleague,
                                position: IIconPosition.Left,
                                component: FindColleagueIcon,                    
                            }
                        },
                        {
                            id: MenuItemKey.Flex_Schedule,
                            label: this.labels.HubLabelScheduleView,
                            link: () => "/flex/schedule-view",
                            appMenu: 'flex',
                            permissions: (rights?.FLEX?.Search?.includes?.("Spaces") ?? false),
                            children: [],
                            icon: {
                                name: 'schedule',
                                position: IIconPosition.Left,
                                component: FlexScheduleIcon,                    
                            }
                        },
                        {
                            id: MenuItemKey.Flex_MySchedule,
                            label: labels.HubMenumySchedule,
                            link: () => null,
                            appMenu: 'flex',
                            permissions: (rights?.FLEX?.MySchedule?.length > 0 ?? false),
                            icon: {
                                name: 'schedule',
                                position: IIconPosition.Left,
                                component: CalendarIcon,                    
                            },
                            children: [
                                {
                                    id: MenuItemKey.Flex_MySchedule_MyBookings,
                                    label: labels.HubMenuBookings,
                                    link: () => "/flex-my-bookings",
                                    permissions: (rights?.FLEX?.MySchedule?.includes?.("MyBookings") ?? false),
                                    children: [],
                                    icon: {
                                        name: 'bookings',
                                        position: IIconPosition.Left,
                                        component: CategoryIcon,                    
                                    },
                                },
                                {
                                    id: MenuItemKey.Flex_MySchedule_MyVisitors,
                                    label: labels.HubMenuVisitors,
                                    link: () => "/flex-my-visitor",
                                    permissions: (rights?.FLEX?.MySchedule?.includes?.("MyVisitors") ?? false),
                                    children: [],
                                    icon: {
                                        name: 'visitors',
                                        position: IIconPosition.Left,
                                        component: VisitorManagementIcon,                    
                                    },
                                },
                                {
                                    id: MenuItemKey.Flex_MySchedule_MyBookingsForOthers,
                                    label: labels.HubMenuBookingsForOthers,
                                    link: () => "/flex-my-bookings-for-others",
                                    permissions: (rights?.FLEX?.MySchedule?.includes?.("MyBookingsForOthers") ?? false),
                                    children: [],
                                    icon: {
                                        name: 'bookings for others',
                                        position: IIconPosition.Left,
                                        component: CalendarIcon,                    
                                    },

                                }
                            ]
                        },
                        {
                            id: MenuItemKey.Flex_MyNotification,
                            label: labels.HubMenuMyNotifications,
                            link: () => "/flex-my-notification",
                            appMenu: 'flex',
                            permissions: (rights.DATAMODEL.Notifications.includes('Read')),
                            children: [],
                            icon: {
                                name: 'bell',
                                position: IIconPosition.Left,
                                component: BellIcon
                            }
                        },
                        {
                            id: MenuItemKey.Flex_MyTasks,
                            label: labels.HubMenuMyTask,
                            link: () => null,
                            appMenu: 'flex',
                            permissions: (rights?.FLEX?.MyTasks?.length > 0 ?? false),
                            icon: {
                                name: 'tasks',
                                position: IIconPosition.Left,
                                component: MyTasksIcon,                    
                            },
                            children: [
                                {
                                    id: MenuItemKey.Flex_MyTask_TasksRaised,
                                    label: labels.HubMenuTasksRaised,
                                    link: () => "/flex-my-tasks",
                                    permissions: (rights?.FLEX?.MyTasks?.includes?.("MyTasks") ?? false),
                                    children: [],
                                    icon: {
                                        name: 'tasks',
                                        position: IIconPosition.Left,
                                        component: TasksIcon,                    
                                    },
                                },
                            ]
                        },
                        {
                            id: MenuItemKey.Flex_Settings,
                            label: labels.HubMenuSettings,
                            link: () => null,
                            appMenu: "flex",
                            permissions: (rights?.FLEX?.UserPreferences?.length > 0 ?? false),
                            icon: {
                                name: 'settings',
                                position: IIconPosition.Left,
                                component: UsersIcon,                    
                            }, 
                            children: [
                                {
                                    id: MenuItemKey.Flex_Settings_Notifications,
                                    label: labels.HubLabelNotifications,
                                    link: () => "/flex-user-pref-notifications",
                                    permissions: (rights?.FLEX?.UserPreferences?.includes?.('Notifications') ?? false),
                                    children: [],
                                    icon: {
                                        name: 'bell',
                                        position: IIconPosition.Left,
                                        component: BellIcon,                 
                                    },
                                },
                                {
                                    id: MenuItemKey.Flex_Settings_Workplace,
                                    label: labels.HubMenuWorkplacePreferences,
                                    link: () => "/flex-user-pref-workplace",
                                    permissions: (rights?.FLEX?.UserPreferences?.includes?.('Workplace') ?? false),
                                    children: [],
                                    icon: {
                                        name: 'tasks',
                                        position: IIconPosition.Left,
                                        component: WorkplaceIcon,                    
                                    },
                                },
                                {
                                    id: MenuItemKey.Flex_Settings_Account,
                                    label: labels.HubMenuAccountSettings,
                                    link: () => "/flex-user-pref-account",
                                    permissions: (rights?.FLEX?.UserPreferences?.includes?.('AccountSettings') ?? false),
                                    children: [],
                                    icon: {
                                        name: 'cog',
                                        position: IIconPosition.Left,
                                        component: SetupIcon,                    
                                    },
                                },
                                {
                                    id: MenuItemKey.Flex_Settings_CostCodes,
                                    label: this.labels.funcMenuDefaultCostCodes_Short,
                                    link: () => "/flex/settings/default-cost-codes",
                                    permissions: (rights?.FLEX?.UserPreferences?.includes?.('CostCodes') ?? false),
                                    children: [],
                                    icon: {
                                        name: 'default cost code',
                                        position: IIconPosition.Left,
                                        component: CostCodeIcon,               
                                    },
                                },
                                {
                                    id: MenuItemKey.Flex_Settings_DelegatePermissions,
                                    label: labels.funcDelegatePermissions_S,
                                    link: () => "/flex/delegates/create",
                                    permissions: this.local.hasRight("FLEX.UserPreferences.Delegates"),
                                    children: [],
                                    icon: {
                                        name: 'delegate',
                                        position: IIconPosition.Left,
                                        component: DelegateIcon,                    
                                    },
                                },
                                {
                                    id: MenuItemKey.Flex_Settings_DelegateAccess,
                                    label: labels.funcDelegateAccess_S,
                                    link: () => "/flex/delegates",
                                    permissions: this.local.hasRight("FLEX.UserPreferences.Delegates"),
                                    children: [],
                                    icon: {
                                        name: 'delegate',
                                        position: IIconPosition.Left,
                                        component: DelegateIcon,                    
                                    },
                                }
                            ]
                        }
                    ]
            },
            {
                id: MenuItemKey.OneLens,
                label: labels.HubMenuoneLens360,
                link: () => null,
                permissions: (rights && rights.ONELENS360 && Object.keys(rights.ONELENS360).length > 0),
                appMenu: "onelen360",
                titleImageUrl: "/images/OneLens360 Menu Hero.svg",
                icon: {
                    name: 'OneLens360',
                    position: IIconPosition.Left,
                    component: OneLensIcon,
                },
                children:
                    [
                        {
                            id: MenuItemKey.OneLens_BuildingSelector,
                            label: '',
                            link: () => null,
                            permissions: true,
                            children: [],
                            component: BuildingSelector
                        },
                        {
                            id: MenuItemKey.OneLens_Home,
                            label: labels.HubMenuHome, // Home
                            link: () => "/operational-services-home/",
                            appMenu: "onelen360",
                            permissions: true,
                            icon: {
                                name: 'home',
                                position: IIconPosition.Left,
                                component: HomeIcon,
                            },
                            children:[]
                        },
                        {
                            id: MenuItemKey.OneLens_Operations,
                            label: labels.HubMenuOperations, //'Operations'
                            link: () => null,
                            appMenu: "onelen360",
                            permissions: rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.length > 0,
                            icon: {
                                name: 'cog',
                                position: IIconPosition.Left,
                                component: SetupIcon,
                            },
                            children: [
                                {
                                    id: MenuItemKey.OneLens_Operations_Bookings,
                                    label: labels.HubMenuBookings,
                                    link: () => null,
                                    permissions: (rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.indexOf("Bookings") > -1),
                                    children: [
                                        {
                                            id: MenuItemKey.OneLens_Operations_Bookings_List,
                                            label: labels.HubLabelBookingList,
                                            link: () => "/operational-services-bookings-list/" + this.appState.buildingId.toString(),
                                            permissions: (rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.indexOf("Bookings") > -1),
                                            children: [],
                                        },
                                        {
                                            id: MenuItemKey.OneLens_Operations_Bookings_Trends, 
                                            label: labels.HubLabelBookingTrends,
                                            link: () => `/buildings/${this.appState.buildingId}/bookings/trends`,
                                            permissions: (rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.indexOf("Bookings") > -1),
                                            children: [],
                                        },
                                        {  
                                            id: MenuItemKey.OneLens_Operations_Bookings_ScheduleView,
                                            label: labels.HubLabelScheduleView,
                                            link: () => `/buildings/${this.appState.buildingId.toString()}/bookings/schedule-view`,
                                            permissions: (rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.indexOf("ScheduleView") > -1),
                                            children: [],
                                        }
                                    ],
                                    icon: {
                                        name: 'calendar',
                                        position: IIconPosition.Left,
                                        component: CalendarIcon,
                                    },
                                },
                                {
                                    id: MenuItemKey.OneLens_Operations_Visitors,
                                    label: labels.HubMenuVisitors,
                                    link: () => null,
                                    permissions: (rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.indexOf("Visitors") > -1),
                                    icon: {
                                        name: 'visitor management',
                                        position: IIconPosition.Left,
                                        component: VisitorManagementIcon,
                                    },
                                    children: [
                                        {
                                            id: MenuItemKey.OneLens_Operations_Visitors_Dashboard,
                                            label: labels.HubLabelVisitorDashboard,
                                            link: () => "/operational-services-visitors-dashboard/" + this.appState.buildingId.toString(),
                                            permissions: (rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.indexOf("Visitors") > -1),
                                            children: [],
                                        },
                                        {
                                            id: MenuItemKey.OneLens_Operations_Visitors_List,
                                            label: labels.HubLabelVisitorList,
                                            link: () => "/operational-services-visitors/" + this.appState.buildingId.toString(),
                                            permissions: (rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.indexOf("Visitors") > -1),
                                            children: [],
                                        },
                                        {
                                            id: MenuItemKey.OneLens_Operations_Visitors_Trends, 
                                            label: labels.HubLabelVisitorTrends,
                                            link: () => "/operational-services-visitors-trends/" + this.appState.buildingId.toString(),
                                            permissions: (rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.indexOf("Visitors") > -1),
                                            children: [],
                                        },
                                    ],
                                },
                                {
                                    id: MenuItemKey.OneLens_Operations_Catering,
                                    label: labels.HubMenuCatering,
                                    link: () => null,
                                    permissions: this.local.hasRight("ONELENS360.OperationalServices.Catering"),
                                    icon: {
                                        name: 'hot drink',
                                        position: IIconPosition.Left,
                                        component: HotDrinkIcon,
                                    },
                                    children: [
                                        {
                                            id: MenuItemKey.OneLens_Operations_Catering_CateringOrders,
                                            label: labels.HubMenuCateringOrders, //'Catering Orders',
                                            link: () => "/catering-orders/" + this.appState.buildingId.toString(),
                                            permissions: this.local.hasRight("ONELENS360.OperationalServices.Catering") && this.local.hasRight("DATAMODEL.CateringOrders.Read"),
                                            children: [],
                                        },
                                        {
                                            id: MenuItemKey.Onelens_Operations_Catering_CateringItems, 
                                            label: labels.HubMenuCateringItems, //'Catering Items'
                                            link: () => "/catering-items/" + this.appState.buildingId.toString(),
                                            permissions: this.local.hasRight("ONELENS360.OperationalServices.Catering") && this.local.hasRight("DATAMODEL.CateringMenuItems.Read"),
                                            children: [],
                                        },
                                        {
                                            id: MenuItemKey.Onelens_Operations_Catering_CateringMenus,
                                            label: labels.HubMenuCateringMenus,
                                            link: () => `/buildings/${this.appState.buildingId}/catering-menus/`,
                                            permissions: this.local.hasRight("ONELENS360.OperationalServices.Catering") && this.local.hasRight("DATAMODEL.CateringMenus.Read"),
                                            children: [],
                                        },
                                        {
                                            id: MenuItemKey.Onelens_Operations_Catering_OrderPolicy, 
                                            label: labels.HubLabelMenuOrderPolicy, //'Catering Privacy Policy'
                                            link: () => "/buildings/" + this.appState.buildingId.toString() + "/catering-order-policies",
                                            permissions: this.local.hasRight("ONELENS360.OperationalServices.Catering") && this.local.hasRight("DATAMODEL.CateringOrderPolicies.Read"),
                                            children: [],
                                        },
                                        {
                                            id: MenuItemKey.Onelens_Operations_Catering_DietaryTerms, 
                                            label: this.labels.funcCateringDietaryTerms_S,
                                            link: () => `/catering-dietary-terms/${this.appState.buildingId.toString()}`,
                                            permissions: this.local.hasRight("ONELENS360.OperationalServices.Catering") && this.local.hasRight("DATAMODEL.CateringRestrictions.Read"),
                                            children: [],
                                        },
                                        {
                                            id: MenuItemKey.Onelens_Operations_Catering_Suppliers, 
                                            label: labels.HubLabelMenuCateringSuppliers, //'Catering Suppliers'
                                            link: () => `/buildings/${this.appState.buildingId.toString()}/catering-suppliers`,
                                            permissions: this.local.hasRight("ONELENS360.OperationalServices.Catering") && this.local.hasRight("DATAMODEL.CateringSuppliers.Read"),
                
                                            children: [],
                                        },
                                    ],
                                },
                                {
                                    id: MenuItemKey.OneLens_Operations_CostCodes,
                                    label: this.labels.HubLabelCostCodes,
                                    link: ()=> "/operational-cost-codes/" + this.appState.buildingId.toString(),
                                    permissions: rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.includes('CostCodes'),
                                    children: [],
                                    icon: {
                                        name: 'cost code',
                                        position: IIconPosition.Left,
                                        component: CostCodeIcon,
                                    }
                                },
                                {
                                    id: MenuItemKey.OneLens_Operations_Tasks,
                                    label: labels.HubMenuTasks,
                                    link: () => "/operational-services-tasks/" + this.appState.buildingId.toString(),
                                    permissions: (rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.indexOf("Tasks") > -1),
                                    children: [],
                                    icon: {
                                        name: 'tasks',
                                        position: IIconPosition.Left,
                                        component: TasksIcon,
                                    },
                                },
                                {
                                    id: MenuItemKey.OneLens_Operations_Announcements,
                                    label: this.labels.funcSidebarAnnouncements_Short,
                                    link: () => "/oneLens/" + this.appState.buildingId.toString() + "/announcements/list",
                                    permissions: (rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.indexOf("Notifications") > -1),
                                    children: [],
                                    icon: {
                                        name: 'announcements',
                                        position: IIconPosition.Left,
                                        component: AnnouncementsIcon,
                                    },
                                },
                                {
                                    id: MenuItemKey.OneLens_Operations_Notifications,
                                    label: labels.HubMenuOpservNotifications,
                                    link: () => "/operational-services-notifications/" + this.appState.buildingId.toString(),
                                    permissions: !!(rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.indexOf("Notifications") > -1),
                                    children: [],
                                    icon: {
                                        name: 'bell',
                                        position: IIconPosition.Left,
                                        component: BellIcon,
                                    },
                                }, 
                                {
                                    id: MenuItemKey.OneLens_Operations_Spaces,
                                    label: labels.HubMenuSpaces,
                                    link: () => "/operational-services-spaces/" + this.appState.buildingId.toString(),
                                    permissions: this.local.hasRight("ONELENS360.OperationalServices.Spaces"),
                                    icon:
                                    {
                                        name: 'spaces',
                                        position: IIconPosition.Left,
                                        component: SpacesIcon,
                                    },
                                    children: [],
                                },
                                {
                                    id: MenuItemKey.OneLens_Operations_DirectedCleaning,
                                    label: labels.HubMenuOpservDirectedCleaning,
                                    link: () => "/operational-services-directed-cleaning/" + this.appState.buildingId.toString(),
                                    permissions: (rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.indexOf("DirectedCleaning") > -1),
                                    children: [],
                                    icon: {
                                        name: 'cleaning',
                                        position: IIconPosition.Left,
                                        component: CleaningIcon,
                                    },
                                },
                                {
                                    id: MenuItemKey.OneLens_Operations_DeletedUsers,
                                    label: this.labels.funcOrphanedUsers_M,
                                    link: () => "/users/deleted",
                                    permissions: (rights && rights.ONELENS360 && rights.ONELENS360.OperationalServices && rights.ONELENS360.OperationalServices.indexOf("OrphanedBookings") > -1),
                                    children: [],
                                    icon: {
                                        name: 'orphanedRecords',
                                        position: IIconPosition.Left,
                                        component: OrphanedRecordsIcon,
                                    },
                                },
                            ]
                        },
                        {
                            id: MenuItemKey.OneLens_Analytics,
                            label: labels.HubMenuAnalytics, //'Analytics'
                            link: () => null,
                            appMenu: "onelens360",
                            permissions: (rights?.ONELENS360?.SpaceAnalytics?.length > 0 || rights?.ONELENS360?.SustainabilityAnalytics?.length > 0 ) ?? false,
                            icon: {
                                name: 'histogram',
                                position: IIconPosition.Left,
                                component: HistogramIcon,
                            },
                            children: [
                                {
                                    id: MenuItemKey.OneLens_Analytics_Sustainability,
                                    label: labels.HubMenuSustainability, //'Sustainability'
                                    link: () => null,
                                    appMenu: "onelen360",
                                    permissions: (rights?.ONELENS360?.SustainabilityAnalytics?.length > 0 ?? false),
                                    icon: {
                                        name: 'leaf',
                                        position: IIconPosition.Left,
                                        component: LeafIcon,
                                    },
                                    children: [
                                        {
                                            id: MenuItemKey.OneLens_Analytics_Sustainability_Overview,
                                            label: labels.HubMenuOverview,
                                            link: () => "/sustainability-analytics-overview/" + this.appState.buildingId.toString(),
                                            permissions: (rights?.ONELENS360?.SustainabilityAnalytics?.includes?.('Overview') ?? false),
                                            children: [],
                                        },
                                        {
                                            id: MenuItemKey.OneLens_Analytics_Sustainability_Floors,
                                            label: labels.HubMenuFloor,
                                            link: () => "/sustainability-analytics-floors/" + this.appState.buildingId.toString(),
                                            permissions: (rights?.ONELENS360?.SustainabilityAnalytics?.includes?.('Floors') ?? false),
                                            children: [],
                                        },
                                        {
                                            id: MenuItemKey.OneLens_Analytics_Sustainability_Zones,
                                            label: labels.HubMenuZones,
                                            link: () => "/sustainability-analytics-zones/" + this.appState.buildingId.toString(),
                                            permissions: (rights?.ONELENS360?.SustainabilityAnalytics?.includes?.('Zones') ?? false),
                                            children: [],
                                        },
                                        {
                                            id: MenuItemKey.OneLens_Analytics_Sustainability_Sensors,
                                            label: labels.HubMenuSensor,
                                            link: () => "/sustainability-analytics-sensors/" + this.appState.buildingId.toString(),
                                            permissions: (rights?.ONELENS360?.SustainabilityAnalytics?.includes?.('Sensors') ?? false),
                                            children: [],
                                        }
                                    ]
                                },
                                {
                                    id: MenuItemKey.OneLens_Analytics_Space,
                                    label: labels.HubMenuSpace,
                                    link: () => null,
                                    appMenu: "onelen360",
                                    permissions: (rights?.ONELENS360?.SpaceAnalytics?.length > 0 ?? false),
                                    icon: {
                                        name: 'spaces',
                                        position: IIconPosition.Left,
                                        component: SpacesIcon,
                                    },
                                    children: [
                                        {
                                            id: MenuItemKey.OneLens_Analytics_Space_Overview,
                                            label: labels.HubLabelSpaceoverview,
                                            link: () => "/space-analytics-overview/" + this.appState.buildingId.toString(),
                                            permissions: (rights?.ONELENS360?.SpaceAnalytics?.includes?.('Overview') ?? false),
                                            children: [],
                                        },
                                        {
                                            id: MenuItemKey.OneLens_Analytics_Space_Heatmaps,
                                            label: labels.HubMenuHeatMaps,
                                            link: () => "/space-analytics-heatmaps/" + this.appState.buildingId.toString(),
                                            permissions: (rights?.ONELENS360?.SpaceAnalytics?.includes?.('Heatmaps') ?? false),
                                            children: [],
                                        }
                                    ]
                                },
                            ] 
                        },
                    ]
            },
            {
                id: MenuItemKey.Admin,
                label: labels.HubMenuAdminPortal,
                link: () => null,
                appMenu: "adminPortal",
                permissions: (rights && rights.ADMINPORTAL && Object.keys(rights.ADMINPORTAL).length > 0),
                titleImageUrl: "/images/Admin Portal Menu Hero.svg",
                icon: {
                    name: 'admin',
                    position: IIconPosition.Left,
                    component: AdminPortalIcon,
                },
                children:[
                    // ***** new admin menu *****
                    // remove until we have the admin home route and admin home pages. 
                    // {
                    //     id: MenuItemKey.Admin_Home,
                    //     label: labels.HubMenuHome, //'Home',
                    //     link: () => null,
                    //     appMenu: 'adminPortal', 
                    //     permissions: !!(rights && rights.ADMINPORTAL),
                    //     icon: {
                    //         name: 'home',
                    //         position: IIconPosition.Left,
                    //         component: HomeIcon,
                    //     },
                    //     children: [],
                    // },

                    {
                        id: MenuItemKey.Admin_Setup,
                        label: labels.HubMenuSetup, //'Setup',
                        link: () => null,
                        appMenu: 'adminPortal', 
                        permissions: !!(rights && rights.ADMINPORTAL),
                        icon:{
                            name: 'setup',
                            position: IIconPosition.Left,
                            component: SetupIcon,
                        },
                        children: [
                            {
                                id: MenuItemKey.Admin_Setup_Security,
                                label: labels.HubMenuSecurity,
                                link: () => null,
                                appMenu: "adminPortal",
                                permissions: (rights?.ADMINPORTAL?.Security ? true : false),
                                subLabel: labels.HubMenuSecuritySetup, //'Security setup for system',
                                icon: {
                                    name: 'lock',
                                    position: IIconPosition.Right,
                                    component: SecurityIcon,
                                },
                                children: [
                                    {
                                        id: MenuItemKey.Admin_Setup_Security_IdentityProviders,
                                        label: labels.HubMenuIdentityproviders,
                                        link: () => "/security-identity-providers",
                                        permissions: (rights?.ADMINPORTAL?.Security?.includes?.("IdentityProviders") ?? false),
                                        children: [],
                                    },
                                    {
                                        id: MenuItemKey.Admin_Setup_Security_Roles,
                                        label: labels.HubMenuRolesDataSecurity, //'Roles & Data Security'
                                        link: () => "/security-roles",
                                        permissions: (rights?.ADMINPORTAL?.Security?.includes?.("Roles") ?? false),
                                        children: [],
                                    },
                                    {
                                        id: MenuItemKey.Admin_Setup_Security_DelegatePermissions,
                                        label: labels.funcDelegatePermissions_S,
                                        link: () => "/admin/delegates/create",
                                        permissions: this.local.hasRight("ADMINPORTAL.Security.Delegates"),
                                        children: [],
                                    },
                                ],
                            },
                            /* comment out the organisation section until rights.ADMINPORTAL.notifications is resolved. */
                            {
                                id: MenuItemKey.Admin_Setup_Organisation,
                                label: labels.HubMenuOrganisation,
                                subLabel: labels.HubMenuSetupClient, //'Setup client'
                                link: () => null,
                                appMenu: 'adminPortal',
                                permissions: !!(rights && rights.ADMINPORTAL && rights.ADMINPORTAL.PortfolioSetup && (rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1)),
                                icon: {
                                    name: 'organisation',
                                    position: IIconPosition.Right,
                                    component: OrganisationIcon,
                                },
                                children: [
                                    {
                                        id: MenuItemKey.Admin_Setup_Organisation_Buildings,
                                        label: labels.HubMenuBuildings,
                                        link: () => "/portfolio-setup-spaces/filter=Building",
                                        appMenu: 'adminPortal',
                                        permissions: !!(rights && rights.ADMINPORTAL && rights.ADMINPORTAL.PortfolioSetup && (rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1)),
                                        children: [],
                                        icon: {
                                            name: 'building',
                                            // lightIcon: "/Sidebar_Icons/Light_theme/Building.svg",
                                            // darkIcon: "/Sidebar_Icons/Dark_Theme/Building.svg",
                                            // iconActive: "Sidebar_Icons/Active_icons/Building.svg",
                                            // active: false,
                                            // visibility: true,
                                            position: IIconPosition.Left,
                                            component: ManageIcon,
                                        }
                                    },
                                    {
                                        id: MenuItemKey.Admin_Setup_Organisation_Regions,
                                        label: labels.HubMenuRegions,
                                        link: () => "/portfolio-setup-spaces/filter=Region",
                                        appMenu: 'adminPortal',
                                        permissions: !!(rights && rights.ADMINPORTAL && rights.ADMINPORTAL.PortfolioSetup && (rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1)),
                                        children: [],
                                        icon: {
                                            name: 'region',
                                            // lightIcon: "/Sidebar_Icons/Light_theme/Region.svg",
                                            // darkIcon: "/Sidebar_Icons/Dark_Theme/Region.svg",
                                            // iconActive: "Sidebar_Icons/Active_icons/Region.svg",
                                            // active: false,
                                            // visibility: true,
                                            position: IIconPosition.Left,
                                            component: ManageIcon,
                                        }
                                    },
                                    {
                                        id: MenuItemKey.Admin_Setup_Organisation_Notifications,
                                        label: labels.HubMenuNotificationSetup,
                                        link: () => '/notifications',
                                        appMenu: "adminPortal",
                                        permissions: (rights?.ADMINPORTAL?.PortfolioSetup?.includes('Notifications') ?? false),
                                        children: [],
                                    },
                                ],
                            },
                        ],                        
                    },

                    {
                        id: MenuItemKey.Admin_Manage,
                        label: labels.HubMenuManage, //'Manage'
                        link: () => null,
                        appMenu: 'adminPortal', 
                        permissions: !!(rights && rights.ADMINPORTAL),
                        icon:{
                            name: 'manage',
                            position: IIconPosition.Left,
                            component: ManageIcon,
                        },
                        children: [
                            {
                                id: MenuItemKey.Admin_Manage_Users,
                                label: labels.HubMenuUsers,
                                link: () => "/security-users",
                                permissions: (rights?.ADMINPORTAL?.Security?.includes?.("Users") ?? false),
                                children: [],
                                icon:{
                                    name: 'user',
                                    position: IIconPosition.Right,
                                    component: UsersIcon,
                                }
                            },
                            {
                                id: MenuItemKey.Admin_Manage_CostCodes,
                                label: this.labels.HubLabelCostCodes,
                                link: ()=> "/cost-codes/",
                                permissions: !!((rights && rights.ADMINPORTAL && rights.API && rights.API?.CostCodes?.includes('V2')) ?? false ),
                                children: [],
                                icon: {
                                    name: 'cost code',
                                    position: IIconPosition.Left,
                                    component: CostCodeIcon,
                                }
                            },
                            {
                                id: MenuItemKey.Admin_Manage_BuildingSelector,
                                label: '',
                                link: () => null,
                                permissions: true,
                                children: [],
                                component: BuildingSelector
                            },
                            {
                                id: MenuItemKey.Admin_Manage_BookingPolicies,
                                label: labels.HubMenuBookingPolicies,
                                link: () => '/booking-policies/' + this.appState.buildingId.toString(),
                                permissions: (rights?.DATAMODEL?.BookingPolicies?.includes?.("Read") ?? false), 
                                children: [],
                                icon: {
                                    name: 'booking policies',
                                    position: IIconPosition.Right,
                                    component: BookingPoliciesIcon,
                                }
                            },
                            {
                                id: MenuItemKey.Admin_Manage_Spaces,
                                label: 'Spaces',
                                link: () =>  `/admin/buildings/${this.appState.buildingId.toString()}/spaces`,
                                permissions: (rights?.DATAMODEL?.Spaces?.includes?.("Read") ?? false), 
                                children: [],
                                icon: {
                                    name: 'spaces',
                                    position: IIconPosition.Right,
                                    component: BuildingIcon,
                                }
                            },
                            // comment out the following block until building selector component and the ability to use buildingSelector's id to define the data returned on these pages is done.
                            // {
                            //     id: MenuItemKey.Admin_PortfolioSetup_Floors,
                            //     label: labels.HubMenuFloorLabel,
                            //     link: "/portfolio-setup-spaces/filter=Floor",
                            //     appMenu: 'adminPortal',
                            //     permissions: !!(rights && rights.ADMINPORTAL && rights.ADMINPORTAL.PortfolioSetup && (rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1)),
                            //     children: [],
                            //     icon: {
                            //         name: 'floor',
                            //         lightIcon: "/Sidebar_Icons/Light_theme/Floor.svg",
                            //         darkIcon: "/Sidebar_Icons/Dark_Theme/Floor.svg",
                            //         iconActive: "Sidebar_Icons/Active_icons/Floor.svg",
                            //         active: false,
                            //         visibility: true,
                            //         position: IIconPosition.Left,
                            //     }
                            // },
                            // {
                            //     id: MenuItemKey.Admin_PortfolioSetup_Zones,
                            //     label: labels.HubMenuZones,
                            //     link: "/portfolio-setup-spaces/filter=Zone",
                            //     appMenu: 'adminPortal',
                            //     permissions: !!(rights && rights.ADMINPORTAL && rights.ADMINPORTAL.PortfolioSetup && (rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1)),
                            //     children: [],
                            //     icon: {
                            //         name: 'zone',
                            //         lightIcon: "/Sidebar_Icons/Light_theme/Zone.svg",
                            //         darkIcon: "/Sidebar_Icons/Dark_Theme/Zone.svg",
                            //         iconActive: "Sidebar_Icons/Active_icons/Zone.svg",
                            //         active: false,
                            //         visibility: true,
                            //         position: IIconPosition.Left,
                            //     }
                            // },
                            // {
                            //     id: MenuItemKey.Admin_PortfolioSetup_Rooms,
                            //     label: labels.HubMenuRoomsDesks,
                            //     link: "/portfolio-setup-spaces/filter=Space",
                            //     permissions: !!(rights && rights.ADMINPORTAL && rights.ADMINPORTAL.PortfolioSetup && (rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1)),
                            //     children: [],
                            //     icon: {
                            //         name: 'room',
                            //         lightIcon: "/Sidebar_Icons/Light_theme/Room.svg",
                            //         darkIcon: "/Sidebar_Icons/Dark_Theme/Room.svg",
                            //         iconActive: "Sidebar_Icons/Active_icons/Room.svg",
                            //         active: false,
                            //         visibility: true,
                            //         position: IIconPosition.Left,
                            //     }
                            // },
                            {
                                id: MenuItemKey.Admin_Manage_FileManagement,
                                label: labels.HubMenuFileManagement,
                                link: () => null,
                                appMenu: "adminPortal",
                                permissions: (rights?.ADMINPORTAL?.Files?.length > 0 ?? false),
                                icon: {
                                    name: 'filemanagement',
                                    position: IIconPosition.Right,
                                    component: FileManagementIcon,
                                },
                                children: [
                                    {
                                        id: MenuItemKey.Admin_Manage_FileManagement_Roamer,
                                        label: labels.HubMenuRoamer,
                                        link: () => "/filemgmt/roamer",
                                        permissions: (rights?.ADMINPORTAL?.Files?.includes?.("Roamer") ?? false),
                                        children: [],
                                    },
                                    {
                                        id: MenuItemKey.Admin_Manage_FileManagement_Unity,
                                        label: labels.HubMenuUnity,
                                        link: () => "/filemgmt/unity",
                                        permissions: (rights?.ADMINPORTAL?.Files?.includes?.("Unity") ?? false),
                                        children: [],
                                    },
                                    {
                                        id: MenuItemKey.Admin_Manage_FileManagement_Inspire,
                                        label: labels.HubMenuInspire,
                                        link: () => "/filemgmt/inspire",
                                        permissions: (rights?.ADMINPORTAL?.Files?.includes?.("Inspire") ?? false),
                                        children: [],
                                    },
                                    {
                                        id: MenuItemKey.Admin_Manage_FileManagement_Common,
                                        label: labels.HubMenuCommon,
                                        link: () => "/filemgmt/common",
                                        permissions: (rights?.ADMINPORTAL?.Files?.includes?.("Common") ?? false),
                                        children: [],
                                    }
                                ],
                            }
                        ],
                    },
                ]
                    
            }
        ];
    }
}

export interface IIconData
{
    name: string,
    component: React.FC,
    position: IIconPosition,
}

// each list of these represent one list of menu items out of the three - Flex, Admin Portal and oneLens360
export interface IMenuItem
{
    id: MenuItemKey,
    label: string,
    subLabel?: string, // an optional 2nd line for 2nd layer menu items.
    link: () => (string | null),
    permissions: boolean,
    icon?: IIconData, // new icon of type React.FC, which can be used with MUI's svgIcon.
    titleImageUrl?: string,
    children: Array<IMenuItem>,
    appMenu?: string, // name of the menu
    component?: React.ComponentType,
}
