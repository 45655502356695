
export class ApiHelper
{
    public static toODataSelect<TResponse>(responseType: new() => TResponse): string
    {
        const responseInstance = new responseType();
        const oDataSelect = Object.entries(responseInstance as {}).map(i => i[0]).join(",");
        return oDataSelect;
    }
}
