import { routeList } from "../routes";
import { Component } from "react";

export class RouteHelper
{
    public static getCurrentRoute(): Route | null
    {
        let path = window.location.pathname.replace(/\/$/, '');
        if (!path.startsWith("/"))
        {
            path = "/" + path;
        }

        for (let i = 0; i < routeList.length; i++)
        {
            let route = routeList[i];
            let routePattern = '^' + route.path.replace(/:[^/]+/g, '[^/]+') + '$';
            let isMatch = new RegExp(routePattern, 'gi').test(path);

            if (isMatch)
            {
                return route;
            }
        }

        return null;
    }
}

export interface Route
{
    exact: boolean;
    protected: boolean;
    path: string;
}
