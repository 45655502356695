import { Component } from 'react';
import { appContext } from '../../../../AppContext';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { Box } from '@mui/material';

class CancelBookingModal extends Component<IProps, IState>
{
    private get alert() { return appContext().alert; }
    private get bookingService() { return appContext().bookingService; }
    private get labels() { return appContext().labels; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            isLoading: false,
        };
    }

    private async cancelBooking(): Promise<void>
    {
        const nodeId = this.props.floorId;
        const bookingId = this.props.bookingId;

        if(nodeId && bookingId)
        {
            try
            {
                this.setState({ isLoading: true});
                const response = await this.bookingService.delete(nodeId, bookingId);

                if(response)
                {
                    this.alert.show(this.labels.HubLabelSuccess, response.Message);
                }
            }
            finally
            {
                // refresh bookings, close BookingCriteria after a cancel booking try.
                await this.props.getBookings();
                this.props.handleShowCancelModal();
                this.props.showBookingCriteria(false);
                this.setState({ isLoading: false});
            }
        }
    }

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                aria-modal="true"
                aria-label="cancel booking modal"
                open={this.props.showCancelModal}
                header=
                {
                    <>
                        <label className="modal-heading">{this.labels.HubButtonCancelBooking}</label>
                    </>
                }
                content=
                {
                    <>
                        <div>{this.labels.HubLabelconfirmCancel}</div>
                        <br></br>
                        <div>{this.labels.funcBookingScheduleCancelSubtitle_L}</div>
                    </>
                }
                footer=
                {
                    <Box sx={{display: 'flex', justifyContent: 'center', gap: '0.5em',width: '100%'}}>
                        <IbssButton
                            variant='contained'
                            color='secondary'
                            sx={{width: '35%'}}
                            disabled={this.state.isLoading===true}
                            onClick={() => this.props.handleShowCancelModal()}
                        >
                            {this.labels.funcBookingScheduleCancelNo_S}
                        </IbssButton>
                        <IbssButton
                            variant='contained'
                            color='secondary'
                            sx={{width: '35%'}}
                            disabled={this.state.isLoading===true}
                            onClick={async() => await this.cancelBooking()}
                        >
                            {this.labels.funcBookingScheduleCancelYes_S}
                        </IbssButton>
                    </Box>
                }
                onClose={() => 
                {
                    if(this.state.isLoading === false)
                    {
                        this.props.handleShowCancelModal()
                    }
                }}
                fullWidth
            />
        )
    }
}

export default CancelBookingModal;

export interface IProps
{
    bookingId: string | undefined,
    floorId: number,
    getBookings: () => Promise<void>,
    handleShowCancelModal: () => void,
    showBookingCriteria: (val: boolean)=> void,
    showCancelModal: boolean, 
}

export interface IState
{
    isLoading: boolean,
}