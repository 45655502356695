import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import { MenuItem, Typography } from "@mui/material";
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { appContext } from '../../../../AppContext';
import { IFloor } from '../../../../Providers.Api/Models';
import Helper from '../../../../Common/Helper';
import IbssSwitchLabel from '../../../../Components/Inputs/Switch/IbssSwitchLabel';

class ScheduleViewModal extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            floor: this.props.floor,
            spaceType: this.props?.spaceType ?? this.labels.HubLabelAny,
            spaceWorkType: this.props?.spaceWorkType ?? this.labels.HubLabelAny,
            capacity: this.props.capacity,
            capacityError: "",
            hasCatering: this.props.hasCatering,
            hasEquipment: this.props.hasEquipment,
            floorTypeOptions: [],
            spaceTypeOptions: [],
            spaceWorkTypeOptions: [],
        };
    }

    // on opening modal, using buildingId, load floors, spaceTypes of building. create options for the select fields.
    public async componentDidMount(): Promise<void>
    {
        // load options
        this.populateSpaceTypes(this.props.buildingId);
        this.populateSpaceWorkTypes(this.props.buildingId);
        this.populateFloors(this.props.buildingId, this.state.floor);
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        // load options
        if(prevProps.buildingId !== this.props.buildingId)
        {
            this.populateSpaceTypes(this.props.buildingId);
            this.populateSpaceWorkTypes(this.props.buildingId);
            this.populateFloors(this.props.buildingId, this.state.floor);
        }
        if(prevProps.floor !== this.props.floor)
        {
            this.setState({floor: this.props.floor});
        }
        if(prevProps.spaceType !== this.props.spaceType)
        {
            this.setStateAsync({spaceType: this.props.spaceType });
        }
        if(prevProps.spaceWorkType !== this.props.spaceWorkType)
        {
            // make sure spaceType and spaceType options are not both 'Any'
            if(this.state.spaceType === 'Any' && this.props.spaceWorkType ==='Any')
            {
                this.populateSpaceWorkTypes(this.props.buildingId);
            }
            else
            {
                this.setState({spaceWorkType: this.props.spaceWorkType });
            }
        }
        if(prevProps.capacity !== this.props.capacity)
        {
            this.setState({capacity: this.props.capacity });
        }
        if(prevProps.hasCatering !== this.props.hasCatering)
        {
            this.setState({hasCatering: this.props.hasCatering });
        }
        if(prevProps.hasEquipment !== this.props.hasEquipment)
        {
            this.setState({hasEquipment: this.props.hasEquipment });
        }
    }

    private populateSpaceWorkTypes(selectedBuildingId: number): void
    {
        const spaceWorkTypes = Helper.getWorkSpaceTypesByNodeId(selectedBuildingId);

        const options = spaceWorkTypes
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1)); // sort by name;

        options.unshift({ label: this.labels.HubLabelAny, value: "Any" });
        if (options.filter(x => x.value === 'FormalMeeting').length > 0)
        {
            this.setState({ spaceWorkType: 'FormalMeeting' });
        }
        else
        {
            this.setState({ spaceWorkType: options[1]?.value ?? options[0]?.value });
        }

        this.setState({ spaceWorkTypeOptions: options });
    }

    private populateSpaceTypes(selectedBuildingId: number): void
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(selectedBuildingId);

        const options = spaceTypes.result
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }))
            .sort((a, b) => a.label.localeCompare(b.label))

        options.unshift({ label: this.labels.HubLabelAny, value: "Any" });
        this.setState({ spaceTypeOptions: options });
    }

    private populateFloors(selectedBuildingId: number, selectedFloor: string): void
    {
        const floors: IFloor[] = Helper.getFloorsByBuildingId(selectedBuildingId);

        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id.toString() }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1)); // sort by name

        options.unshift({ label: this.labels.HubLabelAny, value: 'Any' });
        this.setState({ floorTypeOptions: options, floor: selectedFloor });
    }

    private filterModalCancelled(): void
    {
        // reset states to original values if modal is closed or cancelled.
        this.props.handleFilterModal(false);
        this.setState({
            floor: this.props.floor,
            spaceType: this.props.spaceType || this.labels.HubLabelAny,
            // use props.spaceWorkType, unless both it and props.spaceType are 'Any', then try to use this.state.spaceWorkType
            spaceWorkType: this.props.spaceWorkType === 'Any' && this.props.spaceType==='Any'? this.state.spaceWorkType: (this.props.spaceWorkType ?? this.state.spaceWorkType),
            capacity: this.props.capacity,
            hasCatering: this.props.hasCatering,
            hasEquipment: this.props.hasEquipment,
        });
    }

    private filterModalSubmitted(): void
    {      
        // update parent component CostCodes' states on submission.

        const selectedFloorName = this.state.floorTypeOptions.find(option => option.value === this.state.floor)?.label ?? this.labels.HubLabelAny;
        
        this.props.handleFilterModal(false);
        this.props.changeFloor(this.state.floor, selectedFloorName);
        this.props.changeCapacity(this.state.capacity);
        this.props.changeHasCatering(this.state.hasCatering);
        this.props.changeHasEquipment(this.state.hasEquipment);
        this.props.changeSpaceType(this.state.spaceType);
        this.props.changeSpaceWorkType(this.state.spaceWorkType);
    }

    private floorChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        const floor = e.target.value;
        this.setState({ floor: floor });
    }

    private spaceTypeChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        const spaceType = e.target.value;
        if(spaceType === 'Any')
        {
            // check state.spaceWorkType
            if(this.state.spaceWorkType === 'Any')
            {
                // set spaceWorkType to something oether than 'Any' with second in list of spaceWorkTypes options
                this.setState({spaceWorkType: this.state.spaceWorkTypeOptions[1].value});
            } 

            this.setState({spaceType: spaceType});

        }
        else
        {
            // set spaceType & set spaceWorkType to "Any"
            this.setState({spaceType: spaceType, spaceWorkType: 'Any'});
        }
    }

    private spaceWorkTypeChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        const spaceWorkType = e.target.value;
        if(spaceWorkType === 'Any')
        {
            // check state.spaceType
            if(this.state.spaceType === 'Any')
            {
                // set spaceType to something oether than 'Any' with second in list of spaceTypes options
                this.setState({spaceType: this.state.spaceTypeOptions[1].value});
            } 

            this.setState({spaceWorkType: spaceWorkType});

        }
        else
        {
            // set SpaceWorkType & set spaceType to "Any"
            this.setState({ spaceWorkType: spaceWorkType , spaceType: 'Any'});
        }
    }

    private capacityChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        const input = e.target.value;
        const expression = /^[0-9\b]+$/ // only accept digits
        if (expression.test(input))
        {
            this.setState({ capacity: input }); // sets the capacity with input first (only numbers allowed), before generating the error messages for that input.
        }

        const capacityAsNumber = this.capacityAsNumber(input);
        if (capacityAsNumber === null)
        {
            this.setState({ capacityError: this.labels.funcMustBeNumber_S });
            return;
        }
        else if (capacityAsNumber < 2)
        {
            this.setState({ capacityError: this.labels.funcMustBeTwoOrMore_S });
            return;
        }
        else
        {
            this.setState({ capacityError: "" });
        }
    }

    private hasCateringChanged(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.setState({hasCatering: e.target.checked});
    }

    private hasEquipmentChanged(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.setState({ hasEquipment: e.target.checked });
    }

    private capacityAsNumber(input: string): number | null
    {
        const capacityAsNumber= Number(input);
        if(capacityAsNumber >= 0)
        {
            return capacityAsNumber
        }
        else 
        {
            return null;
        }
    }

    private get isFilterValid(): boolean
    {
        return (this.state.capacityError === "");
    }

    public render(): JSX.Element
    {
        return (
            <IbssDialog
            open={this.props.openFilterModal}
            onClose={() => this.filterModalCancelled()}
            fullWidth
            header={this.labels.HubLabelFilter}
            content=
            {
                <>
                    
                    <Typography className="row mb-3">{this.labels.HubMessageScheduleFilters}</Typography>

                    <div className="row mb-3">
                        <IbssTextField
                            id="floorSelection"
                            select={true}
                            label={this.labels.HubLabelFloor}
                            fullWidth
                            value={this.state.floor}
                            variant={'outlined'}
                            onChange={(e) => { this.floorChanged(e) }}
                        >
                            {this.state.floorTypeOptions.map(floor => (
                                <MenuItem key={floor.value} value={floor.value}>
                                    {floor.label}
                                </MenuItem>
                            ))}
                        </IbssTextField>
                    </div>
                    <div className="row mb-3">
                        <IbssTextField
                            id="spaceWorkTypeSelection"
                            select={true}
                            label={this.labels.HubLabelworkType}
                            fullWidth
                            value={this.state.spaceWorkType}
                            variant={'outlined'}
                            onChange={(e) => { this.spaceWorkTypeChanged(e) }}
                        >
                            {this.state.spaceWorkTypeOptions.map(spaceWorkType => (
                                <MenuItem key={spaceWorkType.value} value={spaceWorkType.value}>
                                    {spaceWorkType.label}
                                </MenuItem>
                            ))}
                        </IbssTextField>
                    </div>
                    <div className="row mb-3">
                        <IbssTextField
                            id="spaceTypeSelection"
                            select={true}
                            label={this.labels.HubLabelSpaceType}
                            fullWidth
                            value={this.state.spaceType}
                            variant={'outlined'}
                            onChange={(e) => { this.spaceTypeChanged(e) }}
                        >
                            {this.state.spaceTypeOptions.map(spaceType => (
                                <MenuItem key={spaceType.value} value={spaceType.value}>
                                    {spaceType.label}
                                </MenuItem>
                            ))}
                        </IbssTextField>
                    </div>
                    <div className="row mb-3">
                        <IbssTextField
                            id="capacitySelection"
                            label={this.labels.HubLabelCapacity}
                            fullWidth
                            type='number'
                            value={this.state.capacity}
                            variant={'outlined'}
                            error={!!this.state.capacityError}
                            helperText={this.state.capacityError}
                            
                            onChange={(e) => { this.capacityChanged(e) }}
                            inputProps={{ min: "2", inputMode: 'numeric', pattern: '[0-9]*' }}
                        >
                        </IbssTextField>
                    </div>
                    <div className="row mb-3">
                        <IbssSwitchLabel 
                            id="cateringSelection"
                            label={
                                <Typography sx={{ color: (theme) => theme.palette.text.primary }}>
                                    {this.labels.HubLabelHasCatering}
                                </Typography>
                                } 
                            checked={this.state.hasCatering} 
                            onChange={e => this.hasCateringChanged(e)}
                        />
                    </div>
                    <div className="row mb-3">
                        <IbssSwitchLabel 
                            id="equipmentSelection" 
                            label={
                                <Typography sx={{ color: (theme) => theme.palette.text.primary }}>
                                    {this.labels.HubLabelHasEquipment}
                                </Typography>
                                } 
                            checked={this.state.hasEquipment} 
                            onChange={e => this.hasEquipmentChanged(e)} 
                        />
                    </div>
                </>
            }
            footer=
            {
                <>
                    <IbssButton
                        onClick={() => this.filterModalCancelled()}
                        color="secondary"
                        variant="outlined"
                    >
                        {this.labels.HubButtonCancel}
                    </IbssButton>
                    <IbssButton
                        color="primary"
                        variant="contained"
                        size="medium"
                        disabled={!this.isFilterValid}
                        onClick={() => this.filterModalSubmitted()}
                    >
                        {this.labels.HubLabelOk}
                    </IbssButton>
                </>
            }
        />
        )
    }
}

export default ScheduleViewModal;

export interface IProps
{
    openFilterModal: boolean,
    handleFilterModal: (status: boolean) => void,
    buildingId: number,
    floor: string,
    spaceType: string,
    spaceWorkType: string,
    capacity: string,
    hasCatering: boolean,
    hasEquipment: boolean,
    changeFloor: (floor: string, floorName: string) => void,
    changeCapacity: (capacity: string)=> void,
    changeSpaceType: (spaceType: string)=> void,
    changeSpaceWorkType: (spaceWorkType: string)=> void,
    changeHasCatering: (hasCatering: boolean) => void,
    changeHasEquipment: (hasEquipment: boolean)=> void,
}

export interface IState
{
    floor: string,
    spaceType: string,
    spaceWorkType: string,
    capacity: string,
    capacityError: string,
    hasCatering: boolean,
    hasEquipment: boolean,
    floorTypeOptions: Array<IListOption<string>>,
    spaceTypeOptions: Array<IListOption<string>>,
    spaceWorkTypeOptions: Array<IListOption<string>>,
}

export interface IListOption<TValue>
{
    label: string,
    value: TValue,
}