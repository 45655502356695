import { AccordionSummary, Typography, AccordionDetails, Accordion } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Component } from 'react';

class IbssAccordion extends Component<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            expandedKey: '',
        };
    }

    private handleAccordionChange(key: string): void 
    {
        key == this.state.expandedKey ? this.setState({ expandedKey: '' }) : this.setState({ expandedKey: key });
    };

    public render(): JSX.Element 
    {
        return (
            <div>
                {
                    this.props.values.map((x) => (
                        <Accordion
                            key={x.key}
                            sx={{ marginTop: this.props.spacing ?? 'unset' }}
                            expanded={this.state.expandedKey === x.key}
                        >
                            <AccordionSummary
                                expandIcon={x.hideExpand != undefined && x.hideExpand == true ? <div style={{ width: '24px' }} /> : <ExpandMoreIcon onClick={() => this.handleAccordionChange(x.key)}/>}
                                aria-controls={`panel${x.key}-content`}
                                id={x.key}
                            >
                                {x.summary}
                            </AccordionSummary>
                            <AccordionDetails>
                                {x.details}
                            </AccordionDetails>
                        </Accordion>
                ))}
            </div>
        )
    }
}

export default IbssAccordion;

export interface IProps
{
    values: IAccordion[];
    spacing?: string;
}

export interface IState
{
    expandedKey: string; 
}

export interface IAccordion 
{
    key: string;
    summary: React.ReactNode;
    details: React.ReactNode;
    hideExpand?: boolean
}