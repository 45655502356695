import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IbssRequestConfig } from "../ApiClient";

export class CreateV2BookingEndpoint implements ICreateV2BookingEndpoint
{
    public async execute(nodeId: number, booking: ICreateV2BookingRequest, suppressErrorPopup: boolean): Promise<ICreateV2BookingResponse>
    {
        try
        {
            const utcBooking = {
                ...booking,
                Start: DateTime.fromISO(booking.Start).toUTC().toISO(),
                End: DateTime.fromISO(booking.End).toUTC().toISO(),
            };
            const axiosConfig: IbssRequestConfig = { suppressErrorPopup: suppressErrorPopup };
            const response = await axios.post<ICreateV2BookingResponse>(apis.allApiv2 + nodeId + `/Bookings`, utcBooking, axiosConfig);
            return response.data;
        }
        catch (error: any)
        {
            throw ApiError.fromAxiosResponse(error.response as AxiosResponse);
        }
    }
}

export interface ICreateV2BookingEndpoint
{
    execute(nodeId: number, booking: ICreateV2BookingRequest, suppressErrorPopup: boolean): Promise<ICreateV2BookingResponse>;
}

export interface ICreateV2BookingRequest
{
    _CreatedAt: string;
    _CreatedBy: string;
    SpaceId: string;
    Start: string;
    End: string;
    Name: string;
    Description: string;
    DisableExtUpdate: boolean;
    BookingAutoCheckin: number;
    Space_Layout?: string;
    Space_Name?: string;
    OnBehalfOf?: string;
    Booking_Parties: ICreateV2Booking_BookingParty[];
    Cost_Code_Allocation: ICreateV2Booking_CostCodeAllocation[];
    AddOnlineMeetingLink: number;
    UseOnBehalfOfCostCodes?:number;
}

export interface ICreateV2Booking_BookingParty
{
    Booking_Participant_Email: string;
    Booking_Participant_Name: string;
    Booking_Participant_Organisation: string;
    Booking_Resource_Id: string;
    Booking_Visitor: boolean;
    Booking_Participant_Type: number;
}

export interface ICreateV2Booking_CostCodeAllocation
{
    Cost_Code: string;
    Cost_Code_Id: string;
    Allocation: number;
}

export interface ICreateV2BookingResponse
{
    Booking: IV2BookingResponse;
    Warnings: string[];
}

export interface IV2BookingResponse 
{
    Node_Id: number;
    Booking_Id: string;
    Space_Id: string;
    Booking_Start: string;
    Booking_End: string;
}
