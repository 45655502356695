import React, { Component } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, DialogProps, styled, DialogTitleProps, DialogContentProps, DialogActionsProps, Breakpoint } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import IbssIconButton from '../../Buttons/IconButton/IbssIconButton';

export default class IbssDialog extends Component<IProps>
{
    public render(): JSX.Element {
        const { header, content, footer, maxWidth } = this.props
        return (
            <>
                <Dialog maxWidth={maxWidth} {...this.props}>
                    {header &&
                        <Box sx={{backgroundColor: "var(--ui-background)" }}>
                            <StyledDialogTitle>
                                {header}
                                {this.props.onClose && 
                                (
                                    <IbssIconButton
                                        aria-label="close"
                                        onClick={this.props.onClose}
                                    >
                                        <CloseIcon />
                                    </IbssIconButton>
                                )} 
                            </StyledDialogTitle>
                        </Box>
                    }
                    <StyledDialogContent dividers>
                        {content}
                    </StyledDialogContent>
                    {footer &&
                        <StyledDialogActions>
                            {footer}
                        </StyledDialogActions>
                    }
                </Dialog>
            </>
        )
    }
}

const StyledDialogTitle = styled(DialogTitle)<DialogTitleProps>(({ theme, color }) =>
({
    '&.MuiDialogTitle-root': {
        fontSize: "25px",
        Color: "var(--ui-text)",
        backgroundColor: "var(--ui-background)",
        borderRadius: "10px 10px 0px 0px",
        padding: "0px 58px",
        height: "89px",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    }
}));

const StyledDialogContent = styled(DialogContent)<DialogContentProps>(({ theme, color }) =>
({
    '&.MuiDialogContent-root': {
        Color: "var(--ui-text)",
        overflowY: "auto",
        backgroundColor: "var(--ui-background-alternate)",
        padding: "20px 58px",
        boxSizing: "border-box",

    }
}));

const StyledDialogActions = styled(DialogActions)<DialogActionsProps>(({ theme, color }) =>
({
    '&.MuiDialogActions-root': {
        display: "flex",
        justifyContent: "center",
        borderTop: "1px solid var(--ui-line-break)",
        padding: "20px 0px",

    }
}));

export interface IProps extends DialogProps {
    header?: React.ReactNode;
    content: React.ReactNode;
    footer?: React.ReactNode;
    maxWidth?: Breakpoint;
    onClose?: () => void
}
