import { Component } from "react";
import "../../../../styles/css/dashboard.scss";
import "../../../../styles/css/header.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import { enableRipple } from "@syncfusion/ej2-base";
import "../../../../styles/css/form.scss";
import Inputbox from "../../../../Components/Inputs/TextInput/Inputbox";
import SelectBox from "../../../../Components/Inputs/SelectBox/SelectBox";
import ConfirmModal from "../../../../Components/Dialogs/ConfirmDialog/ConfirmModal";
import Submenu from "../../../../Components/Layout/Tabs2/SubMenu";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import { MDBDataTable } from "mdbreact";
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay"
import TablePanelInfo from "../../../../Components/Headings/TableHeading/TablePanelInfo"
import { appContext } from "../../../../AppContext";


enableRipple(true);
class EditUser extends Component {
  private alert = appContext().alert;

  private get labels() { return appContext().labels; }
  private get local() { return appContext().localStorageProvider; }

  state = {
    isLoading: false,
    isLoadingForResetPassword: false,
    isLoadingToSave: false,
    phoneNumber: null,
    location: null,
    deletedAt: null,
    showRoles: 0,
    loading: false,
    activePage: 0,
    updates: false,
    title: "",
    identityProviderId: 1, // set to 1 as the page can only create users with native authentication
    securityProviderTypeId: 0,
    id: null,
    email: null,
    firstName: null,
    password: "",
    lastName: null,
    displayName: null,
    userType: 0,
    XApiKey: "",
    applicationKey: "",
    userPreferences: "",
    concurrencyStamp: '',
    availableRolesColumn: 'none',
    manageRolesButton: 'block',
    userRoles: [{
      name: null,
      identityProviderName: null,
      active: null,
      configurable: null
    }],
    rowData: [],
    rowRolesData: [],
    manageRoleData: [],
    breadcrumbs: null,
    roleID: null,
    removeRole: [],
    addRole: [],
    show: false,
    isDelete: false,
    isResetPassword: false,
    isUpdateRight: false,
    isDeleteRight: false,
    editMode: true,
    context: ""
  };

  componentDidMount() {
    const { match, history }: any = this.props;
    if (sessionStorage.getItem("userTitle") !== null || history.location.state === 'cancel') {
      this.setState({ activePage: 2 });
    }
    if (match.params.id !== "0") {
      match.params.id && this.getDetails(match.params.id);
      this.setState({
        id: match.params.id,
        password: "111111111111111"
      });
      this.getRightList();
    } else {
      this.setState({
        title: this.labels.HubButtonNewUser,
        id: 0,
        isUpdateRight: true,
        isDeleteRight: true,
        context: this.labels.HubButtonNewUser,
      });
    }
    sessionStorage.removeItem("userTitle")
    sessionStorage.removeItem("userID")

    if (sessionStorage.getItem('roleTitle') !== null) {
      this.setState({
        breadcrumbs: sessionStorage.getItem('roleTitle'),
        roleID: sessionStorage.getItem('roleID')
      })
    }
  }

  getRightList = () => {
    const res = this.local.getIbssRightList();
      if (res.ADMINPORTAL && res.ADMINPORTAL.Users && res.ADMINPORTAL.Users.indexOf("Update") > -1) {
        this.setState({
          isUpdateRight: true
        })
      }
      if (res.ADMINPORTAL && res.ADMINPORTAL.Users && res.ADMINPORTAL.Users.indexOf("Delete") > -1) {
        this.setState({
          isDeleteRight: true
        })
      }
     else {
      this.setState({
        isUpdateRight: false,
        isDeleteRight: false,
        editMode: false
      })
    }
  }

  getDetails = (id: any) => {
    this.availableRoles();
    apis.getIdentityProvidersUsersById(id).then((res) => {
      this.setState({ ...res.data }, () => {
        if (this.state.displayName === "" || this.state.displayName === null) {
          this.setState({
            loading: false,
            title: this.state.email,
            context: `${res.data.identityProviderName} / ${this.state.email}`
          })
        } else {
          this.setState({
            loading: false,
            title: this.state.displayName,
            context: `${res.data.identityProviderName} / ${this.state.displayName}`

          })
        }
      });

    }).catch((error: any) => { });
    apis.getIdentityProvidersUserRolesById(id).then((res) => {

      if (res.data.length === 0) {
        this.setState({
          availableRolesColumn: 'none',
          loading: false, manageRolesButton: 'block',
          userRoles: res.data,
          tableMessage: this.labels.HublabelErrorEmptyData
        });
      } else {
        for (let j = 0; j < res.data.length; j++) {
          let params = res.data[j];
          let isOwnerStatus = "";
          if (params.isOwner === 1) {
            isOwnerStatus = "Yes";
          }
          if (params.isOwner === 0) {
            isOwnerStatus = "No";
          }
          res.data[j] = {
            ...res.data[j],
            name: res.data[j].name,
            isOwner: isOwnerStatus,
            identityProvidesName: res.data[j].identityProviderName === 'AzureActiveDirectory' ? 'Azure AD' : res.data[j].identityProviderName,
            clickEvent: () => this.redirectRoles(params.id)
          }
        }
        this.setState({
          availableRolesColumn: 'none',
          loading: false, manageRolesButton: 'block',
          userRoles: res.data,
          rowData: res.data
        });
      }
    }).catch((error: any) => { });
  };

  // changeToRoles = () => {
  //   this.setState({
  //     showRoles: 1
  //   })
  // }

  renderManageRole = (data: any) => {
    let tempData: any = data;
    for (let j = 0; j < tempData.length; j++) {
      let params = tempData[j];
      let isOwnerStatus = "";
      if (params.isOwner === 1) {
        isOwnerStatus = "Yes";
      }
      if (params.isOwner === 0) {
        isOwnerStatus = "No";
      }
      tempData[j] = {
        ...tempData[j],
        name: tempData[j].name,
        isOwner: isOwnerStatus,
        identityProvidesName: tempData[j].identityProviderName === 'AzureActiveDirectory' ? 'Azure AD' : tempData[j].identityProviderName,
        manageRole:
          <label className="newCheckBox">
            <input name={params.id} type="checkbox" className="checkBoxHide" value={tempData[j].id}
              onChange={(e) => { this.updateActiveStatus(e, params); }}
              checked={tempData[j].active === "1" ? true : false} />
            <span className="geekmark"></span>
          </label>,
      }
    }
    this.setState({
      rowRolesData: tempData,
      loading: false,
      manageRoleData: tempData
    })
  }
  availableRoles = () => {
    const { match }: any = this.props;
    const id = match.params.id;
    this.setState({ loading: true });
    apis.getIdentityProvidersUserAvailableRolesById(id).then((res) => {
      if (res.data.length === 0) {
        this.setState({
          availableRolesColumn: 'block',
          loading: false, manageRolesButton: 'none',
          tableMessage: this.labels.HublabelErrorEmptyData,
          rowRolesData: []
        });
      } else {
        this.setState({
          availableRolesColumn: 'block',
          loading: false,
          manageRolesButton: 'none',
          manageRoleData: res.data,
        }, () => {
          let tempData: any = res.data;
          for (let j = 0; j < tempData.length; j++) {
            let params = tempData[j];
            let isOwnerStatus = "";
            if (params.isOwner === 1) {
              isOwnerStatus = "Yes";
            }
            if (params.isOwner === 0) {
              isOwnerStatus = "No";
            }
            tempData[j] = {
              ...tempData[j],
              name: tempData[j].name,
              isOwner: isOwnerStatus,
              identityProvidesName: tempData[j].identityProviderName === 'AzureActiveDirectory' ? 'Azure AD' : tempData[j].identityProviderName,
              manageRole: <input name="activeRole" type="checkbox" className="input-check-box border" value={tempData[j].id}
                onChange={(e) => { this.updateActiveStatus(e, params); }}
                checked={tempData[j].active === "1" ? true : false} />,
            }
          }
          this.setState({
            rowRolesData: tempData,
            loading: false
          })
          this.renderManageRole(this.state.manageRoleData);
          // let tempData: any = this.state.manageRoleData;
          // for (let j = 0; j < tempData.length; j++) {
          //   let params = tempData[j];
          //   tempData[j] = {
          //     ...tempData[j],
          //     name: tempData[j].name,
          //     identityProvidesName: tempData[j].identityProviderName === 'AzureActiveDirectory' ? 'Azure AD' : tempData[j].identityProviderName,
          //     manageRole: <input name={params.id} type="checkbox" className="input-check-box border" value={tempData[j].id}
          //       onChange={(e) => { this.updateActiveStatus(e, params); }}
          //       checked={tempData[j].active === "1" ? true : false} />,
          //   }
          // }
          // this.setState({
          //   rowRolesData: tempData,
          //   loading: false
          // })
        });
      }


    }).catch((error: any) => {
      let errorMessage = this.labels.HubLabelerrorSomethingWentWrong
      if (error.response) {
        if (error.response.statusText) {
          errorMessage = error.response.statusText
          this.setState({
            tableMessage: errorMessage
          })
        }
      } else {
        this.setState({
          tableMessage: errorMessage
        })
      }
    });
  }

  maskRowData = () => {
    const { userRoles }: any = this.state;
    for (let j = 0; j < userRoles.length; j++) {
      let params = userRoles[j].id;
      let isOwnerStatus = "";
      if (params.isOwner === 1) {
        isOwnerStatus = "Yes";
      }
      if (params.isOwner === 0) {
        isOwnerStatus = "No";
      }
      userRoles[j] = {
        ...userRoles[j],
        name: userRoles[j].name,
        isOwner: isOwnerStatus,
        identityProvidesName: userRoles[j].identityProviderName === 'AzureActiveDirectory' ? 'Azure AD' : userRoles[j].identityProviderName,
        clickEvent: () => this.redirectRoles(params)
      }
    }

    this.setState({
      rowData: userRoles,
      loading: false
    })
  }

  hideAvailableRoles = () => {
    const { match }: any = this.props;
    const id = match.params.id;
    this.setState({
      availableRolesColumn: 'none',
      loading: false,
      manageRolesButton: 'block',
      showRoles: 0
    });
    this.getDetails(id);
  }

  submit = () => {
    this.setState({ isLoadingToSave: true })
    const { userPreferences, addRole, removeRole }: any = this.state;
    const { match }: any = this.props;

    for (let i = 0; i < removeRole.length; i++) {
      apis.deleteAssignedRoleToUserById(removeRole[i].userId, removeRole[i].roleId).catch((error: any) => { });
    }

    for (let i = 0; i < addRole.length; i++) {
      apis.assignRoleToUser(addRole[i]).catch((error: any) => { });
    }

    if (match.params.id === "0") {
      let payloadUser = {
        "firstName": this.state.firstName,
        "lastName": this.state.lastName,
        "displayName": this.state.displayName,
        "email": this.state.email,
        "password": this.state.password,
        "phoneNumber": this.state.phoneNumber,
        "location": this.state.location,
        "deletedAt": this.state.deletedAt,
        "applicationKey": this.state.applicationKey,
        "identityProviderId": this.state.identityProviderId,
        "securityProviderTypeId": this.state.securityProviderTypeId,
        "userType": this.state.userType
      }

      this.create(payloadUser)
    } else {
      let payloadUser = {
        "firstName": this.state.firstName,
        "lastName": this.state.lastName,
        "displayName": this.state.displayName,
        "email": this.state.email,
        "emailConfirmed": true,
        "phoneNumber": this.state.phoneNumber,
        "location": this.state.location,
        "deletedAt": this.state.deletedAt,
        "phoneNumberConfirmed": true,
        "twoFactorEnabled": true,
        "lockoutEnabled": true,
        "concurrencyStamp": this.state.concurrencyStamp,
        "userPreferences": userPreferences ? userPreferences : "",
        "applicationKey": this.state.applicationKey,
        "identityProviderId": this.state.identityProviderId,
        "securityProviderTypeId": this.state.securityProviderTypeId,
        "userType": this.state.userType
      }
      this.update(payloadUser)
    }

  };

  create = (payload: any) => {
    apis.createUser(payload).then((res) => {
      this.setState({ isLoadingToSave: false })
      const { history }: any = this.props;
      history.push("/security-users/userdetails/" + res.data.id);
      window.location.reload();
    }).catch((error: any) => {
      this.setState({ isLoadingToSave: false })
    });
  };

  update = (payload: any) => {
    const { match, history }: any = this.props;
    const { updates }: any = this.state;
    const id = match.params.id;
    if (updates) {
      apis.updateUserDetails(id, payload).then((res) => {
        // sessionStorage.clear();
        this.setState({ isLoadingToSave: false })
        history.push("/security-users");
      }).catch((error: any) => {
        this.setState({ isLoadingToSave: false })
      });
    } else {
      // sessionStorage.clear();
      history.push("/security-users");
    }

  };

  change = (e: any) => {
    this.setState({ updates: true })
    this.setState({ [e.target.name]: e.target.value });
  };

  updateActiveStatus = (e: any, active: any) => {
    const { match }: any = this.props;
    const { userRoles, addRole, removeRole, manageRoleData }: any = this.state;
    const id = match.params.id;
    let payload = {
      "userId": id,
      "roleId": e.target.value,
    }

    let tempData = userRoles;
    let rolesData = manageRoleData;
    let tempRemove = removeRole;
    let tempAdd = addRole;
    let insertmode = true;

    for (let i = 0; i < rolesData.length; i++) {
      if (rolesData[i].id === e.target.value) {
        if (rolesData[i].active === "0") {
          rolesData[i].active = "1";
          this.setState({
            rolesData: rolesData
          })
          for (let j = 0; j < tempRemove.length; j++) {
            if (tempRemove[j].roleId === e.target.value) {
              tempRemove.splice(j, 1);
              insertmode = false;
            }
          }
          if (insertmode) {
            tempAdd.push(payload);
          }
        } else {
          rolesData[i].active = "0";
          this.setState({
            rolesData: rolesData
          })
          for (let j = 0; j < tempAdd.length; j++) {
            if (tempAdd[j].roleId === e.target.value) {
              tempAdd.splice(j, 1);
              insertmode = false;
            }
          }
          if (insertmode) {
            tempRemove.push(payload);
          }
        }
      }

    }

    this.setState({
      addRole: tempAdd,
      removeRole: tempRemove
    });

    this.setState({
      userRoles: tempData,
    }, () => {
      this.renderManageRole(rolesData);
      this.maskRowData();
    })

  }

  isDelete = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
    this.delete();
  }

  cancelDeleteModal = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
  }
  delete = () => {
    const { match }: any = this.props;
    const id = match.params.id;
    if (this.state.isDelete) {
      apis.deleteUserById(id).then((res) => {
        const { history }: any = this.props;
        // sessionStorage.clear();
        history.push("/security-users");
      }).catch((error: any) => { });
    }
  };
  handleModal = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  cancel = () => {
    const { updates } = this.state
    const { history }: any = this.props;

    if (updates) {
      this.handleModal();
    } else {
      if (this.state.roleID) {
        history.push("/security-roles/rolesdetails/" + this.state.roleID, 'cancel');
      } else {
        // sessionStorage.clear();
        history.push("/security-users");
      }
    }
  }
  isResetPassword = () => {
    this.setState({
      isResetPassword: !this.state.isResetPassword,
      show: !this.state.show,
    })
  }

  cancelResetPasswordModal = () => {
    this.setState({
      isResetPassword: !this.state.isResetPassword,
      show: !this.state.show,
    })
  }
  resetPassword = () => {
    this.setState({ isLoadingForResetPassword: true })
    const { id }: any = this.state;
    if (this.state.isResetPassword) {
      apis.resetPasswordUser(this.state.email).then((res) => {
        const { history }: any = this.props;
        history.push("/security-users");
      }).catch((error: any) => { });
    }
    this.setState({ isLoadingForResetPassword: true })
    return;

  }

  redirectRoles = (id: any) => {
    const { history, match }: any = this.props;
    const userId = match.params.id;
    sessionStorage.setItem('userID', match.params.id);
    sessionStorage.setItem('userTitle', this.state.title);
    history.push("/security-roles/rolesdetails/" + id, `roles=${userId}`);
  }

  formatJson() {
    this.setState({ updates: true })
    try {
      let newData = JSON.parse(this.state.userPreferences)
      this.setState({
        userPreferences: JSON.stringify(newData, null, 2)
      })
    } catch (e) {
      this.alert.show("", "Invalid JSON");
    }
  }

  render() {
    const { userPreferences, rowRolesData, rowData }: any = this.state;
    const { history, match }: any = this.props;
    const userId = match.params.id;
    // console.log("state values are..", this.state)
    const emailFld = {
      name: "email",
      label: this.labels.HubLabelEmailAddress,
      value: this.state.email,
      mandatory: true,
      placeholder: this.labels.HubLabelEmailAddressPlaceholder,
      readonly: this.state.id !== 0,
      onchange: this.change,
    };

    const XApiKeyFld = {
      name: "applicationKey",
      label: this.labels.HubLabelApplicationKey,
      readonly: true,
      value: this.state.applicationKey,
      placeholder: this.labels.HubLabelApplicationKey,
      onchange: this.change,
    }

    const IdentityProviderTypeFld = {
      name: "identityProviderType",
      label: this.labels.HubLabelIdentityProviderType,
      value: this.state.securityProviderTypeId,
      mandatory: true,
      disabled: true,
      onchange: (value: number) => {
        this.setState({
          securityProviderTypeId: value,
          userType: 0,
          updates: true
        });
      },
      type: "option",
      options: [
        { title: this.labels.HubLabelNative, value: 0, },
        { title: this.labels.HubLabeloktaLabel, value: 2, selected: true },
        { title: this.labels.HubLabelAAD, value: 1 },
      ],
      placeholder: "Provide value",
    };

    const UserTypeFld = {
      name: "userType",
      label: this.labels.HubLabeluserType,
      value: this.state.userType,
      mandatory: true,
      disabled: this.state.id !== 0,
      onchange: (value: any) => {
        this.setState({
          userType: value,
          updates: true
        });
      },
      type: "option",
      options: [
        { title: this.labels.HubLabeluserLabel, value: 0 },
        { title: this.labels.HubLabelApplication, value: 1 }
      ],
      placeholder: "Provide value",
    };

    const firstNameFld = {
      name: "firstName",
      label: this.labels.HubLabelFirstName,
      value: this.state.firstName,
      mandatory: true,
      placeholder: this.labels.HubLabelFirstNamePlaceholder,
      readonly: this.state.editMode === false,
      onchange: this.change,
    };
    const lastNameFld = {
      name: "lastName",
      label: this.labels.HubLabelLastName,
      value: this.state.lastName,
      mandatory: true,
      placeholder: this.labels.HubLabelProvideLastName,
      readonly: this.state.editMode === false,
      onchange: this.change,
    };

    const displayNameFld = {
      name: "displayName",
      label: this.labels.HubLabelDisplayName,
      value: this.state.displayName,
      mandatory: true,
      placeholder: this.labels.HubLabelDisplayNamePlaceholder,
      readonly: this.state.editMode === false,
      onchange: this.change,
    };

    const locationFld = {
      name: "location",
      label: this.labels.HubLabelLocation,
      value: this.state.location,
      mandatory: true,
      readonly: this.state.editMode === false,
      placeholder: this.labels.HubLabelLocationPlaceholder,
      onchange: this.change,
    };

    const deletedAtFld = {
      name: "deletedAt",
      label: this.labels.HubLabelDeletedAt,
      value: this.state.deletedAt,
      mandatory: true,
      placeholder: this.labels.HubLabelDeletedAtPlaceholder,
      readonly: this.state.id !== 0,
      onchange: this.change,
    };

    const phoneNumberFld = {
      name: "phoneNumber",
      label: this.labels.HubLabelPhoneNumber,
      value: this.state.phoneNumber,
      mandatory: true,
      placeholder: this.labels.HubLabelPhoneNumberPlaceholder,
      readonly: this.state.editMode === false,
      onchange: this.change,
    };

    const props = {
      ...this.props,
      submenu: [
        {
          title: this.labels.HubTabUserDetails,
          tabNo: 0,
          active: this.state.activePage === 0 ? true : false,
        },
        {
          title: this.labels.HubTabUserPreferecnces,
          tabNo: 1,
          active: this.state.activePage === 1 ? true : false,
        },
        {
          title: this.labels.HubLabelRoles,
          tabNo: 2,
          active: this.state.activePage === 2 ? true : false,
          display: this.state.id !== 0 ? 'block' : 'none',
        },
      ],
      activePageValue: this.state.activePage,
      childValue: (i: any) => {
        this.setState({ activePage: i });
      },
    };

    const data = {
      columns: [
        {
          label: this.labels.HubLabelName,
          field: "name",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelIdentityprovider,
          field: "identityProvidesName",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelType,
          field: "securityProviderTypeName",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelIsOwner,
          field: "isOwner",
          sort: "",
          width: 50
        },
        {
          label: "",
          field: "",
          sort: "disabled",
        },
      ],
      rows: rowData
    };

    const dataRoles = {
      columns: [
        {
          label: this.labels.HubLabelManageRoles,
          field: "manageRole",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelName,
          field: "name",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelIdentityprovider,
          field: "identityProvidesName",
          sort: "",
          width: 50
        },

        {
          label: this.labels.HubLabelType,
          field: "securityProviderTypeName",
          sort: "",
          width: 50
        },
        {
          label: this.labels.HubLabelIsOwner,
          field: "isOwner",
          sort: "",
          width: 50
        },
        {
          label: "",
          field: "",
          sort: "disabled",
        },
      ],
      rows: rowRolesData
    };
    const confModalData = this.state.isDelete ? {
      name: this.state.displayName,
      show: this.state.show,
      handleModal: this.cancelDeleteModal,
      okButton: this.delete,
      modalHeading: this.labels.HubLabelConfirmation,
      modalMessage: this.labels.HubLabelDeleteMessage,
    }
      : {
        name: this.state.displayName,
        show: this.state.show,
        handleModal: this.state.isResetPassword ? this.cancelResetPasswordModal : this.handleModal,
        okButton: () => this.state.isResetPassword ? this.resetPassword() : history.push("/security-users"),
        modalHeading: this.labels.HubLabelConfirmation,
        modalMessage: this.state.isResetPassword ? "Do you want to change the password for this user?" : this.labels.HubLabelConfirmCancelMessage,
      }

    return (
      <>
        {this.state.isLoadingToSave && <LoadingOverlay />}
            <Submenu {...props} />
            <div className="rightPanel-main-content">
              {this.state.show ? (
                <ConfirmModal {...confModalData} />
              ) : ""}
              {(this.state.isLoading || this.state.isLoadingForResetPassword) && <Spinner />}
              {this.state.loading && (<div className="row">{this.labels.HubLabelLoadingText}</div>)}

              {/* {this.state.breadcrumbs !== null ?
                  <div className="breadcrumbs"><Link className="breadcrumbs" to={{ pathname: `/security-roles/rolesdetails/` + this.state.roleID }}>{this.state.breadcrumbs}</Link> / <Link className="breadcrumbs" to={{ pathname: `security-roles` }}>{this.labels.HubLabelUsers}</Link></div>
                  :
                  <div className="breadcrumbs"><Link className="breadcrumbs" to={{ pathname: `/security-users` }}>{this.labels.HubLabelUsers}</Link></div>
                } */}
              <div className="table-panel">
                <TablePanelInfo
                  tableHeading={this.state.title}
                  iconSrc="/images/User.svg"
                />
                <div className="mb-1">{this.state.context}</div>
                <div className="table-panel-content">
                  {(this.state.activePage === 2 && this.state.showRoles === 0) ?
                    <div>
                        <button className={this.state.isUpdateRight ? "button-primary": "button-primary-disable" } disabled={this.state.isUpdateRight ? false : true} onClick={() => this.setState({ showRoles: 1 })}>
                          {this.labels.HubLabelManageRoles}
                        </button>
                    </div>
                    : <></>
                  }
                </div>

                {this.state.activePage === 0 && (
                  <div className="main-content">
                    {/* <div className="" > */}
                    {this.state.loading || (
                      <div className="card card-scroller">
                        <div className="p-45">
                          <form>
                            <div className="row" >
                              <div className="col-6"><Inputbox {...emailFld} /> </div>
                              <div className="col-3">
                                <SelectBox {...IdentityProviderTypeFld} />
                              </div>
                              {this.state.securityProviderTypeId === 0 &&
                                <div className="col-3">
                                  <SelectBox {...UserTypeFld} />
                                </div>
                              }
                            </div>
                            {this.state.userType === 0 ?
                              <>
                                {userId !== '0' ?
                                  <div className="row">
                                    <div className="col-3"><Inputbox {...firstNameFld} /></div>
                                    <div className="col-3" ><Inputbox {...lastNameFld} /></div>
                                    <div className="col-6"><Inputbox {...displayNameFld} /></div>
                                  </div>
                                  :
                                  <div className="row">
                                    <div className="col-6"><Inputbox {...firstNameFld} /></div>
                                    <div className="col-6"><Inputbox {...lastNameFld} /></div>
                                  </div>
                                }
                              </>
                              :
                              <div className="row">
                                <div className="col-6"><Inputbox {...XApiKeyFld} /></div>
                                <div className="col-6"></div>
                              </div>
                            }
                            <div className="row">
                              {userId !== '0' ?
                                <div className="col-6"><Inputbox {...locationFld} /></div>
                                :
                                <div className="col-6"><Inputbox {...displayNameFld} /></div>
                              }
                              <div className="col-6"><Inputbox {...phoneNumberFld} /></div>
                            </div>
                            <>
                              {userId !== 0 ?
                                <div className="row">
                                  <div className="col-6">
                                    {this.state.id !== 0 && this.state.securityProviderTypeId === 0 ?
                                      <div className="input-controls">
                                        <label>Password<span className="required"> *</span></label>
                                        <input className="input-box border" type="password" name="password" value={this.state.password} onChange={(e) => { this.change(e); }} disabled={this.state.id !== 0} />
                                      </div>
                                      :
                                      <>
                                        {this.state.id === 0 ?
                                          <div className="input-controls">
                                            <label>Password<span className="required"> *</span></label>
                                            <input className="input-box border" type="password" name="password" value={this.state.password} onChange={(e) => { this.change(e); }} disabled={this.state.id !== 0} />
                                          </div>
                                          :
                                          <div className="col-6"></div>
                                        }
                                      </>
                                    }
                                  </div>
                                  <>
                                    {this.state.id !== 0 && this.state.securityProviderTypeId === 0 ?
                                      <div className="col-3 button-center"><button className="button-outline mr-2" type="button" onClick={() => this.isResetPassword()}>{this.labels.HubLabelResetPassword}</button></div>
                                      :
                                      <div className="col-3"></div>
                                    }
                                    {this.state.id !== 0 ?
                                      <div className="col-3"><Inputbox {...deletedAtFld} /></div>
                                      :
                                      <div className="col-3"></div>
                                    }
                                  </>
                                </div>
                                :
                                <div className="col-6"></div>
                              }
                            </>
                          </form>
                        </div>
                      </div>)}
                    {/* </div> */}
                  </div>
                )}
                {this.state.activePage === 1 && (
                  <div className="main-content">
                    {/* <div className=""> */}
                    {this.state.loading || (
                      <div className="card card-scroller">
                        <div className="p-45">
                          <form>
                            <div className="row" style={{ display: "block" }}>
                              <div className="label"><label>{this.labels.HubTabUserPreferecnces}</label></div>
                              <textarea
                                name="userPreferences"
                                className="textAreaUserDetails"
                                onChange={this.change}
                                readOnly={this.state.editMode === false}
                                value={userPreferences}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                    {/* </div> */}
                  </div>
                )}

                {this.state.activePage === 2 && (
                  <div className="main-content">
                    {this.state.loading || (
                      <div className="row">
                        {/* <div className="col-12"> */}
                        {this.state.showRoles === 1 &&
                          <MDBDataTable className="standardTable tableTabButtons tableRoles" searchLabel={this.labels.HubLabelMySearchLabel} displayEntries={false} entries={50} hover data={dataRoles} />
                        }
                        {this.state.showRoles === 0 &&
                          <MDBDataTable className="standardTable tableTabButtons tableRoles" searchLabel={this.labels.HubLabelMySearchLabel} displayEntries={false} entries={50} hover data={data} />
                        }
                        {/* </div> */}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="main-button-content">
                <div className="buttons-group">
                  <div className="col-8" style={{ display: 'flex' }}>
                    {this.state.showRoles === 0 &&
                      <button className="button-tertiary mr-2" onClick={() => this.cancel()} >{this.labels.HubButtonCancel}</button>
                    }
                    {this.state.showRoles === 1 &&
                      <button className="button-tertiary mr-2" onClick={() => this.hideAvailableRoles()} >{this.labels.HubButtonCancel}</button>
                    }
                    {(this.state.id !== 0 && this.state.isDeleteRight === false) &&
                      <button className="button-tertiary-disable mr-2" disabled={true} onClick={() => this.isDelete()}>{this.labels.HubButtonDelete}</button>
                    }
                    {(this.state.id !== 0 && this.state.isDeleteRight === true) &&
                      <button className="button-tertiary mr-2" onClick={() => this.isDelete()}>{this.labels.HubButtonDelete}</button>
                    }
                  </div>

                  {this.state.isUpdateRight === false &&
                    <div className=" space-between">
                      {this.state.activePage === 1 &&
                        <button className="button-tertiary ml-2" disabled={true}>{this.labels.HubLabelFormat}</button>
                      }
                      <button className="button-primary-disable ml-2" disabled={true}>{this.labels.HubButtonSave}</button>
                    </div>
                  }
                  {this.state.isUpdateRight === true &&
                    <div className=" space-between">
                      {this.state.activePage === 1 &&
                        <button className="button-tertiary ml-2" onClick={() => this.formatJson()}>{this.labels.HubLabelFormat}</button>
                      }
                      <button className="button-primary ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</button>
                    </div>
                  }
                </div>
              </div>
            </div>
    
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProvidersDetails,
    mainPageTitle: state.mainPageTitle,
  };
};
export default connect(mapStateToProps)(EditUser);