import { Component } from "react";
import "../../../styles/css/searchspace.scss";
import "../../../App.css";
import { appContext } from "../../../AppContext";
import IbssButton from "../../../Components/Buttons/Button/IbssButton";
import IbssSwitchLabel, { LabelPlacementType } from "../../../Components/Inputs/Switch/IbssSwitchLabel";
import { getUserPrefrenceJosn } from "../../../Common/UserPreferenceJSON";
import { IUserPreferences } from "../../../Providers.Api/UserPreferences/UserPreferenceRepository";

class State {
    public DataPrivacy = getUserPrefrenceJosn();
    public isLoading = false;
    public dataPrivacy = false;
};

export default class OnBoardingColleagueFinding extends Component<IOnBoardingColleagueFinding, State>
{

    private labels = appContext().labels;
    private userPreferences = appContext().userPreferencesService;
    private incrementActiveStepProp;
    private local = appContext().localStorageProvider;

    constructor(props: IOnBoardingColleagueFinding) {
        super(props);

        this.incrementActiveStepProp = props.incrementActiveStep;
        this.state = new State();
    }

    public submitDataPrivacy = async () => {
        const payload = {
            ...this.local.getUserPreferences(),
            PrivacyPrefs:
            {
                ShareLocation: this.state.dataPrivacy === false ? 0 : 1,
            },

        };
        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.incrementActiveStepProp();
            }
        }
        catch { }
    }



    render(): JSX.Element {
        return (<>
            <div className="px-50">
                <h2 className="ibss-modal-title mb-10"> {this.labels.HubLabelColleagueFinding}</h2>
                <div className="my-28">
                    <p className="ibss-modal-text-md"> {this.labels.HubLabelColleagueFindingParagraph}</p>
                    <div className="fullWidthIbssSwitchLabel">
                        <IbssSwitchLabel
                            label={this.labels.HubLabelBookingData}
                            name="ShareLocation"
                            labelPlacement={LabelPlacementType.start}
                            checked={this.props.dataPrivacy}
                            onChange={(e) => {this.props.change(e); this.setState({ dataPrivacy: e.target.checked })}}
                        />
                    </div>
                </div>
                <IbssButton variant="contained"
                    color="primary"
                    onClick={() => {
                        this.submitDataPrivacy();
                    }}> {this.labels.HubButtonNext} </IbssButton>
            </div>
        </>);
    }
}



export interface IOnBoardingColleagueFinding {
    incrementActiveStep?: any;
    dataPrivacy: boolean;
    change: (event: React.ChangeEvent<HTMLInputElement>) => void;
    userPrefrences: IUserPreferences;
}

