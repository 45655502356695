import React, { Component } from 'react'
import { getUserPrefrenceJosn } from "../../../../Common/UserPreferenceJSON";
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssUserPrefNotificationUI from './IbssUserPrefNotificationUI';
import { appContext } from '../../../../AppContext';

class BookingNotificationState {
    public BookingNotification = getUserPrefrenceJosn();
    public bookingsRoamer = false;
    public bookingsPush = false;
    public bookingsEmail = false;
};

class BookingNotification extends Component<any, BookingNotificationState> {
    private labels = appContext().labels;
    private alert = appContext().alert;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider;


    constructor(props: any) {
        super(props)
        this.state = new BookingNotificationState();
    };

    componentDidMount() {
        const response = this.local.getUserPreferences();
        this.setState({ BookingNotification: response });
        this.makeData(response);
    };

    public makeData = (response: any) => {
        //convert number to binary
        let bookings = Number(response?.NotificationPrefs?.Bookings && response?.NotificationPrefs?.Bookings);
        this.setState({
            bookingsRoamer: bookings !== 0 ? true : false,
            bookingsEmail: (bookings === 3 || bookings === 7) ? true : false,
            bookingsPush: (bookings === 5 || bookings === 7) ? true : false,

        })
    };

    public change = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "bookingsRoamer") {
            await this.setState({ bookingsRoamer: e.target.checked });
        } else if (e.target.name === "bookingsEmail") {
            await this.setState({ bookingsEmail: e.target.checked });
        } else if (e.target.name === "bookingsPush") {
            await this.setState({ bookingsPush: e.target.checked });
        }

        if (this.state.bookingsEmail || this.state.bookingsPush) {
            this.setState({
                bookingsRoamer: true
            });
        }
    };

    public getInteger = (roamer: boolean, push: boolean, email: boolean) => {
        let integerValue = 0
        if (push && !email) {
            integerValue = 5;
        }
        if (!push && email) {
            integerValue = 3;
        }
        if (push && email && roamer) {
            integerValue = 7;
        }
        if (roamer && !push && !email) {
            integerValue = 1;
        }
        return integerValue
    };

    public submit = () => {
        this.setState({
            BookingNotification: {
                ...this.state.BookingNotification,
                NotificationPrefs: {
                    ...this.state.BookingNotification.NotificationPrefs,
                    Bookings: this.getInteger(this.state.bookingsRoamer, this.state.bookingsPush, this.state.bookingsEmail),
                },
            }
        }, async () => {
            const payload = this.state.BookingNotification;
            try {
                await this.userPreferences.update(payload);
                this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelDataHasBeenSavedSuccessfully);
            }
            catch { }
        });
    };

    render() {
        const labelsList = [
            {
                label: this.labels.HubLabelBellIconInRoamer,
                checked: this.state.bookingsRoamer,
                onChange: (e: any) => this.change(e),
                name: "bookingsRoamer"
            },
            {
                label: this.labels.HubLabelPushNotifications,
                checked: this.state.bookingsPush,
                onChange: (e: any) => this.change(e),
                name: "bookingsPush",
            },
            {
                label: this.labels.HubLabelEmailNotifications,
                checked: this.state.bookingsEmail,
                onChange: (e: any) => this.change(e),
                name: "bookingsEmail"
            },

        ];
        return (
            <>

                <IbssUserPrefNotificationUI labelsList={labelsList} />

                <div className='btn-right'>
                    <IbssButton variant='contained' onClick={() => { this.submit() }}>
                        {this.labels.HubButtonSave}
                    </IbssButton>
                </div>

            </>
        )
    }
}

export default BookingNotification;
