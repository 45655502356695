import { Component } from 'react'
import { Card, Grid, Typography } from '@mui/material';
import IbssSwitchLabel, { LabelPlacementType } from '../../../../Components/Inputs/Switch/IbssSwitchLabel';
import { getUserPrefrenceJosn } from "../../../../Common/UserPreferenceJSON";
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { appContext } from '../../../../AppContext';

class CalendarIntegrationState {
    public CalendarIntegration = getUserPrefrenceJosn();
    public isLoading = false;
    public syncCalendar = false;
};

class CalendarIntegration extends Component<any, CalendarIntegrationState> {
    private labels = appContext().labels;
    private alert = appContext().alert;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider;


    constructor(props: any) {
        super(props)
        this.state = new CalendarIntegrationState();
    };

    componentDidMount() {
        this.setState({ isLoading: true });
        const response = this.local.getUserPreferences();
        this.setState({ isLoading: false, syncCalendar: response?.CalendarPrefs && response?.CalendarPrefs?.SyncCalendar === 0 ? false : true, CalendarIntegration: response });
    };

    public change = (e: any) => {
        this.setState({
            syncCalendar: e.target.checked
        });
    };

    public submitCalendarPrefs = async () => {
        const payload = {
            ...this.state.CalendarIntegration,
            CalendarPrefs:
            {
                SyncCalendar: this.state.syncCalendar === false ? 0 : 1,
            },

        };
        try
        {
            await this.userPreferences.update(payload);
            this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelDataHasBeenSavedSuccessfully);
        }
        catch { }
    };

    render() {
        return (
            <>
                <Grid container>
                    <Card className='card-userPref'>
                        <Grid
                            container
                            gap={1}
                        >
                            <Typography className='card-userPref-title' variant="h6" gutterBottom>{this.labels.HubTitleCalendar}</Typography>

                            <Typography className='card-userPref-text' variant="body2" marginBottom={"32px"} gutterBottom>{this.labels.HubSubTitleCalendar}</Typography>

                        </Grid>

                        <Grid
                            container
                            item
                            display={"flex"}
                            alignItems="start"
                            direction="column"
                            gap={2}
                        >
                            <IbssSwitchLabel
                                labelClasses={{ "style": { margin: "16px 0px" } }}
                                label={this.labels.HubLabelSynchronise365}
                                labelPlacement={LabelPlacementType.start}
                                checked={this.state.syncCalendar}
                                onChange={this.change}
                                name="SyncCalendar"
                            />
                        </Grid>

                    </Card>
                </Grid>
                <div className='btn-right'>
                    <IbssButton variant='contained' onClick={() => { this.submitCalendarPrefs() }}>
                        {this.labels.HubButtonSave}
                    </IbssButton>
                </div>
            </>
        )
    }
};

export default CalendarIntegration;
