import { Box, Card, Grid, Typography } from "@mui/material";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import { appContext } from "../../../../AppContext";
import UserPicker, { IUser } from "../../../../Components/Inputs/UserPicker/UserPicker";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import { GridColDef, GridInputRowSelectionModel, GridRowSelectionModel } from "@mui/x-data-grid";
import IbssDataGrid, { IIbssGridColDef } from "../../../../Components/Data/DataGrid/IbssDataGrid";
import Helper from "../../../../Common/Helper";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import IbssAvatar from "../../../../Components/Miscellaneous/Avatar/IbssAvatar";
import { Fragment } from "react";
import { MessageSeverity } from "../../../../Components/Layout/Messages/Messages";
import ConfirmModal from "../../../../Components/Dialogs/ConfirmDialog/ConfirmModal";
import IbssIconButton from "../../../../Components/Buttons/IconButton/IbssIconButton";
import CloseIcon from '@mui/icons-material/Close';


export default class CreateManyDelegates extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get messages() { return appContext().messages; }
    private get local() { return appContext().localStorageProvider; }
    private searchDelegatesTimeout: NodeJS.Timeout | null = null;

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            delegatorSearchText: "",
            delegator: null,
            delegatesSearchText: "",
            delegates: [],
            delegatesError: "",
            selectedDelegates: [],
            showDelegatorDeselectionDialog: false,
            disableSave: true
        };
    }

    private get isFlex(): boolean
    {
        return new URL(window.location.href).pathname.includes('flex');
    }

    private get isAdmin(): boolean
    {
        return !this.isFlex;
    }

    public async componentDidMount(): Promise<void>
    {
        this.pageTitle = this.labels.funcDelegatePermissions_S;
        if (this.isFlex)
        {
            await this.setDelegatorToCurrentUser();
        }
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void> {
        const prevSelectedDelegateIds = prevState.selectedDelegates.map(delegate => delegate.id).sort().join(",");
        const currSelectedDelegateIds = this.state.selectedDelegates.map(delegate => delegate.id).sort().join(",");
        if (prevSelectedDelegateIds === currSelectedDelegateIds) 
        {
            return;
        }

        const hasErrors = this.selectedDelegatesWithErrors.some(i => i.error);
        const noSelectedDelegates = prevState.selectedDelegates === this.state.selectedDelegates && this.state.selectedDelegates.length === 0;
        const shouldDisableSave = hasErrors || noSelectedDelegates;
    
        this.setState({ disableSave: shouldDisableSave });
    }

    private async setDelegatorToCurrentUser(): Promise<void>
    {
        const currentUser = this.local.getUserDetails();
        const nameParts = currentUser.displayName.split(" ").filter(i => i != " ");

        const delegator = {
            label: currentUser.displayName,
            email: currentUser.email,
            userName: currentUser.email,
            firstName: nameParts[0] ?? "",
            lastName: nameParts[1] ?? "",
            displayName: currentUser.displayName,
            company: "",
        };
        await this.setStateAsync({ delegator: delegator });
        await this.loadSelectedDelegates();
    }

    private async loadSelectedDelegates(): Promise<boolean>
    {
        if (!this.state.delegator)
        {
            this.setState({ selectedDelegates: [] });
            return true;
        }

        try
        {
            const delegateData = await this.apiClient.delegates.getManyByDelegator(this.state.delegator.email);
            const delegates = delegateData.value.map(i => new Delegate({
                id: i.DelegateEmail,
                name: (i.DelegateDisplayName || i.DelegateEmail),
                email: i.DelegateEmail,
            }));

            this.setState({ selectedDelegates: delegates });
            return true;
        }
        catch
        {
            this.messages.add(MessageSeverity.Error, this.labels.funcFailedToLoadDelegates_L);
            return false;
        }
    }

    private get selectedDelegateIds(): GridInputRowSelectionModel
    {
        return this.state.selectedDelegates.map(i => i.id);
    }

    private get selectedDelegatesWithErrors(): Delegate[]
    {
        const withErrors = this.state.selectedDelegates.map(i =>
        ({
            id: i.id,
            email: i.email,
            name: i.name,
            error: (i.id.toLocaleLowerCase() === this.state.delegator?.email?.toLocaleLowerCase() ? this.labels.funcDelegatorCannotDelegateSelf_L : ""),
        }));
        return withErrors;
    }

    private deselectDelegator(): void
    {
        this.setState({ delegator: null, selectedDelegates: [] });
    }

    private hideMessages(): void
    {
        this.setState({ delegatesError: "" });
    }

    private async delegatorChanged(user: IUser | null): Promise<void>
    {
        await this.setStateAsync({ delegator: user });
        this.hideMessages();
        const success = await this.loadSelectedDelegates();

        if (!success)
        {
            this.deselectDelegator();
        }
    }

    private delegatorDeselected(): void
    {
        this.setState({ showDelegatorDeselectionDialog: true });
    }

    private delegatorDeselectionConfirmed(): void
    {
        this.deselectDelegator();
        this.setState({ showDelegatorDeselectionDialog: false, delegatorSearchText: "" });
    }

    private async delegatesSearchTextChanged(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): Promise<void>
    {
        clearTimeout(this.searchDelegatesTimeout ?? undefined);
        const searchText = event.target.value;
        await this.setStateAsync({ delegatesSearchText: searchText });
        this.hideMessages();

        if (searchText.length < 3)
        {
            this.setState({ delegates: [] });
            return;
        }

        this.searchDelegatesTimeout = setTimeout(() => this.searchDelegates(), 500);
    }

    private async searchDelegates(): Promise<void>
    {
        try
        {
            const { delegatesSearchText } = this.state;
            const users = await this.apiClient.users.getUsers(delegatesSearchText);
            const delegates = users.map(i => new Delegate({
                id: i.email,
                name: (i.displayName || i.email),
                email: i.email,
            }));
            this.setState({ delegates: delegates });
        }
        catch
        {
            this.messages.add(MessageSeverity.Error, this.labels.funcFailedToLoadDelegates_L);
        }
    }

    private delegateSelectionChanged(selection: GridRowSelectionModel): void
    {
        if (this.state.delegator == null)
        {
            this.hideMessages();
            this.setState({ delegatesError: this.labels.funcMustSelectDelegator_L });
            return;
        }

        // add delegates
        const currentlySelectedIds = this.state.selectedDelegates.map(i => i.id);
        const idsToAdd = selection.filter(i => !currentlySelectedIds.some(j => j === i));
        const delegatesToAdd = this.state.delegates.filter(i => idsToAdd.some(j => j === i.id));

        // remove delegates
        const idsToRemove = this.state.delegates.map(i => i.id).filter(i => currentlySelectedIds.some(j => j === i) && !selection.some(j => j === i));
        const delegatesNotRemoved = this.state.selectedDelegates.filter(i => !idsToRemove.some(j => j === i.id));

        // join
        const selectedDelegates = [...delegatesNotRemoved, ...delegatesToAdd];
        this.setState({ selectedDelegates: selectedDelegates });
    }

    private delegateDeselected(id: string): void
    {
        this.setState({ selectedDelegates: this.state.selectedDelegates.filter(i => i.id !== id) });
    }

    private async saveClicked(): Promise<void>
    {
        if (!this.state.delegator)
        {
            return; // no need for error because in practice this can't happen
        }

        this.hideMessages();
        try
        {
            const delegates = await this.apiClient.delegates.getManyByDelegator(this.state.delegator.email);
            const delegatesEmailsToCreate = this.state.selectedDelegates.filter(i => !delegates.value.some(j => j.DelegateEmail === i.id)).map(i => i.email);
            if (delegatesEmailsToCreate.length > 0)
            {
                await this.apiClient.delegates.createManyByDelegator(this.state.delegator.email, delegatesEmailsToCreate);
            }

            const delegateIdsToDelete = delegates.value.filter(i => !this.state.selectedDelegates.some(j => j.id === i.DelegateEmail)).map(i => i.Delegate_Id);
            if (delegateIdsToDelete.length > 0)
            {
                await this.apiClient.delegates.deleteMany(delegateIdsToDelete);
            }

            this.messages.add(MessageSeverity.Success, this.labels.funcDelegatesSaved_L);
            if (this.isAdmin)
            {
                this.deselectDelegator();
            }
        }
        catch
        {
            this.messages.add(MessageSeverity.Error, this.labels.funcFailedToSaveDelegates_L);
        }
    }

    private get delegateColumns(): IIbssGridColDef<Delegate>[]
    {
        const columns: GridColDef<Delegate>[] = [
            {
                headerName: "",
                field: "initials",
                valueGetter: i => i.row.name,
                renderCell: i => <IbssAvatar fullName={i.value} />,
                flex: 1,
            },
            {
                headerName: this.labels.funcDelegateName_S,
                field: Helper.nameOf<Delegate>("name"),
                flex: 5,
            },
            {
                headerName: this.labels.funcDelegateEmail_S,
                field: Helper.nameOf<Delegate>("email"),
                renderCell: i => i.value.toLocaleLowerCase(),
                flex: 5,
            }
        ];
        return columns;
    }

    public render(): JSX.Element
    {
        const state = this.state;
        const labels = this.labels;

        return (
            <>
                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content" style={{ height: "calc(100% - 40px)" }}>
                        <Grid container columnSpacing={{ sm: 2, md: 3 }}>
                            <Grid  item sm={12} md={4}>
                                <Card>
                                    <Box className="m-3">
                                        <Typography variant="h5">{labels.funcDelegateSummary_S}</Typography>
                                        {
                                            this.isAdmin &&
                                            <Typography variant="body1">{labels.funcAdminDelegateSummary_D}</Typography>
                                        }
                                        {
                                            this.isAdmin && !state.delegator &&
                                            <>
                                                <UserPicker
                                                    searchText={this.state.delegatorSearchText}
                                                    label={labels.funcSearchDelegator_S}
                                                    placeholder={labels.funcSearchDelegator_L}
                                                    onChange={async text => this.setState({ delegatorSearchText: text })}
                                                    onUserChange={user => this.delegatorChanged(user)}
                                                />
                                            </>
                                        }
                                        {
                                            this.isAdmin && state.delegator &&
                                            <>
                                                <hr />
                                                <Typography variant="body2">{labels.funcDelegator_S}</Typography>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Box>
                                                        {this.renderUser(state.delegator.displayName, state.delegator.email)}
                                                    </Box>
                                                    <Box marginLeft="auto">
                                                        <IbssButton
                                                            color="secondary"
                                                            variant="contained"
                                                            className="mr-2"
                                                            onClick={() => this.delegatorDeselected()}>
                                                            {labels.funcSwitch_S}
                                                        </IbssButton>
                                                    </Box>
                                                </Box>
                                            </>
                                        }
                                        {
                                            this.isFlex && state.delegator &&
                                            <>
                                                <Typography variant="body2" className="ui-text-light">{labels.funcFlexDelegateSummary_D}</Typography>
                                                <hr />
                                                <Typography>{this.labels.funcCurrentAccount_S}</Typography>
                                                <Box className='d-flex justify-content-between my-2'>
                                                    {this.renderUser(state.delegator.displayName ?? "", state.delegator.email ?? "")}
                                                </Box>
                                            </>
                                        }
                                        {
                                            state.delegator &&
                                            <>
                                                <hr />
                                                <Typography variant="h6">{labels.funcDelegates_S}</Typography>
                                                {state.selectedDelegates.length == 0 && <Typography variant="body1" style={{ textAlign: "center" }}>{labels.funcNoDelegates_S}</Typography>}
                                            </>
                                        }
                                        </Box>
                                        <Box px={3} sx={{maxHeight: 'calc(100vh - 457px)',overflow: 'auto'}}>
                                        {
                                            this.selectedDelegatesWithErrors.map(i =>
                                                <Fragment key={i.id}>
                                                    {i.error && <div className="text-danger">{i.error}</div>}
                                                    <Box display="flex" justifyContent="space-between">
                                                        <Box>
                                                            {this.renderUser(i.name, i.email)}
                                                        </Box>
                                                        <Box marginLeft="auto">
                                                            <IbssIconButton
                                                                aria-label="close"
                                                                size="small"
                                                                className="mr-2"
                                                                onClick={() => this.delegateDeselected(i.id)}>
                                                                <CloseIcon />
                                                            </IbssIconButton>
                                                        </Box>
                                                    </Box>
                                                    <hr />
                                                </Fragment>
                                            )
                                        }
                                        </Box>
                                        {
                                            state.delegator &&
                                            <Box display="flex" m={2}>
                                                <IbssButton
                                                    disabled={this.state.disableSave}
                                                    size="large"
                                                    color="primary"
                                                    variant="contained"
                                                    className="btn-block"
                                                    onClick={() => this.saveClicked()}
                                                >
                                                    {labels.HubButtonSave}
                                                </IbssButton>
                                            </Box>
                                        }
                                </Card>
                            </Grid>
                            <Grid  item sm={12} md={8} ml={0}>
                                <Card>
                                    <Box className="m-3">
                                        <Typography variant="h5">{labels.funcDelegateYourAccountAccess_S}</Typography>
                                        <Typography variant="body2" className="ui-text-light">{labels.funcDelegateYourAccountAccess_D}</Typography>
                                        <Box my={3}>
                                            <IbssTextField
                                                label={labels.funcSearchDelegates_S}
                                                placeholder={labels.funcSearchDelegates_L}
                                                variant="outlined"
                                                fullWidth
                                                value={state.delegatesSearchText}
                                                onChange={i => this.delegatesSearchTextChanged(i)}
                                            />
                                        </Box>
                                        {state.delegatesError && <div className="text-danger">{state.delegatesError}</div>}
                                        <IbssDataGrid
                                            height="calc(100vh - 310px)"
                                            rows={state.delegates}
                                            columns={this.delegateColumns}
                                            checkboxSelection={true}
                                            disableRowSelectionOnClick={true}
                                            rowSelectionModel={this.selectedDelegateIds}
                                            onRowSelectionModelChange={e => this.delegateSelectionChanged(e)}
                                        />
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <ConfirmModal
                    show={this.state.showDelegatorDeselectionDialog}
                    modalHeading={this.labels.funcSwitchDelegator_S}
                    modalMessage={this.labels.funcSwitchDelegator_D}
                    handleModal={() => this.setState({ showDelegatorDeselectionDialog: false })}
                    okButton={() => this.delegatorDeselectionConfirmed()}
                />
            </>
        );
    }

    private renderUser(fullName: string, email: string): JSX.Element
    {
        return (
            <Box display="flex" alignItems="center">
                <IbssAvatar fullName={fullName} />
                <Box ml={2}>
                    <Typography variant="subtitle1">{fullName}</Typography>
                    <Typography variant="subtitle2">{email.toLocaleLowerCase()}</Typography>
                </Box>
            </Box>
        );
    }
}

export interface IProps
{
}

export interface IState
{
    delegatorSearchText: string;
    delegator: IUser | null;
    delegatesSearchText: string;
    delegates: Delegate[];
    delegatesError: string;
    selectedDelegates: Delegate[];
    showDelegatorDeselectionDialog: boolean;
    disableSave: boolean;
}

export class Delegate
{
    public id = "";
    public name = "";
    public email = "";
    public error = "";

    constructor(value: Partial<Delegate>)
    {
        Object.assign(this, value);
    }
}
