import { Component } from 'react';
import { Card, Grid, Typography, TextField } from '@mui/material';
import { getUserPrefrenceJosn } from "../../../../Common/UserPreferenceJSON";
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { appContext } from '../../../../AppContext';
import IbssTimePicker from '../../../../Components/Inputs/TimePicker/IbssTimePicker';
import { getWkngDaysBySelectedBuilding } from '../../../../Common/Helper';
import { IUserPreferences } from '../../../../Providers.Api/UserPreferences/UserPreferenceRepository';
import { DateHelper } from '../../../../Common/DateHelper';

class WorkingDayState {
    public startTime: Date | null = DateHelper.fromIsoToJsDate(DateHelper.now().toString());
    public endTime: Date | null = DateHelper.fromIsoToJsDate(DateHelper.now().toString());
    public isLoading = false;
    public BuildingSpecificNotification = getUserPrefrenceJosn();
};

class WorkingDay extends Component<any, WorkingDayState> {
    private labels = appContext().labels;
    private alert = appContext().alert;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider;

    constructor(props: any) {
        super(props)
        this.state = new WorkingDayState();
    };

    componentDidMount() {
        this.setState({ isLoading: true });
        const response = this.local.getUserPreferences();
        this.setState({ isLoading: false, BuildingSpecificNotification: response });


        const getStrtDate: any = getWkngDaysBySelectedBuilding(response?.SearchPrefs?.DefaultBuilding);
        let Occ_Wkng_Hrs_Stt = getStrtDate?.Occ_Wkng_Hrs_Stt.split(":");
        let Hrs_Stt = Occ_Wkng_Hrs_Stt[0] + ":" + Occ_Wkng_Hrs_Stt[1];
        let Occ_Wkng_Hrs_Stp = getStrtDate?.Occ_Wkng_Hrs_Stp.split(":");
        let Hrs_Stp = Occ_Wkng_Hrs_Stp[0] + ":" + Occ_Wkng_Hrs_Stp[1];

        // set start time
        const myStart = DateHelper.fromIsoToJsDate(DateHelper.now().toString());

        const myStartDateTime = myStart.toISOString();
        const myStartDate = myStartDateTime.split('T')[0];

        let startTime = ""
        if (response?.WorkingHoursPrefs?.UserStartTime != "date" && response?.WorkingHoursPrefs?.UserStartTime != "" && response?.WorkingHoursPrefs?.UserStartTime != null && response?.WorkingHoursPrefs?.UserStartTime != undefined) {
            startTime = response.WorkingHoursPrefs.UserStartTime;
        }
        else if (response?.WorkingHoursPrefs?.UserStartTime == "" || response?.WorkingHoursPrefs?.UserStartTime == null || response?.WorkingHoursPrefs?.UserStartTime == undefined) {
            startTime = Hrs_Stt;
        }
        const myStartTime = DateHelper.fromIsoToJsDate(myStartDate + 'T' + startTime);

        // set end time
        const myEnd = DateHelper.fromIsoToJsDate(DateHelper.now().toString());
        const myEndDateTime = myEnd.toISOString();
        const myEndDate = myEndDateTime.split('T')[0];

        let endTime = "";
        if (response?.WorkingHoursPrefs?.UserEndTime != "date" && response?.WorkingHoursPrefs?.UserEndTime != "" && response?.WorkingHoursPrefs?.UserStartTime != null && response?.WorkingHoursPrefs?.UserStartTime != undefined) {
            endTime = response.WorkingHoursPrefs.UserEndTime;
        }
        else if (response?.WorkingHoursPrefs?.UserEndTime == "" || response?.WorkingHoursPrefs?.UserEndTime == null || response?.WorkingHoursPrefs?.UserEndTime == undefined) {
            endTime = Hrs_Stp;
        }
        const myEndTime = DateHelper.fromIsoToJsDate(myEndDate + 'T' + endTime);

        this.setState({
            startTime: myStartTime,
            endTime: myEndTime,
        });

    };

    public submit = async () => {
        this.setState({
            isLoading: true,
        });

        const startDate = new Date(this.state.startTime as Date);
        const endDate = new Date(this.state.endTime as Date);

        const startTime = `${(startDate.getHours() < 10 ? '0' : '') + startDate.getHours()}:${(startDate.getMinutes() < 10 ? '0' : '') + startDate.getMinutes()}`;
        const endTime = `${(endDate.getHours() < 10 ? '0' : '') + endDate.getHours()}:${(endDate.getMinutes() < 10 ? '0' : '') + endDate.getMinutes()}`

        const payload: IUserPreferences =
        {
            ...this.state.BuildingSpecificNotification,
            WorkingHoursPrefs:
            {
                UserStartTime: startTime,
                UserEndTime: endTime,
            },
        };

        try {
            await this.userPreferences.update(payload);
            this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelDataHasBeenSavedSuccessfully);
        }
        catch { }
        this.setState({ isLoading: false })
    };


    render() {
        return (
            <>
                <Grid container>
                    <Card className='card-userPref'>
                        <Grid
                            container
                            gap={1}
                        >
                            <Typography className='card-userPref-title' variant="h6" gutterBottom>{this.labels.HubTitleWorkingDay}</Typography>

                            <Typography className='card-userPref-text mb-32' variant="body2" marginBottom={"32px"}>{this.labels.HubSubTitleWorkingDay}</Typography>

                        </Grid>
                        <div className='space-between al-c'>
                            <div className="date-label">{this.labels.HubLabelStartTime}</div>
                            <div>
                                <IbssTimePicker
                                    className='ibss-timepicker'
                                    value={this.state.startTime}
                                    onChange={(e) => this.setState({ startTime: e })}
                                    ampm
                                    minutesStep={15}
                                    renderInput={(params) => {
                                        const { sx, ...paramsMinusSx } = params
                                        return <TextField {...paramsMinusSx} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        />
                                    }}
                                />
                            </div>
                        </div>
                        <div className='space-between al-c'>
                            <div className="date-label">{this.labels.HubLabelEndTime}</div>
                            <div>
                                <IbssTimePicker
                                    className='ibss-timepicker'
                                    value={this.state.endTime}
                                    onChange={(e) => this.setState({ endTime: e })}
                                    ampm
                                    minutesStep={15}
                                    renderInput={(params) => {
                                        const { sx, ...paramsMinusSx } = params
                                        return <TextField {...paramsMinusSx} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        />
                                    }}
                                />
                            </div>
                        </div>
                    </Card>
                </Grid>
                <div className='btn-right'>
                    <IbssButton variant='contained' onClick={() => this.submit()}>
                        {this.labels.HubButtonSave}
                    </IbssButton>
                </div>
            </>
        )
    }
};

export default WorkingDay;