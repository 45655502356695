import React, { Component } from 'react'
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { Box, Paper, SelectChangeEvent } from '@mui/material';
import { RouteComponentProps, RouterProps, generatePath } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from '@mui/icons-material/Add';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { DateTime, Duration } from 'luxon';
import { appContext } from '../../../../AppContext';
import IbssActionButton, { IActionButton } from '../../../../Components/Buttons/ActionButton/IbssActionButton';
import IbssDataGrid from '../../../../Components/Data/DataGrid/IbssDataGrid';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import apis from '../../../../Providers.Api/apis';
import { IUserList } from '../../../../Providers.Api/Models';
import { IDispatch, IPropsFromState } from '../../../../redux/Interfaces';
import { dateConverter, getBuildingTimeZoneByNodeId } from '../../../../Common/Helper';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import IbssButtonRedo from '../../../../Components/Buttons/Button/IbssButton';
import IbssPageHeader, { StartOrEnd } from '../../../../Components/Forms/DateRange/IbssPageHeader';
import IbssFilter from '../../../../Components/Forms/Filter/IbssFilter';
import IbssAutocomplete from '../../../../Components/Inputs/AutoComplete/IbssAutocomplete';
import Spinner from '../../../../Components/Navigation/LoadingSpinner/Spinner';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import { DateHelper } from '../../../../Common/DateHelper';
import { Task, ITasksFilter } from '../../../../Providers.Api/Tasks/TaskRepository';
import BookingStatus from '../../../../Components/Miscellaneous/BookingStatus/BookingStatus';
import TimeZoneOffset from '../../../../Components/Miscellaneous/TimeZoneOffset/TimeZoneOffset';
import TickIcon from '../../../../Components/Icons/TickIcon';
import { Space } from '../../../../Providers.Api/Spaces/SpaceRepository';
import UserPicker, * as UserPickerModule from '../../../../Components/Inputs/UserPicker/UserPicker';

export default class ListTasks extends Component<IProps, IState>
{
    private isFlex = false;
    private get alert() { return appContext().alert; }
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get session() { return appContext().sessionStorageProvider; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }
    private get apiCache() { return appContext().apiCache; }

    private buildingTimeZone: string;
    private modalConstants: ITaskModal =
        {
            messageModal: "messageModal",
            userSelectionModal: "userSelectionModal",
            filterModal: "filterModal",
            priorityModal: "priorityModal"
        }

    private readonly actionType =
        {
            assign: "assign",
            setInProgress: "set-in-progress",
            cancel: "cancel",
            unassign: "unassign",
            reAllocate: "re-allocate",
            changePriority: "change-priority",
            resolve: "resolve", 
            
            
        } 
    constructor(props: IProps)
    {
        super(props);
        this.buildingTimeZone = getBuildingTimeZoneByNodeId(this.session.getBuildingId())

        this.state =
        {
            loading: false,
            taskList: [],
            todayTaskList: [],
            canExport: false,
            isCreateRight: false,
            isUpdateRight: false,
            isAssignRight: false,
            isCancelRight: false,
            isChangePriorityRight: false,
            isReallocateRight: false,
            isSetInProgressRight: false,
            isUnAssignRight: false,
            buildingid: this.appState.buildingId,
            startDate: DateHelper.now().toJSDate(),
            endDate: DateHelper.now().toJSDate(),
            openUserModal: false,
            openMessageModal: false,
            message: "",
            selectedTaskIds: [],
            actionType: "",
            daysFilter: this.labels.HubLabelToday,
            dateDisabled: true,
            searchTerm: "",
            openFilterModal: false,
            statusValue: this.labels.HubLabelAssigned,
            disableReallocate: true,
            disableCancel: true,
            disableUnassign: true,
            disablePriority: true,
            disableInprogress: true,
            inProgressModal: false,
            userSearchText: "",
            selectedUser: { label: "", userName: "" },
            openPriorityModal: false,
            setPriority: 1,
            urlParameter: "",
            disableResolved: true, 
            isAtleastOneInProgress: true
        }
    }

    public async componentDidMount(): Promise<void>
    {
        const { history } = this.props;
        this.appState.autoMap(this, i => ({ buildingid: i.buildingId }));
        let buildingId = parseInt(this.props.match.params["buildingid"]);
        this.setState({ daysFilter: this.labels.HubLabelToday });
        const URL = this.getRoute();
        this.setState({ urlParameter: URL });
        this.isFlex = URL === '/flex-my-tasks';
        if(!Number.isNaN(buildingId))
        {
            if (buildingId !== undefined)
            {
                await this.appState.set({ buildingId: buildingId });
                if (buildingId !== 0)
                {
                    if (this.isFlex)
                    {
                        buildingId = 1;
                    }   
        
                    history.push("/operational-services-tasks/" + this.state.buildingid +  (this.props.match.params.filter ? "/" + this.props.match.params.filter : ""));
                } 
                else 
                {
                    this.alert.show("", this.labels.HubLabelSelectBuildingError);
                }
            }
        }
        else
        {
            this.alert.show("", this.labels.HubLabelSelectBuildingError);
        }
        
        const payload: IFilter = {
            buildingid: buildingId,
            startDate: this.fromDateToString(DateHelper.now().toJSDate(), this.labels.HubLabelStart),
            endDate: this.fromDateToString(DateHelper.now().toJSDate(), this.labels.HubLabelEnd),
        };
        this.setRights();
        this.loadTasks(payload);
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: IState, snapshot?: IState): void
    {
        if (prevState.buildingid !== this.state.buildingid)
        {
            const { history } = this.props;
            const buildingID = this.state.buildingid;
            const path = generatePath(this.props.match.path, { buildingid: buildingID });
            const payload: IFilter = {
                buildingid: this.state.buildingid,
                startDate: this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
                endDate: this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd),
            };
            history.replace(path);
            this.loadTasks(payload);
        }
    }

    private fromDateToString(date: Date, type: string): string
    {
        if (type === this.labels.HubLabelStart)
        {
            return DateTime.fromISO(dateConverter(date)).setZone(this.buildingTimeZone).startOf('day').toUTC().toISO();
        }
        else
        {
            return DateTime.fromISO(dateConverter(date)).setZone(this.buildingTimeZone).endOf('day').toUTC().toISO();
        }
    }

    private setRights(): void
    {
        const rights = this.local.getIbssRightList();
        this.setState({ canExport: this.local.hasRight("API.Tasks.Export") });

        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("Create") > -1)
        {
            this.setState({ isCreateRight: true });
        }
        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("Update") > -1)
        {
            this.setState({ isUpdateRight: true });
        }
        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("Assign") > -1)
        {
            this.setState({ isAssignRight: true });
        }
        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("Unassign") > -1)
        {
            this.setState({ isUnAssignRight: true });
        }
        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("Cancel") > -1)
        {
            this.setState({ isCancelRight: true });
        }
        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("Reallocate") > -1)
        {
            this.setState({ isReallocateRight: true });
        }
        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("SetInProgress") > -1)
        {
            this.setState({ isSetInProgressRight: true });
        }
        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("ChangePriority") > -1)
        {
            this.setState({ isChangePriorityRight: true });
        }
    }

    private getRoute(): string
    {
        const url = window.location.href;
        const path = new URL(url).pathname;
        return path;
    }

    private getSpacesByIds(spaces: Space[], delimitedSpaceIds: string ): Space[]
    {
        const taskSpaces = delimitedSpaceIds.split(";").map(id => spaces.find(space => space.Space_Id === id)).filter(space => space != null)as Space[];
        return taskSpaces;
    }

    private async loadTasks(payload: IFilter): Promise<void>
    {
        try
        {
            const URL = this.getRoute();
            this.setState({ loading: true });
            this.setState({ statusValue: payload.statusValue ?? "" });
            let email = this.local.getUserDetails().email;
            const tasksFilter: ITasksFilter =
            {
                status: payload.statusValue ?? undefined,
                createdFrom: DateTime.fromISO(payload.startDate).toUTC(),
                createdTo: DateTime.fromISO(payload.endDate).toUTC(),
            };
            let response;
            if (this.isFlex) 
            {
                if(this.state.statusValue != 'Active' && this.state.statusValue != 'Awaiting Re-allocation')
                {
                    tasksFilter.createdBy = email;
                }
                response = await this.apiClient.tasks.getTasks(Task, 1, tasksFilter);
            } 
            else if(URL === "/flex-my-tasks")
            {
                const tasksFilter: ITasksFilter =
                {
                    taskStatusNot: ["Resolved","Cancelled"],
                    taskEventStart: DateTime.fromISO(payload.startDate).toUTC(),
                    taskEventEnd: DateTime.fromISO(payload.endDate).toUTC(),
                    createdBy:  email
                };
                response = await this.apiClient.tasks.getTasks(Task, 1, tasksFilter);
            }
            else
            {
                response = await this.apiClient.tasks.getTasks(Task, payload.buildingid, tasksFilter);
            } 

            if (!response)
            {
                return;
            }

            const spaces = await this.apiCache.getSpacesByBuilding(this.state.buildingid);
            let tasks = response.map(item =>
            {
                const diff = DateTime.fromISO(`${(item.Task_Event_Time).slice(0, 19)}`).diff(DateTime.fromISO(`${(item.CreatedAt).slice(0, 19)}`)).toFormat("mm");
                const hours = Duration.fromMillis(+diff).as('hours');
                const duration = Duration.fromObject({ hours: hours });
                const minuteDiff = duration.minutes % 60;
                const timeDiff = duration.hours;
                const resolutionTime = `${timeDiff} hrs ${minuteDiff === 0 ? "" : minuteDiff} ${minuteDiff === 0 ? "" : "min"}`;
                const space = this.getSpacesByIds(spaces, item.Space_Id);
                const spaceNames = space.map(i => i.Space_Name);

                return ({
                    id: item.Task_Id,
                    Task_MinutesRemaining: resolutionTime,
                    spaceNames: spaceNames,
                    ...item                   
                }) as Task;
            });

            this.setState({ taskList: tasks, loading: false });
            if (this.state.daysFilter === this.labels.HubLabelToday)
            {
                this.setState({ todayTaskList: tasks });
            }
        }
        finally
        {
            this.setState({ loading: false });
        }
    }


    private async dateRangeDropdownChanged(e: SelectChangeEvent<string>): Promise<void>
    {
        this.setState({ daysFilter: e.target.value });

        if (e.target.value === this.labels.HubLabelToday)
        {
            await this.setState(
                {
                    startDate: DateHelper.now().toJSDate(),
                    endDate: DateHelper.now().toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelTomorrow)
        {
            await this.setState(
                {
                    startDate: DateHelper.now().plus({ days: 1 }).toJSDate(),
                    endDate: DateHelper.now().plus({ days: 1 }).toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelThisWeek)
        {
            await this.setState(
                {
                    startDate: DateHelper.now().startOf('week').toJSDate(),
                    endDate: DateHelper.now().endOf('week').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelNextWeek)
        {
            await this.setState(
                {
                    startDate: DateHelper.now().startOf('week').plus({ days: 7 }).toJSDate(),
                    endDate: DateHelper.now().startOf('week').plus({ days: 13 }).toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelThisMonth)
        {
            await this.setState(
                {
                    startDate: DateHelper.now().startOf('month').toJSDate(),
                    endDate: DateHelper.now().endOf('month').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelNextMonth)
        {
            await this.setState(
                {
                    startDate: DateHelper.now().startOf('month').plus({ months: 1 }).toJSDate(),
                    endDate: DateHelper.now().plus({ months: 1 }).endOf('month').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelCustom)
        {
            await this.setState({ dateDisabled: false });
        }

        const payload: IFilter = {
            buildingid: this.state.buildingid,
            startDate: this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
            endDate: this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd),
        };
        this.loadTasks(payload);
    }

    private dateChanged(e: Date, type: StartOrEnd): void
    {
        if (type === StartOrEnd.Start)
        {
            this.setState({ startDate: e });
        }
        else
        {
            this.setState({ endDate: e });
        }

        const filterObject: IFilter =
        {
            buildingid: this.state.buildingid,
            startDate: type === StartOrEnd.Start ? this.fromDateToString(e, this.labels.HubLabelStart) : this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
            endDate: type === StartOrEnd.End ? this.fromDateToString(e, this.labels.HubLabelEnd) : this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd)
        };

        this.loadTasks(filterObject);
    }

    private actionClicked(type: string): void
    {
        if (type === this.actionType.cancel || type === this.actionType.unassign)
        {
            this.setState({
                openMessageModal: true,
                actionType: type,
                message: "",
            });
        }
        if (type === this.actionType.reAllocate)
        {
            this.setState({
                openUserModal: true,
                actionType: type
            });
        }
        if (type === this.actionType.changePriority)
        {
            this.setState({
                openPriorityModal: true,
                actionType: type,
            });
        }
        if (type === this.actionType.setInProgress)
        {
            this.setState({
                openMessageModal:true,
                actionType: type,
                inProgressModal:true,
            });
        }
        if (type === this.actionType.resolve)
        {
            this.setState({
                openMessageModal: true,
                actionType: type,
                disableResolved: true,
            })
        }
    }

    private resolveTaskFromList(): void
    {
        const { selectedTaskIds } = this.state;

        const updatedTaskList = this.state.taskList.filter(task => !selectedTaskIds.includes(task.Task_Id));

        this.setState({
            taskList: updatedTaskList,
            openMessageModal: false,
            disableResolved: false,
        });
    } 

    private async resolveTask(nodeId: number, taskId: string): Promise<void>
    {
        try
        {
            this.setState({ loading: true });
            await this.apiClient.tasks.resolveTask(nodeId, taskId);
            this.resolveTaskFromList();
        }
        finally
        {
            this.setState({ loading: false });
        }

    }

    private gridActionClicked(status: string, taskId: string): void
    {
        if (status === this.labels.HubButtonAssign)
        {
            this.setState({
                openUserModal: true,
                selectedTaskIds: [taskId],
                actionType: this.actionType.assign
            })
        }
        if (status === this.labels.HubButtonSetInProgress)
        {
            this.setState({
                openMessageModal: true,
                selectedTaskIds: [taskId],
                actionType: this.actionType.setInProgress
            })
        }
        if (status === this.labels.HubLabelResolved)
        {
            this.setState({
                openMessageModal: true,
                selectedTaskIds: [taskId],
                actionType: this.actionType.resolve
            })
        }
    }

    private messagePopupChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
    {
        this.setState({ message: e.target.value });
    }

    private async userPopupChanged(newValue: UserPickerModule.IUser | null): Promise<void>
    {
        if (newValue)
        {
            this.setState({ selectedUser: { label: newValue.label, userName: newValue.userName } })
        }
        else
        {
            this.setState({ selectedUser: { label: "", userName: "" } })
        }
    }

    private popupClose(type: string): void
    {
        if (type === this.modalConstants.messageModal)
        {
            this.setState({
                openMessageModal: false,
            })
        }
        if (type === this.modalConstants.filterModal)
        {
            this.setState({
                openFilterModal: false,
            })
        }
        if (type === this.modalConstants.userSelectionModal)
        {
            this.setState({
                openUserModal: false,
            })
        }
        if (type === this.modalConstants.priorityModal)
        {
            this.setState({
                openPriorityModal: false,
            })
        }
        this.setState({ loading: false });
    }

    private popupCancelled(type: string): void
    {
        if (type === this.modalConstants.priorityModal)
        {
            this.setState({
                openPriorityModal: !this.state.openPriorityModal,
                message: ""
            })
        }
        if (type === this.modalConstants.messageModal)
        {
            this.setState({
                openMessageModal: !this.state.openMessageModal,
                message: ""
            })
        }
        if (type === this.modalConstants.filterModal)
        {
            this.setState({
                openFilterModal: !this.state.openFilterModal,
                statusValue: this.labels.HubLabelAssigned,
            })
        }
        if (type === this.modalConstants.userSelectionModal)
        {
            this.setState({
                openUserModal: !this.state.openUserModal,
                selectedUser: { label: "", userName: "" }
            })
        }
        this.setState({ loading: false });
    }

    private messagePopupSubmitted(): Promise<void>
    {
        return (this.state.inProgressModal ? this.setInProgressForManyTasks(this.modalConstants.messageModal) : this.popupSubmitted(this.modalConstants.messageModal));
    }

    private async popupSubmitted(type: string): Promise<void>
    {
        this.setState({ loading: true });
        let message = "";
        let apiPayload = {};

        let payload: IFilter =
        {
            startDate: this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
            endDate: this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd),
            buildingid: this.state.buildingid,
        }

        if (type === this.modalConstants.filterModal)
        {
            payload =
            {
                ...payload,
                statusValue: this.state.statusValue == 'Any' ? '' : this.state.statusValue,
            };

            await this.loadTasks(payload);
            this.setState({ statusValue: this.state.statusValue });
            this.popupClose(type);
            return;
        }

        let buildingID = this.state.buildingid;
        
        
        if (type === this.modalConstants.messageModal || type === this.modalConstants.priorityModal || type === this.modalConstants.userSelectionModal)
        {
            const promises = this.state.selectedTaskIds.map(async (id: number | string) =>
            {
                let response;
                if (type === this.modalConstants.messageModal)
                {
                    // preparing API payload for messageModal
                    if(this.state.actionType == 'cancel')
                    {
                        response = await this.apiClient.tasks.cancelTask(buildingID, id.toString(), this.state.message);
                    }
                    else
                    {
                        if (type === this.modalConstants.messageModal)
                        {
                            apiPayload = {
                                message: this.state.message,
                            }
                        }
                        response = await apis.updateTasksSetInProgressByTaskId(buildingID, id, this.state.actionType, apiPayload);
                    }
                }
                if (type === this.modalConstants.priorityModal)
                {
                    // preparing API payload for priority
                    if (type === this.modalConstants.priorityModal)
                    {
                        apiPayload = {
                            "priority": this.state.setPriority,
                        }
                    }
                    response = await apis.updateTasksPriorityByTaskId(buildingID, id, apiPayload);
                }
                if (type === this.modalConstants.userSelectionModal)
                {
                    // preparing API payload for userSelection
                    if (type === this.modalConstants.userSelectionModal)
                    {
                        if (this.state.actionType === this.actionType.reAllocate)
                        {
                            apiPayload = {
                                "resolver_id": this.state.selectedUser.userName,
                                "priority": 1,
                            }
                        }
                        if (this.state.actionType === this.actionType.assign)
                        {
                            apiPayload = {
                                "resolver_id": this.state.selectedUser.userName,
                            }
                        }
                    }
                    response = await apis.updateTasksAssignByTaskId(buildingID, id, this.state.actionType, apiPayload);
                }

                // setting the alert message
                if (response)
                {
                    message = typeof(response) == 'string' ? response : response.data.Message
                    this.popupClose(type);
                    return Promise.resolve(response);
                }
                else
                {
                    return Promise.reject(new Error(`Failed to delete resource with ID ${id}`));
                }
            });

            Promise.all(promises)
                .then(() =>
                {
                    // All responses are okay, hide the modal
                    this.alert.show("", message);
                    this.loadTasks(payload);
                    this.popupClose(type);
                })
                .catch(() =>
                {
                    this.setState({ loading: false });
                    this.popupCancelled(type);
                });
        }
    }

    private async setInProgressForManyTasks(type: string): Promise<void>
    {
        this.setState({ openMessageModal:false, loading: true });
        let successMessage = "";

        const promises = this.state.selectedTaskIds.map(async resourceId =>
        {
            const payload = { message: this.state.message };
            let buildingID = this.state.buildingid;
                        
            const response = await apis.updateTasksSetInProgressByTaskId(buildingID, resourceId, this.state.actionType, payload);
            
            if (response)
            {
                successMessage = response.data.Message
                this.setState({ loading: false });
                return Promise.resolve(response);
            }
            else
            {
                this.setState({ loading: false });
                return Promise.reject(new Error(`Failed to delete with ID ${resourceId}`));
            }
        });

        try
        {
            const responses = await Promise.all(promises);
            this.alert.show("", successMessage);

            const payload: IFilter =
            {
                startDate: this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
                endDate: this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd),
                buildingid: this.state.buildingid,
            };
            this.loadTasks(payload);
            this.setState({ selectedTaskIds:[] });
        }
        finally
        {
            this.setState({ loading: false });
        }
    }

    private tasksSelected(selection: GridRowSelectionModel)
    {
        this.setState({ selectedTaskIds: selection });
        const selectedRows = this.filterTasks(this.state.searchTerm).filter((row:Task) => selection.includes(row.Task_Id));
        const cancelRows = new Array<Task>();
        const inProgressTasks = new Array<Task>();

        for (let i = 0; i <= selectedRows.length - 1; i++)
        {
            const status = selectedRows[i]["Task_Status"]
            if(status === "Active" || status === "Assigned" || status === "In Progress" || status === "Awaiting Re-allocation")
            {
                cancelRows.push(selectedRows[i]);
            }
        }

        if (selectedRows.length <= 0 && cancelRows.length <= 0)
        {
            this.setState({ disableCancel: true });
        } 
        else if (selectedRows.length === cancelRows.length && this.state.isCancelRight)
        {
            this.setState({ disableCancel: false });
        } 
        else
        {
            this.setState({ disableCancel: true });
        }

        for (let i = 0; i <= selectedRows.length - 1; i++)
        {
            const status = selectedRows[i]["Task_Status"];
            if(status === "Assigned")
            {
                inProgressTasks.push(selectedRows[i])
            }
        }

        if (selectedRows.length <= 0 && inProgressTasks.length <= 0)
        {
            this.setState({ disableInprogress: true });
        } 
        else if (selectedRows.length === inProgressTasks.length && this.state.isSetInProgressRight)
        {
            this.setState({ disableInprogress: false });
        } 
        else
        {
            this.setState({ disableInprogress: true });
        }

        // disable the change Priority button
        if (selection.length !== 0 && this.state.isChangePriorityRight)
        {
            this.setState({ disablePriority: false });
        }
        else
        {
            this.setState({ disablePriority: true });
        }

        const list = selection.map((id: number | string) => this.state.taskList.find((el: Task) => el.Task_Id == id));
        const unAssigned: boolean = list.length ? list.some((e: any) => e.Task_Owner === "") : true
        const reallocate: boolean = list?.length > 0 ? list?.some((e: any) => e.Task_Status !== this.labels.HubLabelReAllocation) : true

        if (!reallocate && this.state.isReallocateRight)
        {
            this.setState({ disableReallocate: false });
        }
        else
        {
            this.setState({ disableReallocate: true });
        }


        // disable the unassign button
        if (!unAssigned && this.state.isUnAssignRight)
        {
            this.setState({ disableUnassign: false });
        } else
        {
            this.setState({ disableUnassign: true });
        } 

        const isAtleastOneInProgress = selectedRows.some((row: Task) => row.Task_Status === "In Progress"); 
        this.setState({ isAtleastOneInProgress });

        if (selection.length > 0)
        {
            this.setState({ disableResolved: !isAtleastOneInProgress });

        } else
        {
            this.setState({ disableResolved: true });
        }

    }

    private filterTasks(searchTerm: string): Task[] 
    {
        const filteredTasks = this.state.taskList.filter(task =>
        {
            let key: keyof Task;
            for (key in task)
            {
                if (task[key]?.toString().includes(searchTerm))
                {
                    return true;
                }
            }
            return false;
        });
        return filteredTasks;
    }

    private async exportTaskList(): Promise<void>
    {
        let filter: ITasksFilter =
        {
            status: this.state.statusValue ?? undefined,
            createdFrom: DateTime.fromISO(this.fromDateToString(this.state.startDate, this.labels.HubLabelStart)).toUTC(),
            createdTo: DateTime.fromISO(this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd)).toUTC(),
        };
        await this.apiClient.tasks.exportTasks(this.state.buildingid, filter);
    }

    private navigateToAddTask(): void
    {
        const { history } = this.props;
        if (this.state.urlParameter === '/flex-my-tasks')
        {
            history.push(`/flex-my-tasks/${this.state.buildingid}/mytask/0`);
        } 
        else
        {
            history.push(`/operational-services-tasks/${this.state.buildingid}/task/0/1`);
        }
    }


    public render(): JSX.Element
    {
        const { history } = this.props

        const dataGridHeader: GridColDef[] =
            [
                {
                    headerName: this.labels.HubLabeltaskType,
                    field: "Task_Type_Name",
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelCategory,
                    field: "Task_Category_Name",
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelLocation,
                    field: "spaceNames",
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelPriorityLabel,
                    field: "Task_Priority",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => this.renderTaskPriority(params.row.Task_Priority),
                },
                {
                    headerName: this.labels.HubLabelDueDate,
                    field: "Task_Due_Date",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => params.row.Task_Due_Date == null ? '-' : DateTime.fromISO(params.row.Task_Due_Date).toLocaleDateTimeString(),
                },
                {
                    headerName: this.labels.HubLabelresolutionTimeLabel,
                    field: "Task_TTC",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => 
                    {
                        if(params.row.Task_Status == 'Resolved')
                        {
                            const createDateTime = DateTime.fromISO(params.row.CreatedAt);
                            const resolutionTime = createDateTime.plus({seconds: params.row.Task_TTC});
                            return resolutionTime.toLocaleDateTimeString();
                        }
                        else
                        {
                            return '-';
                        }
                    }
                    
                },
                {
                    headerName: this.labels.HubLabelTimeZone,
                    cellClassName: "table-cell-font",
                    field: "timeZone",
                    minWidth: 140,
                    renderCell: (params) => <TimeZoneOffset nodeId={params.row.Node_Id} />
                },
                {
                    headerName: this.labels.HubLabelCreatedBy,
                    field: "Task_CreatedBy",
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelResolver,
                    field: "Task_Owner",
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelAction,
                    field: "",
                    minWidth: 150,
                    flex: 1,
                    filterable: false,
                    sortable: false,
                    renderCell: (params) => this.renderTaskAction(params.row.Task_Status, params.row.Task_Id)
                },
                {
                    headerName: this.labels.HubLabelStatus,
                    field: "Task_Status",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => <BookingStatus status={params.row.Task_Status} />
                },
            ];
        
        const taskRaisedGridHeader: GridColDef[] =
            [
                {
                    headerName: this.labels.HubLabelTask,
                    field: "Task_Id",
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabeltaskType,
                    field: "Task_Type_Name",
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelCategory,
                    field: "Task_Category_Name",
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelLocation,
                    field: "spaceNames",
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelPriorityLabel,
                    field: "Task_Priority",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => this.renderTaskPriority(params.row.Task_Priority),
                },
                {
                    headerName: this.labels.HubLabelDueDate,
                    field: "Task_Due_Date",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => params.row.Task_Due_Date == null ? '-' : DateTime.fromISO(params.row.Task_Due_Date).toLocaleDateTimeString(),
                },
                {
                    headerName: this.labels.HubLabelAction,
                    field: "",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => this.renderTaskAction(params.row.Task_Status, params.row.Task_Id)
                },
                {
                    headerName: this.labels.HubLabelStatus,
                    field: "Task_Status",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => <BookingStatus status={params.row.Task_Status} />
                },
            ];

        // filter status options
        let filterStatusOption =
            [
                { label: this.labels.HubLabelAssigned, value: "Assigned" },
                { label: this.labels.HubLabelUnassigned, value: "Active" },
                { label: this.labels.HubLabelInProgress, value: "In Progress" },
                { label: this.labels.HubLabelCancelled, value: "Cancelled" },
                { label: this.labels.HubLabelResolved, value: "Resolved" },
                { label: this.labels.HubLabelReAllocation, value: "Awaiting Re-allocation" },
            ].sort((a, b) => {
                var textA = a.label.toUpperCase();
                var textB = b.label.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            filterStatusOption = [{label: this.labels.HubLabelAny, value: "Any"}, ...filterStatusOption]

        // priority options
        const priorityOption =
            [
                { label: this.labels.HubLabelHighPriority, value: 1 },
                { label: this.labels.HubLabelNormalPriority, value: 2 },
                { label: this.labels.HubLabelLowPriority, value: 3 },
            ]

        // Action buttons
        const actionButtons: IActionButton[] =
            [
                {
                    label: this.labels.HubButtonCancel,
                    icon: (
                        <IbssSvgIcon>
                            <DeleteIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => this.actionClicked(this.actionType.cancel),
                    disabled: this.state.disableCancel
                },
                {
                    label: this.labels.HubButtonReallocate,
                    icon: (
                        <IbssSvgIcon>
                            <EditIcon />
                        </IbssSvgIcon>
                    ),
                    color: "info",
                    onClick: () => this.actionClicked(this.actionType.reAllocate),
                    disabled: this.state.disableReallocate
                },
                {
                    label: this.labels.HubButtonUnAssign,
                    icon: (
                        <IbssSvgIcon>
                            <EditIcon />
                        </IbssSvgIcon>
                    ),
                    color: "inherit",
                    onClick: () => this.actionClicked(this.actionType.unassign),
                    disabled: this.state.disableUnassign,
                },
                {
                    label: this.labels.HubButtonChangePriority,
                    icon: (
                        <IbssSvgIcon>
                            <PriorityHighIcon />
                        </IbssSvgIcon>
                    ),
                    color: "warning",
                    onClick: () => this.actionClicked(this.actionType.changePriority),
                    disabled: this.state.disablePriority,
                },
                {
                    label: this.labels.HubButtonSetInProgress,
                    icon: (
                        <IbssSvgIcon>
                            <PriorityHighIcon />
                        </IbssSvgIcon>
                    ),
                    color: "warning",
                    onClick: () => this.actionClicked(this.actionType.setInProgress),
                    disabled: this.state.disableInprogress,
                },
                {
                    label: this.labels.HubButtonAdd,
                    icon: (
                        <IbssSvgIcon>
                            <AddIcon />
                        </IbssSvgIcon>
                    ),
                    color: "primary",
                    onClick: () => this.navigateToAddTask(),
                    disabled: !this.state.isCreateRight
                }, 
                {
                    label: this.labels.HubLabelResolved,
                    icon: (
                        <IbssSvgIcon>
                            <TickIcon />
                        </IbssSvgIcon>
                    ),
                    color: "success",
                    onClick: () => this.actionClicked(this.actionType.resolve),
                    disabled: this.state.disableResolved || !this.state.isAtleastOneInProgress,
                }
            ];
        
            const taskRaisedActionButtons: IActionButton[] =
            [
                
                {
                    label: this.labels.HubButtonCancel,
                    icon: (
                        <IbssSvgIcon>
                            <DeleteIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => this.actionClicked(this.actionType.cancel),
                    disabled: this.state.disableCancel
                },
                {
                    label: this.labels.HubButtonChangePriority,
                    icon: (
                        <IbssSvgIcon>
                            <PriorityHighIcon />
                        </IbssSvgIcon>
                    ),
                    color: "warning",
                    onClick: () => this.actionClicked(this.actionType.changePriority),
                    disabled: this.state.disablePriority,
                },
                {
                    label: this.labels.HubButtonAdd,
                    icon: (
                        <IbssSvgIcon>
                            <AddIcon />
                        </IbssSvgIcon>
                    ),
                    color: "primary",
                    onClick: () => this.navigateToAddTask(),
                    disabled: !this.state.isCreateRight
                }
            ];

            
        const filteredTasks = this.filterTasks(this.state.searchTerm);
        return (
            <>
                <div>
                    {/* Modal for Set In Progress */}
                    <IbssDialog
                        aria-modal="true"
                        aria-label="set in progress modal"
                        open={this.state.openMessageModal}
                        onClose={() => this.popupCancelled(this.modalConstants.messageModal)}
                        fullWidth
                        header={this.labels.HubLabelMessageBox}
                        content=
                        {
                            <>
                                <label>{this.labels.HubLabelMessage}</label>
                                <IbssTextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    type="text"
                                    name="message"
                                    className="mt-1"
                                    fullWidth
                                    value={this.state.message}
                                    onChange={(e) => { this.messagePopupChanged(e) }}
                                />
                            </>
                        }
                        footer=
                        {
                            <>
                                <IbssButtonRedo
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => this.popupCancelled(this.modalConstants.messageModal)}
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo
                                    color='primary'
                                    variant='contained'
                                    onClick={() => this.messagePopupSubmitted()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    {/* User Selection Modal */}
                    <IbssDialog
                        aria-modal="true"
                        aria-label="user selection modal"
                        open={this.state.openUserModal}
                        onClose={() => this.popupCancelled(this.modalConstants.userSelectionModal)}
                        header={this.labels.HubLabelUserSelection}
                        fullWidth
                        content=
                        {
                            <>
                                <label>{this.labels.HubLabelnameEmailAddress}</label>
                                <UserPicker
                                    searchText={this.state.userSearchText}
                                    onChange={async text => this.setState({ userSearchText: text })}
                                    onUserChange={user => this.userPopupChanged(user)}
                                />
                            </>
                        }
                        footer=
                        {
                            <>
                                <div className="d-flex justify-content-center w-100">
                                    <IbssButtonRedo
                                        className="mr-2"
                                        color='secondary'
                                        variant='outlined'
                                        onClick={() => this.popupCancelled(this.modalConstants.userSelectionModal)}
                                    >
                                        {this.labels.HubButtonCancel}
                                    </IbssButtonRedo>
                                    <IbssButtonRedo
                                        color="primary"
                                        variant='contained'
                                        disabled={!this.state.selectedUser.userName}
                                        onClick={() => this.popupSubmitted(this.modalConstants.userSelectionModal)}
                                    >
                                        {this.labels.HubLabelOk}
                                    </IbssButtonRedo>
                                </div>
                            </>
                        }
                    />
                    {/* Priority Modal */}
                    <IbssDialog
                        aria-modal="true"
                        aria-label="change priority modal"
                        open={this.state.openPriorityModal}
                        onClose={() => this.popupCancelled(this.modalConstants.priorityModal)}
                        fullWidth
                        header={this.labels.HubLabelPriorityLabel}
                        content=
                        {
                            <>
                                <div className="dropdown-selector">
                                    <IbssInputDropDown
                                        id="prioritySelect"
                                        options={priorityOption}
                                        value={this.state.setPriority}
                                        onChange={(e: SelectChangeEvent<string>) => this.setState({ setPriority: parseInt(e.target.value) })}
                                        inputLabel={this.labels.HubLabelSetPriority}
                                    />
                                </div>
                            </>
                        }
                        footer=
                        {
                            <>
                                <div className="d-flex justify-content-center w-100">
                                    <IbssButtonRedo
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() => this.popupCancelled(this.modalConstants.priorityModal)}
                                    >
                                        {this.labels.HubButtonCancel}
                                    </IbssButtonRedo>
                                    <IbssButtonRedo
                                        className="button-primary ml-2"
                                        onClick={() => this.popupSubmitted(this.modalConstants.priorityModal)}
                                    >
                                        {this.labels.HubLabelOk}
                                    </IbssButtonRedo>
                                </div>
                            </>
                        }
                    />
                    {/* Filter Modal */}
                    <IbssDialog
                        aria-modal="true"
                        aria-label="filter modal"
                        open={this.state.openFilterModal}
                        onClose={() => this.popupCancelled(this.modalConstants.filterModal)}
                        fullWidth
                        header={this.labels.HubLabelFilter}
                        content=
                        {
                            <>
                                <div className="row">
                                    <IbssInputDropDown
                                        options={filterStatusOption}
                                        id="statusSelection"
                                        value={this.state.statusValue}
                                        inputLabel={this.labels.HubLabelStatus}
                                        fullWidth
                                        onChange={(event: SelectChangeEvent<string>) => { this.setState({ statusValue: event.target.value }) }}
                                    />
                                </div>
                            </>
                        }
                        footer=
                        {
                            <>
                                <IbssButtonRedo
                                    onClick={() => this.popupCancelled(this.modalConstants.filterModal)}
                                    color="secondary"
                                    variant="outlined"
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={() => this.popupSubmitted(this.modalConstants.filterModal)}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    <div className="">
                        {this.state.loading && <Spinner />}
                        <div className="rightPanel-main-content">
                            <div className="table-panel">
                                    <IbssPageHeader
                                        pageTitle={this.labels.HubMenuTasks}
                                        daysFilter={this.state.daysFilter}
                                        todayChanged={(e) => this.dateRangeDropdownChanged(e)}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        dateDisabled={this.state.dateDisabled}
                                        DEPRECATED_onDateChange={(event, value) => this.dateChanged(event, value)}
                                        additionalDateFilterOptions={[this.labels.HubLabelTomorrow, this.labels.HubLabelThisWeek, this.labels.HubLabelNextWeek, this.labels.HubLabelThisMonth, this.labels.HubLabelNextMonth]}
                                    />
                                    <IbssFilter
                                        showExport={this.state.canExport}
                                        searchTerm={this.state.searchTerm}
                                        searchTermChanged={(event) => this.setState({ searchTerm: event.target.value })}
                                        filterButtonClicked={() => this.setState({ openFilterModal: true })}
                                        exportButtonClicked={() => { this.exportTaskList() }}
                                    />
                                   <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                        <IbssActionButton
                                            buttons={this.state.urlParameter === "/flex-my-tasks" ? taskRaisedActionButtons :actionButtons}
                                        />
                                    </Box>
                                    <Box sx={{mt:1}}>
                                <IbssDataGrid
                                    checkboxSelection
                                    columns={this.state.urlParameter === "/flex-my-tasks" ? taskRaisedGridHeader : dataGridHeader}
                                    disableRowSelectionOnClick
                                    rows={filteredTasks}
                                    onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel) => this.tasksSelected(rowSelectionModel)}
                                    initialState={{
                                        pagination: { paginationModel: {pageSize: 25} },
                                    }}
                                    pageSizeOptions={[25,50,100]}
                                />
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    private renderTaskPriority(value: number): React.ReactNode
    {
        let priority = "-";
        if (value === 0)
        {
            priority = priority;
        }
        else if (value === 1)
        {
            priority = this.labels.HubLabelHighPriority;
        }
        else if (value === 2)
        {
            priority = this.labels.HubLabelNormalPriority;
        }
        else if (value === 3)
        {
            priority = this.labels.HubLabelLowPriority;
        }
        return priority;
    }

    private renderTaskAction(value: string, taskId: string): React.ReactNode
    {
        const URL = this.getRoute();
        let status = "-";
        let disabled = true;

        if (value === "Active" && URL !== "/flex-my-tasks")
        {
            status = this.labels.HubButtonAssign;
            disabled = !this.state.isAssignRight;
        }
        else if(value === "Awaiting Re-allocation"  && URL === "/flex-my-tasks")
        {
            status = this.labels.HubButtonAssign;
            disabled = !this.state.isAssignRight;  
        }
        else if (value === "Assigned" && URL !== "/flex-my-tasks")
        {
            status = this.labels.HubButtonSetInProgress;
            disabled = !this.state.isSetInProgressRight;
        }
        else if (value === "In Progress" && URL !== "/flex-my-tasks")
        {
            status = this.labels.HubLabelResolved;
            disabled = !this.state.isSetInProgressRight;
        }
        const myTasks = `/flex-my-tasks/${this.state.buildingid}/mytask/${taskId}`;
        const onelensTasks = `/operational-services-tasks/${this.state.buildingid}/task/${taskId}/1`;

        let navigationRoute = onelensTasks;
       
        if (URL === "/flex-my-tasks")
        {
            navigationRoute = myTasks;
        }

        return (
            <>
                {value === "In Progress" ? (
                    <IbssButtonRedo
                        className="text-link"
                        disabled={disabled}
                        onClick={() => this.gridActionClicked(status, taskId)}
                    >
                        {status}
                    </IbssButtonRedo>
                ) : (
                    <IbssButtonRedo
                        className="text-link"
                        disabled={disabled}
                        onClick={() => this.gridActionClicked(status, taskId)}
                    >
                        {status}
                    </IbssButtonRedo>
                )}
                {this.state.isUpdateRight && (
                    <IbssSvgIcon
                        className="pointer"
                        onClick={() => this.props.history.push(navigationRoute)}
                    >
                        <EditIcon />
                    </IbssSvgIcon>
                )}
            </>
        );
    }

}

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState, IDispatch
{
}

interface IMatchParams 
{
    buildingid: string;
    filter: string;
}

interface IState
{
    loading: boolean;
    taskList: Task[];
    todayTaskList: Task[];
    canExport: boolean;
    isCreateRight: boolean;
    isUpdateRight: boolean;
    isAssignRight: boolean;
    isUnAssignRight: boolean;
    isCancelRight: boolean;
    isReallocateRight: boolean;
    isSetInProgressRight: boolean;
    isChangePriorityRight: boolean;
    buildingid: number;
    startDate: Date;
    endDate: Date;
    openUserModal: boolean;
    openMessageModal: boolean;
    message: string;
    selectedTaskIds: GridRowSelectionModel;
    actionType: string;
    daysFilter: string;
    dateDisabled: boolean;
    searchTerm: string;
    openFilterModal: boolean;
    statusValue: string;
    disableReallocate: boolean;
    disableCancel: boolean;
    disableUnassign: boolean;
    disablePriority: boolean;
    disableInprogress: boolean;
    userSearchText: string;
    selectedUser: { label?: string, userName: string },
    openPriorityModal: boolean,
    setPriority: number;
    inProgressModal: boolean;
    urlParameter: string;
    disableResolved: boolean;
    isAtleastOneInProgress: boolean;
}

export interface ITaskModal
{
    messageModal: string,
    filterModal: string,
    userSelectionModal: string,
    priorityModal: string,
}

export interface IFilter
{
    buildingid: number;
    startDate: string;
    endDate: string;
    statusValue?: string;
}