import { Autocomplete, Paper, SelectChangeEvent, TextField } from '@mui/material';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import IbssButtonRedo from '../../../../Components/Buttons/Button/IbssButton';
import { appContext } from '../../../../AppContext';
import UserPicker, * as UserPickerModule from '../../../../Components/Inputs/UserPicker/UserPicker';

class ListFilterModal extends IbssComponent<IProps, IState>
{

    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {

        const broadcastDropdownOptions = [{ label: this.labels.HubLabelAny, value: 'any' }, { label: this.labels.HubMenuInspire, value: 'Inspire' }, { label: this.labels.funcAnnouncementRoamerAndFlex_Short, value: 'Roamer and Flex' }];
        const statusDropdownOptions = [{ label: this.labels.HubLabelAny, value: 'any' }, { label: this.labels.HubLabelActive, value: this.labels.HubLabelActive }, { label: this.labels.funcAnnouncementsStatusExpired_Short, value: this.labels.funcAnnouncementsStatusExpired_Short }, { label: this.labels.HubLabelInActive, value: this.labels.HubLabelInActive }];

        return (
            <div>
                <IbssDialog
                    aria-modal="true"
                    aria-label="filter modal"
                    open={this.props.open}
                    onClose={() => this.props.onClose()}
                    fullWidth
                    header={this.labels.HubLabelFilter}
                    content=
                    {
                        <>
                            <div className="row mb-1">
                                <IbssInputDropDown
                                    options={broadcastDropdownOptions}
                                    id="broadcastSelection"
                                    value={this.props.broadcastSelectValue}
                                    inputLabel={this.labels.funcAnnouncementsBroadcast_Short}
                                    fullWidth
                                    onChange={(event: SelectChangeEvent<string>) => this.props.onBroadcastValueChanged(event.target.value)}
                                />
                            </div>
                            <div className="row mb-1">
                                <IbssInputDropDown
                                    options={statusDropdownOptions}
                                    id="statusSelection"
                                    value={this.props.statusSelectValue}
                                    inputLabel={this.labels.HubLabelStatus}
                                    fullWidth
                                    onChange={(event: SelectChangeEvent<string>) => this.props.onStatusValueChanged(event.target.value)}
                                />
                            </div>
                            <div className="row mb-1">
                                <UserPicker
                                    width="100%"
                                    label={this.labels.HubLabelCreatedBy}
                                    searchText={this.props.selectedAnnouncementCreator}
                                    onChange={async text => this.props.onAnnouncementCreatorTextChanged(text)}
                                    onUserChange={async user => this.props.onAnnouncementCreatorChanged(user)}
                                />
                            </div>
                        </>
                    }
                    footer=
                    {
                        <>
                            <IbssButtonRedo
                                onClick={() => this.props.onClose()}
                                color="secondary"
                                variant="outlined"
                            >
                                {this.labels.HubButtonCancel}
                            </IbssButtonRedo>
                            <IbssButtonRedo
                                color="primary"
                                variant="contained"
                                size="medium"
                                onClick={() => this.props.onSubmit()}
                            >
                                {this.labels.HubLabelOk}
                            </IbssButtonRedo>
                        </>
                    }
                />
            </div>
        )
    }
}

export default ListFilterModal;

export interface IProps
{
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
    broadcastSelectValue: string;
    statusSelectValue: string;
    selectedAnnouncementCreator: string;
    onAnnouncementCreatorTextChanged: (selectedAnnouncementCreator: string) => void;
    onAnnouncementCreatorChanged: (user: UserPickerModule.IUser | null) => void;
    onBroadcastValueChanged: (value: string) => void;
    onStatusValueChanged: (value: string) => void;
}

export interface IState
{
}
