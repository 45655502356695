export class ODataFilter implements IFilter
{
    public filterItems: ODataFilterItem<unknown>[] = [];

    public static get empty(): ODataFilter
    {
        return new ODataFilter();
    }

    public toODataString(): string
    {
        const filterString = this.filterItems.map(i => i.toODataString()).join(" and ");
        return filterString;
    }
}

export class ODataFilterItem<TValue>
{
    public field: string;
    public operator: ODataFilterOperator;
    public value: TValue;

    constructor(field: string, operator: ODataFilterOperator, value: TValue)
    {
        this.field = field;
        this.operator = operator;
        this.value = value;
    }

    public toODataString(): string
    {
        let value: string;
        if (this.value == null)
        {
            value = `null`; // todo: check
        }
        else if (typeof(this.value) == "string")
        {
            value = `'${this.value.replace("'", "''")}'`;
        }
        else if (typeof(this.value) == "number")
        {
            value = `${this.value}`;
        }
        else if (typeof(this.value) == "boolean")
        {
            value = `${this.value ? 1 : 0}`;
        }
        //else if (typeof(this.value) == "object" && this.value.isLuxonDateTime)
        //{
        //    isDateTime(this.value);
        //    value = `'${this.value.replace("'", "''")}'`;
        //}
        else
        {
            throw new Error("Type not supported.");
        }
        return `${this.field} ${this.operator} ${value}`;
    }
}

export type ODataFilterOperator = "eq" | "ne" | "lt" | "le" | "gt" | "ge";

export interface IFilter
{
    toODataString(): string;
}
