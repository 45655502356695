import { Component } from "react";
import "../../../styles/css/searchspace.scss";
import "../../../App.css";
import { getUserPrefrenceJosn } from "../../../Common/UserPreferenceJSON";
import { appContext } from "../../../AppContext";
import IbssButton from "../../../Components/Buttons/Button/IbssButton";
import IbssSwitchLabel, { LabelPlacementType } from "../../../Components/Inputs/Switch/IbssSwitchLabel";
import { IUserPreferences } from "../../../Providers.Api/UserPreferences/UserPreferenceRepository";

class State {
    public userpref = getUserPrefrenceJosn();
    public visitorArrival = false
    public BookingReminders = false
    public serviceReuest = false

}

export default class OnBoardingNotified extends Component<IOnBoardingNotified, State>
{

    private labels = appContext().labels;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider;
    private closePopup;
    
    constructor(props: IOnBoardingNotified) {
        super(props);
        this.closePopup = props.closePopup;
        this.state = new State();
    };

    public componentDidMount() {
        const userPreferences = this.local.getUserPreferences();
        this.makeData(userPreferences)

    };

    public change = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "visitorArrival") {
            this.setState({ visitorArrival: e.target.checked });
        } else if (e.target.name === "BookingReminders") {
            this.setState({ BookingReminders: e.target.checked });
        } else if (e.target.name === "serviceReuest") {
            this.setState({ serviceReuest: e.target.checked });
        }
    };

    private makeData(userPreferences: IUserPreferences): void
    {

        // these are the default values for userPreferences.NotificationPrefs[x] the backend generates in case user is new or userPrefs obj is {}
        // visitors  = 0; tasks = 0; bookings = 0;

        const visitors = Number(userPreferences.NotificationPrefs?.Visitors ?? 0);
        const tasks = Number(userPreferences.NotificationPrefs?.Tasks ?? 0);
        const bookings = Number(userPreferences.NotificationPrefs?.Bookings ?? 0);

        this.setState({
            visitorArrival: visitors == 7 || visitors == 5 || visitors == 3 ? true : false,
            BookingReminders: bookings == 7 || bookings == 5 || bookings == 3 ? true : false,
            serviceReuest: tasks == 7 || tasks == 5 || tasks == 3 ? true : false,

        });
    }

    public getIntegerForFlag = (flag: boolean) => {
        let intVal = 0;
        if (flag) {
            intVal = 7;
        }
        return intVal;

    };

    submitBuilding = async () => {
        const payload =
        {
            ...this.local.getUserPreferences(),
            NotificationPrefs:
            {
                Visitors: this.getIntegerForFlag(this.state.visitorArrival),
                Bookings: this.getIntegerForFlag(this.state.BookingReminders),
                Tasks: this.getIntegerForFlag(this.state.serviceReuest),
                BuildingsPrefs: this.props.userPrefrences.NotificationPrefs?.BuildingsPrefs ?? [],
            },

            /* 
            set default values for CalendarPrefs.SyncCalendar, DiagnosticsPrefs.Logging, EnvironmentPrefs.Temperature & EnvironmentPrefs.SoundLevel
            to match what the backend generates when user is new, and hadn't yet set user prefs, in case userPreferences object sent from the backend is incomplete  
            e.g. CalendarPrefs: null,  and CalendarPrefs.SyncCalendar property is undefined.
            */
            CalendarPrefs: 
            { 
                SyncCalendar: this.props.userPrefrences.CalendarPrefs?.SyncCalendar ?? 0
            },
            DiagnosticsPrefs: 
            {
                Logging: this.props.userPrefrences.DiagnosticsPrefs?.Logging ?? 0
            },
            EnvironmentPrefs: 
            {
                Temperature: this.props.userPrefrences.EnvironmentPrefs?.Temperature ?? null,
                SoundLevel: this.props.userPrefrences.EnvironmentPrefs?.SoundLevel ?? null
            },
        };
        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.closePopup();
            }
        }
        catch { }
        
    };

    public render(): JSX.Element {

        return (<>
            <div className="px-50">
                <h2 className="ibss-modal-title mb-10"> {this.labels.HubLabelNotified}</h2>
                <p className="ibss-modal-text-md"> {this.labels.HubLabelNotifiedParagraph}</p>
                <div className="fullWidthIbssSwitchLabel">
                    <IbssSwitchLabel
                        label={this.labels.HubLabelVisitorArrivals}
                        name="visitorArrival"
                        labelPlacement={LabelPlacementType.start}
                        checked={this.state.visitorArrival}
                        onChange={(e: any) => {this.change(e); this.setState({ visitorArrival: e.target.checked })}}
                    />
                </div>
                <div className="fullWidthIbssSwitchLabel">
                    <IbssSwitchLabel
                        label={this.labels.HubLabelBookingReminders}
                        name="BookingReminders"
                        labelPlacement={LabelPlacementType.start}
                        checked={this.state.BookingReminders}
                        onChange={(e: any) => {this.change(e); this.setState({BookingReminders: e.target.checked})}} />
                </div>
                <div className="fullWidthIbssSwitchLabel">
                    <IbssSwitchLabel
                        label={this.labels.HubLabelServiceRequest}
                        name="serviceReuest"
                        checked={this.state.serviceReuest}
                        labelPlacement={LabelPlacementType.start}
                        onChange={(e: any) => {this.change(e); this.setState({ serviceReuest: e.target.checked })}} />
                </div>
                <p>{this.labels.HubLabelDontWorry}
                    <span>{this.labels.HubMenuUserPreference} &gt; {this.labels.HubLabelNotifications} </span> {this.labels.HubLabelMenu}</p>

                <IbssButton color="primary" variant="contained"
                    onClick={() => this.submitBuilding()}
                >{this.labels.HubLabelYouDone}</IbssButton>
            </div>
        </>);
    }
}

export interface IOnBoardingNotified {
    closePopup?: any;
    userPrefrences: IUserPreferences;
}

