import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import AppPermissionsDialog from "./AppPermissionsDialog";
import CheckboxTree, { OnCheckNode } from 'react-checkbox-tree';
import Grid from '@mui/material/Grid';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { Box, Typography } from "@mui/material";
import apis from "../../../../Providers.Api/apis";
import { appContext } from "../../../../AppContext";
import { AxiosResponse } from "axios";
import { Node as CheckBoxTreeNode } from "react-checkbox-tree";
import React from "react";

export default class AppPermissions extends IbssComponent<IProps, IState>
{
    private appliesTo = "APPLIESTO";
    private jsonDialogRef = React.createRef<AppPermissionsDialog>();
    private get jsonDialog() { return this.jsonDialogRef.current; }
    private get labels() { return appContext().labels; }
    private get alert() { return appContext().alert; }
    private get local() { return appContext().localStorageProvider; }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            permissions: [],
            expandedPermissions: [],
            selectedPermission: "",
            expandedBuildings: [],
            nodePermissions: [],
        };
    }

    public async componentDidMount(): Promise<void>
    {
        await this.parsePermissions(this.props.availablePermissions, this.props.selectedPermissions);
    }

    public async componentDidUpdate(prevProps: IProps): Promise<void>
    {
        const props = this.props;
        if (prevProps.availablePermissions != props.availablePermissions || prevProps.selectedPermissions != props.selectedPermissions)
        {
            await this.parsePermissions(this.props.availablePermissions, this.props.selectedPermissions);
        }
    }

    private async parsePermissions(available: IPermissionsObject, selected: IPermissionsObject): Promise<void>
    {
        const permissions = this.unionAvailableAndSelectedPermissions(available, selected);
        const nodePermissions = this.createNodePermissions(selected);

        if (!this.arePermissionsValid(permissions) || !this.areNodePermissionsValid(nodePermissions))
        {
            throw new Error("Permissions are not valid.");
        }
        await this.setStateAsync({ permissions: permissions, nodePermissions: nodePermissions });
    }

    private arePermissionsValid(permissions: Permission[]): boolean
    {
        const areTypesValid = permissions.every(i => (typeof i.area == "string" && typeof i.category == "string" && typeof i.permission == "string"));
        if (!areTypesValid)
        {
            return false;
        }
        const areUnique = permissions.groupBy(i => i.fullyQualifiedName).every(i => i.value.length == 1);
        if (!areUnique)
        {
            return false;
        }
        return true;
    }

    private areNodePermissionsValid(nodePermissions: INodePermission[]): boolean
    {
        const areTypesValid = nodePermissions.every(i => (typeof i.permission == "string" && typeof i.nodeId == "string"));
        if (!areTypesValid)
        {
            return false;
        }
        const areUnique = nodePermissions.groupBy(i => i.permission + "/" + i.nodeId).every(i => i.value.length == 1);
        if (!areUnique)
        {
            return false;
        }
        return true;
    }

    private unionAvailableAndSelectedPermissions(available: IPermissionsObject, selected: IPermissionsObject): Permission[]
    {
        const availablePermissions = this.fromPermissionsObjectToPermissionArray(available);
        const selectedPermissions = this.fromPermissionsObjectToPermissionArray(selected);
        let union: Permission[] = [];

        for (const permission of availablePermissions)
        {
            permission.isAvailable = true;
            permission.isSelected = (!!permission.area && !!permission.category && !!permission.permission && selectedPermissions.some(i => i.fullyQualifiedName == permission.fullyQualifiedName));
            union.push(permission);
        }

        for (const permission of selectedPermissions)
        {
            if (availablePermissions.some(i => i.fullyQualifiedName == permission.fullyQualifiedName))
            {
                continue;
            }
            permission.isAvailable = false;
            permission.isSelected = true;
            union.push(permission);
        }

        return union;
    }

    private fromPermissionsObjectToPermissionArray(permissions: IPermissionsObject): Permission[]
    {
        let permissionsArray = Object.entries(permissions).flatMap(area => [
            new Permission({
                area: area[0] ?? "",
                category: "",
                permission: "",
            }),
            ...Object.entries(area[1]).flatMap(category => [
                new Permission({
                    area: area[0] ?? "",
                    category: category[0] ?? "",
                    permission: "",
                }),
                ...Array.from(category[1]).map(permissionName => new Permission({
                    area: area[0] ?? "",
                    category: category[0] ?? "",
                    permission: permissionName ?? "",
                }))
            ])
        ]);
        permissionsArray = permissionsArray.filter(i =>
            i.area !== this.appliesTo && !!i.area && i.area != "undefined" &&
            i.category != null && i.category != "undefined" &&
            i.permission != null && i.permission != "undefined");

        return permissionsArray;
    }

    private createNodePermissions(selected: IPermissionsObject): INodePermission[]
    {
        const appliesTo: Record<string, string[]> = selected[this.appliesTo] ?? {};

        const nodePermissions = Object.entries(appliesTo).flatMap(permission =>
            permission[1].map(nodeId => ({ permission: permission[0], nodeId: nodeId })));

        return nodePermissions;
    }

    private get permissionNodes(): CheckBoxTreeNode[]
    {
        const nodes = this.state.permissions
            .filter(i => !!i.area && !i.category && !i.permission)
            .map(area => ({
                value: area.fullyQualifiedName,
                label: area.area,
                children: this.state.permissions
                    .filter(i => i.area == area.area && !!i.category && !i.permission)
                    .map(category => ({
                        value: category.fullyQualifiedName,
                        label: category.category,
                        children: this.state.permissions
                            .filter(i => i.area == area.area && i.category == category.category && !!i.permission)
                            .map(permission => ({
                                value: permission.fullyQualifiedName,
                                label: permission.permission,
                            })),
                    })),
            }));

        return nodes;
    }

    private checkPermission(checked: string[]): void
    {
        this.state.permissions.forEach(i => i.isSelected = checked.some(j => j == i.fullyQualifiedName));
        const nodePermissions = this.state.nodePermissions.filter(i => checked.contains(i.permission));
        this.setState({ permissions: this.state.permissions, nodePermissions: nodePermissions });
    }

    private get checkedPermissions(): string[]
    {
        return this.state.permissions.filter(i => i.isSelected).map(i => i.fullyQualifiedName);
    }

    private permissionedClicked(node: OnCheckNode): void
    {
        if (!Permission.isLeaf(node.value))
        {
            this.setState({ selectedPermission: "" });
            return;
        }

        this.setState({ selectedPermission: node.value });

    }

    private get buildingsNodes(): CheckBoxTreeNode[]
    {
        const nodes = this.local.getNodeData().Regions.map(region =>
        ({
            value: region.Node_Id.toString(),
            label: region.Node_Name,
            children: region.Buildings.map(building =>
            ({
                value: building.Node_Id.toString(),
                label: building.Name,
            })),
        }));

        return nodes;
    }

    private get checkedBuildings(): string[]
    {
        return this.state.nodePermissions.filter(i => i.permission == this.state.selectedPermission).map(i => i.nodeId);
    }

    private checkBuilding(checkedBuildings: string[]): void
    {
        const nodePermissions = this.state.nodePermissions.filter(i => i.permission != this.state.selectedPermission);
        const checked = checkedBuildings.map(i => ({ permission: this.state.selectedPermission, nodeId: i }));
        this.setState({ nodePermissions: [...nodePermissions, ...checked] });
    }

    public get payload(): IPermissionsObject
    {
        const permissionsPaylod = this.state.permissions
            .filter(i => i.isSelected && i.area && i.category && i.permission)
            .groupBy(i => i.area)
            .map(area => [
                area.key,
                Object.fromEntries(
                    area.value
                        .groupBy(i => i.category)
                        .map(category => [
                            category.key,
                            category.value.map(i => i.permission)
                        ])
                )
            ]);

        const appliesToPayload = Object.fromEntries(
            this.state.nodePermissions
                .groupBy(i => i.permission)
                .map(permission => [permission.key, permission.value.map(i => i.nodeId)])
        );

        const object = Object.fromEntries([
            ...permissionsPaylod,
            [this.appliesTo, appliesToPayload]
        ]);
        return object;
    }

    public openJsonDialog(): void
    {
        this.jsonDialog?.open(JSON.stringify(this.payload));
    }

    private async jsonDialogOked(json: string): Promise<boolean>
    {
        try
        {
            const jsonAsObject: IPermissionsObject = JSON.parse(json);
            await this.parsePermissions(this.props.availablePermissions, jsonAsObject);
            return true;
        }
        catch
        {
            return false;
        }
    }

    public render(): JSX.Element
    {
        if (!this.props.show)
        {
            return (<></>);
        }

        return (
            <>
                <div className="main-content">
                    {this.props.loading || (
                        <div className="card card-scroller">
                            <form>
                                <Grid container spacing={2} sx={{ pl: 2, mt: 3 }}>
                                    <Grid item xs={6}>
                                        <Box sx={{ pr: 2 }}>
                                            <Typography component="div" sx={{ fontWeight: 'bold', fontSize: '18' }}>
                                                {this.labels.HubLabelUserPermissionCol1}
                                            </Typography>
                                            <Typography color="secondary" component="div" sx={{ mt: 1 }}>
                                                {this.labels.HubLabelUserPermissionDescriptionCol1}
                                            </Typography>
                                            <hr />
                                            <Box sx={{ my: 2 }} className="multicheck-height">
                                                {
                                                    <CheckboxTree
                                                        icons={{
                                                            check: <CheckBoxIcon color="primary" />,
                                                            uncheck: <CheckBoxOutlineBlankIcon />,
                                                            expandClose: <ArrowRightIcon />,
                                                            expandOpen: <ArrowDropDownIcon />,
                                                            halfCheck: <IndeterminateCheckBoxIcon color="primary" />,
                                                        }}
                                                        nodes={this.permissionNodes}
                                                        checked={this.checkedPermissions}
                                                        expanded={this.state.expandedPermissions}
                                                        showNodeIcon={false}
                                                        onCheck={(checked) => this.checkPermission(checked)}
                                                        onExpand={expandedPermissions => this.setState({ expandedPermissions })}
                                                        onClick={node => this.permissionedClicked(node)}
                                                    />
                                                }
                                            </Box>
                                            <hr />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{ pr: 2 }}>
                                            <Typography component="div" sx={{ fontWeight: 'bold', fontSize: '18' }}>
                                                {this.labels.HubLabelUserPermissionCol2}
                                            </Typography>
                                            <Typography color="secondary" component="div" sx={{ mt: 1 }}>
                                                {this.labels.HubLabelUserPermissionDescriptionCol2}
                                            </Typography>
                                            <hr />
                                            {
                                                this.state.selectedPermission &&
                                                <Box sx={{ my: 2 }} className="multicheck-height">
                                                    <CheckboxTree
                                                        icons={{
                                                            check: <CheckBoxIcon color="primary" />,
                                                            uncheck: <CheckBoxOutlineBlankIcon />,
                                                            expandClose: <ArrowRightIcon />,
                                                            expandOpen: <ArrowDropDownIcon />,
                                                            halfCheck: <IndeterminateCheckBoxIcon color="primary" />,
                                                        }}
                                                        nodes={this.buildingsNodes}
                                                        checked={this.checkedBuildings}
                                                        expanded={this.state.expandedBuildings}
                                                        showNodeIcon={false}
                                                        onCheck={checkedBuildings => this.checkBuilding(checkedBuildings)}
                                                        onExpand={expandedBuildings => this.setState({ expandedBuildings })}
                                                    />
                                                    <hr />
                                                </Box>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    )}
                </div>
                <AppPermissionsDialog
                    ref={this.jsonDialogRef}
                    onValidate={json => this.jsonDialogOked(json)}
                />
            </>
        );
    }
}

export interface IProps
{
    show: boolean;
    id: string;
    loading: boolean;
    availablePermissions: IPermissionsObject;
    selectedPermissions: IPermissionsObject;
}

export interface IState
{
    permissions: Permission[];
    expandedPermissions: string[];
    selectedPermission: string;
    expandedBuildings: string[];
    nodePermissions: INodePermission[];
}

export class Permission
{
    public area = "";
    public category = "";
    public permission = "";
    public isAvailable = false;
    public isSelected = false;

    constructor(value: Partial<Permission>)
    {
        Object.assign(this, value);
    }

    public static isLeaf(fullyQualifiedName: string): boolean
    {
        return (fullyQualifiedName.split(".").length >= 3);
    }

    public get fullyQualifiedName(): string
    {
        return [this.area, this.category, this.permission].filter(i => !!i).join(".");
    }
}

export type IPermissionsObject = Record<string, Record<string, string[]>>;

export interface INodePermission
{
    permission: string;
    nodeId: string;
}
