import { Component, SyntheticEvent } from 'react';
import { appContext } from '../../../../AppContext';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import IbssDateTimePicker from '../../../../Components/Inputs/DateTimePicker/IbssDateTimePicker';
import { DateTime } from 'luxon';
import { Box, Card, CardActions, CardContent, Grid, TextField, Typography } from '@mui/material';
import IbssAutocomplete from '../../../../Components/Inputs/AutoComplete/IbssAutocomplete';
import { IUser } from '../../../../Providers.Api/Users/UsersRepository';
import "./NewVisitorWidget.scss"
import Height from '../../../../Components/Icons/SpaceTypeIcons/height';
import apis from '../../../../Providers.Api/apis';
import "./NewVisitorWidget.scss"
import UserPicker, * as UserPickerModule from '../../../../Components/Inputs/UserPicker/UserPicker';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay';

class NewVisitorWidget extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get visitsService() { return appContext().visitsService; }
    private get appState() { return appContext().state; }
    private get local() {return appContext().localStorageProvider;}
    private get apiCache() { return appContext().apiCache;}

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            showModal: false,
            fullname: '',
            company: '',
            email: '',
            invalidVisitorEmail: false,
            hostName: '',
            hostEmail: '',
            pacs: '',
            arrival: DateTime.now().plus({ minutes: 5 }),
            departure: DateTime.now().plus({ minutes: 5 }),
            buildingId: this.appState.buildingId,
            creatingVisitor: false,
            spaceName: '',
            spaceId: '',
        };
    };

    private arrivalChanged(value: Date | null): void
    {
        if (value)
        {
            const arrivalDate = value && DateTime.fromISO(value.toString()).toJSDate();
            if (DateTime.fromJSDate(arrivalDate) > this.state.departure)
            {
                this.setState({ departure: DateTime.fromJSDate(arrivalDate) });
            }
            this.setState({ arrival: DateTime.fromJSDate(arrivalDate) });
        }
    }

    private departureChanged(value: Date | null): void
    {
        if (value)
        {
            const departureDate = value && DateTime.fromISO(value.toString()).toJSDate();
            if (DateTime.fromJSDate(departureDate) < this.state.arrival)
            {
                this.setState({ arrival: DateTime.fromJSDate(departureDate) });
            }
            this.setState({ departure: DateTime.fromJSDate(departureDate) });
        }
    }

    private async handleSelectUser(user: UserPickerModule.IUser | null): Promise<void>
    {
        if (user)
        {
            this.setState({ hostEmail: user.email });
        }
        if (!user)
        {
            this.setState({ hostEmail: '' });
        }
    }

    private visitorEmailChanged(value: string): void
    {
        const pattern = new RegExp(
            /^(("[\w-\s']+")|([\w-']+(?:\.[\w-']+)*)|("[\w-\s']+")([\w-']+(?:\.[\w-']+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );

        this.setState({ invalidVisitorEmail: !pattern.test(value), email: value })

    }

    private async createVisitorRecord(): Promise<void>
    {
        this.setState({ creatingVisitor: true });
        const visitorFullName = this.state.fullname.split(' ');
        const payload = {
            Space_Id: this.state.spaceId,
            Space_Name: this.state.spaceName,
            Visitor_First_Name: visitorFullName[0],
            Visitor_Last_Name: visitorFullName.length > 1 ? visitorFullName[visitorFullName.length - 1] : '',
            Visitor_Email: this.state.email,
            Visitor_Company: this.state.company,
            Visit_Host_Name: this.state.hostName,
            Visit_Host_Email: this.state.hostEmail,
            Visit_Start_Date: this.state.arrival.setZoneByNode(this.state.buildingId, true).toUTC().toString(),
            Visit_End_Date: this.state.departure.setZoneByNode(this.state.buildingId, true).toUTC().toString(),
            Visit_Approval_Comments: "",
            DisableExtUpdate: false,
            Visit_Pacs_Id: this.state.pacs,
            Visitor_Dietary_Pref: 0,
            Visit_Save_Info: 0,
            Disable_Ext_Update: false
        }

        try
        {
            const visitResponse = await this.visitsService.create(this.state.buildingId, payload);
            const buildingData = this.local.getNodeData().Regions.flatMap(i => i.Buildings).find(x => x.Node_Id == this.state.buildingId);
            const visitArrivalWindow = buildingData?.Vis_Arvl_Wndw_Mins;
            const visitReqsApproval = buildingData?.Vis_Req_Approval;
            if(visitReqsApproval == 1 && this.local.hasRight('API.Visits.Approve') && visitResponse.Visit_IsApproved == 0)
            {
                await this.visitsService.approve(this.state.buildingId, [visitResponse.Visit_Id], '');
            }
            if(this.state.arrival.minus({ minutes: visitArrivalWindow }) <= DateTime.now())
            {
                await this.visitsService.checkin(this.state.buildingId, [visitResponse.Visit_Id]);
            }
        } catch (error)
        {
            this.setState({ creatingVisitor: false })
        }
        this.setState({ showModal: false, creatingVisitor: false, fullname: '', company: '', email: '', hostName: '', hostEmail: '', pacs: '' })
        this.props.fetchVisits();
    }

    public async widgetClicked():Promise<void>
    {
        this.setState({
            showModal: true,
            arrival: DateTime.now().plus({ minutes: 5 }),
            departure: DateTime.now().plus({ minutes: 5 }),
        });
        await this.getSpaceDetails(this.state.buildingId);
    }

    private async getSpaceDetails(selectedBuildingId: number):Promise<void>
    {
        try 
        { 
            const visitorArrivalLocation = await apis.getParametersByName(selectedBuildingId);
            const spaceId = visitorArrivalLocation.data.Parameter_Value;
            if(spaceId)
            {
                const spaceList = await this.apiCache.getSpacesByBuilding(selectedBuildingId);
                const space = spaceList.find(x => x.Space_Id === spaceId);
                const spaceName = space ? space.Space_Name : '';
                this.setState({ 
                    spaceName: spaceName,
                    spaceId: spaceId,
                });
            }    
        } 
            catch (error) 
        {
        }
    }

    public render(): JSX.Element
    {
        const allFieldsValid = this.state.fullname.length > 0 && this.state.company.length > 0 && this.state.email.length > 0 && this.state.hostName.length > 0 && !this.state.invalidVisitorEmail

        return (
            <>
                <IbssDialog
                    open={this.state.showModal}
                    onClose={() => this.setState({ showModal: false })}
                    content=
                    {
                        <>
                            {this.state.creatingVisitor && <LoadingOverlay />}
                            <div className='font-sm mb-3'>{this.labels.HubLabelAddAdhocVisitorText}</div>
                            <IbssFormControl fullWidth className='mb-3'>
                                <IbssTextField
                                    value={this.state.fullname}
                                    fullWidth
                                    variant='outlined'
                                    onChange={e => this.setState({ fullname: e.target.value })}
                                    label={this.labels.HubLabelFullName}
                                />
                            </IbssFormControl>
                            <IbssFormControl fullWidth className='mb-3'>
                                <IbssTextField
                                    value={this.state.company}
                                    fullWidth
                                    variant='outlined'
                                    onChange={e => this.setState({ company: e.target.value })}
                                    label={this.labels.HubLabelCompany}
                                />
                            </IbssFormControl>
                            <IbssFormControl fullWidth className='mb-3'>
                                <IbssTextField
                                    value={this.state.email}
                                    error={this.state.invalidVisitorEmail}
                                    helperText={this.state.invalidVisitorEmail ? this.labels.HubLabelInvalidEmailAddress : ''}
                                    fullWidth
                                    variant='outlined'
                                    onChange={e => this.visitorEmailChanged(e.target.value)}
                                    label={this.labels.HubLabelEmail}
                                />
                            </IbssFormControl>
                            <IbssFormControl fullWidth className='mb-4'>
                                <UserPicker
                                    searchText={this.state.hostName}
                                    placeholder={this.labels.HubLabelHostName}
                                    onChange={async text => this.setState({ hostName: text })}
                                    onUserChange={user => this.handleSelectUser(user)}
                                />
                            </IbssFormControl>
                            <IbssFormControl fullWidth className='mb-3'>
                                <IbssTextField
                                    disabled
                                    value={this.state.hostEmail}
                                    fullWidth
                                    variant='outlined'
                                    onChange={e => this.setState({ hostEmail: e.target.value })}
                                    label={this.labels.HubLabelHostNamePlaceholder}
                                />
                            </IbssFormControl>
                            <IbssFormControl fullWidth className='mb-3'>
                                <IbssTextField
                                    value={this.state.pacs}
                                    fullWidth
                                    variant='outlined'
                                    onChange={e => this.setState({ pacs: e.target.value })}
                                    label={this.labels.HubLabelPacs}
                                />
                            </IbssFormControl>
                            <Grid container spacing={2}>
                            <Grid item xs={6}>
                                    <IbssDateTimePicker
                                        label={this.labels.HubLabelArrival}
                                        renderInput={(props) => <TextField {...props} error={false} />}
                                        value={this.state.arrival.toJSDate()}
                                        onChange={newValue => this.arrivalChanged(newValue)}
                                        minDateTime={new Date()}
                                        minutesStep={5}
                                        ampm={false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <IbssDateTimePicker
                                        label={this.labels.HubLabelDeparture}
                                        renderInput={(props) => <TextField {...props} error={false} />}
                                        value={this.state.departure.toJSDate()}
                                        onChange={newValue => this.departureChanged(newValue)}
                                        minDateTime={new Date()}
                                        minutesStep={5}
                                        ampm={false}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }
                    header={this.labels.HubLabelAddVisitor}
                    footer=
                    {
                        <>
                            <IbssButton disabled={!allFieldsValid || this.state.creatingVisitor} onClick={() => this.createVisitorRecord()} variant="contained" sx={{ width: '100px' }}>
                                {this.labels.HubLabelOk}
                            </IbssButton>
                        </>
                    }
                    fullWidth
                />
                <div className="visitors-card">
                    <div className='widget-hover-overlay height-100' onClick={() => this.widgetClicked()} >
                            <Card className='bg-transparent height-100'>
                                <CardContent className='height-70'>
                                    <Box>
                                        <Typography variant="h5" className='visitors-title-white mb-3' gutterBottom>{this.labels.HubLabelAddNewVisitor}</Typography>
                                        <Typography variant="h5" className='visitors-title-white' gutterBottom>{this.labels.HubLabelAddAdhocVisitorText}</Typography>
                                    </Box>
                                </CardContent>
                                <CardActions>
                                    <Box className="visitors-card-footer">
                                        <IbssButton variant="outlined" className='button-xl-white'>
                                           {this.labels.HubLabelAddVisitor}
                                        </IbssButton>
                                    </Box>
                                </CardActions>
                            </Card>
                    </div>
                </div>
            </>
        )
    }
}

export default NewVisitorWidget;

export interface IProps
{
    fetchVisits: Function;
}

export interface IState
{
    showModal: boolean;
    fullname: string;
    company: string;
    email: string;
    invalidVisitorEmail: boolean;
    hostName: string;
    hostEmail: string;
    pacs: string;
    arrival: DateTime;
    departure: DateTime;
    buildingId: number;
    creatingVisitor: boolean;
    spaceName: string;
    spaceId:string;
}
