import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import "./FloorsData.scss";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import { MDBDataTable } from "mdbreact";
import { handleSortTableDataDivWrapProperyArray } from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import TablePanelInfo from "../../../../Components/Headings/TableHeading/TablePanelInfo";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { IPartialAppState, appContext } from "../../../../AppContext";
import { UPDATE_MAIN_PAGE_TITLE } from "../../../../app/constants";

class ListFloorsAndViewFloorPlan extends Component {
  private labels = appContext().labels;
  private appState = appContext().state;
  private session = appContext().sessionStorageProvider;
  state = {
    isLoading: false,
    floorsData: { Name: "" },
    buildingData: { Name: '' },
    facilityDataSort: { columnName: "", direction: "" },
    floorsList: [],
    floorInfo: { Floor_MapURI: '' },
    svg: '',
    selectedBuildingId: this.appState.buildingId.toString(),
  };

  public async componentDidMount() {
    const { history, match, dispatch }: any = this.props;
    this.appState.subscribe(this, i => this.appStateChanged(i));
    const buildingId = match.params["buildingid"];
    await this.appState.set({ buildingId: buildingId });
    dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubLabelSustainabilityAnalyticsFloors });
    if (this.session.getBuildingId() !== match.params.buildingid) {
      const temp = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));
      history.push(temp + "/" + this.session.getBuildingId())
    }
    this.handleGetFloorsData();
  }

  public componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any): void {
    if(prevState.selectedBuildingId != this.state.selectedBuildingId)
    {
      this.handleGetFloorsData();
    }
  }

  private async appStateChanged(state: IPartialAppState): Promise<void>
  {
    const { history, match }: any = this.props;
      if (state.buildingId != undefined)
      {
          this.setState({ selectedBuildingId: state.buildingId.toString() });
          history.push(match.path.replace(":buildingid", state.buildingId));
      }
  }

  handleGetFloorsData = async () => {
    this.setState({ isLoading: true })
    try {
      const response: any = await apis.getFloorData(this.session.getBuildingId())
      const responseData = response.data;
      let StrId: any = this.session.getBuildingId();
      let buildingId = parseInt(StrId);
      let buildingInfo = { Floors: [] }
      responseData && responseData.Regions.forEach((item: any) => {
        item.Buildings.forEach((element: any) => {
          if (element.Node_Id === buildingId) {
            buildingInfo = element;
          }
        });
      })

      const newResponseData = buildingInfo && buildingInfo.Floors.map((item: any) => {
        let newItem = { ...item }

        newItem.Node_Name = <div onClick={() => this.handleClickFloor(item)}>{item.Node_Name}</div>
        newItem.Node_Capacity = <div onClick={() => this.handleClickFloor(item)}>{item.Node_Capacity}</div>
        newItem.Node_Size = <div onClick={() => this.handleClickFloor(item)}>{item.Node_Size}</div>
        newItem.Floor_Id = <div onClick={() => this.handleClickFloor(item)}>{item.Node_Id}</div>
        return newItem
      })

      this.setState({ isLoading: false, floorsData: responseData ? responseData : [] })
      this.setState({ isLoading: false, floorsList: newResponseData ? newResponseData : [], buildingData: buildingInfo })
    } catch (error: any) {
      this.setState({
        isLoading: false,
        spaces: [{ id: 0, name: "" }],
      })
    }
  }

  handleClickFloor = async (item: any) => {

    let svgString: string;
    try {
      let path = item.Floor_MapURI.replace(/^.*?\/images\//, "/");
      if(path == '')
      {
        return;
      }

      let file = await apis.getFile(path);
      svgString = await file.text();
      let new_floorInfo = { ...item }
      new_floorInfo.itm_src = svgString
      this.setState({ floorInfo: new_floorInfo })
    }
    catch
    {
      this.setState({ svg: "" });
      return;
    }

    this.setState({ svg: svgString });
    this.setState({ floorInfo: item })
  }

  handleFacilityDataSort = (e: any) => {
    this.setState({ facilityDataSort: { columnName: e.column, direction: e.direction }, })
    const newArray = handleSortTableDataDivWrapProperyArray(this.state.floorsData, e.column, e.direction)
    this.setState({ floorsData: newArray })
  }
  backToFloors = () => {
    this.setState({ floorInfo: { Floor_MapURL: "" } })
  }
  render() {
    const tableData = {
      columns: [
        {
          label: this.labels.HubLabelName,
          field: "Node_Name",
          sort: this.state.facilityDataSort.columnName == "Node_Name" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelCapacity,
          field: "Node_Capacity",
          sort: this.state.facilityDataSort.columnName == "Node_Capacity" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelSizeText,
          field: "Node_Size",
          sort: this.state.facilityDataSort.columnName == "Node_Size" ? this.state.facilityDataSort.direction : "",
          width: 50
        },
        {
          label: this.labels.HubLabelId,
          field: "Floor_Id",
          sort: this.state.facilityDataSort.columnName == "Floor_Id" ? this.state.facilityDataSort.direction : "",
          width: 50
        },

      ],
      rows: this.state.floorsList ? this.state.floorsList : []
    };

    return (
      <>
            <div className="rightPanel-main-content ">
              {Object.keys(this.state.floorInfo).length <= 1 ? (
                <div className="table-panel">
                  <TablePanelInfo
                    tableHeading={this.labels.HubLabelBuildingText + this.state.buildingData.Name}
                    iconSrc="/images/Sidebar_Icons/Light_theme/Building.svg"
                  />
                  <div className="position-relative">
                    {(this.state.isLoading || this.state.isLoading) && <Spinner />}
                    <MDBDataTable searching={false} className="standardTable tablePageNoButtons sustainability-table" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small onSort={this.handleFacilityDataSort} data={tableData} />
                  </div>
                </div>
              ) : (<div className="table-panel padding-floor">
                <div className="row">
                  <TransformWrapper>
                    <TransformComponent>
                      {/* <img src={this.state.floorInfo.Floor_MapURI} alt="Floor Map" /> */}
                      <div className="floorplan-container" id="floor-img" dangerouslySetInnerHTML={{ __html: this.state.svg }} />

                    </TransformComponent>
                  </TransformWrapper>
                </div>
              </div>
              )}
              {Object.keys(this.state.floorInfo).length > 1 && <div className="row flex-row-reverse mt-3 mb-0">
                <button className="button-primary" onClick={this.backToFloors}>Back</button>
              </div>}
            </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(ListFloorsAndViewFloorPlan);
