import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IRawPagedResponse } from "../Models";
import { Space } from "./SpaceRepository";

export class UpdateV2SpaceByIdEndpoint implements IUpdateV2SpaceByIdEndpoint
{
    public async execute(nodeId: number, spaceId: string, payload: ISpace): Promise<Space>
    {
        try
        {
            const response = await axios.put(`${apis.allApiv2}${nodeId}/spaces/${spaceId}`, payload);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateV2SpaceByIdEndpoint
{
    execute(nodeId: number, spaceId: string, payload: ISpace): Promise<Space>;
}


export interface ISpace {
    Space_Name: string;
    Space_Custom_Info: string;
    Booking_Policy_Id: string;
    ImageURI: string;
    Space_Class: string;
    Space_Type: string;
    Space_Work_Type: string;
    Space_Type_Label: string;
    Space_Capacity: number;
    Meta_Loc_Zone: string;
    Meta_Loc_X_m: number;
    Meta_Loc_Y_m: number;
    Meta_Bookable: number;
    Meta_Dig_Sign_Type: number;
    Meta_Occ_Type: number;
    Meta_Sol_Thld_Min: number;
    Env_Temperature_Range: number;
    Env_Sound_Level_Range: number;
    Space_IsEnabled: number;
    Env_Zone_Id: number;
  }