import { appContext } from '../../../../AppContext';
import IbssPageHeader, { StartOrEnd } from '../../../../Components/Forms/DateRange/IbssPageHeader';
import Spinner from '../../../../Components/Navigation/LoadingSpinner/Spinner';
import IbssFilter from '../../../../Components/Forms/Filter/IbssFilter';
import IbssDataGrid from '../../../../Components/Data/DataGrid/IbssDataGrid';
import { GridColDef, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import { DateTime } from 'luxon';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssActionButton, { IActionButton } from '../../../../Components/Buttons/ActionButton/IbssActionButton';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ApiError } from '../../../../Providers.Api/ApiError';
import { LinearProgress, SelectChangeEvent, Typography } from '@mui/material';
import { BookingFilter2 } from '../../../../Providers.Api/Bookings/BookingRepository';
import { ODataQuery } from '../../../../Providers.Api/ODataQuery';
import { DateHelper } from '../../../../Common/DateHelper';
import { Filter } from '../../../../Providers.Api/Users/UsersRepository';

class ListDeletedUsers extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get apiClient() { return appContext().apiClient; }
    private get services() { return appContext().services; }
    private get hasDeleteRights() { return appContext().localStorageProvider.hasRight("API.Bookings.Delete"); }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            buildingid: this.appState.buildingId,
            loading: false,
            startDate: DateTime.now().startOf('month').minus({ months: 1 }),
            endDate: DateTime.now().startOf('month'),
            dateDisabled: true,
            searchTerm: '',
            disableDelete: true,
            deletedInValue: this.labels.funcOrphanedUsersLastMonth_S,
            tableRowData: [],
            showUserBookingModal: false,
            fetchingUserBookings: false,
            selectedUser: {
                id: '',
                displayName: '',
                email: '',
                deletedAt: DateTime.now()
            },
            userBookingsRowData: [],
            selectedBookingIds: [],
            showCancelProgressModal: false,
            cancelBookingsErrors: [],
            cancelledBookingsCount: 0,
            bookingsDeletingTotal: 0
        };
    };

    public async componentDidMount(): Promise<void>
    {
        this.pageTitle = this.labels.funcOrphanedUsers_M;

        this.getDeletedUsers();
    }

    private async getDeletedUsers(): Promise<void>
    {
        this.setState({ loading: true });
        const filter = new Filter({ deletedAfter: this.state.startDate, deletedBefore: this.state.endDate })
        try
        {
            const deletedUsers = await this.apiClient.users.getDeletedUsers(filter);
            const rowData = deletedUsers.map(x => ({
                id: x.Email,
                displayName: x.DisplayName,
                email: x.Email,
                deletedAt: DateTime.fromISO(x.DeletedAt).offsetTimeByNode(this.state.buildingid, true),
            }))
            this.setState({ loading: false, tableRowData: rowData });
        } catch (error)
        {
            this.setState({ loading: false });
        }
    }

    private filterUsers(searchTerm: string): IDeletedUserView[]
    {
        const filteredTasks = this.state.tableRowData.filter(user =>
        {
            let key: keyof IDeletedUserView;
            for (key in user)
            {
                if (user[key]?.toString().toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
                {
                    return true;
                }
            }
            return false;
        });
        return filteredTasks;
    }

    private async showUserBookingsModal(selectedUser: IDeletedUserView): Promise<void>
    {
        this.setState({ showUserBookingModal: true, fetchingUserBookings: true, selectedUser: selectedUser });

        try
        {
            var query = new ODataQuery
                ({
                    nodeId: this.state.buildingid,
                    top: 100,
                    filter: new BookingFilter2
                        ({
                            bookingOwnerEmail: selectedUser.email,
                            statuses: ['Approved', 'Awaiting Approval']
                        })
                });

            const userBookings = await this.services.bookings.getBookings(
                query,
                selectedUser.deletedAt.setZoneByNode(this.state.buildingid, true),
                undefined
            );
            const bookingsRowData = userBookings.value.map(x =>
            {
                return (
                    {
                        id: x.Booking_Id,
                        spaceName: x.Space_Name,
                        bookingId: x.Booking_Id,
                        bookingName: x.Booking_Name,
                        bookingStart: x.Booking_Start.offsetTimeByNode(this.state.buildingid, true),
                        bookingParties: x.Booking_Parties
                    }
                )
            })
            this.setState({ fetchingUserBookings: false, userBookingsRowData: bookingsRowData });
        } catch (error)
        {
            this.setState({ fetchingUserBookings: false });
        }
    }

    private closeUserBookingsModal(): void
    {
        this.setState({ showUserBookingModal: false, showCancelProgressModal: false, selectedBookingIds: [] })
    }

    private async deleteBookings(): Promise<void>
    {
        this.setState({ showCancelProgressModal: true, cancelledBookingsCount: 0, bookingsDeletingTotal: this.state.selectedBookingIds.length });

        for (let index = 0; index < this.state.selectedBookingIds.length; index++)
        {
            try
            {
                await this.services.bookings.delete(this.state.buildingid, this.state.selectedBookingIds[index].toString(), true);
                this.setState({ cancelledBookingsCount: this.state.cancelledBookingsCount + 1 });
            }
            catch (error)
            {
                const apiError = error as ApiError;
                const errorMessage = (index + 1) + ' / ' + this.state.selectedBookingIds.length + ' - ' + apiError.statusText
                this.setState({ cancelBookingsErrors: [...this.state.cancelBookingsErrors, errorMessage] });
            }
        }

        if (this.state.cancelBookingsErrors.length == 0)
        {
            this.setState({ showUserBookingModal: false, showCancelProgressModal: false });
        }
        this.setState({ selectedBookingIds: [] });
    }

    private deleteRemainingBookings(): void
    {
        this.setState({ showCancelProgressModal: false, showUserBookingModal: false });

        for (
            let index = this.state.cancelBookingsErrors.length + this.state.cancelledBookingsCount + 1;
            index < this.state.selectedBookingIds.length;
            index++)
        {
            this.services.bookings.delete(this.state.buildingid, this.state.selectedBookingIds[index].toString());
        }
        this.setState({ selectedBookingIds: [], cancelBookingsErrors: [] });
    }

    private async dateRangeDropdownChanged(selectedOption: SelectChangeEvent<string>): Promise<void>
    {
        this.setState({ deletedInValue: selectedOption.target.value });

        if (selectedOption.target.value === this.labels.HubLabelThisMonth)
        {
            await this.setState(
                {
                    startDate: DateHelper.now().startOf('month'),
                    endDate: DateHelper.now().startOf('month').plus({ months: 1 }),
                    dateDisabled: true,
                });
        }
        if (selectedOption.target.value === this.labels.funcOrphanedUsersLastMonth_S)
        {
            await this.setState(
                {
                    startDate: DateHelper.now().startOf('month').minus({ months: 1 }),
                    endDate: DateHelper.now().startOf('month'),
                    dateDisabled: true,
                });
        }
        if (selectedOption.target.value === this.labels.HubLabelCustom)
        {
            await this.setState({ dateDisabled: false });
        }

        this.getDeletedUsers();
    }

    private async startDateChanged(date: DateTime): Promise<void>
    {
        await this.setStateAsync({ startDate: date });
        this.getDeletedUsers();
    }

    private async endDateChanged(date: DateTime): Promise<void>
    {
        await this.setStateAsync({ endDate: date });
        this.getDeletedUsers();
    }

    public render(): JSX.Element
    {

        const dataGridColumns: GridColDef[] =
            [
                {
                    headerName: this.labels.HubLabelName,
                    field: "displayName",
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.funcOrphanedUsersFutureBookings_S,
                    field: "FutureBooking",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => <div onClick={() => this.showUserBookingsModal(params.row)} style={{ textDecoration: 'underline', color: '#3f51b5c4', cursor: 'pointer' }}>{this.labels.funcOrphanedUsersView_S}</div>
                },
                {
                    headerName: this.labels.HubLabelDeletedAt,
                    field: "deletedAt",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => params.row.deletedAt.toLocaleString(DateTime.DATE_SHORT)
                },
            ];

        const userBookingsDataGridColumns: GridColDef[] =
            [
                {
                    headerName: this.labels.HubLabelName,
                    field: "bookingName",
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelDate,
                    field: "bookingStart",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => params.row.bookingStart.toLocaleString(DateTime.DATE_SHORT)
                },
                {
                    headerName: this.labels.HubLabelSpaceName,
                    field: "spaceName",
                    minWidth: 150,
                    flex: 1
                },
                {
                    headerName: this.labels.HubLabelAttendees,
                    field: "attendees",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => params.row.bookingParties.filter((x: { Booking_Participant_Type: number; }) => x.Booking_Participant_Type == 1 || x.Booking_Participant_Type == 2).length == 0 ? '-' : params.row.bookingParties.length
                },
            ];

        // Action buttons
        const actionButtons: IActionButton[] =
            [
                {
                    label: this.labels.HubButtonDelete,
                    icon: (
                        <IbssSvgIcon>
                            <DeleteOutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => this.deleteBookings(),
                    disabled: !this.hasDeleteRights || this.state.selectedBookingIds.length < 1
                },
            ];

        const filteredRowData = this.filterUsers(this.state.searchTerm);

        const cancelProgressValue = (this.state.cancelledBookingsCount + this.state.cancelBookingsErrors.length) / this.state.selectedBookingIds.length * 100;

        return (
            <>
                <div className="page-container">
                    <div className="rightPanel">
                        <div className="rightPanel-main-content">
                            <div className="table-panel">
                                <div className='heading'>
                                    {this.labels.HubMenuUsers}
                                </div>
                                <IbssPageHeader
                                    pageTitle={this.labels.funcOrphanedUsers_S}
                                    daysFilterLabel={this.labels.funcOrphanedUsersDeletedIn_S}
                                    daysFilter={this.state.deletedInValue}
                                    daysFilterExcludeToday={true}
                                    todayChanged={(e) => this.dateRangeDropdownChanged(e)}
                                    startDate={this.state.startDate.toJSDate()}
                                    endDate={this.state.endDate.toJSDate()}
                                    dateDisabled={this.state.dateDisabled}
                                    onStartDateChange={date => this.startDateChanged(date)}
                                    onEndDateChange={date => this.endDateChanged(date)}
                                    additionalDateFilterOptions={[this.labels.funcOrphanedUsersLastMonth_S, this.labels.HubLabelThisMonth]}
                                />
                                <IbssFilter
                                    searchTerm={this.state.searchTerm}
                                    searchTermChanged={(event) => this.setState({ searchTerm: event.target.value })}
                                />
                                <div className='mt-1 mb-5'>
                                    <Typography className="table-panel-description">{this.labels.funcOrphanedUsersRecords_Message}</Typography>
                                </div>
                                <div>
                                    <IbssDataGrid
                                        columns={dataGridColumns}
                                        disableRowSelectionOnClick
                                        rows={filteredRowData}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 25 } },
                                        }}
                                        pageSizeOptions={[25, 50, 100]}
                                        loading={this.state.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.showUserBookingModal &&
                        <IbssDialog
                            maxWidth='xl'
                            fullWidth
                            open={this.state.showUserBookingModal}
                            header={this.state.selectedUser.displayName + ' - ' + this.labels.HubLabelBookings}
                            onClose={() => this.closeUserBookingsModal()}
                            content={
                                <>
                                    {
                                        this.state.fetchingUserBookings ?
                                            <div style={{ height: '500px' }}>
                                                <Spinner />
                                            </div>
                                            :
                                            <div>
                                                {
                                                    this.state.userBookingsRowData.length > 0 ?
                                                        <div>
                                                            <div style={{ marginTop: '-5px' }}>{this.labels.funcOrphanedUsersBookingsInSystem_M}</div>
                                                            <IbssActionButton buttons={actionButtons} />
                                                            <IbssDataGrid
                                                                height="calc(100vh - 420px)"
                                                                checkboxSelection
                                                                columns={userBookingsDataGridColumns}
                                                                disableRowSelectionOnClick
                                                                rows={this.state.userBookingsRowData}
                                                                onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel) => this.setState({ selectedBookingIds: rowSelectionModel })}
                                                                rowSelectionModel={this.state.selectedBookingIds}
                                                                initialState={{
                                                                    pagination: { paginationModel: { pageSize: 25 } },
                                                                }}
                                                                pageSizeOptions={[25, 50, 100]}
                                                            />
                                                        </div>
                                                        :
                                                        <div style={{ textAlign: 'center' }}>
                                                            <img className="mt-2" alt="NoSpacesImage" src="/images/NoSpaceSearchReults.svg" />
                                                            <div className="noResultsHeading">{this.labels.funcOrphanedUsersNoBookingsFound_M}</div>
                                                        </div>
                                                }
                                            </div>
                                    }
                                </>
                            }
                            footer=
                            {
                                <div className="d-flex justify-content-center">
                                    <IbssButton variant="contained" color="secondary" className="mr-2 modal-button" onClick={() => this.closeUserBookingsModal()}>{this.labels.HubLabelClose}</IbssButton>
                                </div>
                            }
                        />
                    }
                    {
                        this.state.showUserBookingModal &&
                        <IbssDialog
                            maxWidth='md'
                            fullWidth
                            open={this.state.showCancelProgressModal}
                            header={this.labels.funcOrphanedUsersDeletedBookings_S}
                            onClose={() => this.deleteRemainingBookings()}
                            content={
                                <>
                                    <div>{this.labels.funcOrphanedUsersDeleteInProgress_Message}</div>
                                    <div className="mb-4 mt-4" style={{ textAlign: 'center' }}>
                                        {this.state.cancelledBookingsCount} / {this.state.bookingsDeletingTotal} {this.labels.funcOrphanedUsersDeleted_S}
                                        <LinearProgress sx={{ marginTop: '5px' }} variant="determinate" value={cancelProgressValue} />
                                    </div>
                                    {
                                        this.state.cancelBookingsErrors.length > 0 &&
                                        <div className='mb-1'>{this.labels.funcOrphanedUsersDeleteError_Message}</div>
                                    }
                                    {
                                        this.state.cancelBookingsErrors.map(error =>
                                        {
                                            return (
                                                <div className="text-danger mb-1">{error}</div>
                                            )
                                        })
                                    }
                                </>
                            }
                            footer=
                            {
                                <div className="d-flex justify-content-center">
                                    <IbssButton variant="contained" color="secondary" className="mr-2 modal-button" onClick={() => this.deleteRemainingBookings()}>{this.labels.HubLabelClose}</IbssButton>
                                </div>
                            }
                        />
                    }
                </div>
            </>
        )
    }
}

export default ListDeletedUsers;

export interface IProps
{
}

export interface IState
{
    buildingid: number;
    loading: boolean;
    startDate: DateTime;
    endDate: DateTime;
    dateDisabled: boolean;
    searchTerm: string;
    disableDelete: boolean;
    deletedInValue: string;
    showUserBookingModal: boolean;
    fetchingUserBookings: boolean;
    selectedUser: IDeletedUserView;
    userBookingsRowData: IBooking[];
    tableRowData: IDeletedUserView[];
    selectedBookingIds: GridRowId[];
    showCancelProgressModal: boolean;
    cancelBookingsErrors: string[];
    cancelledBookingsCount: number;
    bookingsDeletingTotal: number;
}

export interface IDeletedUserView
{
    id: string
    displayName: string,
    email: string,
    deletedAt: DateTime,
}

export interface IBooking
{
    spaceName: string;
    bookingId: string;
    bookingName: string;
    bookingStart: DateTime;
    bookingParties: string;
}